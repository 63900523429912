import React from 'react';
import {DataRow, Icon} from '../../ui-components';
import {Modal} from '../../ui-components/modals';
import {downloadIcon} from '../../assets/icons';

const pillStyle = 'shrink-0 bg-highlighted-50 rounded-[50px] py-spona-4 px-spona-8 mt-spona-4 mr-spona-4 font-semibold text-highlighted-100';

const UserQuestionnaireModal = props => {
    const {closeModal, data} = props;

    return (
        <Modal
            hasBackdrop={true}
            hasCloseButton={true}
            closeModal={closeModal}
            title="User questionnaire data"
            contentClassName="max-h-[800px] overflow-auto"
        >
            <div className="flex flex-col items-center justify-center">
                <div className="mb-spona-24">
                    <DataRow label="Q1: Industries">
                        {data['q1'] && Array.isArray(data['q1']) ? data['q1'].join(', ') : ''}
                    </DataRow>
                    <DataRow label="Q2: Company names">
                        {data['q2']  || ''}
                    </DataRow>
                    <DataRow label="Q3: Target audience locations">
                        {data['q3'] && Array.isArray(data['q3']) ? data['q3'].join(', ') : ''}
                    </DataRow>
                    <DataRow label="Q4: Revenue">
                        <div className="flex overflow-auto py-spona-4">
                            {data['q4'] && Array.isArray(data['q3']) && (
                                data['q4'].map(revenue => (
                                    <div className={pillStyle} key={revenue}>
                                        {revenue}
                                    </div>
                                ))
                            )}
                        </div>
                    </DataRow>
                    <DataRow label="Q5: Employee sizes">
                        <div className="flex py-spona-4">
                            {data['q5'] && Array.isArray(data['q5']) && (
                                data['q5'].map(employees => (
                                    <div className={pillStyle} key={employees}>
                                        {employees}
                                    </div>
                                ))
                            )}
                        </div>
                    </DataRow>
                    <DataRow label="Q6: Job titles">
                        {data['q6'] && Array.isArray(data['q6']) ? data['q6'].join(', ') : ''}
                    </DataRow>
                    <DataRow label="Q7: Target profile description">
                        {data['q7'] || ''}
                    </DataRow>
                    <DataRow label="Q8: File with companies">
                        {data['q8'] && (
                            <a href={data['q8']} className="cursor-pointer text-highlighted-100 font-semibold flex items-center">
                                <Icon icon={downloadIcon} className='mr-spona-4'/>
                            </a>
                        )}
                    </DataRow>
                    <DataRow label="Q9: No of leads per company">
                        {data['q9'] || ''}
                    </DataRow>
                </div>
            </div>
        </Modal>
    )
}

export default UserQuestionnaireModal;