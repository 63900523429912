import React from 'react';
import {HighlightedText} from '../../../ui-components';

const ThirdPartyIntegrationsContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                SPONA is designed to seamlessly integrate with leading third-party applications, enhancing your lead generation and email outreach capabilities.
                Understanding these integrations can help you maximize the efficiency and effectiveness of your campaigns.<br/>
                Here’s an overview of our key integrations:
            </p>
            <div className="ml-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Quickmail Integration:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Purpose:</span> Quickmail is a premier Swiss cold email outreach provider.
                            Our integration with Quickmail is central to our appointment setting service, as we are able to use latest technical solutions in cold email automation and resemble human behavior.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Functionality:</span> This integration allows for sophisticated, automated email sequences, enabling effective and personalized communication with your leads.
                            You can also track performance of your appointment setting efforts in real time and get notifications every time there is a new sales call in your calendar.
                        </li>
                    </ul>
                    <li>Inbox Access:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Secure Access:</span> When you use these services, you grant SPONA access to your inbox.
                            This access is managed securely, ensuring your data privacy and security are always upheld.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className='font-medium text-default-100 text-lg my-spona-24'>
                If you have any questions about these integrations or need assistance
                in setting them up, our support team is readily available to help.
            </p>
            <HighlightedText className='mt-spona-24'>
                Let SPONA, along with our trusted partners, drive your lead generation success.
            </HighlightedText>
        </div>
    )
}

export default ThirdPartyIntegrationsContent;