import React, {useContext, useState, useMemo, Children, useEffect, useRef} from 'react';
import {Icon} from '../icons';
import {dropdownArrowIcon} from '../../assets/icons';
import DropdownContext from './DropdownContext';
import withDropdownProvider from './withDropdownProvider';
import DropdownList from './DropdownList';
//min-w-[264px]
const defaultInputStyles = "appearance-none " +
    "bg-contrast-100 w-full " +
    "h-[50px] " +
    "justify-center " +
    "items-center " +
    "self-stretch " +
    "text-input-100 " +
    "font-medium border border-contrast-600 " +
    "p-spona-12 " +
    "rounded-[10px] " +
    'text-lg ' +
    ':focus outline-none'

const SelectField = props => {
    const {value, onChange, name, isDisabled, label, children} = props;
    const {openDropDown, closeDropDown, toggleDropDown, isOpen, dropDownRef} = useContext(DropdownContext);

    const selectorRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    useMemo(() => setSearchTerm(value), [value]);

    const areChildrenSet = !!children && Array.isArray(children) && children.length > 0;

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (selectorRef.current && !selectorRef.current.contains(e.target)) {
                closeDropDown();
            }
        }

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    });

    const handleIconOnClick = () => {
        if (!isDisabled) {
            toggleDropDown();
        }
    };

    const getValueContent = value => {
        if (!value) return '';

        let valueContent = value;
        Children.forEach(children, child => {
            if (child.props.value === value) {
                valueContent = child.props?.children;
            }
        });
        return valueContent;
    }

    const handleListOnChange = value => {
        if (!value) return;
        closeDropDown();
        onChange(value);
        setSearchTerm(value);
    };

    const onFocusHandler = () => {
        if (areChildrenSet) {
            openDropDown();
        }
    };

    return (
        <div ref={selectorRef} className="mb-spona-24">
            <div className={`w-full h-full border-contrast-600`}>
                <div className="relative">
                    <label className="block text-xl font-semibold text-default-100 self-start mb-spona-12" htmlFor={name}>
                        {label}
                    </label>
                    <input
                        className={`${defaultInputStyles}`}
                        name={name}
                        value={getValueContent(searchTerm) || searchTerm}
                        type="text"
                        onChange={setSearchTerm}
                        onFocus={onFocusHandler}
                    />
                    <div className='absolute top-[42px] right-spona-4'>
                        <Icon
                            icon={dropdownArrowIcon}
                            className={`p-spona-12 transition-[transform] ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                            onClick={handleIconOnClick}
                        />
                    </div>
                </div>
            </div>
            {isOpen && (
                <div ref={dropDownRef} className="z-[2000] fixed max-h-[160px] min-w-[314px] bg-contrast-100 overflow-y-auto font-bold text-input-100 text-xs mt-spona-4 rounded-[10px] border border-contrast-600">
                    <DropdownList onClick={handleListOnChange}>
                        {children}
                    </DropdownList>
                </div>
            )}
        </div>
    )
}

export default withDropdownProvider(SelectField);