
export default {
    APP_ENV: process.env.REACT_APP_ENV,
    DEV_BASE_URL: process.env.REACT_APP_DEV_BASE_URL,
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    // constants
    STATUS_CODE_404: 404,
    STATUS_CODE_403: 403,
    CARD_NUMBER_MAX_LENGTH: 19,
    CURRENCY: process.env.CURRENCY || 'USD',
    RALEWAY_FONT_URL: 'https://fonts.googleapis.com/css?family=Raleway',
    QUESTIONNAIRE_ONE_FOLDER: 'questioner-one-user-contacts',
    QUESTIONNAIRE_ONE_LEADS_FOLDER: 'questioner-one-leads',
    QN1_NO_OF_QUESTIONS: 9,
    CHATBOT_MESSAGE_LIMIT: 3,
    MINIMUM_SEARCH_QUERY_LENGTH: 3,
    MAXIMUM_TEXT_AREA_CONTENT_LENGTH: 200,
    INITIAL_PAGE_SIZE: 1,
    DEFAULT_LIMIT_SIZE: 25,
    DEMO_YOUTUBE_LINK: 'https://youtu.be/Ot4e6VLJkzg',
    
}