import React, {Children} from 'react';
import './SVGIcon.css';

const SVGIcon = ({ children, size, color, className }) => {

    const enhancedChildren = Children.map(children, child => {
        if (!child || typeof child === 'string' || typeof child.type === 'string') return child;

        return React.cloneElement(child, {
            color: color,
            size: size,
        });
    });

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill={color}
            className={className}
            // className="hover:fill-highlighted-100"
            // className="svg-icon"
        >
            {enhancedChildren}
        </svg>
    );
};

export default SVGIcon;