import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {sentenceCase} from 'change-case';
import {Button, Form, Input, FileDropZone, Option, SelectField} from '../../ui-components';
import {Modal} from '../../ui-components/modals';
import {outstandingThresholdTypes} from '../../entites';
import {updateQuestionnaireOneData} from '../adminActions';
import {uploadFile, deleteFile} from '../../file-assets/fileAssetsActions';
import config from '../../config';
import questionnaireOneStatusTypes from '../../entites/questionnaire/questionnaireOneStatusTypes';

const EditLeadDataModal = props => {
    const {closeModal, data} = props;
    const [leadsData, setLeadsData] = useState({
        name: data.name || '',
        outstanding: data.outstanding || '',
        status: data.status || '',
        extracted: data.extracted || '',
        remaining: data.remaining || '',
    });
    const [file, setFile] = useState();
    const dispatch = useDispatch();
    const statuses = [
        sentenceCase(questionnaireOneStatusTypes.READY),
        sentenceCase(questionnaireOneStatusTypes.NO_MORE_LEADS),
    ];

    const handleOnChange = (key, value) => {
        setLeadsData({
            ...leadsData,
            [key]: value,
        })
    }

    const handleOnEditData = async () => {
        let payloadData = {
            ...leadsData,
            ...{ file: Array.isArray(file) && !file?.length ? null : file }
        };

        try {
            if (!!file && !!file[0] && file[0] instanceof File) {
                if (!!data.file) await dispatch(deleteFile({ file: data.file })).unwrap();
                const fileLink = await dispatch(uploadFile({
                    file: file[0],
                    folder: config.QUESTIONNAIRE_ONE_LEADS_FOLDER
                })).unwrap();

                payloadData = {
                    ...leadsData,
                    file: fileLink,
                }
            }
        } catch (e) {
            // no-op
        }

        if (!payloadData || !data._id) return;

        try {
            await dispatch(updateQuestionnaireOneData({
                questionnaireId: data._id,
                payload: payloadData,
            })).unwrap();
        } catch (e) {
            // no-op
        }

        closeModal();
    };

    return (
        <Modal
            hasBackdrop={true}
            hasCloseButton={true}
            closeModal={closeModal}
            title="Edit customer leads data"
            contentClassName="w-[800px]"
        >
            <div>
                <Form>
                    <div className="grid gap-spona-32 grid-cols-12">
                        <div className="col-span-7 w-full">
                            <Input
                                name="name"
                                label="Name"
                                className='w-full bg-contrast-500'
                                value={leadsData['name'] || ''}
                                onChange={value => handleOnChange('name', value)}
                            />
                            <Input
                                name="extracted"
                                label="Extracted"
                                className='w-full bg-contrast-500'
                                value={leadsData['extracted'] || ''}
                                onChange={value => handleOnChange('extracted', value)}
                            />
                            <Input
                                name="remaining"
                                label="Remaining"
                                className='w-full bg-contrast-500'
                                value={leadsData['remaining'] || ''}
                                onChange={value => handleOnChange('remaining', value)}
                            />
                        </div>
                        <div className="col-span-5 w-full">
                            <SelectField
                                name='status'
                                value={leadsData['status'] || ''}
                                label="Status"
                                onChange={value => handleOnChange('status', value)}
                            >
                                {statuses.map(status => (
                                    <Option key={status} value={status}>
                                        {status}
                                    </Option>
                                ))}
                            </SelectField>
                            <SelectField
                                name='outstanding'
                                value={leadsData['outstanding'] || ''}
                                label="Outstanding"
                                onChange={value => handleOnChange('outstanding', value)}
                            >
                                {Object.values(outstandingThresholdTypes).map(outstanding => (
                                    <Option key={outstanding} value={outstanding}>
                                        {outstanding === outstandingThresholdTypes.S ? '< 500': outstanding}
                                    </Option>
                                ))}
                            </SelectField>
                        </div>
                    </div>
                </Form>
                <div className="w-full mb-spona-24 mt-spona-32">
                    <h3 className="block text-xl font-semibold text-default-100 self-start mb-spona-12">File</h3>
                        <div className="max-h-[130px] overflow-auto">
                            <FileDropZone
                            value={file}
                            onChange={setFile}
                            maxNoOfUploadedFiles={1}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-x-spona-12 w-full">
                    <Button buttonType='basic' className='col-span-6' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button buttonType='primary' className='col-span-6' onClick={handleOnEditData}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default EditLeadDataModal;