import React, { useState, useEffect } from 'react';
import { useScreenResolution } from '../../utils/customHooks';

const Image = props => {
    const {src, alt, height, width, isResponsive} = props;
    const {isDesktopScreen} = useScreenResolution();
    const [calculatedHeight, setCalculatedHeight] = useState(height);
    const [calculatedWidth, setCalculatedWidth] = useState(width);

    useEffect(() => {
        if (!isResponsive || !height || !width) return;
        setCalculatedWidth(!isDesktopScreen
            ? Math.round(window.innerWidth - (window.innerWidth * 0.2))
            : width)
        setCalculatedHeight(!isDesktopScreen
            ? Math.round((height / width) * Math.round(window.innerWidth - (window.innerWidth * 0.2)))
            : height)
    }, [isDesktopScreen, isResponsive, height, width])

    return (
        <div className={`h-[${calculatedHeight}px] w-[${calculatedWidth}px]`}>
            <img loading="lazy" src={src} alt={alt} className="h-full w-full" />
        </div>
    )
}

export default Image;