import React from 'react';

const Avatar = props => {
    const {src, alt, onClick, initials} = props;

    return (
        <div
            className={`inline-flex align-center justify-center overflow-hidden 
                border-solid border-highlighted-100 border-4 rounded-[50%] 
                w-spona-64 h-spona-64 ${onClick ? 'cursor-pointer' : ''}`}
            onClick={onClick}
        >
            <img
                className="w-full h-full object-cover"
                src={src}
                alt={alt}
            />
            {initials && <span>{initials}</span>}
        </div>
    )
}

export default Avatar;