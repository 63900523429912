import React from 'react';

const ProgressBar = props => {
    const {currentProgress} = props;

    return (
        <div className="w-[194px]">
            <div className="h-[6px] bg-input-300 rounded-[50px] w-full">
                <div style={{width: `${currentProgress}%`}} className='transition-[width] duration-700 ease-in-out relative flex items-center h-full rounded-inherit bg-highlighted-100 rounded-[50px]'>
                    <div className="absolute right-[-7px]">
                        <div className="flex items-center justify-center rounded-full h-[26px] w-[26px] bg-[#33BD9C66]">
                            <div className="rounded-full h-spona-14 w-spona-14 bg-highlighted-200" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-between mt-spona-8 font-semibold text-sm text-default-100">
                <span>{`${currentProgress}%`}</span>
                <span>100%</span>
            </div>
        </div>

    )
}

export default ProgressBar;