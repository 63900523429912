import React from 'react';

const Option = props => {
    const {children, onOptionSelect, value, activeOptions} = props;
    const isActive = activeOptions && activeOptions.includes(value);

    return (
        <li
            className={`p-spona-12 whitespace-nowrap hover:underline ${isActive ? 'underline' : ''}`}
            onClick={onOptionSelect} value={value}
        >
            {children}
        </li>
    )
}

export default Option;