import React from 'react';
import {Icon} from '../../ui-components';
import {checkmarkRounded} from '../../assets/icons';

const styles = {
    title: "text-default-100 text-[24px] md:text-[32px] font-bold",
    description: "text-default-100 text-base md:text-lg mt-spona-8",
}

const PricingList = props => {
    const {listItems} = props;
    const listItemsArray = Object.values(listItems);

    if (!listItemsArray) return null;

    return (
        <ul className="flex flex-col lg:items-center lg:justify-center md:grid grid-cols-12 gap-y-spona-32 gap-x-spona-64">
            {listItemsArray.map((listItem, idx) => (
                <li key={`${listItem.title}-${idx}`} className='col-span-6 max-w-[480px]'>
                    <div>
                        <div className="flex items-center">
                            <Icon icon={checkmarkRounded} size='s' className="shrink-0 mr-spona-14" />
                            <p className={styles[`${listItem.title ? 'title' : 'description'}`]}>
                                {listItem.title || listItem.description}
                            </p>
                        </div>
                        {listItem.title && <p className={styles['description']}>{listItem.description}</p>}
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default PricingList;