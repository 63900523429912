import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from '../../ui-components/modals';
import {checkUserPassword} from '../authActions';
import { selectCurrentUser } from '../authSlice';
import EnterPasswordContent from '../ui-elements/EnterPasswordContent';
import DeleteAccountConfirmationContent from '../ui-elements/DeleteAccountConfirmationContent';

const initialPasswordErrorConfig = {
    isFromServer: false,
    errorMessage: '',
}

const DeleteAccountModal = props => {
    const {closeModal} = props;

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState({...initialPasswordErrorConfig});
    const [isAccountVerified, setIsAccountVerified] = useState(false);

    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        setPasswordError({...initialPasswordErrorConfig});
    }, [password])

    const handleOnClick = async () => {
        if (!currentUser) return;
        try {
            await dispatch(checkUserPassword({password})).unwrap();
            setIsAccountVerified(true);
        } catch (e) {
            setIsAccountVerified(false);
            const errorMessage = e.payload;

            if (!errorMessage) return;

            if (errorMessage === 'Wrong email/password') {
                setPasswordError({
                    isFromServer: true,
                    errorMessage: 'Wrong password',
                });
            }
        }
    };

    return (
        <Modal hasBackdrop={true} title={isAccountVerified ? '' : 'Enter password'} closeModal={closeModal} >
            {isAccountVerified
                ? <DeleteAccountConfirmationContent
                    closeModal={closeModal}
                    setIsAccountVerified={setIsAccountVerified}
                />
                : <EnterPasswordContent
                    closeModal={closeModal}
                    handleOnClick={handleOnClick}
                    passwordError={passwordError}
                    setPasswordError={setPasswordError}
                    password={password}
                    setPassword={setPassword}
                />
            }
        </Modal>
    )
};

export default DeleteAccountModal;