import { createSlice } from "@reduxjs/toolkit";
import { createQuestionnaireOne, getQuestionById, getQuestionnaireUserTable } from './questionnaireActions';
import questionnaireOneStatusTypes from '../entites/questionnaire/questionnaireOneStatusTypes';

const questionnaireOneSlice = createSlice({
    name: 'questionnaireOneSlice',
    initialState: {
        questionnaireOneId: '',
        questionData: null,
        userQuestionnaireData: [],
        isFillingQuestionnaireAllowed: false,
        incompleteQuestionnaire: null,
        notification: null,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createQuestionnaireOne.fulfilled, (state, { payload }) => {
            state.questionnaireOneId = payload._id;
        });
        builder.addCase(getQuestionById.fulfilled, (state, { payload }) => {
            state.questionData = payload;
        });
        builder.addCase(getQuestionnaireUserTable.fulfilled, (state, { payload }) => {
            const {data, fillQuestioner, notification} = payload;
            state.userQuestionnaireData = data;
            state.notification = notification;
            state.isFillingQuestionnaireAllowed = fillQuestioner;
            state.incompleteQuestionnaire = data[data.length - 1]?.status.toUpperCase() === questionnaireOneStatusTypes.INCOMPLETE ? data[data.length - 1] : null;
        });
    },
});

// actions
export default questionnaireOneSlice.reducer;

// selectors
export const selectQuestionnaireId = (state) => state.questionnaireOne.questionnaireOneId;
export const selectQuestionData = (state) => state.questionnaireOne.questionData;
export const selectUserQuestionnaireData = (state) => state.questionnaireOne.userQuestionnaireData;
export const selectIsFillingQuestionnaireAllowed = (state) => state.questionnaireOne.isFillingQuestionnaireAllowed;
export const selectIncompleteQuestionnaire = (state) => state.questionnaireOne.incompleteQuestionnaire;
export const selectNotification = (state) => state.questionnaireOne.notification;
