import {davidImage, elementachemImage, etheanImage, liamImage, auditaceImage, steelsynthImage, thompsonImage, gbdImage} from '../../assets/images';

const testimonialsConfig = [
    {
        id: 1,
        title: "Maximum precision",
        highlightedText: "“97% of leads matched our ideal customer profile.”",
        text: "Improve quality, you automatically improve productivity.",
        name: "Ethean Parker",
        jobTitle: "VP of Sales",
        image: etheanImage,
        logo: elementachemImage,
        height: 'h-[40px]',
    },
    {
        id: 2,
        title: "Reliability & Speed",
        highlightedText: "“SPONA never fails to deliver<br/>within 2 days.”",
        text: "Speed is the ultimate weapon in business.",
        name: "Michael Thompson",
        jobTitle: "Sales Director",
        image: thompsonImage,
        logo: steelsynthImage,
        height: 'h-[40px] shadow-[4px_4px_6px_0px_rgba(26,24,24,0.15)]',
    },
    {
        id: 3,
        title: "User-friendliness",
        highlightedText: "“We effortlessly book 5 demos weekly thanks to SPONA.”",
        text: "Simplicity is the key to brilliance.",
        name: "Liam Benett",
        jobTitle: "Head of Sales",
        image: liamImage,
        logo: auditaceImage,
        height: 'h-[40px]',
    },
    {
        id: 4,
        title: "Efficiency",
        highlightedText: "“Cost-benefit of SPONA leads is tough to match.”",
        text: "Doing better what is already being done.",
        name: "David Clarkson",
        jobTitle: "Head of Business Development",
        image: davidImage,
        logo: gbdImage,
        height: 'h-[43px] shadow-[4px_4px_6px_0px_rgba(26,24,24,0.15)]',
    }
]

export default testimonialsConfig;