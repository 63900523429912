import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { getProspectList } from './customMadeProspectListsActions';
import NonAuthenticatedUserContent from './ui-elements/NonAuthenticatedUserContent';
import {selectCurrentUser, selectLoadingCurrentUser} from '../authentification/authSlice';
import {LoaderSpinner} from '../ui-components';
import {getCurrentUser} from '../authentification/authActions';
import QuestionnaireUserDashboard from '../questionnaire-one/QuestionnaireUserDashboard';
import {getQuestionnaireUserTable} from '../questionnaire-one/questionnaireActions';
import {useScreenResolution} from '../utils/customHooks';
import DesktopMessageContent from '../ui-components/general/DesktopMesageContent';

const CustomMadeProspectListsScreen = () => {
    const didComponentMountRef = useRef(false);

    const isLoading = useSelector(selectLoadingCurrentUser);
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const {isDesktopScreen} = useScreenResolution();

    useEffect( () => {
        if (!didComponentMountRef.current) {
            const getCustomMadeProspectList = async () => {
                try {
                    const currentUser = await dispatch(getCurrentUser()).unwrap();
                    if (!currentUser) return;
                    await dispatch(getProspectList()).unwrap();
                    await dispatch(getQuestionnaireUserTable()).unwrap();
                }
                catch (error) {
                   // Todo: Handle error
                }
            }
            getCustomMadeProspectList();
            didComponentMountRef.current = true;
        }
    });

    return isLoading
        ? <div className="flex items-center justify-center h-screen"><LoaderSpinner /></div>
        : (currentUser ? (!isDesktopScreen
            ? <DesktopMessageContent />
            : <QuestionnaireUserDashboard />
        ) : <NonAuthenticatedUserContent />)
}

export default CustomMadeProspectListsScreen;