import React from 'react';

const Panel = props => {
    const {title, children, className, onClick, contentClassName} = props;

    return (
        <div
            className={`${className ? className : ''} z-150 rounded-[10px] bg-contrast-100 shadow-[2px_2px_20px_0px_rgba(26,24,24,0.10)] px-spona-14 py-spona-12 flex flex-col flex-nowrap`}
            onClick={onClick}
        >
            {title && (
                <div className="text-2xl font-semibold mb-spona-14">{title}</div>
            )}
            <div className={`${contentClassName ? contentClassName : ''} text-default-100 text-lg`}>
                {children}
            </div>
        </div>
    )
}

export default Panel;