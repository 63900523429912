import React from 'react';
import {HighlightedText} from '../../../ui-components';

const HandlingTSRequestsContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                We are committed to providing timely and effective support to all our customers.
                We have a structured approach to handling and prioritizing support requests, ensuring that each query is addressed by the right expert and in an orderly manner.<br/>
                Here's how we manage our support process:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Specialized Support Teams:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <HighlightedText className="w-full ml-[-24px] mb-spona-24">
                            <li className="list-inside py-spona-12">
                                <span className="font-bold">Appointment Setting Queries:</span> Our team of professional copywriters handles all support requests related to appointment setting.
                                Their expertise in content and communication ensures accurate and efficient resolutions.
                            </li>
                        </HighlightedText>
                        <HighlightedText className="w-full ml-[-24px] mb-spona-24">
                            <li className="list-inside py-spona-12">
                                <span className="font-bold">Security, Lead Lists, and Pricing Inquiries:</span> Analysts with specialized knowledge in these areas address queries regarding security measures, lead list generation, and pricing plans.
                                This ensures that you receive detailed and accurate information.
                            </li>
                        </HighlightedText>
                    </ul>
                    <li>Prioritization and Timelines:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">One Request at a Time:</span> To maintain quality and focus, SPONA processes one request per customer at a time.
                            This approach allows us to give undivided attention to each query.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Resolution within One Business Day:</span> We aim to resolve each request within one business day, prioritizing queries based on their urgency and complexity.
                        </li>
                    </ul>
                    <li>Efficient Query Management:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Structured Process:</span> Our support system is designed to categorize and route requests to the appropriate team member swiftly, ensuring that your concerns are addressed by the right expert.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className="text-lg font-medium text-default-100 mt-spona-24">
                At SPONA, we understand the importance of prompt and reliable support.
                Our dedicated teams work diligently to provide you with the assistance you need, ensuring your experience with SPONA is smooth and productive.
                If you have any issues or questions, rest assured that our support structure is in place to address them efficiently and effectively.
            </p>
        </div>
    )
}

export default HandlingTSRequestsContent;