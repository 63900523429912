import React from 'react';
import {HighlightedText} from '../../../ui-components';

const FreeServicesContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                SPONA is committed to offering valuable experiences to our users from the very beginning.
                Our free version is designed to provide you with a taste of our capabilities and the quality of leads we can generate.<br/>
                Here's what the free version includes:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal ml-[-24px]">
                    <HighlightedText className="w-full">
                        <li className="list-inside font-bold text-default-100 text-[22px]">
                            Lead Allocation Based on Market Analysis:
                        </li>
                    </HighlightedText>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-48">
                        <li className="pb-spona-24">
                            <span className="font-bold">Tiered Lead Distribution:</span> The number of free leads you receive depends on the
                            volume of matches from your first questionnaire:
                            <ul className="list-disc font-medium text-default-100 text-lg ml-spona-24">
                                <li><span className="font-bold">Less than 500 matches:</span> Receive 2 free leads.</li>
                                <li><span className="font-bold">501-1000 matches:</span> Receive 5 free leads.</li>
                                <li><span className="font-bold">1001+ matches:</span> Receive 10 free leads.</li>
                            </ul>
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Delivery Timeline:</span> These leads will be delivered to you within 48 hours after
                            completing your initial questionnaire.
                        </li>
                    </ul>
                    <HighlightedText className="w-full">
                        <li className="list-inside font-bold text-default-100 text-[22px]">
                            Quality of Leads:
                        </li>
                    </HighlightedText>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-48">
                        <li className="pb-spona-12">
                            <span className="font-bold">Verified Business Emails:</span> Each free lead comes with a verified business email,
                            ensuring that you can start engaging with potential clients right away.
                        </li>
                        <li className="pb-spona-12">
                            <span className="font-bold">Mobile Number:</span> Among the free leads provided, one will include a verified mobile number,
                            offering you an additional channel for outreach.
                        </li>
                    </ul>
                    <HighlightedText className="w-full">
                        <li className="list-inside font-bold text-default-100 text-[22px]">
                            Complimentary Market Analysis:
                        </li>
                    </HighlightedText>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-48">
                        <li className="pb-spona-12">
                            <span className="font-bold">Insightful Overview:</span> With your first search, SPONA offers a free market analysis. This analysis gives
                            you valuable insights into the potential reach and scope of your specified Ideal Customer Profile (ICP).
                        </li>
                        <li className="pb-spona-12">
                            <span className="font-bold">Informed Decisions:</span> The market analysis helps you understand the landscape of your target market,
                            enabling better informed and strategic decisions for future campaigns.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className="font-medium text-default-100 text-lg mt-spona-24">
                The free version of SPONA is an excellent way to experience firsthand how our service can enhance your lead generation efforts.
                It's designed to provide a solid foundation for understanding the market and the efficacy of our lead generation process,
                encouraging a seamless transition to our paid plans when you're ready to scale up your efforts. Experience the
                SPONA difference with our complimentary offer and embark on a journey towards successful lead generation.
            </p>
        </div>
    )
}

export default FreeServicesContent;