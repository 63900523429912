import React from 'react';
import {HighlightedText} from '../../../ui-components';

const CampaignCustomizationContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                At SPONA, we believe in the power of tailored communication to engage specific audiences effectively.<br/>
                Our approach to customizing campaigns is both strategic and personalized, ensuring that your message resonates with the intended recipients.<br/>
                Here's how we achieve this:
            </p>
            <div className="ml-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Professional Copywriting and Content Specialization:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Expert Involvement:</span> Our team of professional copywriters and content specialists, boasting years of experience, play a pivotal role in crafting your campaign messages.
                            Their expertise in various industries enables them to create content that appeals directly to your target audience.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Tailored Messaging:</span> These experts understand the nuances of different market segments.
                            They use this knowledge to develop compelling, relevant content that speaks to the specific needs, interests, and pain points of your prospective leads.
                        </li>
                    </ul>
                    <li>Technical Expertise in Email Outreach:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Strategic Campaign Execution:</span> Leveraging technical expertise in email marketing, our team implements various outreach strategies effectively.
                            This includes segmenting audiences, timing emails for optimal engagement, and using data-driven insights to refine messaging.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Continuous Optimization:</span> As your campaign progresses, our team continually analyzes performance metrics.
                            This enables us to make real-time adjustments, ensuring that your campaign remains effective and engaging for your target audience.
                        </li>
                    </ul>
                </ul>
            </div>
            <HighlightedText className='mt-spona-24'>
                Through this combination of professional content creation and technical email outreach strategies,
                SPONA ensures that your campaigns are not just reaching your audience, but truly engaging them.<br/>
                This level of customization is key to driving meaningful interactions and, ultimately, successful lead generation.
            </HighlightedText>
        </div>
    )
}

export default CampaignCustomizationContent;