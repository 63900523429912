/**
 * @typedef {string} paymentStatusType
 */

/**
 * Payment status types
 *
 * @enum {paymentStatusType}
 */
export default {
    COMPLETE: 'COMPLETE',
    FAILED: 'FAILED',
    INCOMPLETE: 'INCOMPLETE',
};