export const industries = [
    "Manufacturing",
    "Technology, Information and Media",
    "Financial Services",
    "Government Administration",
    "Hospitals and Health Care",
    "IT Services and IT Consulting",
    "Retail",
    "Technology, Information and Internet",
    "Education",
    "Accommodation Services",
    "Entertainment Providers",
    "Construction",
    "Transportation, Logistics, Supply Chain and Storage",
    "Administrative and Support Services",
    "Business Consulting and Services",
    "Advertising Services",
    "Media and Telecommunications",
    "Consumer Services",
    "Food and Beverage Services",
    "Health and Human Services",
    "Transportation Equipment Manufacturing",
    "Software Development",
    "Education Administration Programs",
    "Machinery Manufacturing",
    "Accounting",
    "Credit Intermediation",
    "Chemical Manufacturing",
    "Higher Education",
    "Oil, Gas, and Mining",
    "Real Estate and Equipment Rental Services",
    "Real Estate",
    "Recreational Facilities",
    "Banking",
    "Medical Practices",
    "Motor Vehicle Manufacturing",
    "Wellness and Fitness Services",
    "Legal Services",
    "Wholesale",
    "Hospitality",
    "Industrial Machinery Manufacturing",
    "Oil and Gas",
    "Design Services",
    "Research Services",
    "Telecommunications",
    "Primary and Secondary Education",
    "Insurance",
    "Computers and Electronics Manufacturing",
    "Restaurants",
    "Appliances, Electrical, and Electronics Manufacturing",
    "Pharmaceutical Manufacturing",
    "Food and Beverage Manufacturing",
    "Human Resources Services",
    "Retail Apparel and Fashion",
    "Truck Transportation",
    "Facilities Services",
    "Civil Engineering",
    "Non-profit Organizations",
    "Farming, Ranching, Forestry",
    "Architecture and Planning",
    "Law Practice",
    "Movies, Videos and Sound",
    "Farming",
    "Capital Markets",
    "Administration of Justice",
    "Mining",
    "Retail Art Supplies",
    "Movies and Sound Recording",
    "Information Services",
    "Airlines and Aviation",
    "Utilities",
    "Security and Investigations",
    "Staffing and Recruiting",
    "Travel Arrangements",
    "Writing and Editing",
    "Civic and Social Organizations",
    "Professional Training and Coaching",
    "Musicians",
    "Spectator Sports",
    "Military and International Affairs",
    "Individual and Family Services",
    "Mental Health Care",
    "Personal Care Product Manufacturing",
    "Semiconductor Manufacturing",
    "Automation Machinery Manufacturing",
    "Medical Equipment Manufacturing",
    "Plastics and Rubber Product Manufacturing",
    "Textile Manufacturing",
    "Aviation and Aerospace Component Manufacturing",
    "Public Relations and Communications Services",
    "Environmental Services",
    "Graphic Design",
    "Photography",
    "Book and Periodical Publishing",
    "Broadcast Media Production and Distribution",
    "Media Production",
    "Wholesale Building Materials",
    "Wholesale Import and Export",
    "Events Services",
    "Religious Institutions",
    "E-Learning Providers",
    "Artists and Writers",
    "Investment Management",
    "International Trade and Development",
    "Law Enforcement",
    "Armed Forces",
    "Public Policy Offices",
    "Executive Offices",
    "Computer Hardware Manufacturing",
    "Renewable Energy Semiconductor Manufacturing",
    "Furniture and Home Furnishings Manufacturing",
    "Plastics Manufacturing",
    "Printing Services",
    "Defense and Space Manufacturing",
    "Government Relations Services",
    "Outsourcing and Offshoring Consulting",
    "Strategic Management Services",
    "Computer and Network Security",
    "Food and Beverage Retail",
    "Retail Groceries",
    "Retail Office Equipment",
    "Animation and Post-production",
    "Internet Publishing",
    "Computer Networking Products",
    "Maritime Transportation",
    "Warehousing and Storage",
    "Ranching and Fisheries",
    "Leasing Non-residential Real Estate",
    "Veterinary Services",
    "Packaging and Containers Manufacturing",
    "Performing Arts and Spectator Sports",
    "Market Research",
    "Alternative Medicine",
    "Performing Arts",
    "Online Audio and Video Media",
    "Public Safety",
    "Freight and Package Transportation",
    "Computer Games",
    "Retail Luxury Goods and Jewelry",
    "Sporting Goods Manufacturing",
    "Investment Banking",
    "Translation and Localization",
    "Paper and Forest Product Manufacturing",
    "Marketing Services",
    "International Affairs",
    "Libraries",
    "Beverage Manufacturing",
    "Ranching",
    "Newspaper Publishing",
    "Dairy Product Manufacturing",
    "Gambling Facilities and Casinos",
    "Glass, Ceramics and Concrete Manufacturing",
    "Venture Capital and Private Equity Principals",
    "Wireless Services",
    "Philanthropic Fundraising Services",
    "Think Tanks",
    "Fisheries",
    "Shipbuilding",
    "Museums, Historical Sites, and Zoos",
    "Tobacco Manufacturing",
    "Political Organizations",
    "Railroad Equipment Manufacturing",
    "Fundraising",
    "Alternative Dispute Resolution",
    "Data Infrastructure and Analytics",
    "Office Administration",
    "Repair and Maintenance",
    "Electric Power Generation",
    "Technical and Vocational Training",
    "Nanotechnology Research",
    "Public Health",
    "Legislative Offices",
    "Engineering Services",
    "Building Construction",
    "IT System Custom Software Development",
    "Renewable Energy Power Generation",
    "Sports Teams and Clubs",
    "Interior Design",
    "Business Content",
    "Medical and Diagnostic Laboratories",
    "Fabricated Metal Products",
    "Economic Programs",
    "Social Networking Platforms",
    "Electric Power Transmission, Control, and Distribution",
    "Agriculture, Construction, Mining Machinery Manufacturing",
    "Retail Health and Personal Care Products",
    "Mobile Gaming Apps",
    "IT System Operations and Maintenance",
    "IT System Data Services",
    "Telephone Call Centers",
    "Internet Marketplace Platforms",
    "Ground Passenger Transportation",
    "Sports and Recreation Instruction",
    "Commercial and Industrial Machinery Maintenance",
    "Personal and Laundry Services",
    "Housing and Community Development",
    "Online and Mail Order Retail",
    "Solar Electric Power Generation",
    "Hotels and Motels",
    "Community Services",
    "Child Day Care Services",
    "Retail Motor Vehicles",
    "Home Health Care Services",
    "Water, Waste, Steam, and Air Conditioning Services",
    "Physical, Occupational and Speech Therapists",
    "Operations Consulting",
    "IT System Testing and Evaluation",
    "IT System Training and Support",
    "Dentists",
    "Leasing Real Estate Agents and Brokers",
    "Environmental Quality Programs",
    "Security Guards and Patrol Services",
    "Apparel Manufacturing",
    "Rail Transportation",
    "Security Systems Services",
    "Vehicle Repair and Maintenance",
    "Specialty Trade Contractors",
    "Language Schools",
    "IT System Design Services",
    "Insurance Agencies and Brokerages",
    "Electrical Equipment Manufacturing",
    "Radio and Television Broadcasting",
    "Blockchain Services",
    "Wholesale Food and Beverage",
    "HVAC and Refrigeration Equipment Manufacturing",
    "Nursing Homes and Residential Care Facilities",
    "Retail Appliances, Electrical, and Electronic Equipment",
    "Janitorial Services",
    "Personal Care Services",
    "Retail Furniture and Home Furnishings",
    "Residential Building Construction",
    "Landscaping Services",
    "Space Research and Technology",
    "Embedded Software Products",
    "Electronic and Precision Equipment Maintenance",
    "Funds and Trusts",
    "Utilities Administration",
    "Community Development and Urban Planning",
    "Motor Vehicle Parts Manufacturing",
    "Horticulture",
    "Physicians",
    "Equipment Rental Services",
    "Agricultural Chemical Manufacturing",
    "Services for Renewable Energy",
    "Business Intelligence Platforms",
    "Cosmetology and Barber Schools",
    "Investment Advice",
    "Fire Protection",
    "Engines and Power Transmission Equipment Manufacturing",
    "Industry Associations",
    "Pet Services",
    "Telecommunications Carriers",
    "Leasing Residential Real Estate",
    "Book Publishing",
    "Highway, Street, and Bridge Construction",
    "Paint, Coating, and Adhesive Manufacturing",
    "Wholesale Motor Vehicles and Parts",
    "Courts of Law",
    "Hospitals",
    "Retail Building Materials and Garden Equipment",
    "Desktop Computing Software Products",
    "Nuclear Electric Power Generation",
    "Fine Arts Schools",
    "Wind Electric Power Generation",
    "Bars, Taverns, and Nightclubs",
    "Emergency and Relief Services",
    "Leather Product Manufacturing",
    "Metalworking Machinery Manufacturing",
    "Wood Product Manufacturing",
    "Water Supply and Irrigation Systems",
    "Wholesale Drugs and Sundries",
    "Museums",
    "Insurance Carriers",
    "Fashion Accessories Manufacturing",
    "Wholesale Appliances, Electrical, and Electronics",
    "Chemical Raw Materials Manufacturing",
    "Commercial and Service Industry Machinery Manufacturing",
    "Data Security Software Products",
    "Mobile Computing Software Products",
    "Wholesale Hardware, Plumbing, Heating Equipment",
    "Internet News",
    "Sightseeing Transportation",
    "Waste Treatment and Disposal",
    "Professional Organizations",
    "Air, Water, and Waste Program Management",
    "Conservation Programs",
    "Services for the Elderly and Disabled",
    "Metal Treatments",
    "Wholesale Chemical and Allied Products",
    "Household Services",
    "Golf Courses and Country Clubs",
    "Wholesale Petroleum and Petroleum Products",
    "Forestry and Logging",
    "Insurance and Employee Benefit Funds",
    "Electric Lighting Equipment Manufacturing",
    "Wineries",
    "Blogs",
    "Utility System Construction",
    "Theater Companies",
    "Transportation Programs",
    "Architectural and Structural Metal Manufacturing",
    "Animal Feed Manufacturing",
    "Urban Transit Services",
    "Wholesale Alcoholic Beverages",
    "Caterers",
    "Mobile Food Services",
    "Collection Agencies",
    "Building Structure and Exterior Contractors",
    "Public Assistance Programs",
    "Optometrists",
    "Glass Product Manufacturing",
    "Footwear Manufacturing",
    "Coal Mining",
    "Metal Ore Mining",
    "Holding Companies",
    "Vocational Rehabilitation Services",
    "Measuring and Control Instrument Manufacturing",
    "Construction Hardware Manufacturing",
    "Baked Goods Manufacturing",
    "Commercial and Industrial Equipment Rental",
    "Retail Art Dealers",
    "Natural Gas Distribution",
    "Wholesale Luxury Goods and Jewelry",
    "Claims Adjusting, Actuarial Services",
    "Housing Programs",
    "Building Finishing Contractors",
    "Breweries",
    "Wholesale Apparel and Sewing Supplies",
    "Securities and Commodity Exchanges",
    "Outpatient Care Centers",
    "Soap and Cleaning Product Manufacturing",
    "Boilers, Tanks, and Shipping Container Manufacturing",
    "Primary Metal Manufacturing",
    "Retail Books and Printed News",
    "Waste Collection",
    "Temporary Help Services",
    "Loan Brokers",
    "Office Furniture and Fixtures Manufacturing",
    "Renewable Energy Equipment Manufacturing",
    "Consumer Goods Rental",
    "Retail Florists",
    "Retail Office Supplies and Gifts",
    "Sound Recording",
    "School and Employee Bus Services",
    "Postal Services",
    "Hydroelectric Power Generation",
    "Wholesale Raw Farm Products",
    "Executive Search Services",
    "Dance Companies",
    "Amusement Parks and Arcades",
    "Rubber Products Manufacturing",
    "Wholesale Machinery",
    "Wholesale Metals and Minerals",
    "Nonresidential Building Construction",
    "Building Equipment Contractors",
    "Communications Equipment Manufacturing",
    "Meat Products Manufacturing",
    "Taxi and Limousine Services",
    "Fossil Fuel Electric Power Generation",
    "Pension Funds",
    "Sugar and Confectionery Product Manufacturing",
    "Retail Gasoline",
    "Retail Recyclable Materials & Used Merchandise",
    "Satellite Telecommunications",
    "Shuttles and Special Needs Transportation Services",
    "Bed-and-Breakfasts, Hostels, Homestays",
    "Laundry and Drycleaning Services",
    "Secretarial Schools",
    "Zoos and Botanical Gardens",
    "Trusts and Estates",
    "Audio and Video Equipment Manufacturing",
    "Natural Gas Extraction",
    "IT System Installation and Disposal",
    "Wholesale Computer Equipment",
    "Correctional Institutions",
    "Household Appliance Manufacturing",
    "Wholesale Paper Products",
    "Wholesale Recyclable Materials",
    "Oil Extraction",
    "Periodical Publishing",
    "Wholesale Furniture and Home Furnishings",
    "Turned Products and Fastener Manufacturing",
    "Retail Musical Instruments",
    "Biomass Electric Power Generation",
    "Steam and Air-Conditioning Supply",
    "Historical Sites",
    "Ambulance Services",
    "Chiropractors",
    "Seafood Product Manufacturing",
    "Household and Institutional Furniture Manufacturing",
    "Nonmetallic Mineral Mining",
    "Wholesale Footwear",
    "Metal Valve, Ball, and Roller Manufacturing",
    "Fruit and Vegetable Preserves Manufacturing",
    "Distilleries",
    "Oil and Coal Product Manufacturing",
    "Cable and Satellite Programming",
    "Geothermal Electric Power Generation",
    "Flight Training",
    "Skiing Facilities",
    "Spring and Wire Product Manufacturing",
    "Abrasives and Nonmetallic Minerals Manufacturing",
    "Pipeline Transportation",
    "Subdivision of Land",
    "Reupholstery and Furniture Repair",
    "Savings Institutions",
    "Magnetic and Optical Media Manufacturing",
    "Mattress and Blinds Manufacturing",
    "Clay and Refractory Products Manufacturing",
    "Footwear and Leather Goods Repair",
    "Racetracks",
    "Cutlery and Handtool Manufacturing",
    "Women's Handbag Manufacturing",
    "Sheet Music Publishing",
    "Interurban and Rural Bus Services",
    "Wholesale Photography Equipment and Supplies",
    "Artificial Rubber and Synthetic Fiber Manufacturing",
    "Lime and Gypsum Products Manufacturing",
    "Family Planning Centers",
    "Circuses and Magic Shows"
]