import React from 'react';

const Form = React.forwardRef((props, ref) => {
    const {children, name, onSubmit, onChange} = props;

    return (
        <form
            ref={ref}
            name={name}
            onSubmit={onSubmit}
            onChange={onChange}
        >
            {children}
        </form>
    )
});

export default Form;
