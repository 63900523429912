import React from 'react';
import {HighlightedText} from '../../../ui-components';

const settingCampaignSteps = {
    DEFINE_YOUR_CAMPAIGN: {
        title: 'Define Your Campaign Objectives',
        description: 'Clearly articulate the goal of your campaign. With SPONA, the primary aim is usually to set as many appointments as possible. ' +
            'Having a defined objective helps in tailoring your approach effectively.',
    },
    PREPARE_MARKETING_MATERIALS: {
        title: 'Prepare Marketing Materials',
        description: 'Gather all essential marketing materials before setting up your campaign. This includes digital brochures, email templates, product or service descriptions, and any other content that conveys the value proposition of what you\'re offering. ' +
            'Ensure these materials are polished and reflect the quality of your brand. It is advisable that you write the campaign objectives on a single sheet.',
    },
    IDENTIFY_YOUR_TARGET_AUDIENCE: {
        title: 'Identify Your Target Audience',
        description: 'Understanding who your ideal customers are is crucial. Define the demographics, industries, job titles, and geographical locations of your targeted audience. This step is vital for creating personalized and relevant outreach.',
    },
    OUTLINE_YOUR_PRODUCTS_OR_SERVICE: {
        title: 'Outline Your Products or Services',
        description: 'Provide a broad yet clear description of your products or services. Highlight key features and benefits that resonate with your target audience. This clarity helps in crafting messages that capture the attention and interest of potential leads.',
    },
}

const SettingCampaignContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100">
                Define your campaign objectives before launching a successful campaign with SPONA. To maximize the effectiveness of your lead generation efforts, here are the initial steps you should take:
            </p>
            {Object.entries(settingCampaignSteps).map((entry, idx) => {
                const key = entry[0];
                const value = entry[1];

                return (
                    <div key={key} className="mt-spona-24">
                        <span className="font-bold text-default-100 text-[22px]">
                            {`${idx + 1}. ${value.title}:`}
                        </span>
                        <p className='font-medium text-default-100 text-lg mt-spona-24 ml-spona-24'>
                            {value.description}
                        </p>
                    </div>
                )
            })}
            <HighlightedText className='mt-spona-24'>
                By following these steps, you lay a strong foundation for your campaign.<br/>
                Remember, the more precise and prepared you are, the better SPONA can assist in achieving your campaign goals.<br/>
                If you have any queries or require further assistance in setting up your campaign, our support team is always ready to help.<br/>
                Let’s set the stage for your campaign success with SPONA!
            </HighlightedText>
        </div>
    )
}

export default SettingCampaignContent;