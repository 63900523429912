import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getProspectList} from '../custom-made-prospect-lists/customMadeProspectListsActions';
import {Button} from '../ui-components';
import PricingList from './ui-elements/PricingList';
import PricingCard from './ui-elements/PricingCard';
import SwitchButton from './ui-elements/SwitchButton';
import { useScreenResolution } from '../utils/customHooks';
import {selectProspectsPricing, selectSpecificPricing, selectTargetedPricing} from '../custom-made-prospect-lists/customMadeProspectListsSlice';
import routePaths from '../routing/routePaths';
import {useNavigate} from 'react-router-dom';

const buttonNames = {
    CUSTOM_MADE_PROSPECT_LISTS: 'CUSTOM_MADE_PROSPECT_LISTS',
    APPOINTMENT_SETTING: 'APPOINTMENT_SETTING',
}

const appointmentSettingConfig = {
    AGENCYLIKE_PERFORMANCE: {
        title: 'Top agency performance',
        description: 'We add a personal touch to every email'
    },
    PROFESSIONAL_SUPPORT: {
        title: 'Professional support',
        description: 'Response time is less than 1 business day'
    },
    PAUSE_OR_CANCEL_ANYTIME: {
        title: 'Pause or cancel anytime',
        description: 'No minimum commitment'
    },
    LONG_TERM_SOLUTION: {
        title: 'Long-term solution',
        description: 'Add new leads to your campaign anytime'
    },
    PERFORMANCE_TRACKING: {
        title: 'Performance tracking',
        description: 'Track your booking-,  reply- and open rates'
    },
    REVISION_EVERY_MONTH: {
        title: 'Revision every month',
        description: 'Leads will never get bored of your copies'
    },
}

const prospectListsConfig = {
    AGENCYLIKE_PERFORMANCE: {
        title: 'Top agency performance',
        description: 'Every lead is matching your target profile'
    },
    FREE_MARKET_ANALYSIS: {
        title: 'Free market analysis',
        description: 'Find out the size of your target audience'
    },
    DIRECT_NUMBERS_AND_EMAILS: {
        title: 'Mobile numbers & emails',
        description: 'Only double verified and GDPR compliant datasets'
    },
    FREE_SAMPLES: {
        title: 'Free samples',
        description: 'Get free leads with business emails '
    },
    FAIR_PRICING: {
        title: 'Fair pricing',
        description: 'Determined by the availability of data'
    },
    MONEY_BACK_GUARANTEES: {
        title: 'Money-back guarantees',
        description: '14-day 96% accuracy guarantee on emails and 75% on mobile phone numbers'
    },
}

const PricingScreen = () => {
    const [activeCTA, setActiveCTA] = useState(buttonNames.CUSTOM_MADE_PROSPECT_LISTS);
    const [isSpecificSelected, setIsSpecificSelected] = useState(true);
    const didComponentMountRef = useRef(false);
    const {isDesktopScreen, isTabletScreen} = useScreenResolution();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const targeted = useSelector(selectTargetedPricing);
    const specific = useSelector(selectSpecificPricing);
    const prospect = useSelector(selectProspectsPricing);

    useEffect( () => {
        if (!didComponentMountRef.current) {
            const getCustomMadeProspectList = async () => {
                try {
                    await dispatch(getProspectList()).unwrap();
                }
                catch (error) {
                    // no-op
                }
            }
            getCustomMadeProspectList();
            didComponentMountRef.current = true;
        }
    });

    return (
        <div className="flex flex-col items-center justify-center">
            {activeCTA === buttonNames.CUSTOM_MADE_PROSPECT_LISTS
                ? (
                    <h2 className="text-default-100 align-center text-center text-[40px] lg:text-[40px] font-black mb-spona-32 mt-spona-32">
                        Tailored to your <span className="text-highlighted-100">current</span><br/>business goals
                    </h2>
                )
                :(
                    <h2 className="text-default-100 align-center text-center text-[40px] lg:text-[40px] font-black mb-spona-32">
                        Efficient & high-quality email<br />appointment setting
                    </h2>
                )}
            <div className="flex lg:block mb-spona-32">
                {/* <Button
                    buttonType={activeCTA === buttonNames.CUSTOM_MADE_PROSPECT_LISTS ? 'primary' : 'secondary'}
                    className="mr-spona-24 text-[15px] md:text-2xl w-[40vw] lg:w-[260px]"
                    onClick={() => {setActiveCTA(buttonNames.CUSTOM_MADE_PROSPECT_LISTS)}}
                >
                    Leads
                </Button>
                <Button
                    buttonType={activeCTA === buttonNames.APPOINTMENT_SETTING ? 'primary' : 'secondary'}
                    onClick={() => {setActiveCTA(buttonNames.APPOINTMENT_SETTING)}}
                    className="text-[15px] md:text-2xl w-[40vw] lg:w-[260px]"
                >
                    Prospects
                </Button> */}
            </div>
            {activeCTA === buttonNames.CUSTOM_MADE_PROSPECT_LISTS
                ? <div className="flex justify-center">
                    <SwitchButton setIsClicked={setIsSpecificSelected} isClicked={isSpecificSelected} firstLabel="Specific" secondLabel="Targeted" />
                </div>
                : <p className="text-highlighted-100 font-black text-5xl md:text-[64px] md:leading-normal text-center">
                    {`$ ${prospect.price} / month`}
                </p>
            }
            {activeCTA === buttonNames.CUSTOM_MADE_PROSPECT_LISTS && (
                <div className={`${isTabletScreen || isDesktopScreen ? 'flex-row' : 'flex-col'} flex justify-center items-center gap-spona-24 mt-spona-32`}>
                    {(isSpecificSelected ? specific : targeted).map(pricingCard => {
                        return isDesktopScreen ? (
                            <PricingCard
                                key={pricingCard.price}
                                noOfLeads={pricingCard.leads}
                                price={pricingCard.price}
                                outstanding={pricingCard.outstanding}
                                discount={pricingCard.discount}
                                title={pricingCard.title}
                                buttonName="Get free leads"
                                onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}
                            />
                        ) : (
                            <div className='w-full flex items-center justify-center' key={pricingCard.price}>
                                <PricingCard
                                    noOfLeads={pricingCard.leads}
                                    price={pricingCard.price}
                                    outstanding={pricingCard.outstanding}
                                    discount={pricingCard.discount}
                                    title={pricingCard.title}
                                    buttonName="Get free leads"
                                    onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}
                                />
                            </div>
                        )
                    })}
                </div>
            )}
            <div className="my-spona-40 text-default-100 font-bold text-[28px] md:text-[40px] align-center text-center">
                {activeCTA === buttonNames.CUSTOM_MADE_PROSPECT_LISTS
                    ? <h2> Pay only for the contacts you need</h2>
                    : <h2>Professional, personal and<br />efficient</h2>}
            </div>
            <div className="flex justify-center">
                <PricingList
                    listItems={activeCTA === buttonNames.APPOINTMENT_SETTING
                        ? appointmentSettingConfig
                        : prospectListsConfig}
                />
            </div>
        </div>
    )
};

export default PricingScreen;