import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Icon} from '../../ui-components';
import {warningIcon} from '../../assets/icons';
import {Modal} from '../../ui-components/modals';
import {deleteQuestionnaire, getCustomerOverviewLeadList} from '../adminActions';
import routePaths from '../../routing/routePaths';
import {resolveRoute} from '../../utils';
import {useLocation, useNavigate} from 'react-router-dom';
import config from '../../config';

const DeleteRowConfirmationModal = props => {
    const {closeModal, rowId} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');

    const handleOnDeleteRow = async () => {
        if (!rowId) return;
        setErrorMessage('');
        try {
            await dispatch(deleteQuestionnaire({questionerId: rowId})).unwrap();
            closeModal();
            const searchQueryParams = new URLSearchParams(location.search);
            searchQueryParams.set('page', `${config.INITIAL_PAGE_SIZE}`);
            searchQueryParams.set('limit', `${config.DEFAULT_LIMIT_SIZE}`);
            navigate(resolveRoute(routePaths.ADMIN_LEADS, {}, {search: searchQueryParams.toString()}), { replace: true });
            await dispatch(getCustomerOverviewLeadList({searchParams: searchQueryParams})).unwrap();
        } catch (e) {
            setErrorMessage("Row deletion failed!")
        }
    };

    return (
        <Modal hasBackdrop={true} closeModal={closeModal}>
            <div className="flex flex-col items-center justify-center">
                <Icon icon={warningIcon} />
                <h2 className="text-default-100 text-2xl font-semibold mt-spona-18 align-center text-center">
                    Are you sure you want to<br/>delete this row?
                </h2>
                <p className={`${!!errorMessage ? 'mb-spona-24' : 'mb-spona-44'} text-contrast-800 text-base font-medium mt-spona-24 align-center text-center`}>
                    This action is irreversible.
                </p>
                {errorMessage && (
                    <div className="flex items-center text-warning-100 text-base font-medium mb-spona-24 align-center text-center">
                        <Icon icon={warningIcon} size="xs" className="mr-spona-8" />
                        {errorMessage}
                    </div>
                )}
                <div className="grid grid-cols-12 gap-x-spona-12 w-full">
                    <Button buttonType='basic' className='col-span-6' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button buttonType='warning' className='col-span-6' onClick={handleOnDeleteRow}>
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteRowConfirmationModal;