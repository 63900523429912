import routePaths from '../routing/routePaths';

export const screenLayoutConfig = {
    screensWithLayout: [
        routePaths.ROOT,
        routePaths.ADMIN_LEADS,
        routePaths.CUSTOM_MADE_PROSPECT_LISTS,
        routePaths.APPOINTMENT_SETTING,
        routePaths.ACCOUNT,
        routePaths.PRICING,
        routePaths.FAQ_SECTION,
        routePaths.FAQ_SUBCATEGORIES,
        routePaths.VERIFY_YOUR_ACCOUNT,
        routePaths.TOKEN_ALREADY_ACTIVATED,
    ],
    screensWithoutLayout: [
        routePaths.CHANGE_PASSWORD,
        routePaths.SUCCESSFUL_VERIFICATION,
        routePaths.CHECK_OUT,
        routePaths.QUESTIONNAIRE_ONE,
        routePaths.REGISTER,
        routePaths.START_QUESTIONNAIRE_ONE,
        routePaths.CUSTOM_MADE_PROSPECT_LISTS_CHECKOUT,
        routePaths.LOGIN,
        routePaths.FORGOT_PASSWORD,
        routePaths.EMAIL_VERIFICATION,
        routePaths.TERMS_AND_CONDITIONS,
        routePaths.DATA_PRIVACY,
        routePaths.BILLING_PORTAL,
    ],
}