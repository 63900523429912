import React from 'react';
import {tokenAlreadyActivatedImage} from '../assets/images';

const TokenAlreadyActivatedPage = () => {
    return (
        <div className="flex flex-col justify-center items-center w-full mt-spona-64 text-[32px]">
            <h1 className="text-default-300 font-bold">Token already activated</h1>
            <span className="text-highlighted-100 font-extrabold mb-spona-32">404</span>
            <img src={tokenAlreadyActivatedImage} alt="pageNotFoundImage" />
        </div>
    );
}

export default TokenAlreadyActivatedPage;