/**
 * @typedef {string} questionnaireStatusType
 */

/**
 * Questionnaire status types
 *
 * @enum {questionnaireStatusType}
 */
export default {
    READY: 'READY',
    READY_UNTIL: 'READY_UNTIL',
    INCOMPLETE: 'INCOMPLETE',
    NO_MORE_LEADS: 'NO_MORE_LEADS',
};