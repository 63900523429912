import { createChatBotMessage } from 'react-chatbot-kit';
import ChatBotMessageOption from "./ui-elements/ChatBotMessageOption";

const BOT_NAME = "SponaSupport";
const initialMessage = 'Hello and welcome to SPONA. To make life easier for both of us, we spent significant resources to build a comperhensive & detailed, yet easy to use FAQ section.\n' +
    'If you nevertheless encounter difficulties finding a solution to your problem, please do not hesitate to contact us in the following manner:\n\n' +
    '1. Write down your work email in the first message\n' +
    '2. Describe your problem in a detailed and concise way in the second one\n\n' +
    'You will receive an answer to your inquiry on your email within one business day.';

const config = {
  botName: BOT_NAME,
  initialMessages: [
    createChatBotMessage(
        initialMessage,
        {
            withAvatar: true,
            delay: 200,
        }
    ),
  ],
  widgets: [{
      widgetName: "options",
      widgetFunc: (props) => <ChatBotMessageOption {...props} />,
    },
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: '#28957B',
    },
    chatButton: {
      backgroundColor: '#33BD9C',
    },
  },
};

export default config;