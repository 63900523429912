import React, {useEffect, useRef} from 'react';
import {Icon, LoaderSpinner} from '../ui-components';
import {useMatch, useNavigate} from 'react-router-dom';
import routePaths from '../routing/routePaths';
import {backWhiteIcon, logoIcon} from '../assets/icons';
import {useDispatch, useSelector} from 'react-redux';
import {selectCustomMadeProspect} from '../custom-made-prospect-lists/customMadeProspectListsSlice';
import {selectPaymentMethod, selectPaymentSecret, selectLoadingPaymentSecret} from './checkoutSlice';
import {getProspect} from '../custom-made-prospect-lists/customMadeProspectListsActions';
import {getPaymentMethod, createPaymentIntent} from './checkoutActions';
import CheckoutForm from './ui-elements/CheckoutForm';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import config from '../config';
import {getCurrentUser} from '../authentification/authActions';
import checkoutButtonActionTypes from './checkoutButtonActionTypes';
import SavedPaymentMethod from './ui-elements/SavedPaymentMethod';

const stripePromise = loadStripe(`${config.STRIPE_PUBLIC_KEY}`);

const CheckoutScreen = () => {
    const didComponentMountRef = useRef(false);

    const navigate = useNavigate();
    const match = useMatch(routePaths.CHECK_OUT);
    const {priceId, questionnaireId} = match.params;

    const dispatch = useDispatch();
    const prospect = useSelector(selectCustomMadeProspect);
    const paymentMethod = useSelector(selectPaymentMethod);
    const paymentSecret = useSelector(selectPaymentSecret);
    const isPaymentSecretLoading = useSelector(selectLoadingPaymentSecret);

    const fetchCurrentUser = async () => {
        try {
            await dispatch(getCurrentUser()).unwrap();
            checkIfUserHasPaymentMethod();
        } catch (error) {
            navigate(routePaths.LOGIN);
        }
    }

    const checkIfUserHasPaymentMethod = async () => {
        try {
            const response = await dispatch(getPaymentMethod()).unwrap();
            if (!response) await dispatch(createPaymentIntent({
                prospectId: priceId,
                questionnaireId: questionnaireId,
            })).unwrap();
        } catch (error) {
            // no-op
        }
    }

    const getCustomMadeProspect = async () => {
        try {
            await dispatch(getProspect({prospectId: priceId})).unwrap();
        }
        catch (error) {
            // no-op
        }
    }

    useEffect( () => {
        if (!didComponentMountRef.current) {
            fetchCurrentUser();
            getCustomMadeProspect();

            didComponentMountRef.current = true;
        }
    });

    return (
        <div className="grid grid-cols-12 h-screen w-screen fixed overflow-auto">
            <div className="col-span-5 bg-highlighted-100 overflow-hidden relative px-spona-64 py-spona-44">
                <div className="z-0 absolute left-[-100px] top-[-350px] bg-highlighted-200 opacity-50 rounded-[160px] w-[560px] h-[560px] origin-center rotate-[-135deg]" />
                <div className="z-0 absolute left-[400px] bottom-[-280px] bg-highlighted-200 opacity-50 rounded-[160px] w-[560px] h-[560px] origin-center rotate-[-135deg]" />
                <div className="flex flex-col justify-between h-full text-contrast-100 relative">
                    <div>
                        <div className="flex items-center">
                            <Icon
                                icon={backWhiteIcon}
                                size='m'
                                className='mr-spona-16 z-50'
                                onClick={() => navigate(-1)}
                            />
                            <Icon
                                icon={logoIcon}
                                size='xxl'
                                className='mr-spona-16 z-50'
                                onClick={() => navigate(routePaths.ROOT)}
                            />
                        </div>
                        {prospect && (
                            <div>
                                <h3 className="text-[22px] mt-spona-128 z-50">payment to SPONA</h3>
                                <span className='text-[64px] font-bold'>{`€${prospect.price},00`}</span>
                                <div className="flex items-center justify-between mb-spona-8 mt-spona-44 text-2xl font-bold">
                                   <span>{prospect.leads ? `${prospect.leads} leads` : 'Appointment setting fee'}</span>
                                    {/*ToDo: use getPrice util function*/}
                                   <span>{`$${prospect.price},00`}</span>
                                </div>
                                {!prospect.leads && ( <p className="text-lg font-medium">billed monthly</p> )}
                            </div>
                        )}
                    </div>
                    <div className='text-lg font-medium flex items-center justify-center z-50'>
                        <span className="mr-spona-24 cursor-pointer">Powered by Stripe</span>
                        <span
                            className="mr-spona-24 cursor-pointer"
                            onClick={() => navigate(routePaths.TERMS_AND_CONDITIONS)}
                        >
                            Terms
                        </span>
                        <span
                            className="mr-spona-24 cursor-pointer"
                            onClick={() => navigate(routePaths.DATA_PRIVACY)}
                        >
                            Data Privacy
                        </span>
                    </div>
                </div>
            </div>
            <div className='col-span-7 flex items-center justify-center px-[25%] py-spona-44 bg-contrast-200'>
                {!paymentMethod ? (
                    !paymentSecret
                        ? isPaymentSecretLoading && <LoaderSpinner />
                        : (
                            <Elements
                                stripe={stripePromise}
                                options={{
                                    fonts: [{
                                        cssSrc: config.RALEWAY_FONT_URL,
                                    }],
                                    clientSecret: paymentSecret
                                }}
                            >
                                <CheckoutForm
                                    paymentMethod={paymentMethod}
                                    buttonAction={checkoutButtonActionTypes.PAY}
                                />
                            </Elements>
                        )
                ) : <div className="w-[80%]">
                        <SavedPaymentMethod buttonAction={checkoutButtonActionTypes.PAY} />
                    </div>
                }
            </div>
        </div>
    );
}

export default CheckoutScreen;