import React from 'react';
import {HighlightedText} from '../../../ui-components';

const CompatibilityWithDPRsContent = () => {
    return (
        <div className="text-lg font-medium text-default-100">
            <p className="mb-spona-24">
                At SPONA, we recognize the critical importance of data protection and privacy,
                especially in today’s digital landscape. Compliance with the General Data Protection
                Regulation (GDPR) and other major data protection regulations (DPRs) is a cornerstone of our operations.<br/>
                Here’s how we ensure compliance:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Data Subject Rights:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                           <span className="font-bold">Respect for User Privacy:</span> We fully support and facilitate the rights of data subjects under GDPR, including the right to access, rectify, delete, or port their data. Users can easily request to view or remove their personal information from our systems.
                        </li>
                    </ul>
                    <li>Data Processing Agreements:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Legal Compliance:</span> Our data processing agreements are in strict adherence to GDPR and other DPRs. We ensure that any data processing conducted on behalf of our clients is legally compliant and respects user privacy.
                        </li>
                    </ul>
                    <li>Regular Compliance Audits:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Proactive Measures:</span> We conduct regular audits to ensure continuous compliance with GDPR and other relevant regulations. This includes reviewing our data processing activities and updating our policies as necessary.
                        </li>
                    </ul>
                    <li>Data Protection Officer (DPO):</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Expert Oversight:</span> A designated Data Protection Officer oversees our data protection strategies, ensuring compliance with GDPR and addressing any data protection concerns.
                        </li>
                    </ul>
                    <HighlightedText className="w-full ml-[-32px] mb-spona-24">
                        <li className="font-bold text-default-100 text-[22px] ml-spona-14">Advanced Security Measures:</li>
                        <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                            <li className="pb-spona-24">
                                <span className="font-bold">Safeguarding Data:</span> Implementing the most advanced security measures, we ensure the protection of personal data against unauthorized access and breaches, in line with GDPR requirements.
                            </li>
                        </ul>
                    </HighlightedText>
                    <li>Transparency and Accountability:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Clear Communication:</span> We maintain transparency in our data processing activities, providing clear information about what data is collected, how it is used, and who it is shared with.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className="text-lg font-medium text-default-100 mt-spona-24">
                We continuously evolve our practices to align with the latest legal requirements and best practices in data security and privacy.
            </p>
        </div>
    )
}

export default CompatibilityWithDPRsContent;