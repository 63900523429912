import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, Icon, Testimonial} from '../../ui-components';
import { arrowUpIcon } from '../../assets/icons';
import {cmplTableImage, tableImage} from '../../assets/images';
import routePaths from '../../routing/routePaths';
import { typographyStyles, testimonialsConfig } from '../../ui-components';

const NonAuthenticatedUserContent = () => {
    const navigate = useNavigate();
    const spanStyle = 'font-black text-highlighted-100';

    return (
        <div className="md:px-spona-40 lg:px-spona-0 mt-spona-32 md:mt-spona-44 lg:mt-spona-0 flex items-center justify-center flex-col text-default-100 align-center text-center">
            <p className={`${typographyStyles['h1']} mb-spona-12`}>
                Get the <span className="font-black">exact</span> leads your business needs
            </p>
            <p className={typographyStyles['h2']}>
                Spend up to <span className={spanStyle}>70% less time</span> on lead generation
            </p>
            <div className="flex items-center justify-center my-spona-32">
                <img loading="eager" src={tableImage} alt="tableImage" className="shrink-0" />
            </div>
            <Button
                buttonType='primary'
                className="mb-spona-32 px-spona-32 lg:mb-spona-0 text-xl lg:text-lg lg:leading-normal font-black uppercase"
                onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}
            >
                <Icon className="mr-spona-8" icon={arrowUpIcon} size='s' />
                Get free leads
            </Button>
            <div className="w-full flex items-center justify-center lg:my-spona-64">
                <Testimonial
                    id={testimonialsConfig[0].id}
                    title={testimonialsConfig[0].title}
                    highlightedText={testimonialsConfig[0].highlightedText}
                    text={testimonialsConfig[0].text}
                    name={testimonialsConfig[0].name}
                    jobTitle={testimonialsConfig[0].jobTitle}
                    image={testimonialsConfig[0].image}
                    logo={testimonialsConfig[0].logo}
                    height={testimonialsConfig[0].height}
                />
            </div>
            <p className={`${typographyStyles['h1']} mb-spona-12`}>
                <span className="font-black">up to 15x</span> more affordable than an agency
            </p>
            <p className={`${typographyStyles['h2']} mb-spona-24`}>
                <span className={spanStyle}>Always</span> know the number of outstanding potential buyers
            </p>
            <div className={`mt-spona-32 lg:mt-spona-0 w-11/12 h-[162px] lg:w-[600px] lg:h-[323px] overflow-hidden relative`}>
                <div className={`w-[1280px] h-[162px] lg:h-[323px] absolute animate-scanAnimation bg-cover`}>
                    <img
                        loading="lazy"
                        src={cmplTableImage}
                        alt="customMadeProspectListsTable"
                    />
                </div>
            </div>
            <Button
                buttonType='primary'
                className="mt-spona-32 px-spona-32 lg:mb-spona-0 text-xl lg:text-lg lg:leading-normal uppercase font-black"
                onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}
            >
                <Icon className="mr-spona-8" icon={arrowUpIcon} size='s' />
                Get free leads
            </Button>
            <div className="w-full lg:w-auto flex items-center justify-center mt-spona-12 lg:mt-spona-64">
                <Testimonial
                    id={testimonialsConfig[1].id}
                    title={testimonialsConfig[1].title}
                    highlightedText={testimonialsConfig[1].highlightedText}
                    text={testimonialsConfig[1].text}
                    name={testimonialsConfig[1].name}
                    jobTitle={testimonialsConfig[1].jobTitle}
                    image={testimonialsConfig[1].image}
                    logo={testimonialsConfig[1].logo}
                    height={testimonialsConfig[1].height}
                />
            </div>
        </div>
    )
}

export default NonAuthenticatedUserContent;