import React, { StrictMode } from 'react';
import './index.scss';
import '@stripe/stripe-js';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import ApplicationScreen from './application/ApplicationScreen';
import {ModalProvider} from './ui-components/modals/ModalProvider';
import store from './application/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <StrictMode>
       <Provider store={store} >
            <BrowserRouter>
                <ModalProvider>
                    <ApplicationScreen />
                </ModalProvider>
            </BrowserRouter>
        </Provider>
    </StrictMode>
);