import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { Button, Icon } from '../ui-components';
import { backLightIcon, dropdownArrowBlackIcon, playIcon, playWhiteIcon } from '../assets/icons';
import categoryToSubcategoryMap from './categoryToSubcategoryMap';
import { categoryTitles, categories } from './categories';
import { constantCase, kebabCase } from 'change-case';
import { resolveRoute } from '../utils';
import routePaths from '../routing/routePaths';
import AccountCreationContent from './ui-elements/getting-started/AccountCreationContent';
import BasicSystemRequirementsContent from './ui-elements/getting-started/BasicSystemRequirementsContent';
import ImportingContactsContent from './ui-elements/getting-started/ImportingContactsContent';
import SettingCampaignContent from './ui-elements/getting-started/SettingCampaignContent';
import SPONAKeyFeatures from './ui-elements/features-and-functionality/SPONAKeyFeatures';
import CampaignCustomizationContent from './ui-elements/features-and-functionality/CampainCustomizationContent';
import AnalyticsAndToolsContent from './ui-elements/features-and-functionality/AnalyticsAndToolsContent';
import LeadManagementAndNurturingContent from './ui-elements/features-and-functionality/LeadManagementAndNurturingContent';
import PricingPlansContent from './ui-elements/pricing-and-plans/PricingPlansContent';
import SpecialOffersContent from './ui-elements/pricing-and-plans/SpecialOffersContent';
import FreeServicesContent from './ui-elements/pricing-and-plans/FreeServicesContent';
import CancellingSubscriptionContent from './ui-elements/pricing-and-plans/CancellingSubscriptionContent';
import ThirdPartyIntegrationsContent from './ui-elements/integrations-and-compatibility/ThirdPartyIntegrationsContent';
import IntegrationSetupContent from './ui-elements/integrations-and-compatibility/IntegrationSetupContent';
import SPONACompatibilityContent from './ui-elements/integrations-and-compatibility/SPONACompatibilityContent';
import IssuesWithIntegrationsContent from './ui-elements/integrations-and-compatibility/IssuesWithIngerationsContent';
import ContactTechnicalSupportContent from './ui-elements/tehnical-support/ContactTechnicalSupportContent';
import ReportingIssueContent from './ui-elements/tehnical-support/ReportingIssueContent';
import SelfHelpResourcesContent from './ui-elements/tehnical-support/SelfHelpResourcesContent';
import HandlingTSRequestsContent from './ui-elements/tehnical-support/HandlingTSRequestsContent';
import SecurityMeasuresContent from './ui-elements/security-privacy-gdpr/SecurityMeasuresContent';
import CompatibilityWithDPRsContent from './ui-elements/security-privacy-gdpr/CompatibilityWithDPRsContent';
import ControllingDataContent from './ui-elements/security-privacy-gdpr/ControllingDataContent';
import PoliciesContent from './ui-elements/security-privacy-gdpr/PoliciesContent';
import { useScreenResolution } from '../utils/customHooks';
import config from '../config';

const SubcategoryScreen = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isListExpanded, setIsListExpanded] = useState(false);
    const {isDesktopScreen} = useScreenResolution();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const category = constantCase(searchParams.get('category'));
    const subcategory = constantCase(searchParams.get('subcategory'));
    const hoveredButtonStyle = 'hover:bg-contrast-200 hover:text-highlighted-100';

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const handleOnClick = key => {
        if (!key) return;
        const queryParams = new URLSearchParams();
        queryParams.append('category', `${kebabCase(category).toLowerCase()}`);
        queryParams.append('subcategory', `${kebabCase(key).toLowerCase()}`);
        navigate(resolveRoute(routePaths.FAQ_SUBCATEGORIES, {}, { search: queryParams.toString() }));
    };

    const goBack = () => {
        const queryParams = new URLSearchParams();
        queryParams.append('category', `${kebabCase(category).toLowerCase()}`);
        navigate(resolveRoute(routePaths.FAQ, {}, { search: queryParams.toString() }))
    };

    if (!category || !subcategory) return null;

    const subcategoryData = categoryToSubcategoryMap[category][subcategory];

    const renderSubcategoryContent = () => {
        switch (category) {
            case categories.GETTING_STARTED: {
                switch (subcategory) {
                    case 'SUBCATEGORY_1':
                        return <AccountCreationContent />
                    case 'SUBCATEGORY_2':
                        return <BasicSystemRequirementsContent />
                    case 'SUBCATEGORY_3':
                        return <ImportingContactsContent />
                    case 'SUBCATEGORY_4':
                        return <SettingCampaignContent />
                    default:
                        return null;
                }
            }
            case categories.FEATURES_AND_FUNCTIONALITY: {
                switch (subcategory) {
                    case 'SUBCATEGORY_1':
                        return <SPONAKeyFeatures />
                    case 'SUBCATEGORY_2':
                        return <CampaignCustomizationContent />
                    case 'SUBCATEGORY_3':
                        return <AnalyticsAndToolsContent />
                    case 'SUBCATEGORY_4':
                        return <LeadManagementAndNurturingContent />
                    default:
                        return null;
                }
            }
            case categories.PRICING_AND_PLANS: {
                switch (subcategory) {
                    case 'SUBCATEGORY_1':
                        return <PricingPlansContent />
                    case 'SUBCATEGORY_2':
                        return <SpecialOffersContent />
                    case 'SUBCATEGORY_3':
                        return <FreeServicesContent />
                    case 'SUBCATEGORY_4':
                        return <CancellingSubscriptionContent />
                    default:
                        return null;
                }
            }
            case categories.INTEGRATIONS_AND_COMPATIBILITY: {
                switch (subcategory) {
                    case 'SUBCATEGORY_1':
                        return <ThirdPartyIntegrationsContent />
                    case 'SUBCATEGORY_2':
                        return <IntegrationSetupContent />
                    case 'SUBCATEGORY_3':
                        return <SPONACompatibilityContent />
                    case 'SUBCATEGORY_4':
                        return <IssuesWithIntegrationsContent />
                    default:
                        return null;
                }
            }
            case categories.TECHNICAL_SUPPORT: {
                switch (subcategory) {
                    case 'SUBCATEGORY_1':
                        return <ContactTechnicalSupportContent />
                    case 'SUBCATEGORY_2':
                        return <ReportingIssueContent />
                    case 'SUBCATEGORY_3':
                        return <SelfHelpResourcesContent />
                    case 'SUBCATEGORY_4':
                        return <HandlingTSRequestsContent />
                    default:
                        return null;
                }
            }
            case categories.SECURITY: {
                switch (subcategory) {
                    case 'SUBCATEGORY_1':
                        return <SecurityMeasuresContent />
                    case 'SUBCATEGORY_2':
                        return <CompatibilityWithDPRsContent />
                    case 'SUBCATEGORY_3':
                        return <ControllingDataContent />
                    case 'SUBCATEGORY_4':
                        return <PoliciesContent />
                    default:
                        return null;
                }
            }
            default:
                return null;
        }
    }

    return (
        <div className="lg:grid grid-cols-12 gap-spona-64">
            <div className="col-span-3">
                <Icon
                    icon={backLightIcon}
                    size='m'
                    onClick={goBack}
                    className="hidden lg:block"
                />
                <Icon
                    icon={backLightIcon}
                    size='s'
                    onClick={goBack}
                    className="lg:hidden"
                />
                <div className="flex items-center h-[52px] mb-spona-32 lg:mb-[62px] mt-spona-8">
                    <h2 className="text-2xl	font-bold text-default-100 whitespace-nowrap" onClick={() => { if (!isDesktopScreen) setIsListExpanded(!isListExpanded) }}>
                        {categoryTitles[category]}
                        <Icon className={`${isListExpanded && 'rotate-180'} lg:hidden`} icon={dropdownArrowBlackIcon} />
                    </h2>
                </div>
                <div className="hidden lg:flex flex-col gap-spona-64 text-xl font-bold text-default-100">
                    {Object.entries(categoryToSubcategoryMap[category]).map(entry => {
                        const key = entry[0];
                        const value = entry[1];
                        return (
                            <div
                                key={`${category}-${key}`}
                                className={`px-spona-14 py-spona-14 cursor-pointer
                                    ${key === constantCase(searchParams.get('subcategory'))
                                        ? 'bg-contrast-600 rounded-[10px]'
                                        : ''}`
                                }
                                onClick={() => handleOnClick(key)}
                            >
                                {value.title}
                            </div>
                        )
                    })}
                </div>
                {isListExpanded && (
                    <div className="mb-spona-32 flex lg:hidden flex-col gap-spona-20 text-xl font-bold text-default-100">
                        {Object.entries(categoryToSubcategoryMap[category]).map(entry => {
                            const key = entry[0];
                            const value = entry[1];
                            return (
                                <div
                                    key={`${category}-${key}`}
                                    className={`px-spona-14 py-spona-14 cursor-pointer
                                    ${key === constantCase(searchParams.get('subcategory'))
                                            ? 'bg-contrast-600 rounded-[10px]'
                                            : ''}`
                                    }
                                    onClick={() => handleOnClick(key)}
                                >
                                    {value.title}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
            <div className="col-span-9 w-full lg:ml-spona-16">
                <div className="mb-[62px] lg:mt-[52px] flex flex-col md:flex-row items-center">
                    <h1 className="text-2xl text-bold font-semibold text-center md:text-left">{subcategoryData?.title || ''}</h1>
                    <Button
                        buttonType='primary'
                        className={`shrink-0 mt-spona-20 md:mt-spona-0 md:ml-spona-16 text-2xl ${hoveredButtonStyle}`}
                        onClick={() => window.open(config.DEMO_YOUTUBE_LINK, '_blank')}
                        onMouseOver={() => setIsHovered(true)}
                        onMouseOut={() => setIsHovered(false)}
                    >
                        Watch tutorial
                        <Icon className="ml-spona-4" icon={isHovered ? playIcon : playWhiteIcon} size='xs' />
                    </Button>
                </div>
                <div className="pr-spona-32 md:pr-spona-0 lg:max-w-[1200px] text-justify">
                    {renderSubcategoryContent()}
                </div>
            </div>
        </div>
    );
};

export default SubcategoryScreen;