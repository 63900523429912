import React, {Fragment} from 'react';
import {selectPaymentMethod} from '../checkoutSlice';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from '../../authentification/authSlice';
import {countries} from '../../entites/general';

const PersonalInformation = () => {
    const paymentMethod = useSelector(selectPaymentMethod);
    const currentUser = useSelector(selectCurrentUser);

    if (!currentUser) return null;

    return (
        <div className="flex justify-start mt-spona-24">
            <div className="grid grid-cols-2 gap-x-spona-12">
                <span className="text-default-100">
                    Name
                </span>
                <span className="text-contrast-800">
                    {currentUser.name}
                </span>
                <span className="text-default-100">
                    Email
                </span>
                <span className="text-contrast-800">
                    {currentUser.email}
                </span>
                {paymentMethod?.billing_details?.address && (
                    <Fragment>
                        <span className="text-default-100">
                            Address
                        </span>
                        <span className="text-contrast-800">
                            {paymentMethod.billing_details.address.line1 || '-'}
                        </span>
                        <span className="text-default-100">
                            Post code
                        </span>
                        <span className="text-contrast-800">
                            {paymentMethod.billing_details.address.postal_code || '-'}
                        </span>
                        <span className="text-default-100">
                            City
                        </span>
                        <span className="text-contrast-800">
                            {paymentMethod.billing_details.address.city || '-'}
                        </span>
                        <span className="text-default-100">
                            Country
                        </span>
                        <span className="text-contrast-800">
                            {countries.find(country => {
                                return country.code === paymentMethod.billing_details.address.country
                            })?.name || '-'}
                        </span>
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default PersonalInformation;