import React from 'react';
import {HighlightedText} from '../../../ui-components';

const SelfHelpResourcesContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                At SPONA, we understand the importance of quick and efficient problem-solving.
                To assist you in addressing common issues independently, we offer a comprehensive range of self-help resources.
                These tools are designed to provide immediate assistance and enhance your understanding of our platform:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Extensive FAQ Section:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Comprehensive Coverage:</span> Our FAQ section is meticulously curated to cover a wide array of common questions and issues related to SPONA's features, usage, and troubleshooting.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Easy Navigation:</span> You can easily navigate through different categories to find relevant information and solutions to the most frequently encountered issues.
                        </li>
                    </ul>
                    <li>Video Tutorials:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <HighlightedText className="mb-spona-24 ml-[-32px]">
                            <li className="list-inside">
                                <span className="font-bold">Step-by-Step Guidance:</span> For a more in-depth understanding, each FAQ question is accompanied by a video tutorial. These tutorials offer step-by-step visual guides, making complex concepts easier to grasp.
                            </li>
                        </HighlightedText>
                        <li className="pb-spona-24">
                            <span className="font-bold">Visual Learning Advantage:</span> We recommend utilizing these video tutorials as they can be particularly helpful in breaking down complex processes and providing clear, actionable instructions.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className="text-lg font-medium text-default-100 mt-spona-24">
                These self-help resources are constantly updated to reflect the latest features and solutions. By leveraging these tools, you can quickly find answers and possibly
                resolve issues without the need to reach out to our support team.
            </p>
            <p className="text-lg font-medium text-default-100 mt-spona-24">
                However, if your issue persists or if you require more personalized assistance, our support team is always on standby to help. Our goal at SPONA is to empower you with the knowledge
                and tools needed for a smooth and successful experience with our platform.
            </p>
        </div>
    )
}

export default SelfHelpResourcesContent;