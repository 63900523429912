const questions = {
    Q1: {
        questionNo: 1,
        question: 'In which industries is your target audience operating?',
        description: 'Choose at least one option',
        progressBarPercentage: 11,
        isMandatory: true,
        maxLength: 20,
    },
    Q2: {
        questionNo: 2,
        question: 'Name companies that match your target profile.',
        description: 'Choose at least one',
        progressBarPercentage: 22,
        isMandatory: true,
    },
    Q3: {
        questionNo: 3,
        question: 'Where is your target audience located?',
        description: 'Choose at least one city, country or region',
        progressBarPercentage: 33,
        isMandatory: true,
    },
    Q4: {
        questionNo: 4,
        question: 'How much revenue do your target companies generate?',
        description: 'Choose at least one option',
        progressBarPercentage: 44,
        isMandatory: true,
    },
    Q5: {
        questionNo: 5,
        question: 'How many employees do they have?',
        description: 'Choose at least one option',
        progressBarPercentage: 55,
        isMandatory: true,
    },
    Q6: {
        questionNo: 6,
        question: 'What are the job titles you are looking for?',
        description: 'Choose at least one option',
        progressBarPercentage: 66,
        isMandatory: true,
        maxLength: 5,
    },
    Q7: {
        questionNo: 7,
        question: 'Describe your target profile in one sentence.',
        description: 'Example: Purchasing managers of fish processing companies in the Nordics, Benelux, US  and Canada larger than 10 employees',
        progressBarPercentage: 77,
        isMandatory: true,
    },
    Q8: {
        questionNo: 8,
        question: 'Upload an excel file with companies you want or already have data from.',
        description: 'We use this to make sure you get only leads you need and no duplicate leads',
        progressBarPercentage: 88,
        isMandatory: false,
    },
    Q9: {
        questionNo: 9,
        question: 'How many leads per company do you need?',
        description: 'Please select one option',
        progressBarPercentage: 99,
        isMandatory: false,
        maxLength: 1,
    }
}

const idealCustomersSize = ['Up to 1 million $', '50 - 100 million $', '1 - 5 million $', '100 - 500 million $', '5 - 10 million $', '500 million - 1 billion $', '10 - 50 million $', '> 1 billion $']
const idealCustomerEmployeesSize = ['1-10', '501-1000', '11-50', '1001-5000', '51-200', '5001-10000', '201-500', '10001+']

export {idealCustomersSize, idealCustomerEmployeesSize, questions};