import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../config';

const baseURL = `${config.DEV_BASE_URL}`;

export const createPaymentIntent = createAsyncThunk(
    '/stripe/create-payment-intent',
    async ({ prospectId, questionnaireId }, { rejectWithValue }) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.post(
                `${baseURL}/stripe/create-payment-intent`,
                { prospectId, questionerId: questionnaireId },
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            )
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data});
            } else {
                return rejectWithValue({payload: error.message});
            }
        }
    }
);

export const setupPaymentIntent = createAsyncThunk(
    '/stripe/setup-payment-method',
    async ({email, name}, { rejectWithValue }) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.post(
                `${baseURL}/stripe/setup-payment-method`,
                {email, name},
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            )
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data});
            } else {
                return rejectWithValue({payload: error.message});
            }
        }
    }
);

export const getPaymentMethod = createAsyncThunk(
    '/stripe/get-payment-method',
    async (_, thunkAPI) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(
                `${baseURL}/stripe/get-payment-method`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            )
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message })
        }
    }
);

export const getPaymentList = createAsyncThunk(
    '/stripe/get-payment-list',
    async (_, thunkAPI) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(
                `${baseURL}/stripe/get-payment-list`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            )
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message })
        }
    }
);

export const detachPaymentMethod = createAsyncThunk(
    'stripe/detach-payment-method',
    async (_, thunkAPI) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(
                `${baseURL}/stripe/detach-payment-method`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            )
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message })
        }
    }
);

export const attachPaymentMethod = createAsyncThunk(
    'stripe/attach-payment-method',
    async (_, thunkAPI) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(
                `${baseURL}/stripe/attach-payment-method`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            )
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message })
        }
    }
);