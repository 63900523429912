import React from 'react';
import {HighlightedText} from '../../../ui-components';

const IntegrationSetupContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                Setting up and managing integrations with third-party applications in SPONA is designed to be straightforward,
                ensuring you can quickly enhance your lead generation efforts.<br/>
                Here's a simple guide on how to integrate these essential services:
            </p>
            <div className="ml-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Accessing Integration Options:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Locate Integrations:</span> Log into your SPONA account and navigate to the console.<br/>
                            Here, you’ll find a dedicated section for 'Third-Party Apps'.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Email Provider Integration:</span> In this section, you can connect SPONA with your email provider,
                            a crucial step for leveraging our appointment setting and email outreach services.
                        </li>
                    </ul>
                    <li>Enabling Permissions:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Granting Access:</span> To engage in appointment setting and other email-related functionalities,
                            you must grant SPONA permission to use third-party applications like Quickmail and Mailflow.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Permission Process:</span> Follow the prompts to authorize SPONA to access these services.
                            This process ensures seamless integration and synchronization between SPONA and your email provider.
                        </li>
                    </ul>
                    <li>Campaign Management and Notifications:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Automatic Pause:</span> If, for any reason, the permissions are revoked,
                            your ongoing campaigns will be automatically paused to prevent any disruption or compliance issues.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Email Notifications:</span> In such cases, you will be promptly informed via email about the status change.
                            This ensures you are always aware of your campaign’s operational status.
                        </li>
                    </ul>
                    <li>Re-establishing Integrations:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li>
                            <span className="font-bold">Resuming Campaigns:</span> To resume your paused campaigns, simply
                            reinstate the required permissions in the ‘Third-Party Apps’ section.
                        </li>
                    </ul>
                </ul>
            </div>
            <HighlightedText className='mt-spona-24'>
                Our aim is to make the integration process as intuitive and efficient as possible.
                By ensuring seamless connectivity with key third-party applications, SPONA enhances the effectiveness of your lead generation campaigns.
            </HighlightedText>
            <p className='font-medium text-default-100 text-lg my-spona-24'>
                Should you encounter any challenges or have queries regarding setting up these integrations, our dedicated support team is available to assist you at every step.
                Let SPONA be your partner in seamlessly integrating and managing essential tools for your business growth.
            </p>
        </div>
    )
}

export default IntegrationSetupContent;