import React from 'react';

const TextArea = props => {
    const {name, value, isDisabled, placeholder, maxLength, isReadOnly} = props;
    const {rows, cols, isResizable, onBlur, onChange, className} = props;
    const defaultTextAreaStyles = "inline " +
        "bg-contrast-500 border border-contrast-300 " +
        "w-full " +
        "text-input-100 " +
        "font-medium border " +
        "p-spona-12 " +
        "rounded-[10px] " +
        'text-lg ' +
        ':focus outline-none'

    const handleOnChange = event => {
        if (typeof onChange === 'function') onChange(event.target.value);
    };

    const handleOnBlur = event => {
        if (typeof onBlur === 'function') onBlur(event);
    };

    return (
        <textarea
            name={name}
            maxLength={maxLength}
            rows={rows}
            cols={cols}
            value={value}
            placeholder={placeholder}
            onChange={handleOnChange}
            readOnly={isReadOnly}
            disabled={isDisabled}
            onBlur={handleOnBlur}
            className={`${className ? className : ''} ${defaultTextAreaStyles} ${isResizable ? 'resize' : 'resize-none'}`}
        />
    )
}

export default TextArea;