import React from 'react';
import './LoaderSpinner.css';

const LoaderSpinner = () => {
    return (
        <div className="w-spona-64 h-spona-64 inline-block box-border border-2 border-highlighted-100 rounded-full relative animate-rotation">
            <div className="gradient-line" />
        </div>
    );
};

export default LoaderSpinner;
