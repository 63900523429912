import { useState, useLayoutEffect } from 'react';

const useScreenResolution = () => {
    const [isDesktopScreen, setIsDesktopScreen] = useState(false);
    const [isTabletScreen, setIsTabletScreen] = useState(false);

    useLayoutEffect(() => {
        const checkScreenWidth = () => {
            setIsDesktopScreen(window.innerWidth > 1024);
            setIsTabletScreen(window.innerWidth <= 1024 && window.innerWidth >= 768)
        }
        window.addEventListener("resize", checkScreenWidth);
        checkScreenWidth();
        return () => window.removeEventListener("resize", checkScreenWidth);
    }, []);

    return {
        isDesktopScreen,
        isTabletScreen,
    }

}

export default useScreenResolution;
