const categories = {
    GETTING_STARTED: 'GETTING_STARTED',
    FEATURES_AND_FUNCTIONALITY: 'FEATURES_AND_FUNCTIONALITY',
    PRICING_AND_PLANS: 'PRICING_AND_PLANS',
    INTEGRATIONS_AND_COMPATIBILITY: 'INTEGRATIONS_AND_COMPATIBILITY',
    TECHNICAL_SUPPORT: 'TECHNICAL_SUPPORT',
    SECURITY: 'SECURITY',
}

const categoryTitles = {
    [categories.GETTING_STARTED]: 'Getting started',
    [categories.FEATURES_AND_FUNCTIONALITY]: 'Features & functionality',
    [categories.PRICING_AND_PLANS]: 'Pricing & plans',
    [categories.INTEGRATIONS_AND_COMPATIBILITY]: 'Integrations & compatibility',
    [categories.TECHNICAL_SUPPORT]: 'Technical support',
    [categories.SECURITY]: 'Security, privacy & GDPR',
}

const categoryButtons = {
    [categories.GETTING_STARTED]: {
        name: 'Get started with SPONA',
    },
    [categories.FEATURES_AND_FUNCTIONALITY]: {
        name: 'Learn more about SPONA features',
    },
    [categories.PRICING_AND_PLANS]: {
        name: 'Pricing and plans of SPONA explained in detail',
    },
    [categories.INTEGRATIONS_AND_COMPATIBILITY]: {
        name: 'Learn more about integrations of SPONA',
    },
    [categories.TECHNICAL_SUPPORT]: {
        name: 'See how technical support of SPONA works',
    },
    [categories.SECURITY]: {
        name: 'Find out how we take care of your security and privacy',
    },
}

export {categories, categoryTitles, categoryButtons};