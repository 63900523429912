import React from 'react';
import {logoIcon, logoBIcon} from '../../assets/icons';
import {Icon} from '../icons';

const SponaLogo = React.forwardRef((props, ref) => {
    const {color, onClick} = props;

    return (
        <div ref={ref} className={`inline-block ${typeof onClick === 'function' ? 'cursor-pointer' : ''}`} onClick={onClick}>
            <Icon icon={color === 'white' ? logoIcon : logoBIcon} className="z-100 w-[132px] h-spona-32" />
        </div>
    );
});

export default SponaLogo;
