import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser, login } from './authActions'

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: null,
        error: null,
        loadingCurrentUser: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(login.fulfilled, (state, { payload }) => {
            const {_id, name, email, session, role} = payload;
            state.user =  {
                name,
                email,
                role,
                id: _id,
            };
            state.token = session;
        })
        builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
            const {_id, name, email, session, role} = payload;
            state.loadingCurrentUser = false;
            state.user =  {
                name,
                email,
                role,
                id: _id,
            };
            state.token = session;
        })
        builder.addCase(getCurrentUser.pending, (state) => {
            state.loadingCurrentUser = true;
        })
        builder.addCase(getCurrentUser.rejected, (state) => {
            state.loadingCurrentUser = false;
            state.user = null;
        })
    },
});

// selectors
export const selectCurrentUser = (state) => state.auth.user;
export const selectLoadingCurrentUser = (state) => state.auth.loadingCurrentUser;

export default authSlice.reducer;