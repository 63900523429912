import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sendMessage} from './chatBotActions';
import {selectEmailMessage, storeEmailMessage, selectMessages, addMessage} from './chatBotSlice';
import {selectCurrentUser} from '../authentification/authSlice';
import config from '../config';

const MessageParser = ({ children, actions }) => {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

  const emailMessage = useSelector(selectEmailMessage);
  const messages = useSelector(selectMessages);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const parse = async (message) => {
    const isMessageLimitReached = messages?.length >= config.CHATBOT_MESSAGE_LIMIT;

    if (isMessageLimitReached) {
        actions.tooManyMessagesHandler();
    }

    dispatch(addMessage(message));

    if (!isMessageLimitReached && emailRegex.test(message)) {
      dispatch(storeEmailMessage(message));
      actions.emailMessageHandler();
      try {
        await dispatch(sendMessage({email: message})).unwrap();
      } catch (e) {
        // no op
      }
      return;
    }

    if (!isMessageLimitReached) {
      emailMessage && messages.includes(emailMessage)
          ? actions.problemDescriptionMessageHandler()
          : actions.invalidEmailMessageHandler()
    }

    if (message) {
      try {
        await dispatch(sendMessage({
          email: currentUser?.email ? currentUser.email : emailMessage,
          message: message,
        })).unwrap();
      } catch (e) {
        // handle error
      }
    }
  }

  return (
      <div>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            parse: parse,
            actions,
          });
        })}
      </div>
  )
}

export default MessageParser;