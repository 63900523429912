import React, {useContext, useState, useMemo, Children, useEffect, useRef} from 'react';
import {Icon} from '../icons';
import {dropdownArrowIcon} from '../../assets/icons';
import {FieldSetInput} from '../text-inputs';
import DropdownContext from './DropdownContext';
import withDropdownProvider from './withDropdownProvider';
import DropdownList from './DropdownList';
import borderDirectionTypes from '../text-inputs/borderDirectionTypes';

const Select = props => {
    const {placeholder, borderDirection, children} = props;
    const {value, onChange, name, isDisabled} = props;
    const {openDropDown, closeDropDown, toggleDropDown, isOpen, dropDownRef} = useContext(DropdownContext);

    const selectorRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    useMemo(() => setSearchTerm(value), [value]);

    const areChildrenSet = !!children && Array.isArray(children) && children.length > 0;
    const filteredChildren = areChildrenSet
        ? children.filter(child => (
            child?.props?.children?.toLowerCase().includes(searchTerm.toLowerCase())
            || child?.props?.value.toLowerCase().includes(searchTerm.toLowerCase())))
        : null;

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (selectorRef.current && !selectorRef.current.contains(e.target)) {
                closeDropDown();
            }
        }

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    });

    const handleIconOnClick = () => {
        if (!isDisabled) {
            toggleDropDown();
        }
    };

    const getValueContent = value => {
        if (!value) return '';

        let valueContent = value;
        Children.forEach(children, child => {
            if (child.props.value === value) {
                valueContent = child.props?.children;
            }
        });
        return valueContent;
    }

    const handleListOnChange = value => {
        if (!value) return;
        closeDropDown();
        onChange(value);
        setSearchTerm(value);
    };

    const onFocusHandler = () => {
        if (areChildrenSet) {
            openDropDown();
        }
    };

    const getBorderStyle = () => {
        switch (borderDirection) {
            case borderDirectionTypes.LEFT:
                return 'border-l';
            case borderDirectionTypes.RIGHT:
                return 'border-r';
            case borderDirectionTypes.TOP:
                return 'border-t';
            case borderDirectionTypes.BOTTOM:
                return 'border-b';
            default:
                return '';
        }
    }

    return (
        <div ref={selectorRef}>
            <div className={`flex justify-between w-full h-full ${borderDirection ? `border-contrast-600 ${getBorderStyle()}` : ''}`}>
                <FieldSetInput
                    placeholder={placeholder}
                    name={name}
                    value={getValueContent(searchTerm) || searchTerm}
                    onChange={setSearchTerm}
                    onFocus={onFocusHandler}
                />
                <Icon
                    icon={dropdownArrowIcon}
                    className={`p-spona-12 transition-[transform] ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                    onClick={handleIconOnClick}
                />
            </div>
            {isOpen && (
                <div ref={dropDownRef} className="max-h-[160px] overflow-auto font-bold text-input-100 text-xs">
                    <DropdownList onClick={handleListOnChange}>
                        {filteredChildren}
                    </DropdownList>
                </div>
            )}
        </div>
    )
}

export default withDropdownProvider(Select);