import React from 'react';
import {pageNotFoundImage} from '../assets/images';

const PageNotFound = () => {
    return (
        <div className="flex flex-col justify-center items-center w-full mt-spona-64">
            <img src={pageNotFoundImage} alt="pageNotFoundImage" />
        </div>
    );
}

export default PageNotFound;