import React from 'react';

const activeStyle = 'border-orange-50 bg-orange-50 text-orange-100';

const FilterOption = props => {
    const {className, selectedOption, setSelectedOption, filterOption} = props;

    return (
        <div
            className={`${selectedOption === filterOption.value ? activeStyle : 'border-contrast-800 text-contrast-800'} 
            ${className ? className : ''}
            text-lg font-semibold cursor-pointer rounded-[18px] border px-[14px] py-spona-4`}
            onClick={() => setSelectedOption((selectedOption && selectedOption === filterOption.value) ? '' : filterOption.value)}
        >
            {filterOption.name}
        </div>
    )
}

export default FilterOption;