import React from 'react';

const Testimonial = props => {
    const {title, highlightedText, image, name, jobTitle, text, logo, height} = props;
    const titleTypographyStyle = 'text-contrast-100 text-base md:text-[32px] md:leading-normal font-bold align-center';
    const shadow = 'shadow-[14px_4px_16px_0px_rgba(26,24,24,0.15)]';

    return (
        <div className="relative p-spona-20 md:p-spona-40 lg:p-spona-0 flex flex-col items-center lg:items-start lg:grid grid-cols-12 w-[95%] lg:w-[980px] h-[500px] lg:h-[292px]">
            <div className="col-span-8">
                <div className={`${titleTypographyStyle} ${shadow} lg:w-[420px] flex justify-center bg-highlighted-200 rounded-[20px] px-spona-40 lg:px-spona-0 py-spona-8 text-center lg:text-left`}>
                    {title}
                </div>
                <div className="hidden lg:grid grid-cols-7 mt-spona-44 ml-spona-44 text-left">
                    <div
                        className="text-default-100 font-bold text-2xl mb-spona-12 col-span-5"
                        dangerouslySetInnerHTML={{ __html: highlightedText }}
                    />
                    <p className="text-input-100 text-lg font-semibold col-span-8">
                        {text}
                    </p>
                </div>
            </div>
            <div className="col-span-4 mt-spona-20 lg:mt-spona-0 max-w-[265px]">
                <div className="ml-spona-12">
                    <img loading="lazy" src={image} alt={title} />
                </div>
                <div className='relative lg:static h-[90px] lg:h-[150px]'>
                    <div>
                        <div className="flex flex-col items-center justify-center py-spona-12 bg-highlighted-100 rounded-[20px] font-bold text-contrast-100">
                            <p className="text-base whitespace-nowrap">{name}</p>
                            <p className="text-xs whitespace-nowrap">{jobTitle}</p>
                        </div>
                    </div>
                    <div className={`absolute right-[-30px] md:right-[-40px] bottom-[-2px] lg:right-[10px] lg:bottom-spona-32`}>
                        <img loading="lazy" src={logo} alt={`${title}Logo`} className={height}/>
                    </div>
                </div>
            </div>
            <div className="lg:hidden mt-spona-20 text-center">
                    <div
                        className="text-default-100 font-bold text-lg mb-spona-12 col-span-5"
                        dangerouslySetInnerHTML={{ __html: highlightedText }}
                    />
                    <p className="text-input-100 text-sm font-semibold col-span-8">
                        {text}
                    </p>
                </div>
        </div>
    )
}

export default Testimonial;