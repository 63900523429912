import React from 'react';
import {HighlightedText} from '../../../ui-components';

const ContactTechnicalSupportContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                We prioritize responsive and thorough technical support to ensure a smooth experience for our users.
                If you need assistance or have questions, here are the ways you can reach out to our technical support team:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Chatbot for Immediate Queries:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Accessible Support:</span> Our chatbot feature is readily available on the SPONA platform,
                            offering an immediate point of contact for any queries or issues.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Timely Responses:</span> We strive to reply to chatbot inquiries within 1 business day,
                            ensuring that each request is handled with care and attention.
                        </li>
                    </ul>
                    <li>Preparing Your Inquiry:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24">
                        <li>
                            <span className="font-bold">Details for Efficient Resolution:</span> To facilitate a swift and effective response, please include as much detail as possible
                            about your issue or question when you contact us. This information allows us to better understand your situation
                            and provide targeted assistance.
                        </li>
                    </ul>
                </ul>
            </div>
            <HighlightedText className="w-full mt-spona-24">
                Our technical support team is committed to delivering quality and timely assistance.
                Whether through our chatbot or via email, we are here to help you navigate any challenges and ensure your
                experience with SPONA is successful and hassle-free..
            </HighlightedText>
        </div>
    )
}

export default ContactTechnicalSupportContent;