import React from 'react';
import {useNavigate} from 'react-router-dom';
import {SponaLogo} from '../general';
import {linkedinIcon, youtubeIcon} from '../../assets/icons';
import Icon from '../icons/Icon';
import routePaths from '../../routing/routePaths';

const socialNetworkURLs = {
    YOUTUBE: 'https://www.youtube.com/channel/UCjuPR48_-iX3pbW6L89Nt9g',
    LINKEDIN: 'https://www.linkedin.com/company/spona/'
}

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className='mt-auto w-full lg:flex justify-between items-center h-spona-300 lg:h-spona-112 mx-auto py-spona-32 px-spona-20 md:px-spona-40 lg:py-spona-0 lg:px-spona-80 text-contrast-100 font-[raleway] text-xl bg-highlighted-200'>
            <div className="mb-spona-16 lg:mb-spona-12">
                <SponaLogo color='white' />
            </div>
            <div className="flex flex-col lg:block">
                <span
                    className="mb-spona-16 lg:mb-spona-0 mr-spona-24 cursor-pointer"
                    onClick={() => navigate(routePaths.DATA_PRIVACY)}
                >
                    Data privacy
                </span>
                <span
                    className="mb-spona-16 lg:mb-spona-0 mr-spona-24 cursor-pointer"
                    onClick={() => navigate(routePaths.TERMS_AND_CONDITIONS)}
                >
                    Terms
                </span>
                <span className="mb-spona-16 lg:mb-spona-0 lg:mr-spona-24">Copyright @ 2024 All rights reserved.</span>
            </div>
            <div className="flex items-center -mb-spona-8">
                <span className="mr-spona-12">Follow us:</span>
                <Icon
                    className="mr-spona-8"
                    size='m'
                    icon={youtubeIcon}
                    onClick={() => window.open(socialNetworkURLs['YOUTUBE'], '_blank')}
                />
                <Icon
                    size='m'
                    icon={linkedinIcon}
                    onClick={() => window.open(socialNetworkURLs['LINKEDIN'], '_blank')}
                />
            </div>
        </div>
    )
}

export default Footer;
