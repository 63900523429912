import React from 'react';
import {Button, Input} from '../../ui-components';
import inputTypes from '../../ui-components/text-inputs/inputTypes';
import passwordInfoTypes from '../../ui-components/text-inputs/passwordInfoTypes';
import {validateFields} from '../../utils';

const EnterPasswordContent = props => {
    const {closeModal, handleOnClick, passwordError, password, setPassword, setPasswordError} = props;

    return (
        <div>
            <p className="text-xl text-contrast-800 text-base font-medium mb-spona-32">
                To delete this account, you need to enter your password.
            </p>
            <Input
                name="password"
                label="Password"
                placeholder='Your password'
                className='w-full'
                inputType={inputTypes.PASSWORD.toLowerCase()}
                value={password || ''}
                onChange={setPassword}
                passwordInfo = {!!passwordError.errorMessage
                    ? (!passwordError.isFromServer ? passwordInfoTypes.SHOW_PASSWORD_CONSTRAINS : '')
                    : passwordInfoTypes.SHOW_PASSWORD}
                onBlur={e => {
                    const errorMessage = validateFields(e.target.value, e.target.name);
                    if (!errorMessage) return;
                    setPasswordError({
                        isFromServer: false,
                        errorMessage,
                    });
                }}
                error={!!passwordError.errorMessage ? passwordError.errorMessage : ''}
            />
            <div className="grid grid-cols-12 gap-x-spona-12 mt-spona-48">
                <Button buttonType='basic' className='col-span-6' onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    buttonType='warning'
                    className='col-span-6'
                    onClick={handleOnClick}
                    isDisabled={!password || !!passwordError.errorMessage}
                >
                    Delete
                </Button>
            </div>
        </div>
    );
}

export default EnterPasswordContent;