import { createSlice } from "@reduxjs/toolkit";
import { uploadFile } from './fileAssetsActions';

const fileAssetsSlice = createSlice({
    name: 'fileAssetsSlice',
    initialState: { isFileUploadingPending: '' },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(uploadFile.pending, (state, { payload }) => {
            state.isFileUploadingPending = true;
        });
        builder.addCase(uploadFile.fulfilled, (state, { payload }) => {
            state.isFileUploadingPending = false;
        });
        builder.addCase(uploadFile.rejected, (state, { payload }) => {
            state.isFileUploadingPending = false;
        });
    },
});

// actions
export default fileAssetsSlice.reducer;

// selectors
export const selectIsFileUploadPending = (state) => state.fileAssets.isFileUploadingPending;
