import React, { useState, useEffect, useRef } from 'react';
import { Chatbot } from 'react-chatbot-kit';
import config from "./config.js";
import MessageParser from "./MessageParser.js";
import ActionProvider from "./ActionProvider.js";
import { Avatar, Icon } from '../ui-components';
import {resetEmailMessage, selectIsOpen, setIsOpen} from './chatBotSlice';
import { useDispatch, useSelector } from 'react-redux';
import { avatarImage } from '../assets/images';
import { exitIcon } from '../assets/icons/index.js';
import 'react-chatbot-kit/build/main.css';
import './ChatBotContainer.css';

const ChatBotContainer = () => {
    const [toggleChatBot, setToggleChatBot] = useState(false);
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsOpen);
    const chatbotRef = useRef();
    const avatarRef = useRef();

    useEffect(() => {
        if (!isOpen || !toggleChatBot) return;

        const handleOutsideClick = (e) => {
            if (chatbotRef.current && !chatbotRef.current?.contains(e.target) && !avatarRef.current?.contains(e.target)) {
                dispatch(setIsOpen(false));
                setToggleChatBot(false)
            }
        }

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    })

    const handleOnToggle = () => {
        dispatch(setIsOpen(!toggleChatBot));
        setToggleChatBot(!toggleChatBot);
        if (!toggleChatBot) dispatch(resetEmailMessage());
    };

    return (
        <div>
            <div className="fixed bottom-[112px] right-spona-12 hidden lg:block">
                {toggleChatBot && (
                    <div ref={chatbotRef} className="z-9999 shadow-[2px_2px_8px_0px_rgba(26,24,24,0.18)] w-[350px]">
                        <Chatbot
                            headerText='SPONA Support'
                            config={config}
                            messageParser={MessageParser}
                            actionProvider={ActionProvider}
                        />
                    </div>
                )}
                <div className="fixed bottom-spona-24 right-spona-12" ref={avatarRef}>
                    <Avatar src={avatarImage} alt="avatar" onClick={handleOnToggle} />
                </div>
            </div>
            <div className="fixed bottom-spona-0 lg:bottom-[112px] left-spona-0 lg:left-auto right-spona-24 z-[1050] lg:hidden">
                {toggleChatBot && (
                    <div className="shadow-[2px_2px_8px_0px_rgba(26,24,24,0.18)] w-[100vw] lg:w-[350px]">
                        <Icon icon={exitIcon} size="s" className="absolute top-[70px] right-spona-8 z-20" onClick={handleOnToggle} />
                        <Chatbot
                            headerText='SPONA Support'
                            config={config}
                            messageParser={MessageParser}
                            actionProvider={ActionProvider}
                        />
                    </div>
                )}
            </div>
            {!toggleChatBot && (
                <div className="fixed bottom-spona-24 right-spona-24 lg:hidden z-[1000]">
                    <Avatar src={avatarImage} alt="avatar" onClick={handleOnToggle} />
                </div>
            )}
        </div>
    );
};

export default ChatBotContainer;
