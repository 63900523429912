/**
 * @typedef {string} inputTypes
 */

/**
 * Input types
 *
 * @enum {inputTypes}
 */
export default {
    TEXT: 'TEXT',
    PASSWORD: 'PASSWORD',
};
