import React, {useEffect, useState} from 'react';
import {Button, Icon, Pill} from '../ui-components';
import Table from '../ui-components/data-elements/Table';
import {arrowUpIcon, downloadDisabledIcon, downloadIcon} from '../assets/icons';
import routePaths from '../routing/routePaths';
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {constantCase} from 'change-case';
import { useToast } from '../ui-components/general/useToast';
import {toastPositionType} from '../ui-components/general/toastNotificationThemeConfig';
import {outstandingThresholdTypes, questionnaireOneStatusTypes} from '../entites';
import {selectIsFillingQuestionnaireAllowed, selectUserQuestionnaireData, selectNotification} from './questionnaireOneSlice';
import useDashboardToastConfig from './useDashboardToastConfig';
import {resolveRoute} from '../utils';

const pillVariants = {
    [questionnaireOneStatusTypes.READY]: 'positive',
    [questionnaireOneStatusTypes.READY_UNTIL]: 'negative',
    [questionnaireOneStatusTypes.NO_MORE_LEADS]: 'negative',
    [questionnaireOneStatusTypes.INCOMPLETE]: 'pending',
};
// HIDE FOR NOW
// const outstandingVariants = {
//     [outstandingThresholdTypes.S]: 'text-highlighted-500',
//     [outstandingThresholdTypes.L]: 'text-warning-300',
// };

const getPillType = status => {
    return status.toLowerCase().includes('until') ? questionnaireOneStatusTypes.READY_UNTIL : status.toUpperCase();
}

const QuestionnaireUserDashboard = () => {
    const linkStyle = "underline decoration-1 underline-offset-2 text-input-100 text-base font-semibold";
    const columns = [
        {
            id: "name",
            accessorKey: "name",
            header: "Name",
            cell: (info) => (
                <div className="text-contrast-800 font-medium text-xs break-words">
                    {info.getValue()}
                </div>
            ),
            size: 450,
        },
        {
            id: "status",
            accessorKey: "status",
            header: "Status",
            size: 300,
            cell: (info) => (
                <Pill type={pillVariants[getPillType(info.getValue())]} className="w-[152px]">
                    {info.getValue()}
                </Pill>
            ),
        },
        {
            id: 'file',
            accessorKey: "file",
            header: "File",
            cell: (info) => {
                const fileLink = info.getValue();
                return (
                    <Icon
                        icon={!!fileLink ? downloadIcon : downloadDisabledIcon}
                        {...(!!fileLink && {onClick: () => handleOnFileDownload(fileLink)})}
                    />
                )
            },
            size: 150,
        },
        // HIDE FOR NOW
        // {
        //     id: "outstanding",
        //     accessorKey: "outstanding",
        //     header: "Outstanding",
        //     size: 200,
        //     cell: (info) => (
        //         <div className={`${outstandingVariants[info.getValue()]}`}>
        //             {info.getValue()}
        //         </div>
        //     ),
        // },
        {
            id: "extracted",
            accessorKey: "extracted",
            header: "Extracted",
            size: 200,
        },
        {
            id: "remaining",
            accessorKey: "remaining",
            header: "Remaining",
            size: 200,
        },
        {
            id: "buyMore",
            accessorKey: "buyMore",
            header: () => (
                <div className="text-highlighted-200 font-extrabold">
                    Buy more
                </div>
            ),
            cell: (info) => {
                const outstanding =  info.row.original?.outstanding === outstandingThresholdTypes.L ? 'targeted' : 'specific';
                return (
                    !info.getValue()
                        ? <span className="text-contrast-800 text-base font-semibold">Checkout</span>
                        : <a href={resolveRoute(routePaths.CUSTOM_MADE_PROSPECT_LISTS_CHECKOUT,
                            {outstanding: outstanding, questionnaireId: info.row.original?._id})?.pathname}
                             className={linkStyle}
                        >
                                Checkout
                          </a>
                )
            },
            size: 250,
        },
    ];

    const toast = useToast();
    const {userDashboardToastConfig} = useDashboardToastConfig();

    const navigate = useNavigate();
    const userQuestionnaireData = useSelector(selectUserQuestionnaireData);
    const isFillingQuestionnaireAllowed = useSelector(selectIsFillingQuestionnaireAllowed);
    const notification = useSelector(selectNotification);
    const [currentNotification, setCurrentNotification] = useState(notification);

    const handleOnFileDownload = file => {
        const link = document.createElement('a');
        link.href = file;
        link.click();
    }

    useEffect(() => {
        if (!notification) return;
        if (currentNotification !== notification) {
            toast.openToast(
                notification.title,
                toastPositionType.BELOW_HEADER,
                userDashboardToastConfig[constantCase(notification.title)].description,
                userDashboardToastConfig[constantCase(notification.title)].type,
                false,
            );
            setCurrentNotification(notification);
        }
    } , [notification])

    return (
        <div>
            <h1 className="text-[40px] font-bold text-default-100 mb-spona-48">
                My target profiles
            </h1>
            <div className="flex flex-col items-center justify-center">
                <div className="w-[650px]">
                    <Button
                        buttonType='primary'
                        className="mb-spona-48 w-full"
                        isDisabled={!isFillingQuestionnaireAllowed}
                        onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}
                    >
                        <Icon icon={arrowUpIcon} className="mr-spona-8" />
                        Fill in the questionnaire
                    </Button>
                </div>
                <div className="flex justify-center">
                    <Table data={userQuestionnaireData} columns={columns} />
                </div>
            </div>
        </div>
    );
};

export default QuestionnaireUserDashboard;