import React from 'react';
import {HighlightedText} from '../../../ui-components';

const PricingPlansContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                SPONA offers a range of pricing plans tailored to meet your lead generation needs.
                Our plans are structured based on the volume of leads available in your target market and the services you require.<br/>
                Here’s a breakdown of our pricing options:
            </p>
            <div className="ml-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Monthly Appointment Setting Subscription:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg my-spona-24 ml-spona-24">
                        <li>
                            <span className="font-bold">Features:</span> This plan assigns you a professional copywriter, focusing on setting appointments and enhancing your campaign's effectiveness.
                        </li>
                    </ul>
                    <li>Lead Purchase Plans:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="mb-spona-24">
                            Less than 500 Contacts Available
                        </li>
                        <li className="mb-spona-24">
                            501 - 1000 Contacts Available
                        </li>
                        <li>
                            1001+ Contacts Available
                        </li>
                    </ul>
                </ul>
            </div>
            <p className='font-medium text-default-100 text-lg mt-spona-24'>
                Each plan is designed to offer flexibility and value, ensuring that you can choose the option that best aligns with your lead generation strategy.
                Whether you need a steady flow of appointments or a bulk purchase of leads, SPONA’s pricing plans cater to diverse business requirements and budgets.
            </p>
            <HighlightedText className="mt-spona-24">
                Our focus is to provide you with quality leads and effective campaign management, driving your business growth and success.
            </HighlightedText>
        </div>
    )
}

export default PricingPlansContent;