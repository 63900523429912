import React from 'react';
import {HighlightedText} from '../../../ui-components';

const basicSystemRequirement = {
    OPERATING_SYSTEM: {
        title: 'Operating System',
        description: 'SPONA is compatible with various operating systems including Windows 7 and above, macOS X, and Linux. ' +
            'This flexibility ensures a wide range of users can access our platform without compatibility issues.',
    },
    WEB_BROWSER: {
        title: 'Web Browser',
        description: 'A recent version of a web browser is essential for optimal performance. We recommend using the latest versions of Google Chrome, Mozilla Firefox, Safari, or Microsoft Edge. Keeping your browser updated ensures better security and a smoother experience.',
    },
    INTERNET_CONNECTION: {
        title: 'Internet Connection',
        description: 'A stable and reliable internet connection is crucial for accessing SPONA’s online features and ensuring uninterrupted usage. ' +
            'A minimum speed of 5 Mbps is recommended for efficient data processing and real-time updates.',
    },
    SCREEN_RESOLUTION: {
        title: 'Screen Resolution',
        description: 'A minimum screen resolution of 1024x768 is advised for a clear and comfortable viewing experience. ' +
            'Higher resolutions provide enhanced clarity and are beneficial for analyzing detailed reports and dashboards.',
    },
    RAM_AND_PROCESSOR: {
        title: 'RAM and Processor',
        description: 'At least 4GB of RAM and a modern processor (such as Intel i3 or better) are recommended for efficient multitasking and speedy processing of data.',
    },
}

const BasicSystemRequirementsContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100">
                Here’s a quick overview of the basic system requirements necessary to run SPONA:
            </p>
            {Object.entries(basicSystemRequirement).map((entry, idx) => {
                const key = entry[0];
                const value = entry[1];

                return (
                    <div key={key} className="mt-spona-24">
                        <span className="font-bold text-default-100 text-[22px]">
                            {`${idx + 1}. ${value.title}:`}
                        </span>
                        <p className='font-medium text-default-100 text-lg mt-spona-24 ml-spona-24'>
                            {value.description}
                        </p>
                    </div>
                )
            })}
            <HighlightedText className='mt-spona-24'>
                Please note, these requirements are designed to ensure a baseline performance level and may vary based on your specific usage patterns.
                If you have any queries or need further clarification regarding system requirements, feel free to reach out to our support team.
                Welcome to SPONA – let’s optimize your lead generation process together!
            </HighlightedText>
        </div>
    )
}

export default BasicSystemRequirementsContent;