import React from 'react';
import {HighlightedText} from '../../../ui-components';

const ReportingIssueContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                When encountering an issue with SPONA, providing detailed and concise information is crucial for
                our team to offer effective assistance. Our support staff, all humans, are dedicated to understanding
                and resolving your concerns with precision.<br/>
                Here’s a guideline on the information to include in your report:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Specific Nature of the Issue:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Clear Description:</span> Briefly yet clearly describe the issue you are experiencing.
                            Be specific about what is happening and how it deviates from the expected functionality.
                        </li>
                    </ul>
                    <li>Context of the Problem:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Relevant Details:</span> Include any relevant details leading up to the issue.
                            For instance, were you using a particular feature when the problem occurred? Did you notice any error messages?
                        </li>
                    </ul>
                    <HighlightedText className="ml-[-32px] mb-spona-24">
                        <li className="ml-spona-14 font-bold text-default-100 text-[22px]">
                            Your Actions Prior to the Issue:
                        </li>
                        <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24">
                            <li className="pb-spona-24 ml-spona-24">
                                <span className="font-bold">Steps Taken:</span> Mention any specific actions you took before encountering the issue.<br/>
                                This information can help in tracing the root cause.
                            </li>
                        </ul>
                    </HighlightedText>
                    <li>Your Contact Information:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Communication Details:</span> Ensure your contact information is up-to-date so we can reach you for any follow-up questions or updates.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className="text-lg font-medium text-default-100 mt-spona-24">
                By providing comprehensive and precise information, you enable our team to diagnose and resolve your issue more efficiently.<br/>
                At SPONA, we value your time and strive to address each request with 100% confidence.
                Your cooperation in detailing the issue helps us provide the high-quality support you deserve.
            </p>
        </div>
    )
}

export default ReportingIssueContent;