import React from 'react';

const SwitchButton = props => {
    const {isClicked, setIsClicked, firstLabel, secondLabel} = props;

    const defaultStyles = 'flex-1 shrink-0 rounded-tl-[10px] rounded-bl-[10px] relative inline-flex justify-center items-center text-center cursor-pointer font-semibold px-[25px] py-[9px]';
    const primary = 'text-contrast-100 bg-highlighted-100 border-2 border-highlighted-100'
    const secondary = 'text-highlighted-100 border-2 border-highlighted-100'

    return (
        <div className="flex md:min-w-[500px]">
            <div className="flex flex-col w-full">
                <button
                    className={`${defaultStyles} ${isClicked ? primary : secondary}`}
                    onClick={() => {setIsClicked(true)}}
                >
                    {firstLabel}
                </button>
                <span className="whitespace-nowrap px-spona-4 text-sm text-highlighted-100 mt-spona-12 text-center font-semibold">
                    {`<500 outstanding leads`}
                </span>
            </div>
            <div className="flex flex-col w-full">
                <button
                    className={`flex-1 shrink-0 ${!isClicked ? primary : secondary} rounded-tr-[10px] rounded-br-[10px]`}
                    onClick={() => {setIsClicked(false)}}
                >
                    {secondLabel}
                </button>
                <span className="px-spona-4 whitespace-nowrap text-sm text-highlighted-100 mt-spona-12 text-center font-semibold">
                    500+ outstanding leads
                </span>
            </div>
        </div>
    )
}

export default SwitchButton;