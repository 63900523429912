import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useMatch} from 'react-router-dom';
import {Button, Input, passwordInfoTypes} from '../../ui-components';
import routePaths from '../../routing/routePaths';
import { validateFields } from '../../utils';
import { changePassword } from '../authActions';
import config from '../../config';

const ChangePasswordContent = () => {
    const [newPassword, setNewPassword] = useState();
    const [passwordError, setPasswordError] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const match = useMatch(routePaths.CHANGE_PASSWORD);

    useEffect(() => {
        setPasswordError('');
    }, [newPassword])

    const handleOnClick = async () => {
        if (!!passwordError) return;
        const {token} = match.params;

        if (!token) {
            navigate(routePaths.PAGE_NOT_FOUND);
            return;
        }

        try {
            await dispatch(changePassword({token, password: newPassword})).unwrap();
            navigate(routePaths.LOGIN);
        } catch (error) {
            if (error.statusCode === config.STATUS_CODE_404) {
                navigate(routePaths.PAGE_NOT_FOUND);
            }
        }
    }

    return (
        <div className="flex items-center justify-center flex-col">
            <h1 className="text-default-100 text-2xl font-bold my-[36px]">
                Almost there!
            </h1>
            <div className="mb-[36px] text-default-100 font-medium text-xl text-center">
                <span>Please enter new password</span>
            </div>
            <div className="w-full">
                <Input
                    name="password"
                    label="Password"
                    placeholder='Your new password'
                    className='w-full'
                    inputType='password'
                    value={newPassword || ''}
                    onChange={setNewPassword}
                    passwordInfo = {passwordError ? passwordInfoTypes.SHOW_PASSWORD_CONSTRAINS : ''}
                    onBlur={e => {
                        const errorMessage = validateFields(e.target.value, e.target.name);
                        if (!errorMessage) return;
                        setPasswordError(errorMessage);
                    }}
                    error={!!passwordError ? passwordError : ''}
                />
            </div>
            <Button
                className="w-full mt-spona-16 mb-spona-64"
                buttonType='primary'
                isDisabled={!newPassword}
                onClick={handleOnClick}
            >
                Change password
            </Button>
        </div>
    )
}

export default ChangePasswordContent;