import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../config';

const baseURL = `${config.DEV_BASE_URL}`;

export const register = createAsyncThunk(
    '/register',
    async ({ name, email, password, questionnaireOneId }, { rejectWithValue }) => {
        try {
            await axios.post(
                `${baseURL}/register`,
                { name, email, password, questionerId: questionnaireOneId },
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data});
            } else {
                return rejectWithValue({payload: error.message});
            }
        }
    }
);

export const login = createAsyncThunk(
    '/login',
    async ({email, password}, {rejectWithValue}) => {
        try {
            const {data} = await axios.post(
                `${baseURL}/login`,
                {email, password},
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            localStorage.setItem('accessToken', data.session);
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data, statusCode: error.response.status});
            } else {
                return rejectWithValue({payload: error.message, statusCode: error.response.status});
            }
        }
    }
);

export const forgotPassword = createAsyncThunk(
    '/forgot-password',
    async ({email}, {rejectWithValue}) => {
        try {
            const { data } = await axios.post(
                `${baseURL}/forgot-password`,
                { email },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            return data;
        } catch (error) {
            return rejectWithValue({statusCode: error.response.status});
        }
    }
);

export const changePassword = createAsyncThunk(
    '/change-password',
    async ({token, password}, {rejectWithValue}) => {
        try {
            const { data } = await axios.post(
                `${baseURL}/change-password`,
                {token, password},
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return data;
        } catch (error) {
            if (error.response) {
                return rejectWithValue({statusCode: error.response.status});
            } else {
                return rejectWithValue({error: error.message});
            }
        }
    }
);

export const verifyRegistrationToken = createAsyncThunk(
    '/verify-registration-token',
    async ({token}, {rejectWithValue}) => {
        try {
            const { data } = await axios.post(
                `${baseURL}/verify-registration-token`,
                {token},
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            return data;
        } catch (error) {
            if (error.response) {
                return rejectWithValue({statusCode: error.response.status});
            } else {
                return rejectWithValue({error: error.message});
            }
        }
    }
);

export const getCurrentUser = createAsyncThunk('/me', async (_, {rejectWithValue }) => {
    const token = localStorage.getItem('accessToken');
    try {
        const response = await axios.get(
            `${baseURL}/me`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }},
        )
        return response.data;
    } catch (error) {
        if (token) localStorage.removeItem('accessToken');
        return error.response
            ? rejectWithValue({error: error.response.status})
            : rejectWithValue({error: error.message});
    }
});

export const removeCurrentUser = createAsyncThunk('/remove-me', async (_, {rejectWithValue}) => {
    const token = localStorage.getItem('accessToken');
    try {
        await axios.get(
            `${baseURL}/remove-me`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }},
        )
        if (token) localStorage.removeItem('accessToken');
    } catch (error) {
        return rejectWithValue({ error: error.message })
    }
});

export const updatePassword = createAsyncThunk(
    '/update-password',
    async ({password}, {rejectWithValue}) => {
        const token = localStorage.getItem('accessToken');
        try {
            const { data } = await axios.post(
                `${baseURL}/update-password`,
                {password},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            return data;
        } catch (error) {
            if (error.response) {
                return rejectWithValue({statusCode: error.response.status});
            } else {
                return rejectWithValue({error: error.message});
            }
        }
    }
);

export const checkUserPassword = createAsyncThunk('/check-user-password',
    async ({password}, {rejectWithValue}) => {
    const token = localStorage.getItem('accessToken');
    try {
        const response = await axios.post(
            `${baseURL}/check-user-password`,
            {password},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            },
        )
        return response.data;
    } catch (error) {
        if (error.response) {
            return rejectWithValue({payload: error.response.data});
        } else {
            return rejectWithValue({payload: error.message});
        }
    }
});