import React, {Fragment, useRef, useState, useCallback, useEffect} from 'react';
import {Icon} from '../icons';
import {cloudIcon, closeIcon} from '../../assets/icons';
import { useToast } from '../general/useToast';
import {toastPositionType} from '../general/toastNotificationThemeConfig';

const dropZoneDefaultStyle = 'h-[250px] w-full bg-input-200 shrink-0 rounded-[10px] border-dashed border-2 flex justify-center flex-col items-center pb-spona-32';
const ACCEPTED_FILE_TYPES = ".csv,.xls,.xlsx";
const MAX_FILE_SIZE = 3072;
const ALLOWED_MIME_TYPES = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/x-excel',
    'application/x-msexcel',
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const fileErrorTypes = {
    FILE_TYPE_ERROR: 'FILE_TYPE_ERROR',
    FILE_SIZE_ERROR: 'FILE_SIZE_ERROR',
    MAX_FILE_AMOUNT_ERROR: 'MAX_FILE_AMOUNT_ERROR',
}
const toastErrorConfig = {
    title: "File error",
    position: toastPositionType.TOP_RIGHT,
    type: "error",
}

const FileDropZone = React.forwardRef((props, ref) => {
    const {value, onChange, isDisabled, maxNoOfUploadedFiles, shouldShowCloseButton = true} = props;

    const localDropZoneRef = useRef();
    const triggerOnChange = useRef(false);

    const dropZoneRef = ref || localDropZoneRef;
    const [dragElementCount, setDragElementCount] = useState(0);
    const [files, setFiles] = useState(value || []);
    const toast = useToast();

    const errorDescriptions = {
        FILE_TYPE_ERROR: 'This file type is not supported. Only .CSV, .XLS and .XLSX are allowed.',
        FILE_SIZE_ERROR: 'The file is too large. Size limit is 3MB.',
        MAX_FILE_AMOUNT_ERROR: `You cannot upload more than ${maxNoOfUploadedFiles} ${maxNoOfUploadedFiles === 1 ? `file` : 'files'}.`,
    };

    const showErrorToast = useCallback((fileErrorType) => {
        toast.openToast(
            toastErrorConfig.title,
            toastErrorConfig.position,
            errorDescriptions[fileErrorType],
            toastErrorConfig.type
        );
    }, [toast]);

    const handleDragIn = useCallback(event => {
        if (isDisabled) return;

        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.items
            && event.dataTransfer.items.length > 0) {
            setDragElementCount(prevCount => prevCount + 1);
        }
    }, [isDisabled]);

    const handleDragOut = useCallback(event => {
        if (isDisabled) return;

        event.preventDefault();
        event.stopPropagation();

        setDragElementCount(prevCount => prevCount - 1);
    }, [isDisabled]);

    const handleDragOver = useCallback(event => {
        if (isDisabled) return;

        event.preventDefault();
        event.stopPropagation();
    }, [isDisabled]);

    const handleDrop = useCallback(event => {
        if (isDisabled) return;

        event.preventDefault();
        event.stopPropagation();

        const droppedFiles = event.dataTransfer.files;

        if (maxNoOfUploadedFiles && droppedFiles.length > maxNoOfUploadedFiles) {
            showErrorToast(fileErrorTypes.MAX_FILE_AMOUNT_ERROR);
            setDragElementCount(0);
            event.dataTransfer.clearData();
            return;
        }

        if (droppedFiles && droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles);

            if (newFiles.find(file => !ALLOWED_MIME_TYPES.includes(file.type))) {
                showErrorToast(fileErrorTypes.FILE_TYPE_ERROR);
                setDragElementCount(0);
                event.dataTransfer.clearData();
                return;
            }

            if ((newFiles[0].size / 1024) > MAX_FILE_SIZE) {
                showErrorToast(fileErrorTypes.FILE_SIZE_ERROR);
                setDragElementCount(0);
                event.dataTransfer.clearData();
                return;
            }

            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            triggerOnChange.current = true;

            setDragElementCount(0);
            event.dataTransfer.clearData();
        }
    }, [isDisabled, showErrorToast]);

    const handleOnFileChange = event => {
        const selectedFiles = event.target.files;

        if (selectedFiles && selectedFiles.length > 0) {
            const newFiles = Array.from(selectedFiles);
            if ((newFiles[0].size / 1024) > MAX_FILE_SIZE) {
                showErrorToast(fileErrorTypes.FILE_SIZE_ERROR);
                return;
            }
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            triggerOnChange.current = true;
        }
    }

    const handleFileRemoval = index => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        triggerOnChange.current = true;
    };

    useEffect(() => {
        const dropZoneElement = dropZoneRef.current;

        if (!dropZoneElement) return;

        dropZoneElement.addEventListener('dragenter', handleDragIn);
        dropZoneElement.addEventListener('dragleave', handleDragOut);
        dropZoneElement.addEventListener('dragover', handleDragOver);
        dropZoneElement.addEventListener('drop', handleDrop);

        return () => {
            dropZoneElement.removeEventListener('dragenter', handleDragIn);
            dropZoneElement.removeEventListener('dragleave', handleDragOut);
            dropZoneElement.removeEventListener('dragover', handleDragOver);
            dropZoneElement.removeEventListener('drop', handleDrop);
        };
    }, [dropZoneRef, handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    useEffect(() => {
        if (triggerOnChange.current === true) {
            onChange(files);
            triggerOnChange.current = false;
        }
    }, [files, onChange]);

    useEffect(() => {
        if (!!value) {
            setFiles(Array.isArray(value) ? value : [value]);
            triggerOnChange.current = false;
        }
    }, [value, setFiles]);

    const isDraggingActive = dragElementCount > 0;

    return (
        <Fragment>
            {(files.length > 0 && files.length <= maxNoOfUploadedFiles) ? files.map((file, idx) => {
                return (
                    <div key={`${file.name}-${idx}`} className="flex justify-between border items-center rounded-[10px] border-contrast-300 bg-input-200 p-spona-12">
                        <span className="text-lg font-medium text-input-100">
                            {typeof value === 'string' ? value : file.name}
                        </span>
                        {shouldShowCloseButton && (
                            <Icon
                                icon={closeIcon}
                                className="mr-spona-44"
                                size="xs"
                                onClick={() => handleFileRemoval(idx)}
                            />
                        )}
                    </div>
                )
            }) : (
                <div
                    ref={dropZoneRef}
                    className={`${isDraggingActive ? 'border-input-800' : 'border-contrast-300'} ${dropZoneDefaultStyle}`}
                    onDrop={handleDrop}
                    onDragOver={(event) => event.preventDefault()}
                >
                    <Icon icon={cloudIcon} className="w-spona-100 h-spona-100" />
                    <div className="text-contrast-800 text-base font-medium mt-spona-20">
                        <input
                            type="file"
                            id="chooseFile"
                            onChange={e => {
                                handleOnFileChange(e);
                                e.target.value = '';
                            }}
                            accept={ACCEPTED_FILE_TYPES}
                            {...(maxNoOfUploadedFiles > 1 && {multiple: ''})}
                            hidden
                        />
                        <label
                            htmlFor="chooseFile"
                            className="text-input-100 font-bold cursor-pointer"
                        >Choose file</label> or <span className="text-input-100 font-bold">Drag here</span>
                    </div>
                    <p className="text-contrast-800 text-base font-medium mt-spona-4">Size limit: 3MB</p>
                </div>
            )}
        </Fragment>
    );
});

export default FileDropZone;
