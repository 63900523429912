import React, {useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {SVGIcon} from '../icons';
import { ReactComponent as VersionIcon } from '../../assets/icons/version-2-dark.svg';
import { ReactComponent as VersionPrimaryIcon } from '../../assets/icons/version-2-primary.svg';
import { ReactComponent as VersionWhiteIcon } from '../../assets/icons/version-2.svg';
import {useScreenResolution} from '../../utils/customHooks';

// ToDo: refactor logic for icon hover!
const NavigationTab = props => {
    const {children, to, hasIcon, isDisabled} = props;

    const location = useLocation();
    const { isDesktopScreen } = useScreenResolution();
    const [isHovered, setIsHovered] = useState(false);

    const defaultLinkStyle = 'px-spona-12 cursor-pointer lg:hover:text-highlighted-100';
    const disabledLinkStyle = `${isDesktopScreen ? 'text-contrast-800' : 'text-contrast-700'} px-spona-12 !cursor-auto`;
    const isLinkActive = location.pathname === to;

    const icon = (
        <div className={`inline-block select-none align-middle ${isDesktopScreen ? 'mb-spona-2 ml-spona-8' : 'ml-spona-4 mb-spona-8'}`}>
            <SVGIcon
                size={18}
                color={isLinkActive ? '#33BD9C' : '#8D8D8D'}
                className={'hover:fill-highlighted-100'}
            >
                {isDesktopScreen
                    ? (isHovered ? <VersionPrimaryIcon /> : <VersionIcon />)
                    : <VersionWhiteIcon />}
            </SVGIcon>
        </div>
    );

    return (
        <li>
            {!isDisabled ? (
                <NavLink
                    to={to}
                    exact='true'
                    className={({isActive}) =>
                        !isDisabled
                            ? `${defaultLinkStyle} ${isActive ? 'lg:text-highlighted-100' : ''}`
                            : disabledLinkStyle
                    }
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                >
                    {children}
                    {hasIcon && icon}
                </NavLink>
            ) : <div className={disabledLinkStyle}>{children}{hasIcon && icon}</div>}
        </li>
    )
}

export default NavigationTab;