import React, {useState} from 'react';
import {iconSizes} from './iconThemeConfig';

const Icon = React.forwardRef((props, ref) => {
    const {icon, onClick, className, size, hoverIcon} = props;
    const [isHovered, setIsHovered] = useState(false);

    const handleOnClick = event => {
        if (typeof onClick === 'function') {
            onClick(event);
        }
    };

    return (
        <i
            className={`inline-block select-none align-middle 
              ${className ? className : ''} 
              ${size ? iconSizes[size] : ''} 
              ${onClick ? 'cursor-pointer' : ''}`
            }
            onClick={handleOnClick}
            onMouseOver={() => !!hoverIcon && setIsHovered(true)}
            onMouseOut={() => !!hoverIcon && setIsHovered(false)}
            ref={ref}
        >
            {icon && (
                <img className={`w-full h-full`} src={hoverIcon && isHovered ? hoverIcon : icon} alt="icon" />
            )}
        </i>
    );
});

export default Icon;