import React from 'react';
import Icon from '../../ui-components/icons/Icon';

const Card = props => {
    const {icon, title, body} = props;

    return (
        <div className="mt-spona-40 text-default-100 md:mt-spona-0 flex items-center justify-center flex-col md:h-[360px] lg:h-[520px]">
            <div className="hidden lg:block">
                <Icon icon={icon} size="xl"/>
            </div>
            <div className="lg:hidden">
                <Icon icon={icon} size="l"/>
            </div>
            <h3 className="text-[28px] lg:text-[44px] font-extrabold mt-spona-20 mb-spona-16 lg:mt-spona-80">
                {title}
            </h3>
            <p className="text-center text-[22px] md:text-base lg:text-[22px] font-medium mb-spona-16 lg:mb-spona-0 lg:mt-spona-12">
                {body}
            </p>
        </div>
    )
}

export default Card;