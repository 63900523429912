import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {ToastNotificationContext} from './ToastNotificationContext';
import ToastsNotificationContainer from './ToastNotificationContainer';

export const ToastNotificationProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const [position, setPosition] = useState();
    const [openedToastIds, setOpenedToastIds] = useState([]);
    const location = useLocation();

    const openToast = (title, position, description, toastType, isCloseable = true, timeout = 5000) => {
        const id = Math.floor(Math.random() * 10000000);
        setOpenedToastIds([...openedToastIds, id]);
        setPosition(position);
        setToasts((currentToasts) => [
            ...currentToasts,
            { id, title, position, description, toastType, isCloseable },
        ]);
        if (isCloseable) setTimeout(() => closeToast(id), timeout);
    }

    const closeToast = toastId => {
        setToasts((toasts) =>
            toasts.filter((toast) => toast.id !== toastId)
        );
    }

    useEffect(() => {
        if (openedToastIds?.length) {
            openedToastIds.map(id => closeToast(id));
        }
    }, [location])

    return (
        <ToastNotificationContext.Provider value={{openToast, closeToast}}>
            <ToastsNotificationContainer toasts={toasts} position={position} />
            {children}
        </ToastNotificationContext.Provider>
    );
};