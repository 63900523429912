import React from 'react';

const ControllingDataContent = () => {
    return (
        <div className="text-lg font-medium text-default-100">
            <p className="mb-spona-24">
                We empower you with advanced tools and options to manage the data you collect through our platform, ensuring that you have full control and oversight. <br/>
                Here’s how you can manage your data with SPONA:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Access to Your Data:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">User Dashboard:</span> Our user-friendly dashboard allows you to access and review all the data collected through your lead generation campaigns. This includes lead information, campaign statistics, and more.
                        </li>
                    </ul>
                    <li>Data Modification and Deletion:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Edit and Update:</span> You have the capability to edit or update the data as needed. This ensures that your lead information is always current and accurate.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Deletion Requests:</span> If you wish to remove any data from our system, you can easily do so. We provide straightforward options for data deletion, respecting your right to data erasure.
                        </li>
                    </ul>
                    <li>Data Portability:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Export Options:</span> SPONA enables you to export your data in commonly used formats. This feature facilitates data portability, allowing you to use your data across different platforms or for offline analysis.
                        </li>
                    </ul>
                    <li>Data Privacy Compliance:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Regulatory Adherence:</span> Our platform is designed to comply with GDPR and other major data protection regulations, ensuring that the data you manage through SPONA is handled in a legally compliant manner.
                        </li>
                    </ul>
                    <li>Security Measures:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Protection of Your Data:</span> We employ the most advanced security measures to protect the data within our platform, ensuring that your information is safe from unauthorized access or breaches.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className="text-lg font-medium text-default-100 mt-spona-24">
                We are committed to providing you with the tools and capabilities to effectively control and manage your data, while maintaining the highest standards of data protection and compliance.
                Your ability to manage your data with ease and confidence is a key aspect of our service.
            </p>
        </div>
    )
}

export default ControllingDataContent;