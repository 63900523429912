import React from 'react';
import {ToastNotificationProvider} from './ToastNotificationProvider';

const withToastNotificationProvider = BaseComponent => {
    const ComposedComponent = React.forwardRef((props, ref) => {
        return (
            <ToastNotificationProvider baseComponentRef={ref}>
                <BaseComponent {...props} ref={ref} />
            </ToastNotificationProvider>
        );
    });

    ComposedComponent.displayName = `withToastNotificationProvider(${BaseComponent.displayName || BaseComponent.name})`;

    ComposedComponent.propTypes = {
        ...BaseComponent.propTypes,
    };

    ComposedComponent.defaultProps = {
        ...BaseComponent.defaultProps,
    };

    return ComposedComponent;
};

export default withToastNotificationProvider;
