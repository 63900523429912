import React from 'react';
import {buttonTypes} from './buttonThemeConfig';

const Button = props => {
    const {type, onClick, isDisabled, children, buttonType, className, onMouseOver, onMouseOut} = props;

    return (
        <button
            type={type}
            className={`${buttonTypes[buttonType]} ${className ? className : ''} ${isDisabled ? 'cursor-auto opacity-50' : 'cursor-pointer'}`}
            onClick={onClick}
            disabled={isDisabled}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {children}
        </button>
    );
}

export default Button;