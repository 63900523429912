import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button, Input, Panel} from '../ui-components';
import inputTypes from '../ui-components/text-inputs/inputTypes';
import {validateFields} from '../utils';
import {getCurrentUser, updatePassword} from './authActions';
import config from '../config';
import routePaths from '../routing/routePaths';
import passwordInfoTypes from '../ui-components/text-inputs/passwordInfoTypes';
import {modalTypes} from '../modalTypes';
import {useGlobalModalContext} from '../ui-components/modals/useGlobalModal';
import {selectCurrentUser} from './authSlice';
import userRoleTypes from '../entites/user-profile/userRoleTypes';

const UserAccountScreen = () => {
    const [newPassword, setNewPassword] = useState();
    const [newPasswordError, setNewPasswordError] = useState('');
    const { openModal } = useGlobalModalContext();
    const didComponentMount = useRef(false);
    const isAuthenticated = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchCurrentUser = async () => {
        try {
            const user = await dispatch(getCurrentUser()).unwrap();
            if (user?.role !== userRoleTypes.CLIENT.toLowerCase()) {
                navigate(routePaths.LOGIN);
            }

        } catch (error) {
            navigate(routePaths.LOGIN);
        }
    }

    useEffect(() => {
        if (!didComponentMount.current) {
            fetchCurrentUser();
            didComponentMount.current = true;
        }
    })

    useEffect(() => {
        setNewPasswordError('');
    }, [newPassword])

    const handleOnPasswordChange = async () => {
        if (newPasswordError || !isAuthenticated) return;

        try {
            await dispatch(updatePassword({password: newPassword})).unwrap();
            navigate(routePaths.LOGIN);
        } catch (error) {
            if (error.statusCode === config.STATUS_CODE_404) {
                navigate(routePaths.PAGE_NOT_FOUND);
            }
        }
    }

    const handleOnLogOut = () => {
        if (!isAuthenticated) return;

        if (localStorage.getItem('accessToken')) {
            localStorage.removeItem('accessToken');
            navigate(routePaths.ROOT);
        }
    }

    const handleOnDeleteAccount = () => {
        openModal(modalTypes.DELETE_ACCOUNT);
    }

    return (
        <div className="flex items-center justify-center">
            <div className="grid grid-cols-12 gap-spona-64">
                <Panel
                    className="col-span-6 px-spona-44 py-spona-24"
                    title="Change password"
                    contentClassName='flex flex-col items-center justify-between h-full'
                >
                    <div className="w-full">
                        <Input
                            name="password"
                            label="New password"
                            placeholder='Your new password'
                            className='w-full'
                            inputType={inputTypes.PASSWORD.toLowerCase()}
                            value={newPassword || ''}
                            onChange={setNewPassword}
                            passwordInfo = {newPasswordError ? passwordInfoTypes.SHOW_PASSWORD_CONSTRAINS : ''}
                            onBlur={e => {
                                const errorMessage = validateFields(e.target.value, e.target.name);
                                if (!errorMessage) return;
                                setNewPasswordError(errorMessage);
                            }}
                            error={!!newPasswordError ? newPasswordError : ''}
                        />
                    </div>
                    <Button
                        buttonType='primary'
                        className="w-full mt-auto self-end"
                        onClick={handleOnPasswordChange}
                        isDisabled={!newPassword}
                    >
                        Update password
                    </Button>
                    <p className="text-contrast-800 text-base font-medium mt-spona-12 self-start">
                        Automatically changes your password and logs you out
                    </p>
                </Panel>
                <Panel
                    className="col-span-6 px-spona-44 py-spona-24"
                    title="Billing information"
                >
                    <div className="flex flex-col">
                        <p className="text-[26px] text-default-100 font-medium mt-spona-32">
                            Edit billing information and manage credit cards
                        </p>
                        <Button
                            buttonType='primary'
                            className="w-full mt-[140px]"
                            onClick={() => navigate(routePaths.BILLING_PORTAL_SECTION)}
                        >
                            Open billing portal
                        </Button>
                    </div>
                </Panel>
                <Panel className="col-span-6 px-spona-44 py-spona-32">
                    <Button buttonType='primary' className="w-full" onClick={handleOnLogOut}>
                        Log out
                    </Button>
                    <p className="text-contrast-800 text-base font-medium mt-spona-12">
                        Automatically logs out
                    </p>
                    <Button buttonType='warning' className="w-full mt-spona-44" onClick={handleOnDeleteAccount}>
                        Delete account
                    </Button>
                </Panel>
            </div>
        </div>
    )
}

export default UserAccountScreen;