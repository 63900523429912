import React, {Fragment, useState} from 'react';
import {useMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {constantCase, sentenceCase} from 'change-case';
import {cardBrandTypes} from '../../entites/stripe';
import {Button, FieldSet, FieldSetInput, Icon} from '../../ui-components';
import borderDirectionTypes from '../../ui-components/text-inputs/borderDirectionTypes';
import {selectPaymentMethod} from '../checkoutSlice';
import {americanExpressIcon, mastercardIcon, visaIcon} from '../../assets/icons';
import checkoutButtonActionTypes from '../checkoutButtonActionTypes';
import {createPaymentIntent, detachPaymentMethod, getPaymentMethod} from '../checkoutActions';
import {homeScreenTabs} from '../../home/homeScreenTabs';
import routePaths from '../../routing/routePaths';
import {
    selectLeadsPricing,
    selectProspectsPricing,
} from '../../custom-made-prospect-lists/customMadeProspectListsSlice';
import {modalTypes} from '../../modalTypes';
import {useGlobalModalContext} from '../../ui-components/modals/useGlobalModal';

const cardIconVariants = {
    [cardBrandTypes.MASTERCARD]: mastercardIcon,
    [cardBrandTypes.VISA]: visaIcon,
    [cardBrandTypes.AMERICAN_EXPRESS]: americanExpressIcon,
}

const SavedPaymentMethod = props => {
    const {buttonAction} = props;
    const match = useMatch(routePaths.CHECK_OUT);
    const dispatch = useDispatch();
    const { openModal } = useGlobalModalContext();

    const paymentMethod = useSelector(selectPaymentMethod);
    const leadsPricingList = useSelector(selectLeadsPricing);
    const prospectsPricing = useSelector(selectProspectsPricing);

    const handleOnClick = async (e) => {
        e.preventDefault();

        if (buttonAction === checkoutButtonActionTypes.PAY) {
            if (!match) return;
            const {priceId, questionnaireId} = match.params;
            let hasPaymentFailed = false;
            let isProspectProduct = false;

            try {
                await dispatch(createPaymentIntent({prospectId: priceId, questionnaireId: questionnaireId})).unwrap();
                isProspectProduct = prospectsPricing?._id === priceId;
            } catch (error) {
                hasPaymentFailed = true;
            }

            openModal(modalTypes.PAYMENT_ACTION, {
                hasPaymentFailed,
                purchasedProduct: {
                    route: routePaths[!isProspectProduct ? 'CUSTOM_MADE_PROSPECT_LISTS' : 'APPOINTMENT_SETTING'],
                    name: homeScreenTabs[!isProspectProduct ? 'CUSTOM_MADE_PROSPECT_LISTS' : 'APPOINTMENT_SETTING'],
                },
            });
            return;
        }

        // Detach payment method
        try {
            await dispatch(detachPaymentMethod()).unwrap();
            await dispatch(getPaymentMethod()).unwrap();
        } catch (e) {
            // no-op
        }
    }

    const formatExpirationDate = (month, year) => {
        if (!month || !year) return;

        const formattedMonth = parseInt(month, 10) < 10 ? `0${month}` : `${month}`;
        const formattedYear = year.toString().slice(-2);

        return `${formattedMonth}/${formattedYear}`;
    }

    if (!paymentMethod) return null;

    const {card} = paymentMethod;

    return (
        <Fragment>
            <div>
                <FieldSet label="Card details">
                    <div className="relative">
                        <FieldSetInput
                            name="cardNumber"
                            borderDirection={borderDirectionTypes.BOTTOM}
                            value={`**** **** **** ${card.last4}`}
                        />
                        <div className='absolute top-[14px] right-spona-12'>
                            <Icon
                                icon={cardIconVariants[constantCase(card.brand)]}
                                className={card.brand !== 'mastercard' ? 'mr-spona-4' : ''}
                            />
                        </div>
                    </div>
                    <FieldSetInput
                        name="expirationDate"
                        value={formatExpirationDate(card.exp_month, card.exp_year)}
                    />
                </FieldSet>
                <Button buttonType='primary' className={'w-full'} onClick={e => handleOnClick(e)}>
                    {sentenceCase(buttonAction)}
                </Button>
            </div>
        </Fragment>
    )
}

export default SavedPaymentMethod;