export const iconSizes = {
    xs: "w-spona-20 h-spona-20",
    s: "w-spona-24 h-spona-24",
    sm: "w-spona-32 h-spona-32",
    m: "w-spona-44 h-spona-44",
    l: "w-spona-128 h-spona-128",
    xl: "w-spona-176 h-spona-176",
};

export const iconColors = {
    success: '!fill-highlighted-100',
    warning: '!fill-warning-100',
    informative: '!fill-blue-100',
};