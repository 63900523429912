import React from 'react';
import {useScreenResolution} from '../../utils/customHooks';

const Main = ({children}) => {
    const {isDesktopScreen} = useScreenResolution();
    return (
        <div className={`${!isDesktopScreen && "z-50"} w-full h-full relative px-spona-20 py-spona-80 md:px-spona-48 lg:px-spona-80 lg:pt-spona-176 lg:pb-spona-80`}>
            {children}
        </div>
    )
}

export default Main;