import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Modal} from '../../ui-components/modals';
import {Icon} from '../../ui-components';
import {backIcon, warningIcon, checkmarkRounded} from '../../assets/icons';

const PaymentActionModal = props => {
    const {closeModal, hasPaymentFailed, purchasedProduct = null} = props;
    const navigate = useNavigate();

    const handleOnRedirect = () => {
        if (hasPaymentFailed) {
            closeModal();
            return;
        }

        if (!purchasedProduct) return;
        navigate(purchasedProduct.route);
        closeModal();
    };

    return (
        <Modal
            hasBackdrop={true}
            title={`Payment ${hasPaymentFailed ? 'failed' : 'complete'}`}
            closeModal={closeModal}
            headerIcon={{
                icon: hasPaymentFailed ? warningIcon : checkmarkRounded,
                size: 'm'
            }}
        >
            <div className="text-contrast-800 text-lg my-spona-24">
                {hasPaymentFailed
                    ? <p>We are sorry, there was an error processing your payment.<br/>Please try again with a different payment method.</p>
                    : <p>Congratulations, your payment has been completed!</p>}
            </div>
            <div className="border-t border-contrast-600 mb-spona-24" />
            <div className="flex items-center justify-end cursor-pointer" onClick={handleOnRedirect}>
                <Icon icon={backIcon} />
                <span className="ml-spona-8 text-input-100 font-semibold text-lg underline decoration-from-font">
                    {hasPaymentFailed || !purchasedProduct
                        ? 'Back to the stripe'
                        : `Back to ${purchasedProduct.name?.toLowerCase()}`}
                </span>
            </div>
        </Modal>
    )
};

PaymentActionModal.propTypes = {
    hasPaymentFailed: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    productScreen: PropTypes.object,
};

PaymentActionModal.defaultProps = {
    productScreen: null,
};

export default PaymentActionModal;