import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import config from '../config';

const baseURL = `${config.DEV_BASE_URL}`;

export const sendMessage = createAsyncThunk(
    '/chat-bot',
    async ({ email, message }, { rejectWithValue }) => {
        try {
           await axios.post(
                `${baseURL}/chatbot`,
                {
                    ...(email ? {email: email} : undefined),
                    ...(message ? {message: message} : undefined),
                },
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data});
            } else {
                return rejectWithValue({payload: error.message});
            }
        }
    }
);