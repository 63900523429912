import React, {useState, useRef} from 'react';
import DropdownContext from './DropdownContext';

const DropdownProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropDownRef = useRef();

    const toggleDropDown = newState => {
        setIsOpen(prevState => (typeof newState !== 'undefined' ? newState : !prevState));
    };

    const openDropDown = () => {
        setIsOpen(true);
    };

    const closeDropDown = () => {
        if (!dropDownRef.current) return;
        setIsOpen(false);
    };

    return (
        <DropdownContext.Provider
            value={{
                dropDownRef,
                isOpen,
                toggleDropDown,
                openDropDown,
                closeDropDown,
            }}
        >
            {children}
        </DropdownContext.Provider>
    );
}

export default DropdownProvider;