import React from 'react';
import routePaths from './routePaths';
import HomeScreen from '../home/HomeScreen';
import FAQScreen from '../faq/FAQScreen';
import ProtectedRouteWrapper from '../authentification/ProtectedRouteWrapper';
import AuthenticationScreen from '../authentification/AuthenticationScreen';
import TokenVerificationScreen from '../authentification/TokenVerificationScreen';
import PageNotFound from '../application/PageNotFound';
import SubcategoryScreen from '../faq/SubcategoryScreen';
import TermsAndConditionsScreen from '../terms-and-privacy/TermsAndConditionsScreen';
import DataPrivacyScreen from '../terms-and-privacy/DataPrivacyScreen';
import CustomMadeProspectListsScreen from '../custom-made-prospect-lists/CustomMadeProspectListsScreen';
// import AppointmentSettingScreen from '../appointment-setting/AppointmentSettingScreen';
import PricingScreen from '../pricing/PricingScreen';
import CheckoutScreen from '../checkout/CheckoutScreen';
import UserAccountScreen from '../authentification/UserAccountScreen';
import UserBillingPortalScreen from '../authentification/UserBillingPortalScreen';
import QuestionnaireScreen from '../questionnaire-one/QuestionnaireScreen';
import CompleteQuestionnaireScreen from '../questionnaire-one/CompleteQuestionnaireScreen';
import QuestionnaireLandingScreen from '../questionnaire-one/QuestionnaireLandingScreen';
import AdminLeadsScreen from '../admin/AdminLeadsScreen';
import LeadsPricingList from '../custom-made-prospect-lists/ui-elements/LeadsPricingList';
import TokenAlreadyActivatedPage from '../application/TokenAlreadyActivatedPage';
import VerfiyYourAccountPage from '../application/VerfiyYourAccountPage';

const routes = [
    /* Public routes */
    {
        path: routePaths.ROOT,
        element: <HomeScreen />,
        exact: true,
    },
    {
        path: routePaths.LOGIN,
        element: <AuthenticationScreen />,
        exact: true,
    },
    {
        path: routePaths.REGISTER,
        element: <AuthenticationScreen />,
        exact: true,
    },
    {
        path: routePaths.EMAIL_VERIFICATION,
        element: <AuthenticationScreen />,
        exact: true,
    },
    {
        path: routePaths.FORGOT_PASSWORD,
        element: <AuthenticationScreen />,
        exact: true,
    },
    {
        path: routePaths.SUCCESSFUL_VERIFICATION,
        element: <TokenVerificationScreen />,
        exact: true,
    },
    {
        path: routePaths.CHANGE_PASSWORD,
        element: <AuthenticationScreen />,
        exact: true,
    },
    {
        path: routePaths.FAQ_SECTION,
        routes: [
            {
                path: routePaths.FAQ,
                element: <FAQScreen />,
                exact: true,
            },
            {
                path: routePaths.FAQ_SUBCATEGORIES,
                element: <SubcategoryScreen />,
                exact: true,
            }
        ],
    },
    {
        path: routePaths.CUSTOM_MADE_PROSPECT_LISTS,
        element: <CustomMadeProspectListsScreen />,
        exact: true,
    },
    {
        path: routePaths.APPOINTMENT_SETTING,
        // ToDo: [IMPORTANT] Switch to old code when product two is ready!
        // element: <AppointmentSettingScreen />,
        element: <PageNotFound />,
        exact: true,
    },
    {
        path: routePaths.PRICING,
        element: <PricingScreen />,
        exact: true,
    },
    {
        path: routePaths.DATA_PRIVACY,
        element: <DataPrivacyScreen />,
        exact: true,
    },
    {
        path: routePaths.TERMS_AND_CONDITIONS,
        element: <TermsAndConditionsScreen />,
        exact: true,
    },
    {
        path: routePaths.QUESTIONNAIRE_ONE,
        element: <QuestionnaireScreen />,
        exact: true,
    },
    {
        path: routePaths.COMPLETE_QUESTIONNAIRE,
        element: <CompleteQuestionnaireScreen />,
        exact: true,
    },
    {
        path: routePaths.START_QUESTIONNAIRE_ONE,
        element: <QuestionnaireLandingScreen />,
        exact: true,
    },
    {
        path: routePaths.TOKEN_ALREADY_ACTIVATED,
        element: <TokenAlreadyActivatedPage />,
        exact: true,
    },
    {
        path: routePaths.VERIFY_YOUR_ACCOUNT,
        element: <VerfiyYourAccountPage />,
        exact: true,
    },
    {
        routes: [
           /* Here goes protected routes */
            {
                path: routePaths.CHECK_OUT,
                element: <ProtectedRouteWrapper><CheckoutScreen /></ProtectedRouteWrapper>,
                exact: true,
            },
            {
                path: routePaths.ACCOUNT,
                element: <ProtectedRouteWrapper><UserAccountScreen /></ProtectedRouteWrapper>,
                exact: true,
            },
            {
                path: routePaths.BILLING_PORTAL,
                element: <ProtectedRouteWrapper><UserBillingPortalScreen /></ProtectedRouteWrapper>,
                exact: true,
            },
            {
                path: routePaths.ADMIN_LEADS,
                element: <ProtectedRouteWrapper><AdminLeadsScreen /></ProtectedRouteWrapper>,
                exact: true,
            },
            {
                path: routePaths.CUSTOM_MADE_PROSPECT_LISTS_CHECKOUT,
                element: <ProtectedRouteWrapper><LeadsPricingList /></ProtectedRouteWrapper>,
                exact: true,
            }
        ]
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
]

export default routes;