import React from 'react';

const withDefaultStyles = (Component, defaultClasses = '') => {
    return function WithDefaultStyles({ className = '', ...props }) {
        const combinedClassName = `${defaultClasses} ${className}`;

        return <Component {...props} className={combinedClassName} />;
    }
};

export default withDefaultStyles;