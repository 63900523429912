import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Icon, SponaLogo} from '../ui-components';
import {backLightIcon} from '../assets/icons';
import routePaths from '../routing/routePaths';

const DataPrivacyScreen = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');
    const headingStyle = 'text-2xl font-bold text-default-100 mb-spona-24';
    const paragraphStyle = 'text-lg font-medium text-default-100 mb-spona-24';

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="px-spona-40 lg:px-spona-80 py-spona-24">
            <SponaLogo {...(!token && {onClick: () => navigate(routePaths.ROOT)})} />
            <div className="my-spona-24 flex flex-col md:flex-row items-start md:items-center">
                <Icon
                    icon={backLightIcon}
                    size='m'
                    className='lg:ml-[-32px]'
                    onClick={() => navigate(-1)}
                />
                <span
                    className="text-[32px] font-bold text-default-100 mt-spona-20 md:ml-spona-20 md:mt-spona-0 lg:ml-spona-64"
                >
                    Data Privacy
                </span>
            </div>
            <div>
                <h2 className={headingStyle}>
                    Our Unwavering Commitment to Data Privacy
                </h2>
                <p className={paragraphStyle}>
                    At SPONA, we profoundly recognize the paramount significance of privacy and are unwaveringly dedicated to upholding
                    the trust and assurance of our esteemed clientele and website patrons.
                    This elaborate Data Privacy Policy meticulously delineates the methodologies by which we acquire,
                    utilize, safeguard, and manage personal information in stringent adherence to the pertinent data protection statutes.
                </p>
                <h2 className={headingStyle}>Detailed Enumeration of Information Acquisition</h2>
                <ul className={`list-disc ml-spona-24 ${paragraphStyle}`}>
                    <li>
                        Data Proactively Furnished by You: This encompasses the specifics tendered by yourself during the registration phase for our services, such as your nomenclature,
                        electronic mailing address, and intricate details pertaining to your corporate entity.
                    </li>
                    <li>
                        Data Accrued Automatically: We autonomously accumulate certain datum upon your digital ingress to our website, such as your
                        Internet Protocol address, comprehensive information regarding your device, and an array of usage statistics.
                    </li>
                    <li>
                        Lead-Related Data: As a vanguard in lead generation services, we are engaged in the collection and processing of data pertinent to potential leads.
                        This data acquisition and utilization is performed in unwavering compliance with the relevant data protection legislations.
                    </li>
                </ul>
                <h2 className={headingStyle}>Utilization of Your Data</h2>
                <ul className={`list-disc ml-spona-24 ${paragraphStyle}`}>
                    <li>
                        Provision of Services: Our primary objective is to furnish and incessantly enhance our Software as a Service (SaaS) solutions.
                    </li>
                    <li>
                        Customer Support and Assistance: We are steadfast in providing customer support and addressing queries with utmost efficiency.
                    </li>
                    <li>
                        Marketing Communications: Contingent upon your explicit consent, we may periodically dispatch updates and promotional materials germane to our services.
                    </li>
                    <li>
                        Legal Compliance and Protection: Our operations are in conformity with legal mandates, ensuring the safeguarding of our rights as well as those of our users.
                    </li>
                </ul>
                <h2 className={headingStyle}>Data Sharing and Disclosure Modalities</h2>
                <p className={paragraphStyle}>
                    We categorically refrain from selling or leasing personal information to external entities. Data sharing is confined to:
                </p>
                <ul className={`list-disc ml-spona-24 ${paragraphStyle}`}>
                    <li>
                        Service Providers: Collaborations with third-party entities, who render services under our aegis, are stringently bound by data protection accords.
                    </li>
                    <li>
                        Legal Adherence: In instances mandated by legal injunctions or for the protection of the rights and safety of our enterprise, clientele, or others.
                    </li>
                </ul>
                <h2 className={headingStyle}>Data Security Protocols</h2>
                <p className={paragraphStyle}>
                    We employ a multifaceted and robust array of security measures to thwart unauthorized access, modification, disclosure, or obliteration of your personal information.
                </p>
                <h2 className={headingStyle}>Your Entitlements and Rights</h2>
                <p className={paragraphStyle}>
                    You retain the unequivocal right to request access, rectification, deletion, or limitation in the usage of your personal data.
                    Should you desire to exercise these rights or if you harbor any inquiries regarding our data privacy protocols, we cordially invite you to reach out to us at legal@sponaglobal.com
                </p>
                <h2 className={headingStyle}>Policy Amendments</h2>
                <p className={paragraphStyle}>
                    We reserve the right to periodically revise this policy to reflect alterations in our operational practices or in response to evolving legal requisites.
                    We fervently encourage a periodic review of this document.
                </p>
                <h2 className={headingStyle}>Engage with Us</h2>
                <p className={paragraphStyle}>
                    For an in-depth understanding of our privacy practices or to address any queries, we warmly invite you to establish contact with us at legal@sponaglobal.com
                </p>
            </div>
        </div>
    )
}

export default DataPrivacyScreen;