import React from "react";
import routePaths from '../routing/routePaths';
import {useSelector} from 'react-redux';
import {selectIncompleteQuestionnaire} from './questionnaireOneSlice';
import {resolveRoute} from '../utils';

const linkStyle = "underline decoration-1 underline-offset-2 text-input-100 text-base font-semibold";
const toastInfoTypes = {
    NO_MORE_LEADS: 'NO_MORE_LEADS',
    OUT_OF_CREDITS: 'OUT_OF_CREDITS',
    CAPACITY_REACHED: 'CAPACITY_REACHED',
    QUESTIONNAIRE_INCOMPLETE: 'QUESTIONNAIRE_INCOMPLETE',
}

const useDashboardToastConfig = () => {
    const incompleteQuestionnaire = useSelector(selectIncompleteQuestionnaire);
    const questionnaireOneId = incompleteQuestionnaire?._id || '';
    const questionNo = incompleteQuestionnaire
        ? Object.entries(incompleteQuestionnaire)
            .filter((entry) => entry[0]?.includes('q'))
            ?.find(entry => (Array.isArray(entry[1]) && !entry[1].length) || (typeof entry[1] === 'string' && !entry[1]))
            ?.[0]
        : 'q1';

    const {pathname} = questionnaireOneId && resolveRoute(routePaths.QUESTIONNAIRE_ONE, {
        questionnaireOneId,
        questionNo: questionNo || 'q1',
    });

    const userDashboardToastConfig = {
        [toastInfoTypes.NO_MORE_LEADS]: {
            title: "No more leads",
            description: "Feel free to fill in another questionnaire, we could not find any more leads matching your last target profile.",
            type: "informative",
        },
        [toastInfoTypes.OUT_OF_CREDITS]: {
            title: "Out of credits",
            description: "Make a purchase in order to define another target profile.",
            type: "informative",
        },
        [toastInfoTypes.CAPACITY_REACHED]: {
            title: "Capacity reached",
            description: "Wait until the analysis is completed to define another target profile.",
            type: "informative",
        },
        [toastInfoTypes.QUESTIONNAIRE_INCOMPLETE]: {
            title: "Questionnaire incomplete",
            description: <>Please <a className={linkStyle} href={pathname}>click here</a> to complete.</>,
            type: "warning",
        },
    }

    return {
        toastInfoTypes,
        userDashboardToastConfig,
    };
};

export default useDashboardToastConfig;