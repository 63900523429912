/**
 * @typedef {string} passwordInfoTypes
 */

/**
 * Password info types
 *
 * @enum {passwordInfoTypes}
 */
export default {
    SHOW_PASSWORD: 'SHOW_PASSWORD',
    SHOW_PASSWORD_CONSTRAINS: 'SHOW_PASSWORD_CONSTRAINS',
};
