import ToastNotification from './ToastNotification';
import {toastPositionType} from './toastNotificationThemeConfig';

const positionStyleMap = {
    [toastPositionType.TOP_RIGHT]: 'top-spona-12 right-spona-12',
    [toastPositionType.BELOW_HEADER]: 'top-[92px] right-spona-12',
    [toastPositionType.BOTTOM_LEFT]: 'bottom-spona-12 left-spona-12',
}

const ToastsNotificationContainer = ({ toasts, position }) => {
    return (
        <div className={`fixed ${positionStyleMap[position]} flex flex-col gap-spona-12 overflow-hidden`}>
            {toasts.map((toast) => (
                <ToastNotification key={toast.id} {...toast} />
            ))}
        </div>
    );
};

export default ToastsNotificationContainer;
