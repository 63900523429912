import React from 'react';
import {pillTypes} from './pillThemeConfig';
import {camelCase} from 'change-case';

const pillStates = ['negative', 'pending', 'positive'];

const Pill = props => {
    const {children, type, className} = props;

    return (
        <div className={`${pillTypes['positive']} ${className || ''} ${type && pillStates.includes(type) ? pillTypes[type] : ''} min-w-[100px] rounded-[20px] px-spona-12 py-[6px]`}>
            <div className='w-full text-[12px] text-center whitespace-nowrap overflow-hidden text-ellipsis'>
                {children}
            </div>
        </div>
    );
};

export default Pill;