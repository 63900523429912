/**
 * @typedef {string} outstandingThresholdTypes
 */

/**
 * Outstanding threshold types
 *
 * @enum {outstandingThresholdTypes}
 */
export default {
    S: '<500',
    L: '500+',
}