import React, {useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectSpecificPricing, selectTargetedPricing} from '../customMadeProspectListsSlice';
import PricingCard from '../../pricing/ui-elements/PricingCard';
import PricingList from '../../pricing/ui-elements/PricingList';
import routePaths from '../../routing/routePaths';
import {useMatch, useNavigate} from 'react-router-dom';
import {resolveRoute} from '../../utils';
import {getProspectList} from '../customMadeProspectListsActions';
import {getCurrentUser} from '../../authentification/authActions';
import {SponaLogo} from '../../ui-components';
import {getPaymentMethod} from '../../checkout/checkoutActions';
import {selectPaymentMethod} from '../../checkout/checkoutSlice';

const pricingPackageInclusions = {
    FAST_DELIVERY: {
        description: 'Every lead matches your target profile, delivered in < 48 hours'
    },
    MOBILE_NO: {
        description: 'All mobile phone numbers we can find'
    },
    VERIFIED_BUSINESS_EMAILS: {
        description: 'Verified business emails'
    },
    LINKEDIN_PROFILES: {
        description: 'LinkedIn profiles'
    },
    CORRECT_EMAILS: {
        description: '96+% emails are correct'
    },
    COMPANY_PHONE_NO: {
        description: 'Company phone numbers'
    },
    CORRECT_PHONE_NO: {
        description: '75+% mobile numbers are correct'
    },
    NO_DUPLICATE_LEADS: {
        description: 'No duplicate leads'
    },
    MONEY_BACK_GUARANTEE: {
        description: '14-day money-back guarantee'
    },
    OTHER: {
        description: 'Other available personal- & company data'
    },
}

const LeadsPricingList = () => {
    const didComponentMountRef = useRef(false);
    const match = useMatch(routePaths.CUSTOM_MADE_PROSPECT_LISTS_CHECKOUT);
    const outstanding = match.params?.outstanding;
    const questionnaireId = match.params?.questionnaireId;
    const navigate = useNavigate();
    const targetedPricing = useSelector(selectTargetedPricing);
    const specificPricing = useSelector(selectSpecificPricing);
    const hasUserPaymentMethod = useSelector(selectPaymentMethod);

    const dispatch = useDispatch();

    useEffect( () => {
        if (!didComponentMountRef.current) {
            const getCustomMadeProspectList = async () => {
                try {
                    const currentUser = await dispatch(getCurrentUser()).unwrap();
                    if (!currentUser) return;
                    await dispatch(getProspectList()).unwrap();
                    await dispatch(getPaymentMethod()).unwrap();
                }
                catch (error) {
                    // Todo: Handle error
                }
            }
            getCustomMadeProspectList();
            didComponentMountRef.current = true;
        }
    });

    if (!outstanding) return null;

    const pricingCards = outstanding === 'targeted' ? targetedPricing : specificPricing;

    return (
        <div className="flex flex-col">
            <div className="pl-spona-32 pt-spona-32">
                <SponaLogo />
            </div>
            <div className="p-spona-64">
                <div className={`flex justify-center items-center gap-spona-24 mb-spona-48`}>
                    {pricingCards?.length > 0 && pricingCards.map(pricingCard =>
                        <PricingCard
                            key={pricingCard._id}
                            noOfLeads={pricingCard.leads}
                            price={pricingCard.price}
                            outstanding={pricingCard.outstanding}
                            discount={pricingCard.discount}
                            title={pricingCard.title}
                            buttonName="checkout"
                            onClick={() => navigate(resolveRoute(hasUserPaymentMethod
                                ? routePaths.CHECK_OUT
                                : routePaths.BILLING_PORTAL,
                                { priceId: pricingCard._id, questionnaireId: questionnaireId },
                            ))}
                        />
                    )}
                </div>
                <h1 className="text-default-100 font-black text-[44px] text-center mb-spona-48">Included in all plans</h1>
                <div className="flex justify-center">
                    <PricingList listItems={pricingPackageInclusions} />
                </div>
            </div>
        </div>
    )
}

export default LeadsPricingList;