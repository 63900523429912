import React, {useState, useEffect} from 'react';
import {ModalContext} from './ModalContext';
import modals from '../../modals';
import {useLocation} from 'react-router-dom';

export const ModalProvider = ({ children }) => {
    const [modalParams, setModalParams] = useState();
    const location = useLocation();
    let currentPathname;

    useEffect(() => {
        if (currentPathname && location.pathname !== currentPathname) {
            closeModal();
        }
    }, [location])

    const openModal = (modalType, modalProps) => {
        currentPathname = location.pathname;
        setModalParams({
            ...modalParams,
            modalType,
            modalProps,
        });
    }

    const closeModal = () => {
        setModalParams({
            ...modalParams,
            modalType: null,
            modalProps: {},
        });
    }

    const renderComponent = () => {
        if (!modalParams) return null;
        const { modalType, modalProps } = modalParams;
        if (!modalType) return null;
        const Modal = modals[modalType];
        if (!Modal) return null;
        return <Modal {...modalProps} closeModal={closeModal} />;
    };

    return (
        <ModalContext.Provider value={{openModal, closeModal, modalParams}}>
            {renderComponent()}
            {children}
        </ModalContext.Provider>
    );
};