import { createSlice } from "@reduxjs/toolkit";
import {getPaymentMethod, getPaymentList, setupPaymentIntent, createPaymentIntent} from './checkoutActions';

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        paymentMethod: null,
        error: null,
        loadingPaymentMethod: false,
        loadingPaymentList: false,
        paymentList: [],
        clientSecret: '',
        paymentSecret: '',
        loadingPaymentSecret: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPaymentMethod.fulfilled, (state, { payload }) => {
            state.paymentMethod =  payload;
            state.loadingPaymentMethod =  false;
        })
        builder.addCase(getPaymentMethod.pending, (state) => {
            state.loadingPaymentMethod =  true;
        })
        builder.addCase(getPaymentMethod.rejected, (state, { payload }) => {
            state.error = payload;
            state.loadingPaymentMethod =  false;
        })
        builder.addCase(getPaymentList.fulfilled, (state, { payload }) => {
            state.paymentList =  payload;
            state.loadingPaymentList = false;
        })
        builder.addCase(getPaymentList.pending, (state, { payload }) => {
            state.loadingPaymentList = true;
        })
        builder.addCase(getPaymentList.rejected, (state, { payload }) => {
            state.loadingPaymentList = false;
        })
        builder.addCase(setupPaymentIntent.fulfilled, (state, { payload }) => {
            state.clientSecret =  payload;
        })
        builder.addCase(createPaymentIntent.fulfilled, (state, { payload }) => {
            state.paymentSecret =  payload;
            state.loadingPaymentSecret = false;
        })
        builder.addCase(createPaymentIntent.pending, (state, { payload }) => {
            state.loadingPaymentSecret = true;
        })
        builder.addCase(createPaymentIntent.rejected, (state, { payload }) => {
            state.loadingPaymentSecret = false;
        })
    },
});

// actions
export default checkoutSlice.reducer;

// selectors
export const selectPaymentMethod = (state) => state.checkout.paymentMethod;
export const selectLoadingPaymentMethod = (state) => state.checkout.loadingPaymentMethod;
export const selectPaymentList = (state) => state.checkout.paymentList;
export const selectLoadingPaymentList = (state) => state.checkout.loadingPaymentList;
export const selectClientSecret = (state) => state.checkout.clientSecret;
export const selectPaymentSecret = (state) => state.checkout.paymentSecret;
export const selectLoadingPaymentSecret = (state) => state.checkout.loadingPaymentSecret;
