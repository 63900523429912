import React, {Children, useContext, useEffect, useRef, useState, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import _ from 'lodash';
import {dropdownArrowIcon, roundedMinus} from '../../assets/icons';
import {Icon} from '../icons';
import DropdownList from './DropdownList';
import DropdownContext from './DropdownContext';
import withDropdownProvider from './withDropdownProvider';
import {typographyStyles, inputStyle, dropdownContainerStyle} from './autosuggestThemeConfig';

const Autosuggest = props => {
    const {children, isDisabled, name, value, placeholder, onChange, maxLength, minSearchTermLength} = props;
    const {openDropDown, closeDropDown, toggleDropDown, isOpen, dropDownRef} = useContext(DropdownContext);

    const selectorRef = useRef(null);
    const toggleIconRef = useRef(null);
    const inputRef = useRef(null);
    const minusIconRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState(value || []);

    const location = useLocation();

    const areChildrenSet = !!children && Array.isArray(children) && children.length > 0;

    const filteredChildren = useMemo(() => {
        if (!searchTerm || !areChildrenSet || (minSearchTermLength && searchTerm.length < minSearchTermLength))
            return children;

        return _.filter(children, child => (
            child?.props?.children?.toLowerCase().includes(searchTerm?.toLowerCase())
            || child?.props?.value.toLowerCase().includes(searchTerm?.toLowerCase())
        ));
    }, [searchTerm, children, areChildrenSet, minSearchTermLength]);

    const enhancedChildren = Children.map(filteredChildren, child => {
        if (!child || typeof child === 'string' || typeof child.type === 'string') return child;

        return React.cloneElement(child, {
            activeOptions: selectedItems,
        });
    });

    // reset search term on route change
    useEffect(() => {
        if (!!searchTerm) setSearchTerm('')
    }, [location])

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!inputRef.current?.contains(e.target)
                && !toggleIconRef.current?.contains(e.target)
                && !dropDownRef.current?.contains(e.target)
                && !minusIconRef.current?.contains(e.target)
                && !!dropDownRef.current
            ) closeDropDown();
        }

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    });

    useEffect(() => {
        if (!!value || (maxLength && selectedItems?.length < maxLength)) {
            setSelectedItems(value);
        }
    }, [value, maxLength, selectedItems?.length]);

    const handleIconOnClick = () => {
        if (isDisabled) return;
        toggleDropDown();
    };

    const handleListOnChange = value => {
        if (!value) return;
        let newSelectedValues;

        if ([...selectedItems].includes(value)) {
            newSelectedValues = [...selectedItems].filter(selectedItem => selectedItem !== value);
        }
        else {
            if (!!maxLength) {
                newSelectedValues = selectedItems?.length < maxLength ? [...selectedItems, value] : [...selectedItems];
            }
            else {
                newSelectedValues = [...selectedItems, value];
            }
        }

        setSelectedItems([...newSelectedValues]);

        if (typeof onChange === 'function') {
            onChange([...newSelectedValues]);
        }
    };

    const onFocusHandler = () => {
        if (areChildrenSet) {
            openDropDown();
        }
    };

    const removeSelectedItem = itemToRemove => {
        if (!itemToRemove) return;

        const filteredSelectedItems = [...selectedItems]
            .filter(selectedItem => selectedItem !== itemToRemove);
        setSelectedItems([...filteredSelectedItems]);

        if (typeof onChange === 'function') {
            onChange([...filteredSelectedItems]);
        }
    }

    return (
        <div className="grid grid-cols-12">
            <div ref={selectorRef} className="col-span-9">
                <div className="flex w-full items-start text-contrast-800">
                    <div>
                        <div className={`relative flex justify-between min-w-[515px] h-full`}>
                            <input
                                className={`${typographyStyles['primary']} ${inputStyle}`}
                                name={name}
                                value={searchTerm || ''}
                                type="text"
                                disabled={isDisabled}
                                placeholder={placeholder || "Type or select an option"}
                                onChange={e => setSearchTerm(e.target.value)}
                                onFocus={onFocusHandler}
                                autoComplete="off"
                                ref={inputRef}
                            />
                            <div className='absolute right-spona-0' ref={toggleIconRef}>
                                <Icon
                                    icon={dropdownArrowIcon}
                                    className={`ml-spona-12 transition-[transform] ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                                    onClick={handleIconOnClick}
                                />
                            </div>
                        </div>
                        {!isOpen && (
                            <p className={`${typographyStyles['secondary']} float-right mt-spona-12`}>
                                {`${children.length} options in list`}
                            </p>
                        )}
                    </div>
                </div>
                {isOpen && (
                    <div
                        ref={dropDownRef}
                        className={`${typographyStyles['primary']} ${dropdownContainerStyle}`}
                    >
                        <DropdownList onClick={handleListOnChange}>
                            {enhancedChildren}
                        </DropdownList>
                    </div>
                )}
            </div>
            <div className="col-span-3 max-h-[220px] overflow-auto">
                {selectedItems && selectedItems.length > 0 && selectedItems?.map((selectedItem, idx) => (
                    <div
                        key={`${selectedItem}-${idx}`}
                        className="flex items-center grid grid-cols-8 text-input-100 font-bold text-lg mb-spona-16"
                    >
                        <span className="col-span-6">
                            {selectedItem}
                        </span>
                        <div className="col-span-2 flex justify-end">
                            <Icon
                                size='s'
                                icon={roundedMinus}
                                ref={minusIconRef}
                                onClick={() => removeSelectedItem(selectedItem)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default withDropdownProvider(Autosuggest);