import { createSlice } from "@reduxjs/toolkit";
import { getProspectList, getProspect } from './customMadeProspectListsActions';

const customMadeProspectListsSlice = createSlice({
    name: 'customMadeProspectLists',
    initialState: {
        customMadeProspectList: null,
        customMadeProspect: null,
        error: null,
        prospectsPricing: null,
        leadsPricing: [],
        targeted: [],
        specific: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getProspectList.fulfilled, (state, { payload }) => {
            state.customMadeProspectList = payload;
            state.prospectsPricing = payload.find(pricingItem => !pricingItem.outstanding);
            state.leadsPricing = payload.filter(pricingItem => !!pricingItem.outstanding);
            state.targeted = payload.filter(pricingItem => pricingItem.outstanding === '500+')
                .sort((pricingA, pricingB) => pricingA.price - pricingB.price);
            state.specific = payload.filter(pricingItem => pricingItem.outstanding === '>500')
                .sort((pricingA, pricingB) => pricingA.price - pricingB.price);
        })
        builder.addCase(getProspectList.rejected, (state, { payload }) => {
            state.error = payload;
        })
        builder.addCase(getProspect.fulfilled, (state, { payload }) => {
            state.customMadeProspect = payload;
        })
        builder.addCase(getProspect.rejected, (state, { payload }) => {
            state.error = payload;
        })
    },
});

// actions
export default customMadeProspectListsSlice.reducer;

// selectors
export const selectCustomMadeProspectList = (state) => state.customMadeProspectLists.customMadeProspectList;
export const selectCustomMadeProspect = (state) => state.customMadeProspectLists.customMadeProspect;
export const selectLeadsPricing = (state) => state.customMadeProspectLists.leadsPricing;
export const selectProspectsPricing = (state) => state.customMadeProspectLists.prospectsPricing;
export const selectTargetedPricing = (state) => state.customMadeProspectLists.targeted;
export const selectSpecificPricing = (state) => state.customMadeProspectLists.specific;