import React from 'react';
import {HighlightedText} from '../../../ui-components';

const AnalyticsAndToolsContent = () => {
    return (
        <div>
            <div className="text-lg font-medium text-default-100 mb-spona-24">
                <p className="mb-spona-24">
                    SPONA provides comprehensive analytics and reporting tools to give you a clear picture of your campaign's performance.
                </p>
                <p className="mb-spona-24">
                    Understanding the impact of your outreach efforts is crucial, and our platform ensures you have all the necessary data at your fingertips.<br/>
                    Here's an overview of our analytics and reporting capabilities:
                </p>
            </div>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal">
                    <HighlightedText className="ml-[-24px]">
                        <li className="list-inside font-bold text-default-100 text-[22px]">
                            Detailed Campaign Statistics:
                        </li>
                    </HighlightedText>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Performance Metrics:</span> Track key metrics such as click rates, open rates, reply rates, booked call rates, and unsubscribe rates.
                            These insights are vital for understanding how your audience is engaging with your emails.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Autowarmer Score:</span> Our platform calculates an autowarmer score,
                            which helps in assessing the health and effectiveness of your email outreach over time.
                        </li>
                    </ul>
                    <HighlightedText className="ml-[-24px]">
                        <li className="list-inside font-bold text-default-100 text-[22px]">
                            Real-Time Lead Monitoring:
                        </li>
                    </HighlightedText>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="py-spona-12">
                            <span className="font-bold">Market Matching:</span> View in real-time the number of leads available in the market that match your specific Ideal Customer Profile (ICP).
                            This feature helps in gauging the potential reach and impact of your campaigns.
                        </li>
                        <li className="py-spona-12">
                            <span className="font-bold">Continuous Updates:</span> As the market dynamics change, our platform updates these statistics,
                            providing you with up-to-date information for strategic planning
                        </li>
                    </ul>
                </ul>
            </div>
            <i className="font-medium text-default-100 text-lg mt-spona-24">
                These analytical tools are designed to be user-friendly, providing you with clear, actionable insights.<br/>
                Whether you're looking to refine your approach or scale your campaigns, SPONA’s analytics and reporting capabilities support
                informed decision-making, driving better results in your lead generation efforts.
            </i>
        </div>
    )
}

export default AnalyticsAndToolsContent;