import React from 'react';

const FieldSet = props => {
    const {children, label} = props;
    return (
        <div className="mb-spona-24">
            <label className="block text-lg text-default-100 font-semibold self-start mb-spona-12">
                {label}
            </label>
            <fieldset className="overflow-hidden bg-contrast-500 rounded-[10px] border border-solid border-contrast-600 flex flex-col">
                {children}
            </fieldset>
        </div>
    )
}

export default FieldSet;