import React, {Children} from 'react';

const Checkbox = props => {
    const {value, onChange, isSelected, isDisabled, className, inputClassName, children} = props;

    const handleOnChange = event => {
        if (typeof onChange === 'function') onChange(event.target.checked);
    };

    const enhancedChildren = Children.map(children, child => {
        if (!child || typeof child === 'string' || typeof child.type === 'string') return child;

        return React.cloneElement(child, {
            isSelected: isSelected,
            isDisabled: isDisabled,
        });
    });

    return (
        <label className={`${className ? className : ''} flex items-center`}>
            <input
                type="checkbox"
                value={value}
                style={{"-webkit-appearance": "none", "-moz-appearance": "none"}}
                onChange={handleOnChange}
                checked={isSelected}
                disabled={isDisabled}
                className={`${inputClassName ? inputClassName : ''} w-[19px] h-[19px] peer relative appearance-none focus:outline-none shrink-0 rounded-[3px] border border-contrast-800 bg-contrast-600`}
            />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                className="absolute pointer-events-none hidden peer-checked:block stroke-white outline-none"
            >
                <g clipPath="url(#clip0_1456_13510)">
                    <path
                        fillRule="evenodd"
                        d="M18.0175 4.3856C18.2466 4.6148 18.3753 4.92562 18.3753 5.24971C18.3753 5.5738 18.2466 5.88462 18.0175 6.11382L8.8565 15.2748C8.73544 15.3959 8.59171 15.4919 8.43352 15.5575C8.27532 15.623 8.10577 15.6567 7.93454 15.6567C7.76331 15.6567 7.59376 15.623 7.43557 15.5575C7.27737 15.4919 7.13364 15.3959 7.01258 15.2748L2.46102 10.724C2.34429 10.6113 2.25117 10.4764 2.18712 10.3273C2.12306 10.1782 2.08935 10.0178 2.08794 9.85554C2.08653 9.69325 2.11745 9.53231 2.17891 9.3821C2.24036 9.2319 2.33111 9.09543 2.44587 8.98067C2.56063 8.86592 2.69709 8.77516 2.8473 8.71371C2.99751 8.65225 3.15845 8.62133 3.32073 8.62274C3.48302 8.62415 3.6434 8.65787 3.79252 8.72192C3.94163 8.78598 4.0765 8.87909 4.18924 8.99582L7.93413 12.7407L16.2884 4.3856C16.4019 4.27202 16.5367 4.18193 16.685 4.12045C16.8334 4.05898 16.9924 4.02734 17.1529 4.02734C17.3135 4.02734 17.4725 4.05898 17.6209 4.12045C17.7692 4.18193 17.904 4.27202 18.0175 4.3856Z"
                        fill="#33BD9C"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1456_13510">
                        <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(0.461914 0.222656)"
                        />
                    </clipPath>
                </defs>
            </svg>
            {enhancedChildren}
        </label>
    )
}

export default Checkbox;
