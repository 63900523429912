import {infoIcon, errorIcon, roundedWarningIcon, successStatusIcon} from '../../assets/icons';

const iconsVariants = {
    error: errorIcon,
    informative: infoIcon,
    warning: roundedWarningIcon,
    success: successStatusIcon,
};

const iconBackgroundVariants = {
    error: "bg-warning-50",
    informative: "bg-info",
    warning: "bg-orange-200",
    success: "bg-highlighted-600",
};

const toastPositionType = {
    TOP_RIGHT: 'TOP_RIGHT',
    BELOW_HEADER: 'BELOW_HEADER',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
};

export {iconsVariants, iconBackgroundVariants, toastPositionType};
