import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {Button, Icon} from '../../ui-components';
import {backIcon} from '../../assets/icons';
import {Input} from '../../ui-components/text-inputs';
import routePaths from '../../routing/routePaths';
import { forgotPassword } from '../authActions';
import { validateFields } from '../../utils';
import config from '../../config';

const ForgotPasswordContent = () => {
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState('');
    const [resetPasswordSentError, setResetPasswordSentError] = useState('');
    const [isResetLinkSent, setIsResetLinkSent] = useState(localStorage.getItem('isResetLinkSent'));

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setEmailError('');
    }, [email]);

    const handleOnClick = async () => {
        try {
            await dispatch(forgotPassword({email})).unwrap();
            //localStorage.setItem('isResetLinkSent', 'true');
            setIsResetLinkSent(true);
            setResetPasswordSentError('');
        } catch (error) {
            if (error && error.statusCode === config.STATUS_CODE_403) {
                setResetPasswordSentError('Reset password link has already been sent. Please check your inbox.')
            }
        }
    }

    return (
        <div className="flex items-center justify-center flex-col">
            <h1 className="text-default-100 text-2xl font-bold my-[36px]">
                {isResetLinkSent ? 'Almost there!' : 'Welcome back!'}
            </h1>
            {!isResetLinkSent && (
                <div
                    className="flex items-center justify-center self-start my-[36px] cursor-pointer"
                    onClick={() => navigate(routePaths.LOGIN)}
                >
                    <Icon icon={backIcon} size='s' />
                    <span className="text-default-100 font-semibold	text-xl ml-spona-8">Back to login</span>
                </div>
            )}
            <div className="mb-[36px] text-default-100 font-medium text-xl text-center">
                {isResetLinkSent
                    ? <p className="py-[90px] mb-[160px]">We’ve sent you an email with a link<br/>to reset your password</p>
                    : <span>Forgot your password?<br/>No worries, we’ll send you<br/>reset instruction</span>
                }
            </div>
            {!isResetLinkSent && (
                <div className="w-full">
                    <Input
                        name="email"
                        label="Work email"
                        placeholder='Your work email'
                        className='w-full'
                        value={email || ''}
                        onChange={setEmail}
                        onBlur={e => {
                            const errorMessage = validateFields(e.target.value, e.target.name);
                            if (!errorMessage) return;
                            setEmailError(errorMessage);
                        }}
                        error={!!emailError ? emailError : ''}
                    />
                </div>
            )}
            <div className="mb-spona-64">
                {isResetLinkSent
                    ? null
                    : (
                        <div className="flex justify-center">
                            <Button
                                className="mt-spona-16"
                                buttonType='primary'
                                isDisabled={!email}
                                onClick={handleOnClick}
                            >
                                Receive reset link
                            </Button>
                        </div>
                    )
                }
                {resetPasswordSentError && (
                    <p className="text-warning-100 text-base mt-spona-12 text-center">
                        {resetPasswordSentError}
                    </p>
                )}
            </div>
        </div>
    )
}

export default ForgotPasswordContent;