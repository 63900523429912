import React from 'react';
import { useLocation, Navigate } from "react-router-dom";

const ProtectedRouteWrapper = props => {
    const {children} = props;
    const token = localStorage.getItem('accessToken');
    const location = useLocation();

    return (
        token
            ? children
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default ProtectedRouteWrapper;