export const buttonTypes = {
    primary: "relative inline-flex justify-center items-center text-center min-w-[100px] text-contrast-100 border border-highlighted-100 px-[25px] py-[9px] rounded-md border-solid bg-highlighted-100",
    secondary: "relative inline-flex justify-center items-center text-center min-w-[100px] text-highlighted-100 border-highlighted-100 px-[25px] py-[9px] rounded-md border-2 border-solid",
    warning: "relative inline-flex justify-center items-center text-center min-w-[100px] text-contrast-100 border border-warning-100 px-[25px] py-[9px] rounded-md border-solid bg-warning-100",
    basic: "relative inline-flex justify-center items-center text-center min-w-[100px] text-contrast-800 border-contrast-800 px-[25px] py-[9px] rounded-md border-2 border-solid"
};

// ToDo: Define on hover and refactor
export const onHoverStyles = {
    primary: "hover:bg-contrast-200 hover:text-highlighted-100",
    secondary: "hover:bg-highlighted-100 hover:text-contrast-100",
};