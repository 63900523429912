import React from 'react';
import DropdownProvider from './DropdownProvider';

const withDropdownProvider = BaseComponent => {
    const ComposedComponent = React.forwardRef((props, ref) => {
        return (
            <DropdownProvider baseComponentRef={ref}>
                <BaseComponent {...props} ref={ref} />
            </DropdownProvider>
        );
    });

    ComposedComponent.displayName = `withDropdownProvider(${BaseComponent.displayName || BaseComponent.name})`;

    ComposedComponent.propTypes = {
        ...BaseComponent.propTypes,
    };

    ComposedComponent.defaultProps = {
        ...BaseComponent.defaultProps,
    };

    return ComposedComponent;
};

export default withDropdownProvider;
