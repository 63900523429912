import React from 'react';

const DataRow = props => {
    const {children, label, contentClassName} = props;

    return (
        <div className='flex items-stretch border-b border-highlighted-200'>
            <div className='min-w-[280px] p-spona-12 font-semibold text-lg flex justify-start whitespace-nowrap bg-highlighted-100 text-contrast-100'>
                {label}
            </div>
            <div className={`${contentClassName ? contentClassName : 'min-w-[460px] max-w-[900px] text-input-100 grow flex items-center p-spona-12 overflow-hidden'}`}>
                {children}
            </div>
        </div>
    );
};

export default DataRow;