import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCurrentUser} from '../authentification/authActions';
import {selectCurrentUser} from '../authentification/authSlice';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const token = localStorage.getItem('accessToken');
  const didComponentMountRef = useRef(false);

  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) return;
    if (!didComponentMountRef.current && !currentUser) {
      const fetchCurrentUser = async () => {
        try {
          await dispatch(getCurrentUser()).unwrap();
        }
        catch (error) {
          // Todo: Handle error
        }
      }
      fetchCurrentUser();
      didComponentMountRef.current = true;
    }
  });

  const addMessageToState = message => {
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };

  // todo: remove this action handler if not needed
  const talkToTheHumanOptionHandler = () => {
    const message = currentUser
        ? createChatBotMessage("Please write your problem and we will hit you back.")
        : createChatBotMessage("Please write your email so we could message you back.");
    addMessageToState(message);
  };

  const emailMessageHandler = () => {
    const message = createChatBotMessage("Thank you for entering your work email, please describe your problem in one concise and detailed message.");
    addMessageToState(message);
  };

  const tooManyMessagesHandler = () => {
    const message = createChatBotMessage("Message limit reached, please try again in 30 minutes.");
    addMessageToState(message);
  };

  const problemDescriptionMessageHandler = () => {
    const message = createChatBotMessage("Thank you for message. If you encounter any other issues, please do not hesitate to contact us.");
    addMessageToState(message);
  }

  const invalidEmailMessageHandler = () => {
    const message = createChatBotMessage("You have not entered a valid work email. Please try again to continue.");
    addMessageToState(message);
  }

  return (
      <div>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            actions: {
              talkToTheHumanOptionHandler,
              emailMessageHandler,
              tooManyMessagesHandler,
              problemDescriptionMessageHandler,
              invalidEmailMessageHandler
            },
          });
        })}
      </div>
  );
}

export default ActionProvider;