import React from 'react';
import Slider from "react-slick";
import { Icon } from '../icons';
import { playerButtonIcon } from '../../assets/icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useScreenResolution } from '../../utils/customHooks';

const NextArrow = props => {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <Icon icon={playerButtonIcon} size="m" className="mr-[-80px] shrink-0 rotate-180" />
        </div>
    );
}

const PrevArrow = props => {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <Icon icon={playerButtonIcon} size="m" className="ml-[-80px] shrink-0" />
        </div>
    );
}

const Carousel = props => {
    const { slidesToShow, slidesToScroll, children } = props;
    const {isDesktopScreen} = useScreenResolution();

    const settings = {
        infinite: true,
        slidesToShow,
        slidesToScroll,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    const mobileSettings = {
        infinite: true,
        slidesToShow,
        slidesToScroll,
        dots: true,
    }

    return (
        <Slider {...(isDesktopScreen ? settings : mobileSettings)}>
            {children}
        </Slider>
    )
}

export default Carousel;