import React from 'react';
import {HighlightedText} from '../../../ui-components';

const ImportingContactsContent = () => {
    return (
        <div>
            <div className="text-lg font-medium text-default-100">
                <p className="mb-spona-24">
                    Incorporating your existing contacts into SPONA is a seamless process, tailored to enhance your lead generation strategies.
                </p>
                <p className="mb-spona-24">
                    Whether you're ordering custom prospect lists or managing a cold email outreach campaign, SPONA makes it easy to integrate your data.
                </p>
                <p className="mb-spona-24">
                    Here’s how:
                </p>
            </div>
            <div className="ml-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Importing Contacts to prevent duplicate leads:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">During the Order Process:</span> When you order custom prospect lists, you'll encounter a questionnaire.<br/>
                            At this stage, you have the option to upload a CSV or Excel file containing your existing contacts.<br/>
                            This integration allows us to tailor the prospect list to better suit your existing network and avoid overlaps.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">File Format:</span> Ensure your file is in CSV or XLS format.<br/>
                            This common and versatile format is compatible with most contact management systems, making it easy<br/>to prepare and upload your data.
                        </li>
                    </ul>
                    <li>Importing Leads into Cold Email Outreach Campaigns:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">During Campaign Setup:</span> Similar to ordering prospect lists, you can import leads directly into your campaign during<br/>
                            the initial setup via the questionnaire.
                            This step ensures a personalized and targeted approach from the get-go.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Post-Campaign Setup:</span> After setting up your campaign, you can continue to add or update your leads through the product dashboard.
                            This ongoing flexibility allows you to adapt and refine your campaign as needed.
                        </li>
                    </ul>
                </ul>
            </div>
            <HighlightedText className='mt-spona-24'>
                By enabling these straightforward import options, SPONA ensures that your transition to our platform is smooth, maintaining the integrity and value of your existing data.
                If you need assistance with the import process, our support team is readily available to help.
            </HighlightedText>
        </div>
    )
}

export default ImportingContactsContent;