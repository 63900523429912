import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate, useMatch} from 'react-router-dom';
import {Button, Checkbox, CheckboxGroup, Option, TextArea, Autosuggest, FileDropZone, Icon, SponaLogo, ProgressBar} from '../ui-components';
import {questions, idealCustomersSize, idealCustomerEmployeesSize} from './questionnareConfig';
import {resolveRoute} from '../utils';
import { getQuestionById, updateQuestionById } from './questionnaireActions';
import { selectQuestionnaireId } from './questionnaireOneSlice';
import { selectIsFileUploadPending } from '../file-assets/fileAssetsSlice';
import DesktopMessageContent from '../ui-components/general/DesktopMesageContent';
import {uploadFile} from '../file-assets/fileAssetsActions';
import {useToast} from '../ui-components/general/useToast';
import {toastPositionType} from '../ui-components/general/toastNotificationThemeConfig';
import {industries, jobTitles, regions} from './data';
import routePaths from '../routing/routePaths';
import config from '../config';
import {backIconDisabled, backLightIcon, checkmarkWhiteIcon} from '../assets/icons';
import {getCurrentUser} from '../authentification/authActions';
import {selectCurrentUser} from '../authentification/authSlice';
import {useScreenResolution} from '../utils/customHooks';

const checkboxDivStyle = 'flex justify-between h-[30px] w-full py-spona-4 pl-spona-12 rounded-[10px] bg-contrast-500 border border-contrast-300 mb-spona-12 text-base text-input-100';
const paginatorDirections = {
    NEXT: 'NEXT',
    PREV: 'PREV',
}
const toastInfoConfig = {
    title: "Take your time :)",
    position: toastPositionType.TOP_RIGHT,
    description: "Fill in the questionnaire as thoughtful as possible for optimal results",
    type: "informative",
}

const QuestionnaireScreen = () => {
    const navigate = useNavigate();
    const match = useMatch(routePaths.QUESTIONNAIRE_ONE);
    const currentQuestion = match.params?.questionNo?.toUpperCase();
    const isFileUploadPending = useSelector(selectIsFileUploadPending);
    const {isDesktopScreen} = useScreenResolution();

    const [questionnaireFormData, setQuestionnaireFormData] = useState({
        q1: [],
        q2: '',
        q3: [],
        q4: [],
        q5: [],
        q6: [],
        q7: '',
        q8: '',
        q9: [],
    });
    const [isCloseFileAvailable, setIsCloseFileAvailable] = useState(!questionnaireFormData['q8']);

    const onLoadRef = useRef(false);
    const questionNoRef = useRef(currentQuestion.toLowerCase());
    const onComponentMount = useRef(false);

    const dispatch = useDispatch();
    const questionnaireOneId = useSelector(selectQuestionnaireId) || match.params?.questionnaireOneId;
    const user = useSelector(selectCurrentUser);

    const toast = useToast();

    const loadQuestionResponse = async () => {
        try {
            await dispatch(getCurrentUser()).unwrap();
        } catch (e) {
            // no-op
        }
        if (!questionNoRef.current || !questionnaireOneId) return;

        try {
            const response = await dispatch(getQuestionById({
                questionerId: questionnaireOneId,
                questionId: questionNoRef.current,
            })).unwrap();

            if (questionNoRef.current === 'q8' && !!response) setIsCloseFileAvailable(false);

            if (response) {
                setQuestionnaireFormData(prevFormData => ({
                    ...prevFormData,
                    [questionNoRef.current]: questionNoRef.current === 'q8' && typeof response === 'string'
                        ? response.match(/\/\d+_(.+)$/)[1] || '' : response,
                }));
            }

        } catch (e) {
            // handle error
        }
    };

    const updateQuestion = async (questionId, value) => {
        if (!questionId || questionnaireOneId.current) return;

        try {
            await dispatch(updateQuestionById({
                questionnaireId: questionnaireOneId,
                payload: {
                    [questionId]: value,
                }
            })).unwrap();
        } catch (e) {
            // handle error
        }
    }

    useEffect(() => {
        if (!onLoadRef.current) {
            loadQuestionResponse();
            onLoadRef.current = true;
        }
    }, [loadQuestionResponse]);

    useEffect(() => {
        if (!onComponentMount.current && currentQuestion === 'Q1') {
            toast.openToast(
                toastInfoConfig.title,
                toastInfoConfig.position,
                toastInfoConfig.description,
                toastInfoConfig.type
            );
            onComponentMount.current = true;
        }
    });

    const handlePageNavigation = async (direction) => {
        if (!questionNo) return;

        const isNextCTASelected = direction === paginatorDirections.NEXT;
        const questionId = currentQuestion.toLowerCase();

        if (isNextCTASelected && questionNo + 1 <= config.QN1_NO_OF_QUESTIONS) {
            questionNoRef.current = `q${questionNo + 1}`;
        }

        if (!isNextCTASelected && questionNo - 1 > 0) {
            questionNoRef.current = `q${questionNo - 1}`;
        }

        onLoadRef.current = false;
        onComponentMount.current = true;

        if (isNextCTASelected && questionNo === 8 && isCloseFileAvailable) {
            const file = questionnaireFormData['q8'];
            const folder = config.QUESTIONNAIRE_ONE_FOLDER;

            if (!!file && !!file[0] && !!folder) {
                try {
                    const fileLink = await dispatch(uploadFile({file: file[0], folder})).unwrap();
                    setIsCloseFileAvailable(false);
                    updateQuestion(questionId, fileLink);
                } catch (error) {
                    // no-op
                }
            }
        }

        if (questionNo !== 8 && questionNoRef.current && questionnaireFormData[questionId]) {
            if (!questionnaireFormData[questionId]) return;
            updateQuestion(questionId, questionnaireFormData[questionId]);
        }

        questionNo === config.QN1_NO_OF_QUESTIONS && isNextCTASelected
            ? (user ? navigate(resolveRoute(routePaths.CUSTOM_MADE_PROSPECT_LISTS)) : navigate(resolveRoute(routePaths.REGISTER, { questionnaireOneId })))
            : navigate(resolveRoute(routePaths.QUESTIONNAIRE_ONE, {
                questionnaireOneId,
                questionNo: questionNoRef.current,
            }))
    }

    const onChangeHandler = (questionNo, value) => {
        if (!questionNo) return;

        setQuestionnaireFormData(prevFormData => ({
            ...prevFormData,
            [questionNo]: value,
        }));
    }

    const renderContent = () => {
        switch (currentQuestion) {
            case 'Q1':
                return (
                    <Autosuggest
                        name='industries'
                        value={questionnaireFormData['q1'] || []}
                        onChange={value => onChangeHandler('q1', value)}
                        maxLength={questions['Q1']['maxLength']}
                    >
                        {industries.map(industry => (
                            <Option key={industry} value={industry}>
                                {industry}
                            </Option>
                        ))}
                    </Autosuggest>
                )

            case 'Q2':
                return (
                    <TextArea
                        name="companies"
                        placeholder="Type your answer here..."
                        value={questionnaireFormData['q2']  || ''}
                        onChange={value => onChangeHandler('q2', value)}
                        maxLength={200}
                        rows={3}
                    />
                )

            case 'Q3':
                return (
                    <Autosuggest
                        name='locations'
                        value={questionnaireFormData['q3'] || []}
                        onChange={value => onChangeHandler('q3', value)}
                        minSearchTermLength={config.MINIMUM_SEARCH_QUERY_LENGTH}
                    >
                        {regions.map((location, idx) => (
                            <Option key={`${location}-${idx}`} value={location}>
                                {location}
                            </Option>
                        ))}
                    </Autosuggest>
                )

            case 'Q4':
                return (
                    <CheckboxGroup values={questionnaireFormData['q4']} onChange={value => onChangeHandler('q4', value)}>
                        <div className="grid grid-cols-12 gap-x-spona-64">
                            {idealCustomersSize.map((customerSize, idx) => (
                                <div
                                    key={`${customerSize}-${idx}`}
                                    className={`col-span-6 ${checkboxDivStyle}`}
                                >
                                    {customerSize}
                                    <Checkbox
                                        className="mr-spona-64"
                                        value={customerSize}
                                        isSelected={questionnaireFormData['q4']
                                            ? questionnaireFormData['q4'].includes(customerSize)
                                            : false}
                                    />
                                </div>
                            ))}
                        </div>
                    </CheckboxGroup>
                )

            case 'Q5':
                return (
                    <CheckboxGroup values={questionnaireFormData['q5']} onChange={value => onChangeHandler('q5', value)}>
                        <div className="grid grid-cols-12 gap-x-spona-64">
                            {idealCustomerEmployeesSize.map((employeeCount, idx) => (
                                <div
                                    key={`${employeeCount}-${idx}`}
                                    className={`col-span-6 ${checkboxDivStyle}`}
                                >
                                    {employeeCount}
                                    <Checkbox
                                        className="mr-spona-64"
                                        name={employeeCount}
                                        value={employeeCount}
                                        isSelected={questionnaireFormData['q5']
                                            ? questionnaireFormData['q5'].includes(employeeCount)
                                            : false}
                                    />
                                </div>
                            ))}
                        </div>
                    </CheckboxGroup>
                )

            case 'Q6':
                return (
                    <Autosuggest
                        name='jobTitles'
                        value={questionnaireFormData['q6']}
                        onChange={value => onChangeHandler('q6', value)}
                        maxLength={questions['Q6']['maxLength']}
                    >
                        {jobTitles.map(({jobTitle}) => (
                            <Option key={jobTitle} value={jobTitle}>
                                {jobTitle}
                            </Option>
                        ))}
                    </Autosuggest>
                )

            case 'Q7':
                return (
                    <div>
                        <TextArea
                            name="customerDescription"
                            placeholder="Type your answer here..."
                            value={questionnaireFormData['q7'] || ''}
                            onChange={value => onChangeHandler('q7', value)}
                            maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                            rows={3}
                        />
                        <p className="float-right text-contrast-800 text-sm mr-spona-2">
                            {`${questionnaireFormData['q7']?.length}/${config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}`}
                        </p>
                    </div>
                )

            case 'Q8':
                return <FileDropZone
                    value={questionnaireFormData['q8']}
                    onChange={value => onChangeHandler('q8', value)}
                    maxNoOfUploadedFiles={1}
                    shouldShowCloseButton={isCloseFileAvailable}
                />

            case 'Q9':
                return (
                    <Autosuggest
                        name='contactsPerCompanyNo'
                        value={questionnaireFormData['q9']}
                        onChange={value => onChangeHandler('q9', value)}
                        maxLength={questions['Q9']['maxLength']}
                    >
                        {['1', '2', '3', '4', '5', '6'].map(contactsNo => (
                            <Option key={contactsNo} value={contactsNo}>
                                {contactsNo}
                            </Option>
                        ))}
                    </Autosuggest>
                )

            default:
                return null
        }
    }

    if (!currentQuestion || !questions[currentQuestion]) return null;

    const {questionNo, isMandatory} = questions[currentQuestion];
    const page = questions[currentQuestion].questionNo;
    const isPreviousDisabled = page === 1;

    return (
        <>
            {!isDesktopScreen
                ? <DesktopMessageContent />
                : (
                    <div className="hidden lg:flex flex-col">
                        <div className="pl-spona-32 pt-spona-32">
                            <SponaLogo />
                        </div>
                        <div className="p-spona-64 flex justify-center items-center">
                            <div>
                                {page > 1 && (
                                    <div className={`flex items-center ml-spona-64`}>
                                        <Icon
                                            icon={isPreviousDisabled ? backIconDisabled : backLightIcon}
                                            className={`mr-spona-12 ${isPreviousDisabled ? '' : 'cursor-pointer'}`}
                                            onClick={() => {
                                                if (!isPreviousDisabled) handlePageNavigation(paginatorDirections.PREV);
                                            }}
                                        />
                                        <span
                                            className={`text-2xl font-semibold ${isPreviousDisabled ? 'text-contrast-800' : 'text-default-100 cursor-pointer'}`}
                                            onClick={() => {
                                                if (!isPreviousDisabled) handlePageNavigation(paginatorDirections.PREV);
                                            }}
                                        >
                                    Previous
                                </span>
                                    </div>
                                )}
                                <div className="w-[1300px] flex flex-col justify-between min-h-[545px] mt-spona-24 bg-highlighted-400 rounded-[10px] pt-spona-64 pb-spona-24 px-spona-64">
                                    <div>
                                        <div className="flex justify-between">
                                            <div className="text-default-100 text-2xl font-semibold mb-spona-12">
                                                {!questions[currentQuestion].isMandatory
                                                    ? <p><span className="font-normal">{`${questionNo}. Optional: `}</span>{questions[currentQuestion].question}</p>
                                                    : `${questionNo}. ${questions[currentQuestion].question}`}
                                            </div>
                                            <div className="shrink-0">
                                                <ProgressBar currentProgress={questions[currentQuestion].progressBarPercentage} />
                                            </div>
                                        </div>
                                        <p className="mb-spona-80 text-contrast-800 font-medium	text-lg ml-spona-24">
                                            {questions[currentQuestion].description}
                                        </p>
                                        <div>
                                            {renderContent()}
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            buttonType="primary"
                                            onClick={() => handlePageNavigation(paginatorDirections.NEXT)}
                                            className="mt-spona-24 text-[24px] !rounded-[10px] px-spona-12 py-spona-8"
                                            isDisabled={(isMandatory && !questionnaireFormData[currentQuestion.toLowerCase()].length) || isFileUploadPending}
                                        >
                                            {page === config.QN1_NO_OF_QUESTIONS ? 'Finish' : 'Next'}
                                            <Icon icon={checkmarkWhiteIcon} className="ml-spona-8" />
                                        </Button>
                                        {user && <Button buttonType='secondary' className="float-right mt-spona-24 bg-contrast-100" onClick={() => navigate(routePaths.CUSTOM_MADE_PROSPECT_LISTS)}>Complete later</Button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default QuestionnaireScreen;