import {createSlice} from '@reduxjs/toolkit';
import {getCustomerOverviewLeadList, updateQuestionnaireOneData} from './adminActions';

const adminSlice = createSlice({
    name: 'adminSlice',
    initialState: {
        customerLeadListData: [],
        totalCount: 0,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCustomerOverviewLeadList.fulfilled, (state, { payload }) => {
            state.customerLeadListData = payload.data;
            state.totalCount = payload.count;
        });
        builder.addCase(updateQuestionnaireOneData.fulfilled, (state, { payload }) => {
            state.customerLeadListData = state.customerLeadListData.map(data => {
                return data._id === payload._id ? payload : data;
            });
        });
    },
});

// actions
export default adminSlice.reducer;

// selectors
export const selectCustomerLeadListData = (state) => state.admin.customerLeadListData;
export const selectCustomerLeadListCount = (state) => state.admin.totalCount;
