/**
 * @typedef {string} userRoleTypes
 */

/**
 * User role types
 *
 * @enum {userRoleTypes}
 */
export default {
    CLIENT: 'CLIENT',
    ADMIN: 'ADMIN',
};