export default {
    ROOT: '/',
    ADMIN: '/admin',
    ADMIN_LEADS: '/admin/leads',
    ADMIN_PROSPECTS: '/admin/prospects',
    ADMIN_USERS: '/admin/users',
    LOGIN: '/login',
    REGISTER: '/register/:questionnaireOneId',
    FORGOT_PASSWORD: '/forgot-password',
    EMAIL_VERIFICATION: '/email-verification',
    SUCCESSFUL_VERIFICATION: '/verify-user-registration/:token',
    CHANGE_PASSWORD: '/forgot-password-request/:token',
    CUSTOM_MADE_PROSPECT_LISTS: '/product-one',
    CUSTOM_MADE_PROSPECT_LISTS_CHECKOUT: '/product-one-checkout/:outstanding/:questionnaireId?',
    APPOINTMENT_SETTING: '/appointment-setting',
    ACCOUNT: '/account',
    BILLING_PORTAL_SECTION: '/billing-portal',
    BILLING_PORTAL: '/billing-portal/:priceId?/:questionnaireId?',
    PRICING: '/pricing',
    FAQ_SECTION: '/faq',
    FAQ: '/faq',
    FAQ_SUBCATEGORIES: '/faq/subcategories',
    DATA_PRIVACY: '/data-privacy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    CHECK_OUT: '/checkout/:priceId/:questionnaireId?',
    QUESTIONNAIRE_ONE: '/questionnaire-one/:questionnaireOneId/:questionNo',
    COMPLETE_QUESTIONNAIRE: '/complete-questionnaire',
    START_QUESTIONNAIRE_ONE: '/start-questionnaire-one',
    TOKEN_ALREADY_ACTIVATED: '/token-already-activated',
    VERIFY_YOUR_ACCOUNT: '/verify-account',
    PAGE_NOT_FOUND: '/404',
};