const errorConfig = {
    email: {
        regex: /\b[A-Za-z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|gmx\.com|proton\.com)[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/,
        errorMessage: 'Please enter your work email.',
    },
    password: {
        regex: /^(?=.*\d.*)(?=.*[^\d\S]*[^\d\S]*$).{8,}$/,
        errorMessage: 'Please enter a strong password.',
    }
}

const validateFields = (value, fieldName) => {
    if (!value || !errorConfig[fieldName]) return;
    const {regex, errorMessage} = errorConfig[fieldName];
    return (!regex.test(value))
        ? errorMessage
        : '';
}

export default validateFields;