import React from 'react';
import {HighlightedText} from '../../../ui-components';

const CancellingSubscriptionContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                At SPONA, we strive to make our subscription management as user-friendly as our lead generation services.
                To cancel your subscription or request a refund, please do the following:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Canceling Your Subscription:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="list-inside py-spona-12 max-w-[1200px]">
                            <span className="font-bold">Easy Cancellation Process:</span> To cancel your subscription to our appointment setting service,
                            simply log into your SPONA account, navigate to the product dashboard for appointment setting, and click on the ‘Cancel’ option.
                            The process is straightforward and can be completed in just a few clicks.
                        </li>
                        <HighlightedText className='ml-[-24px] mb-spona-24'>
                            <li className="list-inside py-spona-12">
                                <span className="font-bold">Immediate Effect:</span> Your subscription cancellation will take effect immediately upon completion of the process.
                                All campaigns will stop at the moment of cancellation.
                            </li>
                        </HighlightedText>
                    </ul>
                    <li>Requesting a Refund:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <HighlightedText className='ml-[-24px] mb-spona-24'>
                            <li className="list-inside py-spona-12 ">
                                <span className="font-bold">Our Moneyback Guarantee:</span> We guarantee over 96% accuracy for business emails and 75% for direct/mobile numbers.
                                If you find discrepancies within these thresholds, we offer a refund.
                            </li>
                        </HighlightedText>
                        <li className="list-inside py-spona-12 max-w-[1200px]">
                            <span className="font-bold">Refund Process:</span> Within 10 days of purchasing a lead list, if you identify any incorrect leads,
                            simply highlight them in the Excel file we provided.<br/>
                            Then, email the list to us at <a className="underline decoration-1 underline-offset-2" href="mailto:support@sponasales.com">support@sponasales.com</a>.<br/>
                            Our team will review your claim and process the refund accordingly.
                        </li>
                        <li className="list-inside py-spona-12 max-w-[1200px]">
                            <span className="font-bold">Timely Claims:</span> Since contact information can change rapidly, we urge you to review and report any
                            inaccuracies within the 10-day window to ensure eligibility for a refund.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className="font-medium text-default-100 text-lg mt-spona-24">
                We aim to provide transparency and convenience in all aspects of our service,
                including subscription management and refunds. Your satisfaction is our priority,
                and we are here to assist with any concerns or questions you may have regarding these processes.
                Feel free to reach out to our support team at any time for further assistance.
            </p>
        </div>
    )
}

export default CancellingSubscriptionContent;