import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { SponaLogo } from '../general';
import routePaths from '../../routing/routePaths';
import { Icon } from '../icons';
import { burgerMenuIcon, exitIcon } from '../../assets/icons';

const Navigation = ({ children }) => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuAnimation, setMenuAnimation] = useState('animate-slideLeft')
    const menuRef = useRef(null);
    const location = useLocation();
    const token = localStorage.getItem('accessToken');

    const handleToggleMenu = () => {
        setMenuAnimation(isMenuOpen ? 'animate-slideRight' : 'animate-slideLeft')
        setTimeout(() => {
            setIsMenuOpen(!isMenuOpen);
        }, 400)
    }

    useEffect(() => {
        let timer;
        const handleOutsideClick = (event) => {
            if (menuRef.current && isMenuOpen && !menuRef.current.contains(event.target)) {
                setMenuAnimation('animate-slideRight');
                timer = setTimeout(() => {
                    setIsMenuOpen(false);
                }, 400)
            }
        }

        document.addEventListener("touchstart", handleOutsideClick);

        return () => {
            document.removeEventListener("touchstart", handleOutsideClick);
            clearTimeout(timer);
        }

    }, [menuRef, isMenuOpen]);

    useEffect(() => {
        setIsMenuOpen(false)
    }, [location])

    return (
        <nav className={`fixed top-spona-0 left-spona-0 w-screen z-[1040] bg-contrast-100 flex items-center justify-between flex-wrap bg-spona-white p-spona-20 md:px-spona-40 lg:py-spona-24 lg:px-spona-80 shadow-navbar font-semibold text-xl text-default-100`}>
            <SponaLogo {...(!token && {onClick: () => navigate(routePaths.ROOT)})} />
            <ul className='hidden list-none lg:flex items-center'>
                {children}
            </ul>
            <div className="lg:hidden relative">
                <Icon
                    icon={burgerMenuIcon}
                    size='s'
                    onClick={handleToggleMenu}
                />
                {isMenuOpen && (
                    <div ref={menuRef} className={`${menuAnimation} fixed top-spona-0 left-spona-0 bottom-spona-0 md:left-2/4 md:right-spona-0 md:inset-y-0 w-screen md:w-[50vw] h-screen z-[1060] bg-highlighted-100`}>
                        <div className="w-full p-spona-20 md:p-spona-32 flex items-center justify-end">
                            <Icon icon={exitIcon} size="s" color="informative" onClick={handleToggleMenu} />
                        </div>
                        <ul className="md:px-spona-20 list-none flex flex-col items-center justify-center md:items-start gap-spona-16 uppercase  text-contrast-200 text-xl font-bold">
                            {children}
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Navigation;