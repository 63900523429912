import React, {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import {Icon} from '../icons';
import {closeIcon, logoBIcon} from '../../assets/icons';
import {modalSizes} from './modalThemeConfig';

const Modal = props => {
    const {children, title, hasHeaderLogo, hasCloseButton, hasBackdrop, className, headerIcon, closeModal, contentClassName, size, modalClassName} = props;
    const modalRef = useRef();

    useEffect(() => {
        if (!hasBackdrop || !!hasCloseButton || !closeModal) return;

        const handleOutsideClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal();
            }
        }

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    })

    return (
        createPortal(
            <div className={`fixed top-spona-0 left-spona-0 w-screen h-screen z-[1040] 
                    ${hasBackdrop ? 'bg-backdrop-100 backdrop-blur-[5px]' : ''} ${className ? className : ''}`}
            >
                <div className="fixed z-[1050] w-full h-full overflow-x-hidden overflow-y-auto flex items-center justify-center left-spona-0 top-spona-0">
                    <div ref={modalRef} className={`${modalClassName ? modalClassName : ''} flex flex-col bg-contrast-200 rounded-[12.24px] min-w-[450px] max-w-[1920px] max-h-full p-spona-32 z-100 ${size ? modalSizes[size] : ''}`}>
                        {hasHeaderLogo && (
                            <div className="flex items-center justify-center mb-spona-14">
                                <Icon icon={logoBIcon} className="w-[242px]"/>
                            </div>
                        )}
                        {headerIcon && (
                            <div className="flex items-center justify-start mb-spona-14">
                                <Icon icon={headerIcon.icon} size={headerIcon.size} />
                            </div>
                        )}
                        {title && (
                            <div className={`text-[32px] font-bold text-default-100
                                 ${hasCloseButton ? 'flex items-center justify-between mb-spona-32' : 'mb-spona-8'}`}>
                                {title}
                                {hasCloseButton && (
                                    <div className="">
                                        <Icon icon={closeIcon} size="s" onClick={closeModal} />
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={`${contentClassName ? contentClassName : ''}`}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>, document.getElementById("modal-root"))
    );
}

export default Modal;