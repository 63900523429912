import React from 'react';
import {capitalCase} from 'change-case';
import {HighlightedText} from '../../../ui-components';

const accountSettingSteps = {
    INITIATE_REGISTRATION: {
        isHighlighted: false,
        description: 'Click on the ‘Get free leads‘ or ‘Try for free‘ button. You\'ll be directed to our questionnaire.',
    },
    EXPLAIN_YOUR_TARGET_AUDIENCE: {
        isHighlighted: true,
        description: 'To receive tailored free leads and convince yourself in the quality of our product, you are required to fill in a 7-minute questionnaire. ' +
            'At the end of questionnaire you are required to enter your full name, email and password to register.',
    },
    ACTIVATE_YOUR_ACCOUNT: {
        isHighlighted: false,
        description: 'Upon submission, an activation link will be sent to your email. Click this link to activate your SPONA account. ' +
            'If you can\'t find the email, please check your spam or junk folders.',
    },
    ACCESS_YOUR_ACCOUNT: {
        isHighlighted: false,
        description: 'Post-activation, you’re all set to log in and explore SPONA’s features.',
    },
}

const passwordManagement = {
    CHANGE_PASSWORD: {
        isHighlighted: false,
        description: 'To change your password anytime, navigate to the ‘Account’ tab within the SPONA app and follow the prompts for password reset.'
    },
    FORGOT_PASSWORD: {
        isHighlighted: true,
        description: 'If you forget your password, simply click \'Forgot Password\' on the login page. A reset link will be sent to your email for a hassle-free update.'
    }
}

const AccountCreationContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100">
                Welcome to SPONA!
            </p>
            {Object.entries(accountSettingSteps).map((entry, idx) => {
                const key = entry[0];
                const value = entry[1];

                return (
                    <div key={key} className="mt-spona-24">
                        <span className="font-bold text-default-100 text-[22px]">
                            {`${idx + 1}. ${capitalCase(key)}:`}
                        </span>
                        {value.isHighlighted ? (
                            <HighlightedText className='mt-spona-24'>
                                {value.description}
                            </HighlightedText>
                        ) : (
                            <p className='font-medium text-default-100 text-lg mt-spona-24 ml-spona-24'>
                                {value.description}
                            </p>
                        )}
                    </div>
                )
            })}
            <div>
                <h2 className="font-bold text-default-100 text-[22px] mt-spona-24">Password Management:</h2>
                <ul className="list-disc ml-spona-24">
                    {Object.entries(passwordManagement).map(entry => {
                        const key = entry[0];
                        const value = entry[1];

                        return (
                            <li key={key} className="mt-spona-24">
                                <span className="text-xl font-semibold text-default-100">
                                    {capitalCase(key)}
                                </span>
                                {value.isHighlighted ? (
                                    <HighlightedText className='mt-spona-24 ml-[-24px]'>
                                        {value.description}
                                    </HighlightedText>
                                ) : (
                                    <p className='font-medium text-default-100 text-lg mt-spona-24'>
                                        {value.description}
                                    </p>
                                )}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <p className="font-medium text-default-100 text-lg mt-spona-24">
                For any assistance during this process, our support team is at your service. Welcome aboard, and let’s make your lead generation journey successful with SPONA!
            </p>
        </div>
    )
}

export default AccountCreationContent;