import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../config';

const baseURL = `${config.DEV_BASE_URL}`;

export const uploadFile = createAsyncThunk(
    '/api',
    async ({file, folder}, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(
                `${baseURL}/api/${folder}/upload`,
                formData,
                {
                    headers: {'Content-Type': "multipart/form-data"},
                }
            )
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data});
            } else {
                return rejectWithValue({payload: error.message});
            }
        }
    }
);

export const deleteFile = createAsyncThunk('/api',
    async ({file, folder}, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${baseURL}/api`,
                {
                    headers: {'Content-Type': "multipart/form-data"},
                    body: JSON.stringify({file}),
                }
            )
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data});
            } else {
                return rejectWithValue({payload: error.message});
            }
        }
    }
);