import React from 'react';
import {HighlightedText} from '../../../ui-components';

const SpecialOffersContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                At SPONA, we understand the importance of cost-effective solutions for your lead generation needs. We offer a unique pricing structure that provides opportunities for discounts based on your campaign's scope and scale.<br/>
                Here's how you can benefit from our special offers:
            </p>
            <div className="ml-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Cost Reduction through Broader Customer Profiles:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Profile Scope:</span> The cost per lead can vary depending on how broad or narrow your defined Ideal Customer Profiles (ICPs) are during the questionnaire phase.
                            By setting broader ICPs, you can benefit from a lower cost per lead.
                            This approach encourages wider market outreach while remaining cost-efficient.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Strategic Flexibility:</span> This pricing strategy gives you the flexibility to adjust your targeting criteria.
                            A more inclusive ICP not only reduces your cost but also increases the potential lead pool, enhancing your campaign’s reach.
                        </li>
                    </ul>
                    <li>Unlimited Questionnaires for Paying Customers:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Continuous Market Analysis:</span> Once you become a paying customer, SPONA offers the privilege of conducting an unlimited number of questionnaires.
                            This feature allows you to constantly explore and analyze how many leads are available in the market that match specific search criteria without additional costs.
                        </li>
                        <HighlightedText className="ml-[-38px] pb-spona-24">
                            <li className="list-inside">
                                <span className="font-bold">Dynamic Campaign Planning:</span> This ongoing access to market data is invaluable for dynamically adjusting your campaign
                                strategies and making informed decisions on targeting different audience segments.
                            </li>
                        </HighlightedText>
                    </ul>
                </ul>
            </div>
            <p className='font-medium text-default-100 text-lg mt-spona-24'>
                Our pricing model is designed to reward strategic planning and broader market engagement, providing you with cost-effective solutions for your lead generation campaigns.
                The more comprehensive your approach, the more economical your lead acquisition becomes with SPONA.
            </p>
            <p className='font-medium text-default-100 text-lg mt-spona-24'>
                If you have any specific queries regarding discounts or wish to understand how you can optimize your campaign costs, our team is here to assist.
                Let SPONA be your partner in achieving efficient and effective lead generation.
            </p>
        </div>
    )
}

export default SpecialOffersContent;