/**
 * @typedef {string} cardBrandType
 */

/**
 * Card brand types
 *
 * @enum {cardBrandType}
 */
export default {
    VISA: 'VISA',
    MASTERCARD: 'MASTERCARD',
    AMERICAN_EXPRESS: 'AMERICAN_EXPRESS',
};