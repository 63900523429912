import React from 'react';
import {flexRender, getCoreRowModel, getFilteredRowModel, useReactTable} from "@tanstack/react-table";
import './Table.scss';
import withDefaultStyles from '../withDefaultStyles';

// ToDo: switch to tailwind
const Table = props => {
    const {data, columns, className} = props;

    const table = useReactTable({
        data,
        columns,
        getFilterRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        columnResizeMode: "onChange",
    });

    return (
        <div className={className}>
            <table className="spona-table">
                <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th className="spona-table__head" key={header.id}>
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                            ))}
                        </tr>
                    )
                )}
                </thead>
                <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="spona-table__body-row">
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="spona-table__body-cell" style={{width: cell.column.getSize()}}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default withDefaultStyles(Table, 'spona-table__container');
