import React from 'react';
import {Button, Icon, SponaLogo} from '../ui-components';
import {checkmarkWhiteIcon} from '../assets/icons';
import {useDispatch} from 'react-redux';
import {resolveRoute} from '../utils';
import routePaths from '../routing/routePaths';
import {createQuestionnaireOne} from './questionnaireActions';
import {useNavigate} from 'react-router-dom';
import DesktopMessageContent from '../ui-components/general/DesktopMesageContent';

const QuestionnaireLandingScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const redirectToQuestionnaire = async () => {
        try {
            const questionnaireOneBlueprint = await dispatch(createQuestionnaireOne()).unwrap();
            const questionnaireOneId = questionnaireOneBlueprint?._id;
            if (!questionnaireOneId) return;
            navigate(resolveRoute(routePaths.QUESTIONNAIRE_ONE, {questionnaireOneId, questionNo: 'q1'}));
        } catch (e) {
            // toDo: recheck this flow
            navigate(resolveRoute(routePaths.PAGE_NOT_FOUND));
        }
    };

    return (
        <>
            <DesktopMessageContent />
            <div className="hidden lg:flex flex-col">
                <div className="p-spona-32">
                    <SponaLogo />
                </div>
                <div className="flex items-center justify-center h-screen mt-[-128px] p-spona-64">
                    <div className="w-[1200px] mt-spona-12 bg-highlighted-400 rounded-[10px] pt-[18px] pb-spona-44 px-spona-64">
                        <div className="max-w-[800px] text-2xl">
                            <div className="flex justify-between items-center text-default-100">
                                <h1 className="font-bold mt-spona-64">Welcome!</h1>
                            </div>
                            <div>
                                <p className="my-spona-24">
                                    SPONA is the most efficient way to generate 100% qualified leads.<br/><br/>
                                    To provide you with a perfect sample of free leads, SPONA needs to understand your exact target audience.<br/><br/>
                                    Completing this questionnaire takes around 4 minutes.
                                </p>
                                <Button
                                    buttonType="primary"
                                    onClick={redirectToQuestionnaire}
                                    className="!rounded-[10px] px-spona-12 py-spona-8"
                                >
                                    Let's start
                                    <Icon icon={checkmarkWhiteIcon} className="ml-spona-4" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuestionnaireLandingScreen;