import React from 'react';

const HighlightedText = props => {
    const {className} = props;

    return (
        <div
            className={`bg-blue-50 
                rounded-[10px] 
                px-spona-14 
                w-full
                py-spona-14 
                border-l-8 
                border-blue-150
                font-medium 
                text-default-100 
                text-lg
                ${className ? className : ''}`}
        >
            {props.children}
        </div>
    )
}

export default HighlightedText;