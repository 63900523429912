import React, { Fragment } from 'react';
import {useSelector} from 'react-redux';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { AppLayout, Button, Footer, Icon, Main } from '../ui-components';
import { Navigation, NavigationTab } from '../ui-components/navigation';
import RoutesContainer from './RoutesContainer';
import { homeScreenTabs } from '../home/homeScreenTabs';
import { adminScreenTabs } from '../admin/adminScreenTabs';
import routePaths from '../routing/routePaths';
import {arrowUpIcon} from '../assets/icons';
import ChatBotContainer from '../chat-bot/ChatBotContainer';
import withToastNotificationProvider from '../ui-components/general/withToastNotificationProvider';
import {selectCurrentUser} from '../authentification/authSlice';
import {screenLayoutConfig} from './screenLayoutConfig';
import userRoleTypes from '../entites/user-profile/userRoleTypes';
import './ApplicationScreen.css';

const ApplicationScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const token = localStorage.getItem('accessToken');
    const user = useSelector(selectCurrentUser);

    const isUserAdmin = user && user?.role === userRoleTypes.ADMIN.toLowerCase()
        && (matchPath(routePaths.ADMIN, location.pathname)
            || matchPath(routePaths.ADMIN_LEADS, location.pathname));

    const isScreenWithLayout = screenLayoutConfig['screensWithLayout'].find(routePath => {
        return matchPath(routePath, location.pathname);
    });

    return (
        <Fragment>
            {!!isScreenWithLayout ? (
                <AppLayout>
                    <Navigation>
                        {!isUserAdmin ? (
                            <Fragment>
                                <div className='lg:hidden'>
                                    <NavigationTab to={routePaths.ROOT}>
                                        HOME
                                    </NavigationTab>
                                </div>
                                {Object.keys(homeScreenTabs).map(tab => {
                                    const isProspectsTab = tab.toUpperCase() === 'APPOINTMENT_SETTING';
                                    if (tab === homeScreenTabs.ACCOUNT.toUpperCase() && !token) return null;
                                    if (tab === homeScreenTabs.PRICING.toUpperCase() && token) return null;
                                    return (
                                        <NavigationTab
                                            key={tab}
                                            to={routePaths[tab]}
                                            hasIcon={isProspectsTab}
                                            isDisabled={isProspectsTab}
                                        >
                                            {homeScreenTabs[tab]}
                                        </NavigationTab>
                                    )
                                })}
                                {!token && (
                                    <Fragment>
                                        <Button
                                            buttonType='secondary'
                                            className='mx-spona-24 hidden lg:block h-[48px]'
                                            onClick={() => navigate(routePaths.LOGIN)}
                                        >
                                            Login
                                        </Button>
                                        <Button
                                            buttonType='primary'
                                            onClick={() => navigate(routePaths.LOGIN)}
                                            className="md:py-spona-0 md:px-spona-12 md:justify-start uppercase lg:hidden"
                                        >
                                            Login
                                        </Button>
                                        <Button
                                            buttonType='primary'
                                            onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}
                                            className="md:py-spona-0 md:px-spona-12 lg:px-[25px] lg:py-[9px] lg:flex-row-reverse md:justify-start font-black text-lg lg:normal-case"
                                        >
                                            GET FREE LEADS
                                            <Icon
                                                className="md:mr-spona-0 md:ml-spona-8 lg:mr-spona-8 lg:ml-spona-0"
                                                icon={arrowUpIcon}
                                                size='s'
                                            />
                                        </Button>
                                    </Fragment>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                {Object.keys(adminScreenTabs).map(tab => (
                                    <NavigationTab
                                        key={tab}
                                        to={routePaths[tab]}
                                    >
                                        {adminScreenTabs[tab]}
                                    </NavigationTab>
                                ))}
                                {!!token && (
                                    <Button
                                        buttonType='primary'
                                        onClick={() => {
                                            localStorage.removeItem('accessToken');
                                            navigate(routePaths.LOGIN);
                                        }}>
                                        Logout
                                    </Button>
                                )}
                            </Fragment>
                        )}
                    </Navigation>
                    <Main>
                        <RoutesContainer />
                        <div className='hidden lg:block'>
                            {!matchPath(routePaths.ADMIN_LEADS, location.pathname) && <ChatBotContainer />}
                        </div>
                    </Main>
                    <div className='lg:hidden'>
                        {!matchPath(routePaths.ADMIN_LEADS, location.pathname) && <ChatBotContainer />}
                    </div>
                    <Footer />
                </AppLayout>
            ) : (
                <RoutesContainer />
            )}
        </Fragment>
    )
}

export default withToastNotificationProvider(ApplicationScreen);