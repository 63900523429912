import React from 'react';
import borderDirectionTypes from './borderDirectionTypes';

const FieldSetInput = props => {
    const {name, className, value, isDisabled, onChange, placeholder, borderDirection, maxLength, onFocus} = props;
    const defaultInputStyle = 'bg-contrast-500 ' +
        'w-full h-[50px] ' +
        'p-spona-12 ' +
        'border-contrast-600 ' +
        'border-solid ' +
        'text-input-100 ' +
        'text-lg ' +
        'font-medium ' +
        ':focus outline-none ' +
        'appearance-none';

    const getBorderStyle = () => {
        switch (borderDirection) {
            case borderDirectionTypes.LEFT:
                return 'border-l';
            case borderDirectionTypes.RIGHT:
                return 'border-r';
            case borderDirectionTypes.TOP:
                return 'border-t';
            case borderDirectionTypes.BOTTOM:
                return 'border-b';
            default:
                return '';
        }
    }

    const handleOnChange = event => {
        if (typeof onChange === 'function') onChange(event.target.value);
    };

    return (
        <input
            className={`${className ? className : ''} ${defaultInputStyle} ${borderDirection ? getBorderStyle() : ''}`}
            name={name}
            value={value}
            type='text'
            disabled={isDisabled}
            onChange={handleOnChange}
            onFocus={onFocus}
            placeholder={placeholder}
            maxLength={maxLength}
        />
    )
}

export default FieldSetInput;