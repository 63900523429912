import React from 'react';
//import './AppLayout.scss';

const AppLayout = props => {
    const {children} = props;
    return (
        <div id="app" className="flex flex-col min-h-screen overflow-hidden md:overflow-auto">
            {children}
        </div>
    );
};


export default AppLayout;