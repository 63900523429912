/**
 * @typedef {string} borderDirectionTypes
 */

/**
 * Border direction types
 *
 * @enum {borderDirectionTypes}
 */
export default {
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
};