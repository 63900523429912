import React from 'react';

const ChatBotMessageOption = props => {
    const options = [
        {
            text: "Talk to the human",
            handler: props.actionProvider.talkToTheHumanOptionHandler,
            id: 1,
        }
    ];

    return (
        <div className="py-spona-8">
            {options.map((option) => (
                <button
                    className="border border-highlighted-100 py-spona-4 px-spona-8 cursor-pointer rounded-[50px] text-highlighted-100 text-sm"
                    key={option.id}
                    onClick={option.handler}
                >
                    {option.text}
                </button>
            ))}
        </div>
    )
}

export default ChatBotMessageOption;