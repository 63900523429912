import {modalTypes} from './modalTypes';
import DeleteRowConfirmationModal from './admin/modals/DeleteRowConfirmationModal';
import EditLeadDataModal from './admin/modals/EditLeadDataModal';
import DeleteAccountModal from './authentification/modals/DeleteAccountModal';
import PaymentActionModal from './checkout/modals/PaymentActionModal';
import UserQuestionnaireModal from './admin/modals/UserQuestionnaireModal';

export default {
    [modalTypes.DELETE_ROW]: DeleteRowConfirmationModal,
    [modalTypes.EDIT_LEADS]: EditLeadDataModal,
    [modalTypes.DELETE_ACCOUNT]: DeleteAccountModal,
    [modalTypes.PAYMENT_ACTION]: PaymentActionModal,
    [modalTypes.USER_QUESTIONNAIRE_DATA]: UserQuestionnaireModal,
}

