import React from 'react';
import {Button, Icon} from '../../ui-components';
import {arrowUpIcon} from '../../assets/icons';
import {useScreenResolution} from '../../utils/customHooks';

const PricingCard = props => {
    const {noOfLeads, price, title, discount, onClick, buttonName} = props;
    const {isDesktopScreen} = useScreenResolution();

    return (
        <div className="flex flex-col w-[90%] lg:w-[314px] h-[400px] lg:h-full">
            {discount && (
                <div className="flex justify-center items-center px-spona-12 mb-spona-12">
                    <hr className="mr-spona-4 border border-highlighted-200 w-full"/>
                    <div className="whitespace-nowrap w-full shadow-[2px_2px_20px_0px_rgba(26,24,24,0.18)] bg-highlighted-200 rounded-[8px] px-spona-16 py-spona-8 text-contrast-100">
                        {discount}
                    </div>
                    <hr className="ml-spona-4 border border-highlighted-200 w-full"/>
                </div>
            )}
            <div className={`${discount ? '' : 'mt-[52px]'} flex flex-col justify-center gap-spona-40 lg:gap-spona-0`}>
                <div className="flex flex-col justify-center items-center py-spona-8 font-black bg-highlighted-100 text-contrast-100 text-4xl lg:text-[64px] lg:leading-normal">
                    <p>{title}</p>
                    <span className="text-lg mb-spona-4">{noOfLeads} leads</span>
                </div>
                <div className="bg-contrast-100">
                    <div className="flex items-center justify-center text-highlighted-100 font-black text-4xl lg:text-[64px] lg:leading-normal py-spona-24">
                        {`$${price}`}
                    </div>
                </div>
                <Button buttonType="primary" className="lg:text-lg font-black uppercase whitespace-nowrap" onClick={onClick}>
                    <Icon icon={arrowUpIcon}  className="mr-spona-8" size={isDesktopScreen ? 's' : 'm'} />
                    {buttonName}
                </Button>
            </div>
        </div>
    )
}

export default PricingCard;