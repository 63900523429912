import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../config';

const baseURL = `${config.DEV_BASE_URL}`;
const token = localStorage.getItem('accessToken');
const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
}

export const getProspectList = createAsyncThunk('/prospect-list', async (thunkAPI) => {
    try {
        const response = await axios.get(
            `${baseURL}/prospect-list`,
            { headers: headers },
        )
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }
});

export const getProspect = createAsyncThunk('/prospect', async ({prospectId}, {rejectWithValue}) => {
    try {
        const response = await axios.get(
            `${baseURL}/prospect/${prospectId}`,
            {
                headers: headers,
            },
        )
        return response.data;
    } catch (error) {
        return rejectWithValue({ error: error.message })
    }
});
