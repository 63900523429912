import { createSlice } from "@reduxjs/toolkit";

const chatBotSlice = createSlice({
    name: 'chatBot',
    initialState: {
        email: '',
        messages: [],
        isOpen: false,
    },
    reducers: {
        storeEmailMessage: (state, action) => {
            state.email = action.payload;
        },
        resetEmailMessage: (state) => {
            state.email = '';
        },
        addMessage: (state, action) => {
            state.messages.push(action.payload);
        },
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
    },
});

// actions
export const { storeEmailMessage, resetEmailMessage, addMessage, setIsOpen } = chatBotSlice.actions


// selectors
export const selectEmailMessage = (state) => state.chatBot.email;
export const selectMessages = (state) => state.chatBot.messages;
export const selectIsOpen = (state) => state.chatBot.isOpen;

export default chatBotSlice.reducer;;