import {categories} from './categories';

const categoryToSubcategoryMap = {
    [categories.GETTING_STARTED]: {
        SUBCATEGORY_1: {
            title: 'How do I create an account?',
            description: 'Welcome to SPONA! Click on the ‘Get free leads‘ or ‘Try for free‘ button. You\'ll be directed to our questionnaire...',
        },
        SUBCATEGORY_2: {
            title: 'What are the basic system requirements to run SPONA?',
            description: 'Ensuring a smooth operation of SPONA, our lead generation platform, requires certain system prerequisites. Here’s a quick overview of the basic system requirements...',
        },
        SUBCATEGORY_3: {
            title: 'How can I import my existing contacts/data?',
            description: 'Incorporating your existing contacts into SPONA is a seamless process, tailored to enhance your lead generation strategies...',
        },
        SUBCATEGORY_4: {
            title: 'What are the first steps I should take to set up my campaign?',
            description: 'Launching a successful campaign with SPONA involves careful planning and preparation. To maximize the effectiveness of your lead generation efforts, here are the initial steps you...',
        },
    },
    [categories.FEATURES_AND_FUNCTIONALITY]: {
        SUBCATEGORY_1: {
            title: 'What are SPONA\'s key features?',
            description: 'SPONA is designed with innovative features to enhance your lead generation and sales efforts. Our key offerings focus on personalization and efficiency, ensuring that you reach your...',
        },
        SUBCATEGORY_2: {
            title: 'How does SPONA customize campaigns to specific audiences?',
            description: 'At SPONA, we believe in the power of tailored communication to engage specific audiences effectively. Our approach to customizing campaigns is both strategic and personalized...',
        },
        SUBCATEGORY_3: {
            title: 'Which analytics and reporting tools are available?',
            description: 'SPONA provides comprehensive analytics and reporting tools to give you a clear picture of your campaign\'s performance...',
        },
        SUBCATEGORY_4: {
            title: 'How does SPONA handle lead management and nurturing?',
            description: 'SPONA excels in lead management and nurturing, ensuring that your campaigns effectively connect with and engage your target audience. Our approach is data-driven and user-centric...',
        },
    },
    [categories.PRICING_AND_PLANS]: {
        SUBCATEGORY_1: {
            title: 'What features do pricing plans include?',
            description: 'SPONA offers a range of pricing plans tailored to meet your lead generation needs. Our plans are structured based on the volume of leads available in your target market and the services...',
        },
        SUBCATEGORY_2: {
            title: 'Are there any discounts or special offers available?',
            description: 'At SPONA, we understand the importance of cost-effective solutions for your lead generation needs. We offer a unique pricing structure that provides opportunities for discounts...',
        },
        SUBCATEGORY_3: {
            title: 'Which services does free version of SPONA include?',
            description: 'SPONA is committed to offering valuable experiences to our users from the very beginning. Our free version is designed to provide you with a taste of our capabilities and the quality of...',
        },
        SUBCATEGORY_4: {
            title: 'How can I cancel my subscription or request a refund?',
            description: 'At SPONA, we strive to make our subscription management as user-friendly as our lead generation services. To cancel your subscription or request a refund, here’s how...',
        },
    },
    [categories.INTEGRATIONS_AND_COMPATIBILITY]: {
        SUBCATEGORY_1: {
            title: 'Does SPONA support third-party integrations?',
            description: 'SPONA is designed to seamlessly integrate with leading third-party applications, enhancing your lead generation and email outreach capabilities. Understanding these integrations...',
        },
        SUBCATEGORY_2: {
            title: 'How do I set up and manage integrations in SPONA?',
            description: 'Setting up and managing integrations with third-party applications in SPONA is designed to be straightforward, ensuring you can quickly enhance your lead generation efforts...',
        },
        SUBCATEGORY_3: {
            title: 'Is SPONA compatible with all operating systems and devices?',
            description: 'At SPONA, we understand the importance of accessibility and ease of use, which is why we\'ve ensured our platform is compatible with a wide range of operating systems...',
        },
        SUBCATEGORY_4: {
            title: 'What should I do if I encounter issues with integrations?',
            description: 'At SPONA, we aim to provide a seamless integration experience with third-party applications. However, should you encounter any issues with integrating Quickmail or Mailflow...',
        },
    },
    [categories.TECHNICAL_SUPPORT]: {
        SUBCATEGORY_1: {
            title: 'How can I contact SPONA’s technical support?',
            description: 'At SPONA, we prioritize responsive and thorough technical support to ensure a smooth experience for our users. If you need assistance or have questions, here are the ways you..',
        },
        SUBCATEGORY_2: {
            title: 'What information should I provide when reporting an issue?',
            description: 'When encountering an issue with SPONA, providing detailed and concise information is crucial for our team to offer effective assistance. Our support staff, all humans, are dedicated to...',
        },
        SUBCATEGORY_3: {
            title: 'Are there any self-help resources available for troubleshooting?',
            description: 'At SPONA, we understand the importance of quick and efficient problem-solving. To assist you in addressing common issues independently, we offer a comprehensive range of self-help...',
        },
        SUBCATEGORY_4: {
            title: 'How does SPONA handle and prioritize technical support requests?',
            description: 'At SPONA, we are committed to providing timely and effective support to all our customers. We have a structured approach to handling and prioritizing support requests, ensuring that...',
        },
    },
    [categories.SECURITY]: {
        SUBCATEGORY_1: {
            title: 'What security measures does SPONA implement to protect user data?',
            description: 'At SPONA, safeguarding your data is our utmost priority. We employ the most advanced and robust security measures available in the lead generation software market to ensure the...',
        },
        SUBCATEGORY_2: {
            title: 'How does SPONA comply with GDPR and other major DPRs?',
            description: 'At SPONA, we recognize the critical importance of data protection and privacy, especially in today’s digital landscape. Compliance with the General Data Protection Regulation (GDPR) and other major...',
        },
        SUBCATEGORY_3: {
            title: 'Can I control and manage the data controlled through SPONA?',
            description: 'In today’s data-driven world, having control over your data is crucial. At SPONA, we empower you with advanced tools and options to manage the data you collect through our platform...',
        },
        SUBCATEGORY_4: {
            title: 'What policies are in place for data breaches or security incidents?',
            description: 'At SPONA, we are deeply committed to safeguarding your data and have robust policies in place to respond effectively to any data breaches or security incidents. Recognizing the...',
        },
    }
}

export default categoryToSubcategoryMap;