import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {Button, Form, Input, passwordInfoTypes, inputTypes} from '../../ui-components';
import { validateFields } from '../../utils';
import routePaths from '../../routing/routePaths';
import { login, register } from '../authActions';
import {selectQuestionnaireId} from '../../questionnaire-one/questionnaireOneSlice';
import userRoleTypes from '../../entites/user-profile/userRoleTypes';
import config from '../../config';

const initialPasswordErrorConfig = {
    isFromServer: false,
    errorMessage: '',
}

const AuthenticationFormContent = () => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordError, setPasswordError] = useState({...initialPasswordErrorConfig});
    const [emailError, setEmailError] = useState('');
    const [serverError, setServerError] = useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const match = useMatch(routePaths.REGISTER);

    const questionnaireOneId = useSelector(selectQuestionnaireId) || match?.params?.questionnaireOneId;
    const isLoginRouteActive = location.pathname === routePaths.LOGIN;

    useEffect(() => {
        setEmailError('');
        setServerError('');
    }, [email])

    useEffect(() => {
        setPasswordError({...initialPasswordErrorConfig});
        setServerError('');
    }, [password])

    const handleOnSubmit = async e => {
        e.preventDefault();

        if (!!passwordError.errorMessage || !!emailError) return;

        try {
            const user =  isLoginRouteActive ?
                await dispatch(login({email, password})).unwrap() :
                await dispatch(register({name, email, password, questionnaireOneId})).unwrap()

            if (isLoginRouteActive) {
                (user && user?.role === userRoleTypes.ADMIN.toLowerCase())
                    ? navigate(routePaths.ADMIN_LEADS)
                    : navigate(routePaths.CUSTOM_MADE_PROSPECT_LISTS);
                return;
            }
            navigate(routePaths.EMAIL_VERIFICATION);
        } catch (e) {
            if (e.statusCode === config.STATUS_CODE_403) {
                navigate(routePaths.VERIFY_YOUR_ACCOUNT);
                return;
            }

            const errorMessage = e.payload;
            if (!errorMessage) return;

            if (errorMessage === `User with email=${email} already exists`) {
                setEmailError('User already exists');
                return;
            }

            if (errorMessage === 'Wrong email/password') {
                setPasswordError({
                    isFromServer: true,
                    errorMessage: 'Wrong password',
                });
                return;
            }

            setServerError('Something went wrong');
        }
    };

    return (
        <Form name={`${isLoginRouteActive ? 'loginForm' : 'registrationForm'}`} onSubmit={e => handleOnSubmit(e)}>
            <div className={`flex items-center flex-col justify-center text-default-100 text-2xl font-bold mt-[24px] text-center ${isLoginRouteActive ? 'mb-spona-80' : 'mb-[24px]'}`}>
                <h1>
                    {isLoginRouteActive
                        ? 'Welcome back!'
                        : <>Thank you for completing<br/>SPONA questionnaire!</>
                    }
                </h1>
                {!isLoginRouteActive && (
                    <p className="mt-spona-12 font-medium text-lg">
                        Register and verify account to get free samples
                    </p>
                )}
            </div>
            {!isLoginRouteActive && (
                <Input
                    name="name"
                    label="First name"
                    placeholder='Your first name'
                    className='w-full'
                    value={name || ''}
                    onChange={setName}
                />
            )}
            <Input
                name="email"
                label="Work email"
                placeholder='Your work email'
                className='w-full'
                value={email || ''}
                onChange={setEmail}
                onBlur={e => {
                    const errorMessage = validateFields(e.target.value, e.target.name);
                    if (!errorMessage) return;
                    setEmailError(errorMessage);
                }}
                error={!!emailError ? emailError : ''}
            />
            <Input
                name="password"
                label="Password"
                placeholder='Your password'
                className='w-full'
                inputType={inputTypes.PASSWORD.toLowerCase()}
                value={password || ''}
                onChange={setPassword}
                passwordInfo = {!!passwordError.errorMessage
                    ? (!passwordError.isFromServer ? passwordInfoTypes.SHOW_PASSWORD_CONSTRAINS : '')
                    : (isLoginRouteActive ? passwordInfoTypes.SHOW_PASSWORD : '')}
                onBlur={e => {
                    const errorMessage = validateFields(e.target.value, e.target.name);
                    if (!errorMessage) return;
                    setPasswordError({
                        isFromServer: false,
                        errorMessage,
                    });
                }}
                error={!!passwordError.errorMessage ? passwordError.errorMessage : ''}
            />
            {serverError && (
                <p className="text-warning-100 text-base">
                    {serverError}
                </p>
            )}
            <Button
                className='w-full mt-auto'
                buttonType='primary'
                type='submit'
                isDisabled={!email || !password}
            >
                {isLoginRouteActive ? 'Login' : 'Create account'}
            </Button>
        </Form>
    )
}

export default AuthenticationFormContent;