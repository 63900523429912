import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../config';

const baseURL = `${config.DEV_BASE_URL}`;

export const createQuestionnaireOne = createAsyncThunk(
    '/create_questioner_one',
    async (_, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.post(`${baseURL}/questioner_one`, null, {
                headers: {
                    ...(token && {'Authorization': `Bearer ${token}`})}
                },
            )
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data});
            } else {
                return rejectWithValue({payload: error.message});
            }
        }
    }
);

export const getQuestionById = createAsyncThunk('/questioner_one', async ({questionerId, questionId, userId}, {rejectWithValue}) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
            `${baseURL}/questioner_one/${questionerId}/${questionId}`,{
                method: 'GET',
                ...(userId && {body: JSON.stringify({userId})}),
                headers: {
                    'Content-Type': 'application/json',
                    ...(token && {'Authorization': `Bearer ${token}`})},
            }
        )
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue({payload: error.response.data});
        } else {
            return rejectWithValue({payload: error.message});
        }
    }
});

export const updateQuestionById = createAsyncThunk('/questioner_one', async ({questionnaireId, payload}, {rejectWithValue}) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.patch(
            `${baseURL}/questioner_one/${questionnaireId}`,
            payload,
            {
                headers: {
                    ...(token && {'Authorization': `Bearer ${token}`})
                }
            },
        )
        return response.data;
    } catch (error) {
        return rejectWithValue({ error: error.message })
    }
});

export const getQuestionnaireUserTable = createAsyncThunk('/questioner_one_user_table', async (_, {rejectWithValue}) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
            `${baseURL}/questioner_one`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            }
        )
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue({payload: error.response.data});
        } else {
            return rejectWithValue({payload: error.message});
        }
    }
});