import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { kebabCase } from 'change-case';
import { resolveRoute } from '../../utils';
import routePaths from '../../routing/routePaths';
import { categoryTitles } from '../categories';
import { Icon } from '../../ui-components';
import { dropdownArrowBlackIcon } from '../../assets/icons';
import { useScreenResolution } from '../../utils/customHooks';

const CategoryList = props => {
    const navigate = useNavigate();
    const { active, setActive } = props;
    const [isListExpanded, setIsListExpanded] = useState(false);
    const {isDesktopScreen} = useScreenResolution();

    const handleOnClick = category => {
        setActive(category);
        const queryParams = new URLSearchParams();
        queryParams.append('category', `${kebabCase(category).toLowerCase()}`);
        navigate(resolveRoute(routePaths.FAQ, {}, { search: queryParams.toString() }));
    };

    return (
        <div className="text-default-100">
            <h1 className="mb-spona-32 lg:mb-spona-0 text-[32px] font-bold" onClick={() => {if (!isDesktopScreen) setIsListExpanded(!isListExpanded)}}>
                Categories
                <Icon className={`${isListExpanded && 'rotate-180'} lg:hidden`} icon={dropdownArrowBlackIcon}/> 
            </h1>
            <ul className="text-xl font-bold m-spona-40 hidden lg:block">
                {Object.keys(categoryTitles).map(category => (
                    <li
                        key={category}
                        className={`p-spona-14 cursor-pointer ${active === category
                            ? 'bg-highlighted-0 rounded-[10px] w-full'
                            : ''}`}
                        onClick={() => handleOnClick(category)}
                    >
                        {categoryTitles[category]}
                    </li>
                ))}
            </ul>
            {isListExpanded && (
                <ul className="text-xl font-bold m-spona-40">
                {Object.keys(categoryTitles).map(category => (
                    <li
                        key={category}
                        className={`p-spona-14 cursor-pointer ${active === category
                            ? 'bg-highlighted-0 rounded-[10px] w-full'
                            : ''}`}
                        onClick={() => handleOnClick(category)}
                    >
                        {categoryTitles[category]}
                    </li>
                ))}
            </ul>
            )}
        </div>
    )
}

export default CategoryList;