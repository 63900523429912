import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../config';

const baseURL = `${config.DEV_BASE_URL}`;

export const getCustomerOverviewLeadList = createAsyncThunk('/questioner_one/admin',
    async ({searchParams}, {rejectWithValue}) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
            `${baseURL}/questioner_one/admin?${searchParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                //params: searchParams,
            }
        )
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue({payload: error.response.data});
        } else {
            return rejectWithValue({payload: error.message});
        }
    }
});

export const updateQuestionnaireOneData = createAsyncThunk('/update_questioner_one/admin/', async ({questionnaireId, payload}, {rejectWithValue}) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.patch(
            `${baseURL}/questioner_one/${questionnaireId}/admin`,
            payload,
            {
                headers: {
                    ...(token && {'Authorization': `Bearer ${token}`})
                }
            },
        )
        return response.data;
    } catch (error) {
        return rejectWithValue({ error: error.message })
    }
});

export const deleteQuestionnaire = createAsyncThunk('/questioner_one',
    async ({questionerId}, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.delete(
                `${baseURL}/questioner_one/${questionerId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue({payload: error.response.data});
            } else {
                return rejectWithValue({payload: error.message});
            }
        }
    }
);