import moment from 'moment';
import {Icon} from '../icons';
import {calendarArrowIcon} from '../../assets/icons';

const DatePickerHeader = ({date, nextMonth, previousMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}) => {
    return (
        <div className="flex items-center justify-between px-[10px] mb-spona-4 bg-contrast-200">
            <button disabled={prevMonthButtonDisabled}>
                <Icon icon={calendarArrowIcon} onClick={previousMonth} />
            </button>
            <p className="text-sm font-bold font-['Open_Sans']">
                {moment(date).format('MMMM YYYY')}
            </p>
            <button disabled={nextMonthButtonDisabled}>
                <Icon icon={calendarArrowIcon} className="rotate-180" onClick={nextMonth} />
            </button>
        </div>
    )
}
export default DatePickerHeader;