import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../authentification/authSlice';
import customMadeProspectListsReducer from '../custom-made-prospect-lists/customMadeProspectListsSlice';
import checkoutReducer from '../checkout/checkoutSlice';
import chatBotReducer from '../chat-bot/chatBotSlice';
import questionnaireOne from '../questionnaire-one/questionnaireOneSlice';
import admin from '../admin/adminSlice';
import fileAssets from '../file-assets/fileAssetsSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        customMadeProspectLists: customMadeProspectListsReducer,
        checkout: checkoutReducer,
        // devTools: process.env.REACT_APP_ENV === 'dev',
        chatBot: chatBotReducer,
        questionnaireOne: questionnaireOne,
        admin: admin,
        fileAssets: fileAssets,
    }
})

export default store;