import React from 'react';
import {Route, Routes } from 'react-router-dom';
import routes from '../routing/routes';

const renderRoutes = (routes) => (
    <Routes>
        {routes.map(({ routes: nestedRoutes, ...route }, idx) => (
            <Route
                key={route.path || idx}
                path={route.path}
                element={route.element}
                exact={route.exact}
            >
                {nestedRoutes && nestedRoutes.map((nestedRoute, nestedIdx) => (
                    <Route
                        key={nestedRoute.path || nestedIdx}
                        path={nestedRoute.path}
                        element={nestedRoute.element}
                        exact={nestedRoute.exact}
                    />
                ))}
            </Route>
        ))}
    </Routes>
);

const RoutesContainer = () => {
    return renderRoutes(routes);
}

export default RoutesContainer;