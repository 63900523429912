import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { kebabCase } from 'change-case';
import { Icon, Panel } from '../../ui-components';
import { bookIcon } from '../../assets/icons';
import { resolveRoute } from '../../utils';
import routePaths from '../../routing/routePaths';

const SubcategoryPanel = props => {
    const { subcategory, category, name } = props;
    const navigate = useNavigate();

    if (!subcategory) return null;

    const handleOnClick = () => {
        const queryParams = new URLSearchParams();
        queryParams.append('category', `${kebabCase(category).toLowerCase()}`);
        queryParams.append('subcategory', `${kebabCase(name).toLowerCase()}`);
        navigate(resolveRoute(routePaths.FAQ_SUBCATEGORIES, {}, { search: queryParams.toString() }));
    };

    return (
        <Panel
            title={
                <div className='inline-flex lg:block'>
                    <Icon icon={bookIcon} size='sm' className="mr-spona-14 basis-2/12 md:basis-1/12" />
                    <span className='basis-10/12 md:basis-11/12'>
                        {subcategory?.title || ''}
                    </span>
                </div>
            }
            className="w-full mb-spona-40 cursor-pointer hover:bg-highlighted-0"
            onClick={handleOnClick}
        >
            <div className="ml-[46px]">
                {subcategory?.description || ''}
            </div>
        </Panel>
    );
}

SubcategoryPanel.propTypes = {
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    subcategory: PropTypes.object.isRequired,
};

export default SubcategoryPanel;