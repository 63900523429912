import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {backLightIcon} from '../assets/icons';
import {Icon, SponaLogo} from '../ui-components';
import routePaths from '../routing/routePaths';

const TermsAndConditions = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');
    const headingStyle = 'text-2xl font-bold text-default-100 mb-spona-24';
    const paragraphStyle = 'text-lg font-medium text-default-100 mb-spona-24';

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="px-spona-40 lg:px-spona-80 py-spona-24">
            <SponaLogo {...(!token && {onClick: () => navigate(routePaths.ROOT)})} />
            <div className="my-spona-24 flex flex-col md:flex-row items-start md:items-center">
                <Icon
                    icon={backLightIcon}
                    size='m'
                    className='lg:ml-[-32px]'
                    onClick={() => navigate(-1)}
                />
                <span className="text-[32px] font-bold text-default-100 mt-spona-20 md:ml-spona-20 md:mt-spona-0 lg:ml-spona-64">
                    Terms and conditions
                </span>
            </div>
            <div>
                <h2 className={headingStyle}>
                    Formal Acknowledgment of Terms
                </h2>
                <p className={paragraphStyle}>
                    Upon the engagement and utilization of the services proffered by SPONA,
                    you irrevocably agree to adhere to and be legally bound by these intricate and exhaustive Terms and Conditions (hereinafter referred to as the "Terms").
                    Non-concurrence with the entirety of these Terms necessitates abstention from the use of our services.
                </p>
                <h2 className={headingStyle}>
                    Elaborate Description of Services Rendered
                </h2>
                <p className={paragraphStyle}>
                    SPONA proudly presents a sophisticated lead generation Software as a Service (SaaS) platform, primarily focusing on the meticulous aggregation of business-to-business (B2B)
                    data coupled with the bespoke creation of personalized electronic mail communications, meticulously crafted on behalf of our distinguished clientele.
                </p>
                <h2 className={headingStyle}>Conditions Governing the Utilization of Services</h2>
                <ul className={`list-disc ml-spona-24 ${paragraphStyle}`}>
                    <li>
                        Eligibility Criteria: Our services are exclusively designed for professional and business-oriented utilization.
                        Engaging with our services implicitly confirms your utilization in a professional and business capacity.
                    </li>
                    <li>
                        Mandatory Account Registration: Utilization of our services necessitates the establishment of a user account,
                        requiring the provision of accurate and comprehensive personal and professional information.
                    </li>
                    <li>
                        Obligation for Responsible Usage: You, as a user, are obligated to employ our services in a manner that
                        is responsible, ethical, and in stringent compliance with all pertinent legal statutes and regulations.
                    </li>
                </ul>
                <h2 className={headingStyle}>Intellectual Property Rights and Ownership</h2>
                <ul className={`list-disc ml-spona-24 ${paragraphStyle}`}>
                    <li>
                        Exclusive Rights Ownership: All encompassing intellectual property rights inherent in the services offered by SPONA,
                        including but not limited to the software, data, and content, remain the sole and exclusive property of SPONA or its duly authorized licensors.
                    </li>
                    <li>
                        Conditional License for Utilization: We confer upon you a limited, non-exclusive, and non-transferable license to access
                        and employ our services strictly for your internal business purposes, under the conditions set forth herein.
                    </li>
                </ul>
                <h2 className={headingStyle}>
                    Limitations of Liability
                </h2>
                <p className={paragraphStyle}>
                    SPONA disclaims any liability for indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, revenue, data, use, goodwill,
                    or other non-tangible losses, whether directly or indirectly incurred.
                </p>
                <h2 className={headingStyle}>
                    Disclaimer of Warranties
                </h2>
                <p className={paragraphStyle}>
                    The services offered by SPONA are provided on an "as is" and "as available" basis without any form of warranty, either expressed or implied, including but not limited to implied warranties
                    of merchantability, suitability for a specific purpose, or non-infringement.
                </p>
                <h2 className={headingStyle}>
                    Indemnification Clause
                </h2>
                <p className={paragraphStyle}>
                    You agree to indemnify, defend, and hold harmless SPONA, including its officers, directors, employees, and agents, from any claims, disputes, demands, liabilities, damages, losses, and expenses arising
                    from your utilization of the services or any breach of these Terms.
                </p>
                <h2 className={headingStyle}>
                    Right to Modify Terms
                </h2>
                <p className={paragraphStyle}>
                    SPONA reserves the exclusive right to modify, amend, or revise these Terms at any given time. Notifications regarding significant modifications will
                    be communicated, and periodic review of these Terms is highly recommended.
                </p>
                <h2 className={headingStyle}>
                    Jurisdiction and Governing Law
                </h2>
                <p className={paragraphStyle}>
                    These Terms shall be governed and interpreted in accordance with the laws of United Kingdom, notwithstanding its conflicts of law principles.
                </p>
                <h2 className={headingStyle}>
                    Contact and Communication
                </h2>
                <p className={paragraphStyle}>
                    For inquiries or further clarification regarding these Terms, please reach out to us at legal@sponaglobal.com                </p>
            </div>
        </div>
    )
}

export default TermsAndConditions;