import React from 'react';
import {Icon, Panel} from '../ui-components';
import {backLightIcon, linkedInLogo, youTubeLogo} from '../assets/icons';

const socialNetworkURLs = {
    YOUTUBE: 'https://www.youtube.com/channel/UCjuPR48_-iX3pbW6L89Nt9g',
    LINKEDIN: 'https://www.linkedin.com/company/spona/'
}

const CompleteQuestionnaireScreen = () => {
    return (
        <div className="flex justify-center">
            <div className="min-w-[1300px]">
                <div
                    className="flex items-center cursor-pointer"
                >
                    <Icon icon={backLightIcon} />
                    <span className="text-2xl font-semibold text-default-100 ml-spona-12">
                        Back to custom-made prospect lists
                    </span>
                </div>
                <Panel className="min-h-[480px] mt-spona-12 rounded-[10px] pt-[18px] px-spona-64">
                    <div className="flex flex-col items-center justify-center mt-spona-80">
                        <h1 className="text-default-100 text-2xl font-semibold mb-spona-64">
                            Thank you for completing the SPONA questionnaire
                        </h1>
                        <p className="text-contrast-800 text-base font-medium mb-spona-80">
                            We are processing your request and coming back to you in up to 48 hours
                        </p>
                        <div className="flex items-center">
                            <Icon icon={youTubeLogo} className="mr-spona-8" onClick={() => window.open(socialNetworkURLs['YOUTUBE'], '_blank')}/>
                            <Icon icon={linkedInLogo} onClick={() => window.open(socialNetworkURLs['LINKEDIN'], '_blank')} />
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    )
};

export default CompleteQuestionnaireScreen;