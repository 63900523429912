import React from 'react';
import {Icon} from '../icons';
import {closeIcon} from '../../assets/icons';
import {iconsVariants, iconBackgroundVariants} from './toastNotificationThemeConfig';
import {useToast} from './useToast';

const ToastNotification = props => {
    const {title, description, toastType, id, isCloseable} = props;
    const toast = useToast();

    return (
        <div className="relative animate-toastIn p-spona-16 rounded-[8px] border border-contrast-900 bg-contrast-200 w-[452px]">
            <div className="w-full flex justify-between">
                <div className="flex items-center gap-spona-12">
                    <div className={`flex items-center justify-center ${iconBackgroundVariants[toastType]} rounded-[50%] w-spona-32 h-spona-32`}>
                        <Icon icon={iconsVariants[toastType]} size="xs" />
                    </div>
                    <h2 className="text-default-100 font-semibold">
                        {title}
                    </h2>
                </div>
                {isCloseable && (
                    <Icon
                        icon={closeIcon}
                        size="xs"
                        onClick={() => {toast.closeToast(id)}}
                    />
                )}
            </div>
            <div className="ml-spona-44 text-base font-normal text-contrast-800 mt-spona-8">
                {description}
            </div>
        </div>
    )
}

export default ToastNotification;