import React, {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {constantCase} from 'change-case';
import CategoryList from './ui-elements/CategoryList';
import SubcategoryPanel from './ui-elements/SubcategoryPanel';
import {Button, Icon} from '../ui-components';
import {playIcon, playWhiteIcon} from '../assets/icons';
import categoryToSubcategoryMap from './categoryToSubcategoryMap';
import {categories, categoryButtons} from './categories';
import config from '../config';

const FAQScreen = () => {
    const [searchParams] = useSearchParams();
    const category = searchParams.size ? constantCase(searchParams.get('category')) : '';
    const hoveredButtonStyle = 'hover:bg-contrast-200 hover:text-highlighted-100';

    const [activeCategory, setActiveCategory] = useState(category || categories['GETTING_STARTED']);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="lg:grid grid-cols-12 gap-spona-24">
            <div className="col-span-4">
                <CategoryList active={activeCategory} setActive={setActiveCategory} />
            </div>
            <div className="col-span-8 w-full">
                <div>
                    <div className='md:px-spona-40 lg:px-spona-0'>
                    <Button
                        className={`w-full shadow-[2px_2px_8px_0px_rgba(26,24,24,0.18)] mb-spona-40 text-xl lg:text-2xl ${hoveredButtonStyle}`}
                        buttonType='primary'
                        onClick={() => window.open(config.DEMO_YOUTUBE_LINK, '_blank')}
                        onMouseOver={() => setIsHovered(true)}
                        onMouseOut={() => setIsHovered(false)}
                    >
                        {categoryButtons[activeCategory]?.name}
                        <Icon className="ml-spona-4 hidden lg:block" icon={isHovered ? playIcon : playWhiteIcon} size='xs' />
                        <Icon className="ml-spona-4 basis-2/12 md:basis-auto lg:hidden" icon={isHovered ? playIcon : playWhiteIcon} size='xs' />
                    </Button>
                    </div>
                    {categoryToSubcategoryMap[activeCategory] && (
                        Object.entries(categoryToSubcategoryMap[activeCategory]).map(entry => {
                            const key = entry[0];
                            const value = entry[1];

                            return (
                                <SubcategoryPanel
                                    key={`${activeCategory}-${key}`}
                                    name={key}
                                    category={activeCategory}
                                    subcategory={value}
                                />
                            )
                        })
                    )}
                </div>
            </div>
        </div>
    )
}

export default FAQScreen;