import React from 'react';
import {backLightIcon, backIconDisabled} from '../../assets/icons';
import {Icon} from '../icons';

const Paginator = props => {
    const {onClick, page, count} = props;

    const isRightArrowDisabled = page === count;
    const isLeftArrowDisabled = page === 1;


    return (
        <div className={`flex items-center mt-spona-24 justify-center`}>
            {page > 0 && (
                <Icon
                    icon={isLeftArrowDisabled ? backIconDisabled : backLightIcon}
                    size='s'
                    className='mr-spona-12'
                    onClick={() => (!isLeftArrowDisabled && typeof onClick === 'function' && onClick(page - 1))}
                />
            )}
            <p>
                {`Page ${page} of ${count}`}
            </p>
            <Icon
                icon={isRightArrowDisabled ? backIconDisabled : backLightIcon}
                size='s'
                className="rotate-180 ml-spona-12"
                onClick={() => (!isRightArrowDisabled && typeof onClick === 'function' && onClick(page + 1))}
            />
        </div>
    )
}

export default Paginator;