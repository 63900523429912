import React, {useState, useEffect} from 'react';

const CheckboxGroup = props => {
    const {values, onChange, children} = props;
    const [checkedItems, setCheckedItems] = useState(values || []);

    useEffect(() => {
        if (!!values) {
            setCheckedItems([...values]);
        }
    }, [values, setCheckedItems])

    const handleSelect = event => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            onChange([...values, value]);
        } else {
            const filteredList = checkedItems.filter((item) => item !== value);
            setCheckedItems([...filteredList]);
            onChange(filteredList);
        }
    };

    return (
        <div onChange={event => handleSelect(event)}>
            {children}
        </div>
    );
};

export default CheckboxGroup;
