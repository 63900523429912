import React, {useState, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sentenceCase} from 'change-case';
import {useMatch} from 'react-router-dom';
import {useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import {Button, FieldSet, FieldSetInput, Form, borderDirectionTypes, Icon, Select, Option} from '../../ui-components';
import {americanExpressIcon, mastercardIcon, visaIcon} from '../../assets/icons';
import {countries} from '../../entites/general';
import routePaths from '../../routing/routePaths';
import {homeScreenTabs} from '../../home/homeScreenTabs';
import {attachPaymentMethod, getPaymentMethod} from '../checkoutActions';
import {selectLeadsPricing, selectProspectsPricing} from '../../custom-made-prospect-lists/customMadeProspectListsSlice';
import checkoutButtonActionTypes from '../checkoutButtonActionTypes';
import {selectClientSecret, selectPaymentSecret} from '../checkoutSlice';
import {useGlobalModalContext} from '../../ui-components/modals/useGlobalModal';
import {modalTypes} from '../../modalTypes';

const options = {
    style: {
        base: {
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: "500",
            '::placeholder': {
                color: '#8D8D8D',
            },
        }
    },
}

const defaultStyles = {
    input: 'bg-contrast-500 w-full h-full px-spona-12 py-spona-16 border-contrast-600 text-input-100 text-lg font-medium :focus outline-none appearance-none',
    label: 'block text-lg text-default-100 font-semibold self-start mb-spona-12',
}

const CheckoutForm = props => {
    const {paymentMethod, buttonAction} = props;

    const [country, setCountry] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [billingAddress, setBillingAddress] = useState({
        streetName: '',
        streetNo: '',
        city: '',
        postCode: '',
    });

    const match = useMatch(routePaths.CHECK_OUT);
    const { openModal } = useGlobalModalContext();

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const leadsPricingList = useSelector(selectLeadsPricing);
    const prospectsPricing = useSelector(selectProspectsPricing);
    const clientSecret = useSelector(selectClientSecret);
    const paymentSecret = useSelector(selectPaymentSecret);

    const handleOnAddressChange = (key, value) => {
        setBillingAddress(prevState => ({
            ...prevState,
            [key]: value,
        }));
    }

    const loadPaymentMethod = async () => {
        try {
            await dispatch(getPaymentMethod()).unwrap();
        } catch (error) {
            // no-op
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) return;

        if (!country) {
            setErrorMessage('Please select country from the drop down list');
            return;
        }

        const cardNoElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        if (cardNoElement?._invalid || cardNoElement?._empty
        || cardExpiryElement?._invalid || cardExpiryElement?._empty
        || cardCvcElement?._invalid || cardCvcElement?._empty) {
            setErrorMessage('Please enter valid card details')
            return;
        }

        setErrorMessage('');
        const billingDetails = {
            email,
            address: {
                line1: `${billingAddress?.streetName} ${billingAddress?.streetNo}`,
                city: billingAddress.city,
                postal_code: billingAddress.postCode,
                country,
            },
        };

        if (!paymentMethod && buttonAction === checkoutButtonActionTypes.ATTACH_CARD) {
            try {
                await dispatch(attachPaymentMethod()).unwrap();
                await stripe.confirmCardSetup(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: billingDetails,
                    }});
                loadPaymentMethod();
            } catch (err) {
                // no-op
            }
            return;
        }

        if (!match) return;
        const {priceId} = match.params;

        if (!priceId) return;

        try {
            if (!paymentSecret) return;

            const {error} = await stripe.confirmCardPayment(paymentSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: billingDetails,
                },
            })

            const isProspectProduct = prospectsPricing?._id === priceId;

            openModal(modalTypes.PAYMENT_ACTION, {
                hasPaymentFailed: !!error,
                purchasedProduct: {
                    route: routePaths[!isProspectProduct ? 'CUSTOM_MADE_PROSPECT_LISTS' : 'APPOINTMENT_SETTING'],
                    name: homeScreenTabs[!isProspectProduct ? 'CUSTOM_MADE_PROSPECT_LISTS' : 'APPOINTMENT_SETTING'],
                },
            });
        } catch (error) {
            // no-op
        }
    }

    const isPayButtonDisabled = Object.values(billingAddress).some(value => !value) || !name || !email;

    return (
        <div className="w-full bg-contrast-200">
            <Form name='checkoutForm' onSubmit={e => handleOnSubmit(e)}>
                <Fragment>
                    <label
                        htmlFor="email"
                        className={defaultStyles.label}
                    >
                        Email
                    </label>
                    <input
                        name="email"
                        placeholder="Your work email"
                        className={`${defaultStyles.input} rounded-[10px] border border-solid border-contrast-600 mb-spona-24`}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <FieldSet label="Card details">
                        <div className="relative">
                            <CardNumberElement
                                id="card-number"
                                className={`${defaultStyles.input} border-b`}
                                options={options}
                            />
                            <div className='absolute top-[14px] right-spona-12'>
                                <Icon icon={americanExpressIcon} className='mr-spona-4' />
                                <Icon icon={visaIcon}  className='mr-spona-4' />
                                <Icon icon={mastercardIcon} />
                            </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0">
                            <div className="col-span-3">
                                <CardExpiryElement
                                    id="expiration-date"
                                    className={`${defaultStyles.input} border-r`}
                                    options={options}
                                />
                            </div>
                            <div className="col-span-3">
                                <CardCvcElement
                                    id="cvc"
                                    className={defaultStyles.input}
                                    options={options}
                                />
                                </div>
                            </div>
                        </FieldSet>
                        <label
                            htmlFor="cardHolder"
                            className={defaultStyles.label}
                        >
                            Cardholder name
                        </label>
                        <input
                            name="cardHolder"
                            placeholder="Name on the card"
                            className={`${defaultStyles.input} rounded-[10px] border border-solid border-contrast-600 mb-spona-24`}
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <FieldSet label="Billing address">
                            <Select
                                name='country'
                                placeholder="Country"
                                borderDirection={borderDirectionTypes.BOTTOM}
                                value={country || ''}
                                onChange={setCountry}
                            >
                                {countries.map(country => (
                                    <Option key={country.code} value={country.code}>
                                        {country.name}
                                    </Option>
                                ))}
                            </Select>
                            <FieldSetInput
                                name="streetName"
                                borderDirection={borderDirectionTypes.BOTTOM}
                                placeholder="Street name"
                                value={billingAddress?.streetName || ''}
                                onChange={value => handleOnAddressChange('streetName', value)}
                            />
                            <FieldSetInput
                                name="streetNumber"
                                borderDirection={borderDirectionTypes.BOTTOM}
                                placeholder="Street number"
                                value={billingAddress?.streetNo || ''}
                                onChange={value => handleOnAddressChange('streetNo', value)}
                            />
                            <div className="grid grid-cols-6 gap-0">
                                <div className="col-span-3">
                                    <FieldSetInput
                                        name="postCode"
                                        borderDirection={borderDirectionTypes.RIGHT}
                                        placeholder="Postal code"
                                        value={billingAddress?.postCode || ''}
                                        onChange={value => handleOnAddressChange('postCode', value)}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <FieldSetInput
                                        name="city"
                                        placeholder="City"
                                        value={billingAddress?.city || ''}
                                        onChange={value => handleOnAddressChange('city', value)}
                                    />
                                </div>
                            </div>
                        </FieldSet>
                    </Fragment>
                <div>
                    {errorMessage &&
                        <p className="mt-spona-44 mb-spona-8 text-warning-100 text-base">
                            {errorMessage}
                        </p>
                    }
                    <Button
                        buttonType='primary'
                        className="w-full"
                        isDisabled={!stripe || isPayButtonDisabled}
                    >
                        {sentenceCase(buttonAction)}
                    </Button>
                </div>
            </Form>
        </div>
    )
};

export default CheckoutForm;