import React from 'react';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Button, Icon} from '../../ui-components';
import {warningIcon} from '../../assets/icons';
import {removeCurrentUser} from '../authActions';
import routePaths from '../../routing/routePaths';

const DeleteAccountConfirmationContent = props => {
    const {closeModal, setIsAccountVerified} = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOnDeleteAccount = async () => {
        try {
            await dispatch(removeCurrentUser()).unwrap();
            setIsAccountVerified(false);
            localStorage.removeItem('accessToken');
            navigate(routePaths.ROOT);
        } catch (e) {
            // ToDo: handle error
        }
    };

    const handleOnCloseModal = () => {
        closeModal();
        setIsAccountVerified(false);
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <Icon icon={warningIcon} />
            <h2 className="text-default-100 text-2xl font-semibold mt-spona-18 align-center text-center">
                Are you sure you want to<br/>delete this account?
            </h2>
            <p className="text-contrast-800 text-base font-medium mt-spona-24 mb-spona-44 align-center text-center">
                All your existing subscriptions will be automatically canceled<br/>Deleting the account also deletes all your data
            </p>
            <div className="grid grid-cols-12 gap-x-spona-12 w-full">
                <Button buttonType='basic' className='col-span-6' onClick={handleOnCloseModal}>
                    Cancel
                </Button>
                <Button buttonType='warning' className='col-span-6' onClick={handleOnDeleteAccount}>
                    Delete
                </Button>
            </div>
        </div>
    )
}

export default DeleteAccountConfirmationContent;