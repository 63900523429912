import React from 'react';
import {HighlightedText} from '../../../ui-components';

const SPONAKeyFeatures = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                SPONA is designed with innovative features to enhance your lead generation and sales efforts.<br/>
                Our key offerings focus on personalization and efficiency, ensuring that you reach your target audience effectively.<br/>
                Here’s an overview of our standout features:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Tailor-Made Lead Lists:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <HighlightedText className='w-full mb-spona-24 ml-[-48px]'>
                            <li className="list-inside py-spona-12">
                                <span className="font-bold">Customization via Questionnaire:</span> Simply fill out a questionnaire detailing
                                your specific needs, target market, and other preferences.
                            </li>
                        </HighlightedText>
                        <HighlightedText className='w-full mb-spona-24 ml-[-48px]'>
                            <li className="list-inside py-spona-12">
                                <span className="font-bold">Analyst and Data Entry Specialist Involvement:</span> Our experts, including market analysts and data entry specialists, process your request.<br/>
                                They conduct a thorough market analysis to curate a lead list tailored precisely to your requirements.
                            </li>
                        </HighlightedText>
                    </ul>
                    <li>Sales Calls Booked Through Email Outreach:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <HighlightedText className='w-full mb-spona-24 ml-[-48px]'>
                            <li className="list-inside py-spona-12">
                                <span className="font-bold">Personalized Campaign Setup:</span> Before launching an email outreach campaign, you’ll complete a questionnaire.<br/>
                                This is where you upload marketing materials related to your products or services and grant
                                SPONA access to your email inbox for seamless communication.
                            </li>
                        </HighlightedText>
                        <HighlightedText className='w-full ml-[-48px]'>
                            <li className="list-inside py-spona-12">
                                <span className="font-bold">Professional Email Outreach:</span> Utilizing the information and materials you provide, SPONA conducts email outreach campaigns on your behalf.<br/>
                                This approach aims to secure sales calls and appointments, directly contributing to your sales pipeline.
                            </li>
                        </HighlightedText>
                    </ul>
                </ul>
            </div>
            <i className="font-medium text-default-100 text-lg mt-spona-24">
                SPONA features are designed to take the complexity out of lead generation and sales prospecting. <br/>
                By combining personalization with our expert-driven approach, we ensure that your outreach is targeted, relevant, and effective. <br/>
                Embrace the power of SPONA to transform your lead generation strategy into a results-driven journey.
            </i>
        </div>
    )
}

export default SPONAKeyFeatures;