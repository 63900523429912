import React from 'react';
import { useLocation, matchPath } from 'react-router-dom'
import { Modal } from '../ui-components/modals';
import routePaths from '../routing/routePaths';
import AuthenticationFormContent from './ui-elements/AuthentificationFormContent';
import AccountActivationContent from './ui-elements/AccountActivationContent';
import ForgotPasswordContent from './ui-elements/ForgotPasswordContent';
import ChangePasswordContent from './ui-elements/ChangePasswordContent';
import DesktopMessageContent from '../ui-components/general/DesktopMesageContent';
import { useScreenResolution } from '../utils/customHooks';

const AuthenticationScreen = () => {
    const location = useLocation();
    const isLoginRouteActive = location.pathname === routePaths.LOGIN;
    const hrefStyle = "underline decoration-from-font font-semibold underline-offset-2"
    const {isDesktopScreen} = useScreenResolution();

    const renderContent = () => {
        const changePasswordMatch = matchPath(routePaths.CHANGE_PASSWORD, location.pathname);
        const registerMatch = matchPath(routePaths.REGISTER, location.pathname);

        if (changePasswordMatch) {
            return <ChangePasswordContent />
        }

        if (registerMatch) {
            return <AuthenticationFormContent />
        }

        switch (location.pathname) {
            case routePaths.LOGIN:
                return <AuthenticationFormContent />

            case routePaths.EMAIL_VERIFICATION:
                return <AccountActivationContent />

            case routePaths.FORGOT_PASSWORD:
                return <ForgotPasswordContent />

            default:
                return null
        }
    }

    const isRegisterMatch = matchPath(routePaths.REGISTER, location.pathname);
    const isEmailVerificationMatch = matchPath(routePaths.EMAIL_VERIFICATION, location.pathname);

    return (
        <div className={`${isRegisterMatch || isEmailVerificationMatch ? 'bg-highlighted-400' : 'bg-highlighted-100'} w-full h-full fixed`}>
            {!isDesktopScreen
                ? <DesktopMessageContent />
                : (
                    <div className="hidden lg:block">
                        {!(isRegisterMatch || isEmailVerificationMatch) && (
                            <>
                                <div className="z-0 absolute left-[-300px] top-[450px] bg-highlighted-200 opacity-50 rounded-[160px] w-[750px] h-[750px] origin-center rotate-[-135deg]" />
                                <div className="z-0 absolute right-[-300px] bottom-[450px] bg-highlighted-200 opacity-50 rounded-[160px] w-[750px] h-[750px] origin-center rotate-[-135deg]" />
                            </>
                        )}
                        <Modal
                            hasHeaderLogo={true}
                            isModalOpened={isDesktopScreen}
                            hasBackdrop={false}
                            contentClassName={`${isLoginRouteActive ? 'h-full flex flex-col justify-between' : ''}`}
                            size="sm"
                        >
                            {renderContent()}
                            {location.pathname !== routePaths.FORGOT_PASSWORD && (
                                <div className="flex items-center justify-center flex-col mt-spona-32">
                                    {isLoginRouteActive && (
                                        <p className="text-lg mb-spona-12">
                                            Don't have a SPONA account?&nbsp;
                                            <a
                                                className="text-highlighted-100 font-semibold cursor-pointer"
                                                href={routePaths.START_QUESTIONNAIRE_ONE}
                                            >
                                                Sign up
                                            </a>
                                        </p>
                                    )}
                                    {(matchPath(routePaths.REGISTER, location.pathname)
                                        || matchPath(routePaths.EMAIL_VERIFICATION, location.pathname)) && (
                                        <p className="text-contrast-800 text-sm">
                                            By signing up, you agree to our <a href={routePaths.TERMS_AND_CONDITIONS} className={hrefStyle}>Terms of use</a> and <a href={routePaths.DATA_PRIVACY} className={hrefStyle}>Privacy Policy</a>
                                        </p>
                                    )}
                                </div>
                            )}
                        </Modal>
                    </div>
            )}
        </div>
    )
}

export default AuthenticationScreen;