import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom'
import {closeIcon, eyeClosedIcon, eyeOpenIcon, hoveredSearchIcon, searchIcon} from '../../assets/icons';
import {Icon} from '../icons';
import routePaths from '../../routing/routePaths';
import passwordInfoTypes from './passwordInfoTypes';
import inputTypes from './inputTypes';

const Input = props => {
    const {name, value, isDisabled, maxLength, inputType, label, onBlur, onSearch} = props;
    const {onChange, placeholder, className, error, passwordInfo} = props;
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const text = inputTypes.TEXT.toLowerCase();
    const password = inputTypes.PASSWORD.toLowerCase();

    const navigate = useNavigate();

    const defaultInputStyles = "appearance-none" +
        "bg-contrast-500 " +
        "h-[50px] " +
        "justify-center " +
        "items-center " +
        "self-stretch " +
        "text-input-100 " +
        "font-medium border " +
        "p-spona-12 " +
        "rounded-[10px] " +
        "border-solid " +
        'text-lg ' +
        `${error ? 'border-warning-100 ' : 'border-contrast-600'} ` +
        ':focus outline-none'

    const handleOnChange = event => {
        if (typeof onChange === 'function') onChange(event.target.value);
    };

    const handleToggle = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className="relative mb-spona-24">
            {label && (
                <div className="flex justify-between items-center mb-spona-12">
                    <label
                        className="block text-xl font-semibold text-default-100 self-start"
                        htmlFor={name}
                    >
                        {label}
                    </label>
                    {inputType === password
                        && passwordInfo === passwordInfoTypes.SHOW_PASSWORD && (
                            <div
                                className="text-highlighted-100 text-base cursor-pointer"
                                onClick={() => navigate(routePaths.FORGOT_PASSWORD)}
                            >
                                Forgot password?
                            </div>
                    )}
                    {inputType === password
                        && passwordInfo === passwordInfoTypes.SHOW_PASSWORD_CONSTRAINS && (
                            <div className="text-highlighted-100 text-base">
                                At least 8 characters and one digit
                            </div>
                        )}
                </div>
            )}
            <input
                className={`${className ? className : ''} ${defaultInputStyles}`}
                name={name}
                value={value}
                type={inputType === password ? (!isPasswordVisible ? password : text) : text}
                disabled={isDisabled}
                maxLength={maxLength}
                onChange={handleOnChange}
                placeholder={placeholder}
                onBlur={onBlur}
            />
            {inputType === 'password' && (
                <div className='absolute top-[51px] right-spona-12' onClick={handleToggle}>
                    <Icon icon={isPasswordVisible ? eyeOpenIcon : eyeClosedIcon} size='s' />
                </div>
            )}
            {inputType === 'search' && (
                <div className='absolute top-[12px] right-spona-12'>
                    <Icon
                        icon={searchIcon}
                        size='s'
                        onClick={onSearch}
                        hoverIcon={hoveredSearchIcon} />
                </div>
            )}
            {error && (
                <p className="mt-spona-8 text-warning-100 text-base">{error}</p>
            )}
        </div>
    )
}

export default Input;