import React from 'react';

const AccountActivationContent = () => {
    return (
        <div className="flex items-center justify-center flex-col">
            <h1 className="text-default-100 text-2xl font-bold my-[90px]">Almost there!</h1>
            <p className="text-2xl font-bold text-center py-[36px] mb-[206px]">We’ve sent you an email with a link<br/>to activate your account</p>
        </div>
    )
}

export default AccountActivationContent;