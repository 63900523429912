import React, {useEffect, useRef, Fragment} from 'react';
import {useMatch, useNavigate} from 'react-router-dom';
import moment from 'moment';
import {loadStripe} from '@stripe/stripe-js';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Icon, LoaderSpinner, Pill, typographyStyles} from '../ui-components';
import {backWhiteIcon, logoIcon} from '../assets/icons';
import routePaths from '../routing/routePaths';
import {getPaymentList, getPaymentMethod, setupPaymentIntent} from '../checkout/checkoutActions';
import { getCurrentUser } from './authActions'
import {selectClientSecret, selectLoadingPaymentList, selectLoadingPaymentMethod, selectPaymentList, selectPaymentMethod} from '../checkout/checkoutSlice';
import {paymentStatusTypes} from '../entites/stripe';
import PersonalInformation from '../checkout/ui-elements/PersonalInformation';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from '../checkout/ui-elements/CheckoutForm';
import config from '../config';
import checkoutButtonActionTypes from '../checkout/checkoutButtonActionTypes';
import SavedPaymentMethod from '../checkout/ui-elements/SavedPaymentMethod';
import {resolveRoute} from '../utils';

const pillVariants = {
    [paymentStatusTypes.COMPLETE]: 'positive',
    [paymentStatusTypes.FAILED]: 'negative',
    [paymentStatusTypes.INCOMPLETE]: 'pending',
};

const stripePromise = loadStripe(`${config.STRIPE_PUBLIC_KEY}`);

const UserBillingPortalScreen = () => {
    const didComponentMountRef = useRef(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const match = useMatch(routePaths.BILLING_PORTAL);

    const paymentList = useSelector(selectPaymentList);
    const paymentMethod = useSelector(selectPaymentMethod);
    const clientSecret = useSelector(selectClientSecret);
    const isPaymentMethodPending = useSelector(selectLoadingPaymentMethod);
    const isPaymentListPending = useSelector(selectLoadingPaymentList);

    const {priceId, questionnaireId} = match.params;

    const getAllBills = async () => {
        try {
            await dispatch(getPaymentList()).unwrap();
        } catch (error) {
            // no-op
        }
    }

    const fetchCurrentUser = async () => {
        try {
            const currentUser = await dispatch(getCurrentUser()).unwrap();
            if (currentUser) checkIfUserHasPaymentMethod(currentUser);
        } catch (error) {
            // no-op
        }
    }

    const checkIfUserHasPaymentMethod = async (currentUser) => {
        try {
            await dispatch(getPaymentMethod()).unwrap();
            getClientSecret(currentUser);
        } catch (error) {
            // no-op
        }
    }

    const getClientSecret = async (currentUser) => {
        try {
            await dispatch(setupPaymentIntent({
                email: currentUser.email,
                name: currentUser.name,
            })).unwrap();
        } catch (error) {
            // no-op
        }
    }

    useEffect(() => {
        if (!didComponentMountRef.current) {
            fetchCurrentUser();
            getAllBills();

            didComponentMountRef.current = true;
        }
    });

    return (
        <div className="grid grid-cols-12 h-full w-full fixed overflow-auto">
            <div className="col-span-5 bg-highlighted-100 overflow-hidden relative px-spona-64 py-spona-44">
                <div className="z-0 absolute left-[-100px] top-[-350px] bg-highlighted-200 opacity-50 rounded-[160px] w-[560px] h-[560px] origin-center rotate-[-135deg]" />
                <div className="z-0 absolute left-[400px] bottom-[-280px] bg-highlighted-200 opacity-50 rounded-[160px] w-[560px] h-[560px] origin-center rotate-[-135deg]" />
                <div className="flex flex-col justify-between h-full text-contrast-100">
                    <div>
                        <div className="flex items-center">
                            <Icon
                                icon={backWhiteIcon}
                                size='m'
                                className='mr-spona-16 z-50'
                                onClick={() => navigate(-1)}
                            />
                            <Icon
                                icon={logoIcon}
                                size='xxl'
                                className='mr-spona-16 z-50'
                                onClick={() => navigate(routePaths.ROOT)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-span-7 flex flex-col p-spona-44 bg-contrast-200'>
                <div className="w-full">
                    <div className="w-[70%]">
                        {/* ToDo: Uncomment when subscription feature is ready */}
                        {/*<h2 className={`${typographyStyles['h2']} text-default-100`}>*/}
                        {/*    Subscription*/}
                        {/*</h2>*/}
                        {/*<div className="border-b border-contrast-600"/>*/}
                        {/*<div>*/}
                        {/*    <p className="my-spona-12 text-2xl text-contrast-800 font-bold">*/}
                        {/*        Your payment of € 299,00 is 7 days overdue*/}
                        {/*    </p>*/}
                        {/*    <p className="my-spona-12 text-lg text-contrast-800 font-medium">*/}
                        {/*        Next billing date: 14. November 2023*/}
                        {/*    </p>*/}
                        {/*    <Button buttonType="primary" className='w-[70%] mt-spona-40'>*/}
                        {/*        Pay overdue invoice*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        {!!priceId && !!questionnaireId && (
                            <Button
                                buttonType="primary"
                                className='w-[70%] mt-spona-40'
                                isDisabled={!paymentMethod}
                                onClick={() => navigate(resolveRoute(routePaths.CHECK_OUT, { priceId: priceId, questionnaireId: questionnaireId }))}
                            >
                                Proceed to pay
                            </Button>
                        )}
                    </div>
                    <div className="mt-spona-32">
                        <h2 className={`${typographyStyles['h2']} text-default-100`}>
                            Payment method
                        </h2>
                        <div className="border-b border-contrast-600 mb-spona-24"/>
                        {isPaymentMethodPending
                            ? <LoaderSpinner />
                            : (
                                <Fragment>
                                    <div className="mt-spona-24 w-[50%]">
                                        {!!clientSecret && (
                                            <Elements
                                                stripe={stripePromise}
                                                options={{
                                                    fonts: [{
                                                        cssSrc: config.RALEWAY_FONT_URL,
                                                    }],
                                                    clientSecret: clientSecret
                                                }}
                                            >
                                                {!!paymentMethod
                                                    ? (
                                                        <div className="w-full">
                                                            <SavedPaymentMethod buttonAction={checkoutButtonActionTypes.DETACH_CARD} />
                                                        </div>
                                                    ) : (
                                                        <CheckoutForm
                                                            paymentMethod={paymentMethod}
                                                            buttonAction={checkoutButtonActionTypes.ATTACH_CARD}
                                                        />
                                                    )}
                                            </Elements>
                                        )}
                                    </div>
                                </Fragment>
                            )}
                    </div>
                    <div className="mt-spona-32">
                        <h2 className={`${typographyStyles['h2']} text-default-100`}>
                            Personal information
                        </h2>
                        <div className="border-b border-contrast-600 mb-spona-24`"/>
                        {isPaymentMethodPending
                            ? <LoaderSpinner />
                            : <PersonalInformation />}
                    </div>
                    <div className="mt-spona-32">
                        <h2 className={`${typographyStyles['h2']} text-default-100`}>
                            All bills
                        </h2>
                        <div className="border-b border-contrast-600 mb-spona-24"/>
                        {isPaymentListPending
                            ? <LoaderSpinner />
                            : (
                                <div className="w-[50%]">
                                    {paymentList?.length > 0 && paymentList.filter(paymentListItem => !!paymentListItem.status)
                                        .map((paymentListItem, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    className="text-lg font-semibold text-contrast-800 mb-spona-12 w-full flex items-center grid grid-cols-9"
                                                >
                                                    <span className="mr-spona-32 col-span-3">
                                                        {moment(paymentListItem.date).format('DD.MM.YYYY')}
                                                    </span>
                                                    <span className="mr-spona-32 col-span-2">
                                                        {`${paymentListItem.amount}€`}
                                                    </span>
                                                    <div className="col-span-4">
                                                        <Pill type={pillVariants[paymentListItem.status.toUpperCase()]}>
                                                            {paymentListItem.status}
                                                        </Pill>
                                                    </div>
                                                </div>
                                            )
                                    })}
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserBillingPortalScreen;