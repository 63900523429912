import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {Button, Checkbox, CheckboxGroup, Icon, Input, LoaderSpinner, Paginator, Pill, DateField, Table} from '../ui-components';
import {checkmarkIcon, closeIcon, closeRedIcon, downloadDisabledIcon, downloadIcon, editPenIcon, fileIcon, hoveredEditPenIcon, reloadIcon, hoveredFileIcon} from '../assets/icons';
import {selectLoadingCurrentUser} from '../authentification/authSlice';
import {getCurrentUser} from '../authentification/authActions';
import userRoleTypes from '../entites/user-profile/userRoleTypes';
import {getCustomerOverviewLeadList} from './adminActions';
import {outstandingThresholdTypes, questionnaireOneStatusTypes} from '../entites';
import {selectCustomerLeadListCount, selectCustomerLeadListData} from './adminSlice';
import FilterOption from './ui-elements/FilterOption';
import routePaths from '../routing/routePaths';
import {resolveRoute} from '../utils';
import config from '../config';
import {modalTypes} from '../modalTypes';
import {useGlobalModalContext} from '../ui-components/modals/useGlobalModal';

const filterLabelStyles = 'font-semibold text-[20px] text-default-100 mb-spona-12';
const initialFilters = {
    outstanding: [],
    status: '',
    dateFrom: null,
    dateTo: null,
    tryForFree: false,
}
const pillVariants = {
    [questionnaireOneStatusTypes.READY]: 'positive',
    [questionnaireOneStatusTypes.READY_UNTIL]: 'negative',
    [questionnaireOneStatusTypes.NO_MORE_LEADS]: 'negative',
    [questionnaireOneStatusTypes.INCOMPLETE]: 'pending',
};

const statusFilterOptionsConfig = {
    [questionnaireOneStatusTypes.READY]: {
        name: "Ready",
        value: "Ready",
    },
    [questionnaireOneStatusTypes.READY_UNTIL]: {
        name: "Ready until",
        value: "Until",
    },
    [questionnaireOneStatusTypes.INCOMPLETE]: {
        name: "Incomplete",
        value: "Incomplete",
    },
}

const AdminLeadsScreen = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuAnimation, setMenuAnimation] = useState('animate-slideLeft');
    const menuRef = useRef(null);
    const didComponentMountRef = useRef(false);
    const token = localStorage.getItem('accessToken');

    const isLoading = useSelector(selectLoadingCurrentUser);
    const customerLeadListData = useSelector(selectCustomerLeadListData);
    const customerLeadListCount = useSelector(selectCustomerLeadListCount);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { openModal } = useGlobalModalContext();
    const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        outstanding: !!queryParams.get('outstanding') ? [queryParams.get('outstanding')] : [],
        status: queryParams.get('status') || '',
        dateFrom: queryParams.get('from') || null,
        dateTo: queryParams.get('to') || null,
        tryForFree: queryParams.get('tryForFree') || false,
    });

    const columns = [
        {
            id: "name",
            accessorKey: "name",
            header: "Name",
            cell: ({row}) => (
                <div className="text-contrast-800 font-medium text-xs break-all">
                    {row.original.name}
                </div>
            ),
            size: 200,
        },
        {
            id: "userEmail",
            accessorKey: "userEmail",
            header: "Email",
            cell: ({row}) => (
                <div className="text-contrast-800 font-medium text-xs whitespace-nowrap">
                    {row.original.userEmail}
                </div>
            ),
            size: 200,
        },
        {
            id: "status",
            accessorKey: "status",
            header: "Status",
            size: 200,
            cell: (info) => {
                const status = info.getValue();
                return (
                    <Pill type={pillVariants[status.toLowerCase().includes('until') ? questionnaireOneStatusTypes.READY_UNTIL : status.toUpperCase()]} className="w-full">
                        {info.getValue()}
                    </Pill>
                )
            }
        },
        {
            id: 'file',
            accessorKey: "file",
            header: "File",
            cell: (info) => {
                const fileLink = info.getValue();
                return (
                    <Icon
                        icon={!!fileLink ? downloadIcon : downloadDisabledIcon}
                        {...(!!fileLink && {onClick: () => handleOnFileDownload(fileLink)})}
                    />
                )
            },
            size: 100,
        },
        {
            id: "outstanding",
            accessorKey: "outstanding",
            header: "Outstanding",
            size: 100,
            cell: (outstanding) => (
                <div className={`${outstanding.getValue() === outstandingThresholdTypes.L 
                    ? 'text-warning-300' : 'text-highlighted-500'}`}>
                    {outstanding.getValue() === outstandingThresholdTypes.S
                        ? '< 500'
                        : outstanding.getValue()}
                </div>
            ),
        },
        {
            id: "extracted",
            accessorKey: "extracted",
            header: "Extracted",
            size: 100,
        },
        {
            id: "tryForFree",
            accessorKey: "tryForFree",
            header: "Try for free",
            cell: (info) => (
                <Icon size="xs" icon={info.getValue() ? checkmarkIcon : closeRedIcon} />
            ),
            size: 150,
        },
        {
            id: "date",
            accessorKey: "id",
            header: "Date",
            cell: ({row}) => {
                return (
                    <div>
                        {moment(row.original.createdAt).format('DD/MM/YYYY')}
                    </div>
                )
            },
            size: 150,
        },
        {
            id: 'edit',
            accessorKey: "id",
            size: 80,
            header: () => null,
            cell: ({row}) => (
                <Icon
                    size="xs"
                    icon={editPenIcon}
                    hoverIcon={hoveredEditPenIcon}
                    onClick={() => openModal(modalTypes.EDIT_LEADS, {data: row.original})}
                />
            ),
        },
        {
            id: 'questionnaire',
            accessorKey: "id",
            size: 80,
            header: () => null,
            cell: ({row}) => (
                <Icon
                    size="xs"
                    icon={fileIcon}
                    hoverIcon={hoveredFileIcon}
                    onClick={() => openModal(modalTypes.USER_QUESTIONNAIRE_DATA, {data: row.original})} />
            ),
        },
        {
            id: 'delete',
            accessorKey: "id",
            size: 80,
            header: () => null,
            cell: ({row}) => {
                return (
                    <div className='hover:rounded-[5px] hover:bg-warning-50 w-[24px]'>
                        <Icon
                            icon={closeRedIcon}
                            onClick={() => openModal(modalTypes.DELETE_ROW, {rowId: row.original?._id})}
                        />
                    </div>
                )
            },
        },
    ];

    const fetchCustomerLeadList = async (searchParams) => {
        try {
            await dispatch(getCustomerOverviewLeadList({
                searchParams: searchParams
            })).unwrap();
        }
        catch (error) {
            // no-op
        }
    }

    useEffect(() => {
        if (typeof location.search === 'string') {
            const newQueryParams = new URLSearchParams(location.search);
            if (newQueryParams.toString() !== queryParams.toString() && didComponentMountRef.current) {
                setQueryParams(newQueryParams);
                fetchCustomerLeadList(newQueryParams.toString())
            }
        }
    }, [location.search, queryParams]);

    useEffect( () => {
        if (!token) return;
        if (!didComponentMountRef.current) {
            const loadCurrentUser = async () => {
                try {
                    const currentUser = await dispatch(getCurrentUser()).unwrap();
                    if (!currentUser || currentUser?.role !== userRoleTypes.ADMIN.toLowerCase()) {
                        navigate(routePaths.PAGE_NOT_FOUND);
                        return;
                    }
                    const searchQueryParams = new URLSearchParams(location.search);
                    searchQueryParams.set('page', `${config.INITIAL_PAGE_SIZE}`);
                    searchQueryParams.set('limit', `${config.DEFAULT_LIMIT_SIZE}`);
                    navigate(resolveRoute(routePaths.ADMIN_LEADS, {}, {search: searchQueryParams.toString()}), { replace: true });
                    fetchCustomerLeadList(searchQueryParams.toString());
                }
                catch (error) {
                    navigate(routePaths.LOGIN);
                }
            }

            loadCurrentUser();
            didComponentMountRef.current = true;
        }
    });

    const handleOnFileDownload = file => {
        const link = document.createElement('a');
        link.href = file;
        link.click();
    };

    const handleToggleMenu = () => {
        setMenuAnimation(isMenuOpen ? 'animate-slideRight' : 'animate-slideLeft');
        setTimeout(() => {
            setIsMenuOpen(!isMenuOpen);
        }, 400)
    }

    const handleOnFilterChange = (key, value) => {
        setFilters({
            ...filters,
            [key]: value,
        })
    }

    const handlePaginationPage = page => {
        const searchQueryParams = new URLSearchParams(location.search);
        searchQueryParams.set('page', `${page}`);
        searchQueryParams.set('limit', `${config.DEFAULT_LIMIT_SIZE}`);
        navigate(resolveRoute(routePaths.ADMIN_LEADS, {}, {search: searchQueryParams.toString()}), { replace: true });
    }

    const filterData = () => {
        if (!filters) return;

        const data = {
            ...(filters['outstanding']?.length && {outstanding: filters['outstanding'].join('')}),
            ...(filters['status']?.length && {status: filters['status']}),
            ...(filters['dateFrom'] && {from: moment(filters['dateFrom']).format('YYYY-MM-DD')}),
            ...(filters['dateTo'] && {to: moment(filters['dateTo']).format('YYYY-MM-DD')}),
            ...(filters['tryForFree'] && {tryForFree: filters['tryForFree']}),
            ...(searchTerm && {search: searchTerm}),
        }
        if (!data) return;
        navigate(resolveRoute(
            routePaths.ADMIN_LEADS,
            {},
            {search: formatQueryParams({...data})}),
            { replace: true }
        );
        setIsMenuOpen(false);
    }

    const formatQueryParams = params => {
        const searchQueryParams = new URLSearchParams();
        searchQueryParams.set('page', `${config.INITIAL_PAGE_SIZE}`);
        searchQueryParams.set('limit', `${config.DEFAULT_LIMIT_SIZE}`);

        if (params) {
            Object.keys(params).forEach(formField => {
                if (params[formField] !== undefined && params[formField] !== '') {
                    searchQueryParams.append(`${formField}`, params[formField]);
                }
            });
        }

        return searchQueryParams.toString();
    };

    const resetFilters = () => {
        setSearchTerm('');
        setFilters(initialFilters);
        navigate(resolveRoute(
            routePaths.ADMIN_LEADS,
            {},
            {search: formatQueryParams()}),
            { replace: true }
        );
        setIsMenuOpen(false);
    }

    return (
        isLoading
            ? <div className="flex items-center justify-center h-screen"><LoaderSpinner /></div>
            : (
                <div className="flex flex-col">
                    <h1 className="text-default-100 text-[40px] font-bold z-[1030]">Customer overview lead lists</h1>
                    <div className="grid grid-cols-12">
                        <div className="col-span-10 w-full flex items-center">
                            <div className="mr-spona-24 mt-spona-24">
                                <Input
                                    placeholder="Search"
                                    inputType="search"
                                    className="bg-contrast-500 w-[440px] placeholder:italic"
                                    value={searchTerm}
                                    onChange={setSearchTerm}
                                    onSearch={filterData}
                                />
                            </div>
                            <Button buttonType='primary' onClick={handleToggleMenu} className="shrink-0" >
                                + Advanced search filter
                            </Button>
                            {searchTerm && (
                                <div
                                    className="ml-spona-12 flex items-center justify-center cursor-pointer text-center"
                                    onClick={resetFilters}
                                >
                                    <Icon
                                        size="xs"
                                        icon={reloadIcon}
                                        className="shrink-0 mr-spona-4"
                                    />
                                    <span className="text-highlighted-100 font-semibold">clear search</span>
                                </div>
                            )}
                        </div>
                    </div>
                    {customerLeadListData?.length > 0 && (
                        <div className="inline-flex">
                            <div className="inline-table">
                                <Table data={customerLeadListData} columns={columns} />
                                {customerLeadListCount > 0 && (
                                    <Paginator
                                        page={+queryParams.get('page') || config.INITIAL_PAGE_SIZE}
                                        count={Math.ceil(customerLeadListCount / config.DEFAULT_LIMIT_SIZE)}
                                        onClick={page => handlePaginationPage(page)}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {isMenuOpen && filters && (
                        <div ref={menuRef} className={`${menuAnimation} z-[2000] flex flex-col p-spona-24 fixed top-spona-0 right-spona-0 bottom-spona-0 w-[600px] inset-y-0 bg-contrast-200 shadow-navbar overflow-auto`}>
                            <div className="w-full flex items-center justify-between">
                                <h1 className="text-2xl	font-semibold text-default-100">Advanced filter</h1>
                                <Icon icon={closeIcon} size="s" color="informative" onClick={handleToggleMenu} />
                            </div>
                            <div className="h-full flex flex-col justify-between">
                                <div>
                                    <hr className="border-contrast-600 mt-spona-16 mb-spona-32" />
                                    <h3 className={filterLabelStyles}>Outstanding</h3>
                                    <CheckboxGroup values={filters?.['outstanding'] || []} onChange={value => handleOnFilterChange('outstanding', value)}>
                                        <div className="flex gap-spona-12 items-center mb-spona-24">
                                            <Checkbox
                                                value={outstandingThresholdTypes.S}
                                                isSelected={filters['outstanding'] ? filters['outstanding'].includes(outstandingThresholdTypes.S) : false}
                                                inputClassName="!bg-contrast-200"
                                            >
                                                <span className="ml-spona-8 font-semibold text-lg text-contrast-800">
                                                    {'< 500'}
                                                </span>
                                            </Checkbox>
                                            <Checkbox
                                                value={'500'}
                                                isSelected={filters['outstanding'] ? filters['outstanding'].includes('500') : false}
                                                inputClassName="!bg-contrast-200"
                                            >
                                                <span className="ml-spona-8 font-semibold text-lg text-contrast-800">
                                                    {outstandingThresholdTypes.L}
                                                </span>
                                            </Checkbox>
                                        </div>
                                    </CheckboxGroup>
                                    <h3 className={filterLabelStyles}>Try for free</h3>
                                    <div className="mb-spona-24">
                                        <Checkbox
                                            value={true}
                                            isSelected={filters['tryForFree']}
                                            onChange={value => handleOnFilterChange('tryForFree', value)}
                                        />
                                    </div>
                                    <h3 className={filterLabelStyles}>Status</h3>
                                    <div className="flex gap-spona-12 mb-spona-24">
                                        {Object.values(statusFilterOptionsConfig).map(filterOption => (
                                            <FilterOption
                                                key={filterOption.name}
                                                filterOption={filterOption}
                                                selectedOption={filters['status']}
                                                setSelectedOption={value => handleOnFilterChange('status', value)}
                                            />
                                        ))}
                                    </div>
                                    <h3 className={filterLabelStyles}>Date</h3>
                                    <div className="flex justify-between">
                                        <DateField label="From" selectedDate={filters['dateFrom']} setSelectedDate={value => handleOnFilterChange('dateFrom', value)} />
                                        <DateField label="To" selectedDate={filters['dateTo']} setSelectedDate={value => handleOnFilterChange('dateTo', value)}/>
                                    </div>
                                </div>
                                <div>
                                    <hr className="border-contrast-600 mb-spona-16" />
                                    <div className="flex justify-end items-center">
                                        <span
                                            className="font-semibold text-lg text-contrast-800 mr-spona-16 cursor-pointer"
                                            onClick={resetFilters}
                                        >
                                            Reset filters
                                        </span>
                                        <Button buttonType='primary' onClick={filterData}>
                                            Save filters
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
    )
}

export default AdminLeadsScreen;