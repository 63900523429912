import React from 'react';
import {HighlightedText} from '../../../ui-components';

const SecurityMeasuresContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                At SPONA, safeguarding your data is our utmost priority.
                We employ the most advanced and robust security measures available in the lead generation software market to ensure the highest level of data protection.<br/>
                Here’s how we keep your information secure:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Advanced Encryption:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Data In Transit:</span> All data transmitted to and from SPONA is encrypted using industry-standard protocols. This ensures that your sensitive information is protected from interception during transmission.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Data At Rest:</span> We also encrypt data at rest, adding an extra layer of security to your stored information.
                        </li>
                    </ul>
                    <li>Regular Security Audits:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Continuous Monitoring:</span> Our systems undergo regular security audits to identify and rectify any vulnerabilities. This proactive approach helps in maintaining a robust defense against potential security threats.
                        </li>
                    </ul>
                    <li>Access Control:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Strict Authentication:</span> We implement strict access controls, ensuring that only authorized personnel have access to sensitive data. This includes multi-factor authentication and regular review of access privileges.
                        </li>
                    </ul>
                    <li>Compliance with Industry Standards:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Regulatory Adherence:</span> SPONA adheres to leading industry standards and regulations, ensuring compliance with data protection laws and guidelines.
                        </li>
                    </ul>
                    <li>Ongoing Security Training:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Staff Awareness:</span> Our team receives ongoing training in data security best practices. This equips them with the knowledge to prevent, detect, and respond to security incidents effectively.
                        </li>
                    </ul>
                </ul>
            </div>
            <HighlightedText className="mt-spona-24">
                By implementing these comprehensive security measures,
                SPONA is committed to protecting your data with the highest level of security currently available in the lead generation software market.
                Your data’s security and privacy are our top concerns, and we continuously update our practices to stay ahead of emerging threats and technologies.
            </HighlightedText>
        </div>
    )
}

export default SecurityMeasuresContent;