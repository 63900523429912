import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../authentification/authActions';
import {Button, Icon, Testimonial, Carousel, testimonialsConfig} from '../ui-components';
import { playIcon, securityIcon, gdprIcon, peopleTeamIcon, checkmarkRounded, mailOutlineIcon, creditCardIcon } from '../assets/icons';
import { stepThreeImage, hpImageOne, stepOneImage, stepTwoImage, stepFourImage, dlightImage, packsizeImage, acumedImage, maxPeopleImage, callonImage, ptTechImage, stepTwoNonDesktopImage } from '../assets/images';
import Card from './ui-elements/Card';
import FeatureWidget from './ui-elements/FeatureWidget';
import routePaths from '../routing/routePaths';
import { useScreenResolution } from '../utils/customHooks';
import userRoleTypes from '../entites/user-profile/userRoleTypes';
import config from '../config';

const logos = [dlightImage, packsizeImage, acumedImage, maxPeopleImage, callonImage, ptTechImage]
const cardConfig = [
    {
        icon: gdprIcon,
        title: 'GDPR',
        body: '100% compliant',
    },
    {
        icon: peopleTeamIcon,
        title: 'Scalability',
        body: 'Unlimited  B2B data',
    },
    {
        icon: securityIcon,
        title: 'Security',
        body: 'Double-verified contacts',
    }
];

const HomeScreen = () => {
    const token = localStorage.getItem('accessToken');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isDesktopScreen } = useScreenResolution();
    const didComponentMount = useRef(false);

    const loadCurrentUser = async () => {
        try {
            const user = await dispatch(getCurrentUser()).unwrap();
            if (!user) return;
            (user?.role === userRoleTypes.ADMIN.toLowerCase())
                ? navigate(routePaths.ADMIN_LEADS)
                : navigate(routePaths.CUSTOM_MADE_PROSPECT_LISTS)
        }
        catch (error) {
            // no-op
        }
    }

    useEffect(() => {
        if (didComponentMount.current) return;
        localStorage.removeItem('isResetLinkSent');
        if (token) {
            loadCurrentUser();
        }
        didComponentMount.current = true;
    });

    return (
        <div>
            <div className="md:px-spona-40 lg:px-spona-0 lg:grid grid-cols-12">
                <div className="mt-spona-32 md:mt-spona-44 text-center lg:text-default lg:mt-spona-0 col-span-6">
                    <article className="prose prose-xl">
                        <h2 className="lg:text-left">
                            <span className="font-bold text-highlighted-100">100%</span> qualified leads<br />in 4 minutes
                        </h2>
                    </article>
                    <ul className="inline-block text-left lg:block font-bold my-spona-44">
                        <li className="mb-spona-24">
                            <div className="flex items-center">
                                <Icon icon={checkmarkRounded} size='s' className="shrink-0 mr-spona-20" />
                                <p className="text-default-100 text-base">
                                    <span className="text-highlighted-100">Every contact</span> has a verified business email
                                </p>
                            </div>
                        </li>
                        <li className="mb-spona-24">
                            <div className="flex items-center">
                                <Icon icon={checkmarkRounded} size='s' className="shrink-0 mr-spona-20" />
                                <p className="text-default-100 text-base">
                                    Starting from $99 for 30 leads with <span className="text-highlighted-100">mobile numbers</span>
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <Icon icon={checkmarkRounded} size='s' className="shrink-0 mr-spona-20" />
                                <p className="text-default-100 text-base">
                                    Easy to use, <span className="text-highlighted-100">no</span> tech background needed
                                </p>
                            </div>
                        </li>
                    </ul>
                    <div className="flex flex-col gap-spona-20 lg:gap-spona-0 md:flex-row md:justify-center lg:justify-start">
                        <Button buttonType='primary' className='px-spona-48 lg:mr-spona-44 font-black text-lg' onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}>
                            GET FREE LEADS
                        </Button>
                        <Button buttonType='secondary' className='font-black text-lg' onClick={() => window.open(config.DEMO_YOUTUBE_LINK, '_blank')}>
                            WATCH DEMO
                            <Icon icon={playIcon} size='xs' className="ml-spona-4 hidden lg:block" />
                            <Icon icon={playIcon} size='s' className="ml-spona-8 lg:hidden" />
                        </Button>
                    </div>
                    <div className="flex items-center justify-center lg:justify-start mt-spona-8">
                        <p className="text-default-100 text-xs font-semibold">No credit card required</p>
                        <Icon icon={creditCardIcon} className="ml-spona-4" />
                    </div>
                </div>
                <div className="md:hidden col-span-6 mb-spona-80 flex justify-center">
                    <div className="relative min-h-[580px] w-[90vw]">
                        <div className="mt-spona-32 h-[100%] flex items-center justify-center">
                            <img loading="eager" src={hpImageOne} alt="hpImageOne" className="h-[381px] absolute top-[20%] right-[calc(50%-158px)] z-10" />
                            <div className='h-[100%] flex flex-col items-center justify-between'>
                                <div className="z-1 w-[280px] shadow-[14px_14px_24px_4px_rgba(34,122,101,0.20)] bg-highlighted-100 rounded-[16px] flex flex-col text-contrast-100 px-spona-20 py-spona-16 font-medium text-sm">
                                    <div className="mb-spona-4">
                                        <span>Full name: </span>
                                        <span className="font-bold">Jenny Doe</span>
                                    </div>
                                    <div className="mb-spona-4">
                                        <span>Company name: </span>
                                        <span className="font-bold">XZY Company</span>
                                    </div>
                                    <div>
                                        <span>Job title: </span>
                                        <span className="font-bold">Head of Engineering</span>
                                    </div>
                                </div>
                                <div className='flex flex-col items-center gap-spona-8'>
                                    <div className="z-10 w-[260px] bottom-[40%] right-[6%] shadow-[-16px_14px_24px_4px_rgba(34,122,101,0.20)] bg-highlighted-100 rounded-[16px] flex flex-col text-contrast-100 px-spona-20 py-spona-16 font-medium text-sm">
                                        <div className="mb-spona-4">
                                            <span>Mobile number: </span>
                                            <span className="font-bold">+123 456 789</span>
                                        </div>
                                        <div className="mb-spona-4">
                                            <span>Company number: </span>
                                            <span className="font-bold">+123 456 789</span>
                                        </div>
                                        <div>
                                            <span>Email: </span>
                                            <span className="font-bold">jenny.doe@xyzcorp.com</span>
                                        </div>
                                    </div>
                                    <div className="z-10 w-[184px] bottom-[0%] right-[50%] shadow-[14px_14px_24px_4px_rgba(34,122,101,0.20)] bg-highlighted-100 rounded-[16px] flex flex-col text-contrast-100 px-spona-20 py-spona-16 font-medium text-sm">
                                        <div className="flex justify-between text-[8px]">
                                            <div className="flex">
                                                <div className="flex items-center justify-center bg-contrast-100 px-[3px] w-[24px] h-[20px] rounded-[4px] mr-spona-4">
                                                    <Icon icon={mailOutlineIcon} />
                                                </div>
                                                <span>Mail</span>
                                            </div>
                                            <div>12 min ago</div>
                                        </div>
                                        <p className="font-bold mt-spona-4 text-xs">Booked call</p>
                                        <span className="text-[8px]">Mail message</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden col-span-6 mt-spona-80 lg:mt-spona-0 md:flex justify-center lg:justify-end">
                    <div className="relative h-[380px] w-[640px] z-0">
                        <div className="mt-spona-32 lg:mt-spona-0 flex items-center justify-center lg:justify-end">
                            <img loading="eager" src={hpImageOne} alt="hpImageOne" className="h-[381px] absolute bottom-[15%] right-[calc(50%-158px)] z-10" />
                            <div>
                                <div className="z-1 absolute w-[280px] top-[10%] right-[52%] shadow-[14px_14px_24px_4px_rgba(34,122,101,0.20)] bg-highlighted-100 rounded-[16px] flex flex-col text-contrast-100 px-spona-20 py-spona-16 font-medium text-sm">
                                    <div className="mb-spona-4">
                                        <span>Full name: </span>
                                        <span className="font-bold">Jenny Doe</span>
                                    </div>
                                    <div className="mb-spona-4">
                                        <span>Company name: </span>
                                        <span className="font-bold">XZY Company</span>
                                    </div>
                                    <div>
                                        <span>Job title: </span>
                                        <span className="font-bold">Head of Engineering</span>
                                    </div>
                                </div>
                                <div className="absolute z-10 w-[260px] bottom-[40%] right-[6%] shadow-[-16px_14px_24px_4px_rgba(34,122,101,0.20)] bg-highlighted-100 rounded-[16px] flex flex-col text-contrast-100 px-spona-20 py-spona-16 font-medium text-sm">
                                    <div className="mb-spona-4">
                                        <span>Mobile number: </span>
                                        <span className="font-bold">+123 456 789</span>
                                    </div>
                                    <div className="mb-spona-4">
                                        <span>Company number: </span>
                                        <span className="font-bold">+123 456 789</span>
                                    </div>
                                    <div>
                                        <span>Email: </span>
                                        <span className="font-bold">jenny.doe@xyzcorp.com</span>
                                    </div>
                                </div>
                                <div className="z-10 absolute w-[184px] bottom-[0%] right-[50%] shadow-[14px_14px_24px_4px_rgba(34,122,101,0.20)] bg-highlighted-100 rounded-[16px] flex flex-col text-contrast-100 px-spona-20 py-spona-16 font-medium text-sm">
                                    <div className="flex justify-between text-[8px]">
                                        <div className="flex">
                                            <div className="flex items-center justify-center bg-contrast-100 px-[3px] w-[24px] h-[20px] rounded-[4px] mr-spona-4">
                                                <Icon icon={mailOutlineIcon} />
                                            </div>
                                            <span>Mail</span>
                                        </div>
                                        <div>12 min ago</div>
                                    </div>
                                    <p className="font-bold mt-spona-4 text-xs">Booked call</p>
                                    <span className="text-[8px]">Mail message</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center md:py-spona-48 mt-spona-32">
                <article className="prose prose-xl text-center lg:text-left">
                    <p>+ 500 companies save time with <span className="font-extrabold text-highlighted-100">SPONA</span></p>
                </article>
            </div>
            <div className="grid grid-cols-6 gap-spona-20 mb-spona-20 md:mb-spona-40 lg:gap-spona-80 md:px-spona-40 lg:px-spona-80 lg:mb-spona-80">
                {logos.map((logo, idx) => (
                    <div key={idx} className="flex items-center justify-center">
                        <Icon icon={logo} />
                    </div>
                ))}
            </div>
            <div className="md:px-28">
                <div className="flex items-center justify-center md:mb-spona-40 lg:mb-spona-0">
                    <div className="w-[90vw] lg:w-[980px] lg:h-[300px] mb-spona-44 flex lg:block items-center justify-center">
                        <div className="w-full h-full">
                            <Carousel slidesToScroll={1} slidesToShow={1}>
                                {testimonialsConfig.map(testimonial => {
                                    return isDesktopScreen ? (
                                        <Testimonial
                                            key={testimonial.id}
                                            title={testimonial.title}
                                            highlightedText={testimonial.highlightedText}
                                            text={testimonial.text}
                                            name={testimonial.name}
                                            jobTitle={testimonial.jobTitle}
                                            image={testimonial.image}
                                            logo={testimonial.logo}
                                            height={testimonial.height}
                                        />
                                    ) : (
                                        <div className='w-full flex items-center justify-center' key={testimonial.id}>
                                            <Testimonial
                                                title={testimonial.title}
                                                highlightedText={testimonial.highlightedText}
                                                text={testimonial.text}
                                                name={testimonial.name}
                                                jobTitle={testimonial.jobTitle}
                                                image={testimonial.image}
                                                logo={testimonial.logo}
                                                height={testimonial.height}
                                            />
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center text-[44px] text-default-100 font-black">How it works?</div>
                <div className="mt-spona-64 flex items-center justify-center flex-col">
                    <FeatureWidget
                        src={stepOneImage}
                        alt="questionnaire"
                        title="Step 1: Questionnaire"
                        description={`Answer 9 short questions about your<br/>target profile.`}
                        stepNo={1}
                        even={true}
                        height={300}
                        width={723}
                    />
                    <FeatureWidget
                        src={isDesktopScreen ? stepTwoImage : stepTwoNonDesktopImage}
                        alt="marketAnalysis"
                        title="Step 2: Market analysis"
                        description={`Use SPONA to calculate number of<br/>outstanding leads.`}
                        even={false}
                        stepNo={2}
                        height={330}
                        width={728}
                    />
                    <FeatureWidget
                        src={stepThreeImage}
                        alt="freeSamples"
                        title="Step 3: Free samples"
                        description={`Receive 2-10 100% qualified leads<br/>shortly.`}
                        even={true}
                        stepNo={3}
                        height={388}
                        width={640}
                    />
                    <FeatureWidget
                        src={stepFourImage}
                        alt="buyMoreLeads"
                        title="Step 4: Buy more leads"
                        description={`Get leads you need in less than 48 hours.`}
                        even={false}
                        stepNo={4}
                        height={384}
                        width={640}
                    />
                </div>
            </div>
            <div className="md:flex items-center justify-center">
                <div className="md:grid grid-cols-3 md:gap-spona-32 lg:gap-spona-64">
                    {cardConfig.map(card => (
                        <div key={card.title}>
                            <Card
                                icon={card.icon}
                                title={card.title}
                                body={card.body}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-col items-center justify-center">
                <h2 className="text-center lg:text-left text-4xl font-extrabold text-default-100">Qualified leads within minutes</h2>
                <Button buttonType='primary' className="px-spona-44 mt-spona-44 font-black text-lg uppercase" onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}>
                    Get free leads
                </Button>
            </div>
        </div>
    )
}

export default HomeScreen;