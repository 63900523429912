import React from 'react';
import { useNavigate } from "react-router-dom";
import { Icon } from '..';
import { exitIcon } from '../../assets/icons';

const DesktopMessageContent = () => {
    const navigate = useNavigate();

    return (
        <div className='fixed top-spona-0 left-spona-0 w-screen h-screen z-[1040] w-[100vw] h-[100vh] bg-highlighted-100 flex items-center justify-center lg:hidden'>
            <div className="absolute top-spona-0 left-spona-0 w-full p-spona-20 md:p-spona-32 flex items-center justify-end">
                <Icon icon={exitIcon} size="s" color="informative" onClick={() => navigate(-1)} />
            </div>
            <p className='text-contrast-100 text-lg'>
                Please open SPONA on desktop
            </p>
        </div>
    )
}

export default DesktopMessageContent;
