export const regions = [
    "North America",
    "London, United Kingdom",
    "Europe",
    "United Kingdom",
    "New York, United States",
    "China",
    "DACH",
    "Benelux",
    "Japan",
    "Germany",
    "ANZ",
    "APAC",
    "APJ",
    "Australia and Associated Territories",
    "Central Africa",
    "Denmark and Associated Territories",
    "East Asia",
    "Eastern Africa",
    "EMEA",
    "European Economic Area",
    "European Union",
    "France and Associated Territories",
    "Latin America",
    "MENA",
    "Middle East",
    "NAMER",
    "Netherlands and Associated Territories",
    "New Zealand and Associated Territories",
    "Nordics",
    "Northern Africa",
    "Norway and Associated Territories",
    "Oceania",
    "South Asia",
    "Southeast Asia",
    "Southern Africa",
    "Sub-Saharan Africa",
    "UK",
    "US",
    "Western Africa",
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of Bolivia)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "North Korea",
    "South Korea",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands, Kingdom of the",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen[e]",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Türkiye",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara[b]",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Shanghai, China",
    "Beijing, China",
    "Shenzhen, China",
    "Guangzhou, China",
    "Lagos, Nigeria",
    "Istanbul, Turkey",
    "Chengdu, China",
    "Mumbai, India",
    "Sao Paulo, Brazil",
    "Mexico City, Mexico",
    "Karachi, Pakistan",
    "Tianjin, China",
    "Delhi, India",
    "Wuhan, China",
    "Moscow, Russia",
    "Dhaka, Bangladesh",
    "Seoul, South Korea",
    "Dongguan, China",
    "Cairo, Egypt",
    "Xi'an, China",
    "Nanjing, China",
    "Hangzhou, China",
    "Foshan, China",
    "Ho Chi Minh City, Viet Nam",
    "New York City, United States",
    "Jakarta, Indonesia",
    "Bengaluru, India",
    "Tokyo, Japan",
    "Hanoi, Viet Nam",
    "Taipei, Taiwan, China",
    "Kinshasa, Congo",
    "Lima, Peru",
    "Bogota, Colombia",
    "Hong Kong, Hong Kong, China",
    "Chongqing, China",
    "Baghdad, Iraq",
    "Qingdao, China",
    "Tehran, Iran",
    "Shenyang, China",
    "Hyderabad, India",
    "Rio de Janeiro, Brazil",
    "Suzhou, China",
    "Ahmedabad, India",
    "Abidjan, Côte d'Ivoire",
    "Lahore, Pakistan",
    "Singapore, Singapore",
    "Johannesburg, South Africa",
    "Dar es Salaam, Tanzania",
    "Saint Petersburg, Russia",
    "Alexandria, Egypt",
    "Harbin, China",
    "Sydney, Australia",
    "Bangkok, Thailand",
    "Hefei, China",
    "Melbourne, Australia",
    "Dalian, China",
    "Santiago, Chile",
    "Changchun, China",
    "Cape Town, South Africa",
    "Jeddah, Saudi Arabia",
    "Chennai, India",
    "Kolkata, India",
    "Xiamen, China",
    "Surat, India",
    "Yangon, Myanmar",
    "Kabul, Afghanistan",
    "Wuxi, China",
    "Giza, Egypt",
    "Jinan, China",
    "Taiyuan, China",
    "Zhengzhou, China",
    "Riyadh, Saudi Arabia",
    "Kano, Nigeria",
    "Shijiazhuang, China",
    "Chattogram, Bangladesh",
    "Los Angeles, United States",
    "Kunming, China",
    "Zhongshan, China",
    "Nanning, China",
    "Shantou, China",
    "Yokohama, Japan",
    "Fuzhou, China",
    "Ningbo, China",
    "Busan, South Korea",
    "Ibadan, Nigeria",
    "Puyang, China",
    "Ankara, Turkey",
    "Dubai, United Arab Emirates",
    "Shiyan, China",
    "Berlin, Germany",
    "Tangshan, China",
    "Changzhou, China",
    "Madrid, Spain",
    "Pyongyang, Korea, Dem. People's Rep. of",
    "Casablanca, Morocco",
    "Zibo, China",
    "Pune, India",
    "Durban, South Africa",
    "Bursa, Turkey",
    "Changsha, China",
    "Guiyang, China",
    "UEruemqi, China",
    "Lanzhou, China",
    "Caracas, Venezuela",
    "Incheon, South Korea",
    "Huizhou, China",
    "Buenos Aires, Argentina",
    "Surabaya, Indonesia",
    "Haikou, China",
    "Kanpur, India",
    "Kyiv, Ukraine",
    "Quito, Ecuador",
    "Luanda, Angola",
    "Quezon City, Philippines",
    "Addis Ababa, Ethiopia",
    "Osaka, Japan",
    "Nairobi, Kenya",
    "Linyi, China",
    "Baoding, China",
    "Brooklyn, United States",
    "Guayaquil, Ecuador",
    "Belo Horizonte, Brazil",
    "Salvador, Brazil",
    "Jaipur, India",
    "Chicago, United States",
    "Wenzhou, China",
    "Yunfu, China",
    "Toronto, Canada",
    "Navi Mumbai, India",
    "Mogadishu, Somalia",
    "Brisbane, Australia",
    "Daegu, South Korea",
    "Bekasi, Indonesia",
    "Faisalabad, Pakistan",
    "Izmir, Turkey",
    "Huai'an, China",
    "Dakar, Senegal",
    "Lucknow, India",
    "Bandung, Indonesia",
    "Medan, Indonesia",
    "Fortaleza, Brazil",
    "Cali, Colombia",
    "Nanchang, China",
    "Hohhot, China",
    "Rome, Italy",
    "Mashhad, Iran",
    "Houston, United States",
    "Kowloon, Hong Kong, China",
    "Shaoxing, China",
    "Nantong, China",
    "Queens, United States",
    "Nagpur, India",
    "Yantai, China",
    "Maracaibo, Venezuela",
    "Manaus, Brazil",
    "Brasilia, Brazil",
    "Zhuhai, China",
    "Santo Domingo, Dominican Republic",
    "Perth, Australia",
    "Nagoya, Japan",
    "Havana, Cuba",
    "Baotou, China",
    "Paris, France",
    "Coimbatore, India",
    "Aleppo, Syria",
    "Kunshan, China",
    "Al Mawsil al Jadidah, Iraq",
    "Depok, Indonesia",
    "Weifang, China",
    "Zunyi, China",
    "Al Basrah al Qadimah, Iraq",
    "La Paz, Bolivia",
    "Lianyungang, China",
    "Medellin, Colombia",
    "Tashkent, Uzbekistan",
    "Algiers, Algeria",
    "Ganzhou, China",
    "Almaty, Kazakhstan",
    "Khartoum, Sudan",
    "Sapporo, Japan",
    "Accra, Ghana",
    "Curitiba, Brazil",
    "Ordos, China",
    "Sanaa, Yemen",
    "Tijuana, Mexico",
    "Beirut, Lebanon",
    "Tangerang, Indonesia",
    "Jieyang, China",
    "Jilin, China",
    "Bucharest, Romania",
    "Camayenne, Guinea",
    "Kakamega, Kenya",
    "Port Harcourt, Nigeria",
    "Nanchong, China",
    "Datong, China",
    "Hamburg, Germany",
    "Indore, India",
    "Santa Cruz de la Sierra, Bolivia",
    "Vadodara, India",
    "Iztapalapa, Mexico",
    "Nanyang, China",
    "Gaziantep, Turkey",
    "Abu Dhabi, United Arab Emirates",
    "Jiangmen, China",
    "Diyarbakir, Turkey",
    "Benin City, Nigeria",
    "Jiangyin, China",
    "Adana, Turkey",
    "Davao, Philippines",
    "Fuyang, China",
    "Conakry, Guinea",
    "Montreal, Canada",
    "Bayan Nur, China",
    "Maracay, Venezuela",
    "Chaozhou, China",
    "Rawalpindi, Pakistan",
    "Minsk, Belarus",
    "Budapest, Hungary",
    "Qingyuan, China",
    "Tai'an, China",
    "Leon de los Aldama, Mexico",
    "Warsaw, Poland",
    "Soweto, South Africa",
    "Puebla, Mexico",
    "Vienna, Austria",
    "Mosul, Iraq",
    "Kallakurichi, India",
    "Xining, China",
    "Changshu, China",
    "Palembang, Indonesia",
    "Huainan, China",
    "Rabat, Morocco",
    "Semarang, Indonesia",
    "Recife, Brazil",
    "Suzhou, China",
    "Ecatepec de Morelos, Mexico",
    "Lu'an, China",
    "Barcelona, Spain",
    "Valencia, Venezuela",
    "Pretoria, South Africa",
    "Yancheng, China",
    "Novosibirsk, Russia",
    "Erbil, Iraq",
    "Phoenix, United States",
    "Taizhou, China",
    "Daqing, China",
    "Fukuoka, Japan",
    "Manila, Philippines",
    "Patna, India",
    "Bhopal, India",
    "Wuhu, China",
    "Santiago de Queretaro, Mexico",
    "Dazhou, China",
    "Yangzhou, China",
    "Kaduna, Nigeria",
    "Mecca, Saudi Arabia",
    "Philadelphia, United States",
    "Phnom Penh, Cambodia",
    "Guilin, China",
    "Damascus, Syria",
    "Zhaoqing, China",
    "Onitsha, Nigeria",
    "Mianyang, China",
    "Isfahan, Iran",
    "Ludhiana, India",
    "Harare, Zimbabwe",
    "Putian, China",
    "Shangqiu, China",
    "Goiania, Brazil",
    "Kawasaki, Japan",
    "Kobe, Japan",
    "Kaohsiung, Taiwan, China",
    "Stockholm, Sweden",
    "Ciudad Juarez, Mexico",
    "Khulna, Bangladesh",
    "Caloocan City, Philippines",
    "Belem, Brazil",
    "Yekaterinburg, Russia",
    "Gwangju, South Korea",
    "Porto Alegre, Brazil",
    "Yinchuan, China",
    "Manhattan, United States",
    "Taizhou, China",
    "Asuncion, Paraguay",
    "Yiwu, China",
    "Zapopan, Mexico",
    "Daejeon, South Korea",
    "Quanzhou, China",
    "Kumasi, Ghana",
    "Madurai, India",
    "Jinhua, China",
    "Kyoto, Japan",
    "Cixi, China",
    "Changde, China",
    "Kuala Lumpur, Malaysia",
    "Kaifeng, China",
    "Anshan, China",
    "Karaj, Iran",
    "Kathmandu, Nepal",
    "Baoji, China",
    "Suqian, China",
    "Multan, Pakistan",
    "Liuzhou, China",
    "Tirunelveli, India",
    "San Antonio, United States",
    "Kharkiv, Ukraine",
    "Zhangjiagang, China",
    "Agra, India",
    "Tabriz, Iran",
    "Makassar, Indonesia",
    "Jinjiang, China",
    "Bozhou, China",
    "Qujing, China",
    "Zhanjiang, China",
    "Fushun, China",
    "San Diego, United States",
    "Antananarivo, Madagascar",
    "Rajkot, India",
    "Luoyang, China",
    "Konya, Turkey",
    "Adelaide, Australia",
    "Hyderabad, Pakistan",
    "Guadalajara, Mexico",
    "The Bronx, United States",
    "Gujranwala, Pakistan",
    "Guankou, China",
    "Lubumbashi, Congo",
    "Milan, Italy",
    "South Tangerang, Indonesia",
    "Najafgarh, India",
    "Handan, China",
    "Kampala, Uganda",
    "Yichang, China",
    "Heze, China",
    "Antalya, Turkey",
    "Abobo, Côte d'Ivoire",
    "Jamshedpur, India",
    "Douala, Cameroon",
    "Basrah, Iraq",
    "Saitama, Japan",
    "Gorakhpur, India",
    "Liupanshui, China",
    "Cordoba, Argentina",
    "Maoming, China",
    "Dallas, United States",
    "Medina, Saudi Arabia",
    "Callao, Peru",
    "Yaounde, Cameroon",
    "Bamako, Mali",
    "Qinzhou, China",
    "Luohe, China",
    "Xiangyang, China",
    "Yangjiang, China",
    "Nashik, India",
    "Yixing, China",
    "Brazzaville, Congo",
    "Pimpri, India",
    "Amman, Jordan",
    "Sharjah, United Arab Emirates",
    "Budta, Philippines",
    "Belgrade, Serbia",
    "Montevideo, Uruguay",
    "Lusaka, Zambia",
    "Xuchang, China",
    "Kalyan, India",
    "Zigong, China",
    "Thane, India",
    "Munich, Germany",
    "Nizhniy Novgorod, Russia",
    "Jepara, Indonesia",
    "Xuzhou, China",
    "Dammam, Saudi Arabia",
    "Ra's Bayrut, Lebanon",
    "Neijiang, China",
    "Shiraz, Iran",
    "Yiyang, China",
    "Kazan, Russia",
    "Suwon, South Korea",
    "Jining, China",
    "Barquisimeto, Venezuela",
    "Shubra al Khaymah, Egypt",
    "Abuja, Nigeria",
    "Port-au-Prince, Haiti",
    "Xinyang, China",
    "Liaocheng, China",
    "Jinzhong, China",
    "Meerut, India",
    "Nowrangapur, India",
    "Faridabad, India",
    "Peshawar, Pakistan",
    "Karbala, Iraq",
    "Changzhi, China",
    "Tianshui, China",
    "Mombasa, Kenya",
    "Mandalay, Myanmar",
    "Barranquilla, Colombia",
    "Kayseri, Turkey",
    "Chelyabinsk, Russia",
    "Merida, Mexico",
    "Bulawayo, Zimbabwe",
    "Shymkent, Kazakhstan",
    "Santiago de los Caballeros, Dominican Republic",
    "Omdurman, Sudan",
    "Hiroshima, Japan",
    "Weinan, China",
    "Ghaziabad, India",
    "Dhanbad, India",
    "Dombivli, India",
    "Maputo, Mozambique",
    "Gustavo Adolfo Madero, Mexico",
    "Jiaxing, China",
    "Omsk, Russia",
    "Guarulhos, Brazil",
    "Bandar Lampung, Indonesia",
    "Prague, Czech Republic",
    "Varanasi, India",
    "Batam, Indonesia",
    "Jiujiang, China",
    "Samara, Russia",
    "Copenhagen, Denmark",
    "Sofia, Bulgaria",
    "Tripoli, Libyan Arab Jamahiriya",
    "Anyang, China",
    "Birmingham, United Kingdom",
    "Bijie, China",
    "Monterrey, Mexico",
    "Kigali, Rwanda",
    "Rostov-na-Donu, Russia",
    "Zhuzhou, China",
    "Malingao, Philippines",
    "Ufa, Russia",
    "Ranchi, India",
    "Baku, Azerbaijan",
    "Shangrao, China",
    "Huaibei, China",
    "Meishan, China",
    "Ciudad Nezahualcoyotl, Mexico",
    "Bogor, Indonesia",
    "Sendai, Japan",
    "Yerevan, Armenia",
    "Amritsar, India",
    "Krasnoyarsk, Russia",
    "Fuzhou, China",
    "Ouagadougou, Burkina Faso",
    "Guigang, China",
    "Pekanbaru, Indonesia",
    "Hengyang, China",
    "Prayagraj, India",
    "Goyang-si, South Korea",
    "Visakhapatnam, India",
    "Yulin, China",
    "Jingzhou, China",
    "Tbilisi, Georgia",
    "Voronezh, Russia",
    "Xinxiang, China",
    "Yichun, China",
    "Taichung, Taiwan, China",
    "Teni, India",
    "Xianyang, China",
    "Mexicali, Mexico",
    "Matola, Mozambique",
    "Seongnam-si, South Korea",
    "Maceio, Brazil",
    "Campinas, Brazil",
    "Sanya, China",
    "Rangpur, Bangladesh",
    "Kirkuk, Iraq",
    "Jabalpur, India",
    "Comilla, Bangladesh",
    "Shaoguan, China",
    "Haora, India",
    "San Jose, United States",
    "Longyan, China",
    "Dublin, Ireland",
    "Tiruchirappalli, India",
    "Yongzhou, China",
    "Calgary, Canada",
    "Brussels, Belgium",
    "Sambhaji Nagar, India",
    "Huzhou, China",
    "Odesa, Ukraine",
    "Volgograd, Russia",
    "Edmonton, Canada",
    "Wuwei, China",
    "Namangan, Uzbekistan",
    "Arequipa, Peru",
    "Hanzhong, China",
    "Hezhou, China",
    "Bujumbura, Burundi",
    "Shivaji Nagar, India",
    "Zhu Cheng City, China",
    "Dongying, China",
    "Luzhou, China",
    "Solapur, India",
    "Guatemala City, Guatemala",
    "Meizhou, China",
    "Yueyang, China",
    "Laiwu, China",
    "Da Nang, Viet Nam",
    "Benxi, China",
    "Perm, Russia",
    "Chiba, Japan",
    "Pingdingshan, China",
    "Srinagar, India",
    "Zaria, Nigeria",
    "Managua, Nicaragua",
    "Bengbu, China",
    "Jerusalem, Israel",
    "Dnipro, Ukraine",
    "Port Elizabeth, South Africa",
    "Fes, Morocco",
    "Cebu City, Philippines",
    "Koeln, Germany",
    "Tiruppur, India",
    "Ulsan, South Korea",
    "Chandigarh, India",
    "Xiangtan, China",
    "Linfen, China",
    "Victoria, Hong Kong, China",
    "Jacksonville, United States",
    "Zhenjiang, China",
    "Ciudad Guayana, Venezuela",
    "Rosario, Argentina",
    "Sultanah, Saudi Arabia",
    "Kitakyushu, Japan",
    "Monrovia, Liberia",
    "Kingston, Jamaica",
    "Baoshan, China",
    "Austin, United States",
    "Rui'an, China",
    "Chihuahua, Mexico",
    "Nay Pyi Taw, Myanmar",
    "Jodhpur, India",
    "Trujillo, Peru",
    "Fort Worth, United States",
    "Salem, India",
    "Sao Luis, Brazil",
    "Cartagena, Colombia",
    "Laibin, China",
    "Naples, Italy",
    "Padang, Indonesia",
    "Xiaogan, China",
    "Campo Grande, Brazil",
    "Columbus, United States",
    "Ziyang, China",
    "Bobo-Dioulasso, Burkina Faso",
    "Sale, Morocco",
    "Quzhou, China",
    "Petaling Jaya, Malaysia",
    "Donetsk, Ukraine",
    "Bishkek, Kyrgyzstan",
    "Abu Ghurayb, Iraq",
    "Qom, Iran",
    "Zaozhuang, China",
    "Krasnodar, Russia",
    "Guwahati, India",
    "Eskisehir, Turkey",
    "Aba, Nigeria",
    "Natal, Brazil",
    "Pingxiang, China",
    "Indianapolis, United States",
    "Zhoushan, China",
    "Gwalior, India",
    "Qiqihar, China",
    "Klang, Malaysia",
    "As Sulaymaniyah, Iraq",
    "Puning, China",
    "Mbuji-Mayi, Congo",
    "Vijayawada, India",
    "Charlotte, United States",
    "Pikine, Senegal",
    "Bhiwandi, India",
    "Teresina, Brazil",
    "Marseille, France",
    "Ankang, China",
    "Mysore, India",
    "Langfang, China",
    "Jiaozuo, China",
    "San Francisco, United States",
    "Liverpool, United Kingdom",
    "Aden, Yemen",
    "Rohini, India",
    "Wanxian, China",
    "Guang'an, China",
    "Johor Bahru, Malaysia",
    "Kanayannur, India",
    "Tegucigalpa, Honduras",
    "Bucheon-si, South Korea",
    "Turin, Italy",
    "Al Ain City, United Arab Emirates",
    "Cheongju-si, South Korea",
    "Saratov, Russia",
    "Ulan Bator, Mongolia",
    "Weihai, China",
    "Takeo, Cambodia",
    "Malang, Indonesia",
    "Haiphong, Viet Nam",
    "Cochabamba, Bolivia",
    "Ahvaz, Iran",
    "Hubli, India",
    "Zhabei, China",
    "Ipoh, Malaysia",
    "Xinyu, China",
    "Marrakesh, Morocco",
    "Bhubaneswar, India",
    "Yibin, China",
    "Kampung Baru Subang, Malaysia",
    "Bouake, Côte d'Ivoire",
    "Samarinda, Indonesia",
    "Taicang, China",
    "Bien Hoa, Viet Nam",
    "Nova Iguacu, Brazil",
    "Chenzhou, China",
    "Duque de Caxias, Brazil",
    "Joao Pessoa, Brazil",
    "Jos, Nigeria",
    "Barcelona, Venezuela",
    "Ilorin, Nigeria",
    "Hermosillo, Mexico",
    "Ottawa, Canada",
    "Can Tho, Viet Nam",
    "Culiacan, Mexico",
    "Benghazi, Libyan Arab Jamahiriya",
    "Malatya, Turkey",
    "Anqing, China",
    "Freetown, Sierra Leone",
    "San Pedro Sula, Honduras",
    "Narela, India",
    "Xingtai, China",
    "Niigata, Japan",
    "Muscat, Oman",
    "Zarqa, Jordan",
    "Naucalpan de Juarez, Mexico",
    "Cankaya, Turkey",
    "Hamamatsu, Japan",
    "Valencia, Spain",
    "Rahim Yar Khan, Pakistan",
    "Pasragad Branch, Iran",
    "Zhaotong, China",
    "Panzhihua, China",
    "Boumerdas, Algeria",
    "Jalandhar, India",
    "Thiruvananthapuram, India",
    "Chuzhou, China",
    "Sakai, Japan",
    "Port Said, Egypt",
    "Cotonou, Benin",
    "Cucuta, Colombia",
    "Homs, Syria",
    "Xuanzhou, China",
    "Niamey, Niger",
    "Tainan, Taiwan, China",
    "Shangyu, China",
    "Lodz, Poland",
    "Tyumen, Russia",
    "Erzurum, Turkey",
    "Kahriz, Iran",
    "Anshun, China",
    "Rajshahi, Bangladesh",
    "Kota, India",
    "Wuzhou, China",
    "Qinhuangdao, China",
    "Maiduguri, Nigeria",
    "Krakow, Poland",
    "Aligarh, India",
    "Shaoyang, China",
    "Pietermaritzburg, South Africa",
    "Lome, Togo",
    "Winnipeg, Canada",
    "Bagcilar, Turkey",
    "Bareilly, India",
    "Buraydah, Saudi Arabia",
    "Sao Bernardo do Campo, Brazil",
    "Hegang, China",
    "Morelia, Mexico",
    "Nampula, Mozambique",
    "Riga, Latvia",
    "Amsterdam, Netherlands",
    "Ma'anshan, China",
    "Shah Alam, Malaysia",
    "Kumamoto, Japan",
    "Seattle, United States",
    "Oyo, Nigeria",
    "Torreon, Mexico",
    "Deyang, China",
    "Quetta, Pakistan",
    "Yangquan, China",
    "Sao Jose dos Campos, Brazil",
    "Ashgabat, Turkmenistan",
    "Alvaro Obregon, Mexico",
    "Denpasar, Indonesia",
    "Muzaffarabad, Pakistan",
    "Wanzhou, China",
    "San Luis Potosi, Mexico",
    "Aguascalientes, Mexico",
    "Zhumadian, China",
    "Moradabad, India",
    "N'Djamena, Chad",
    "Okayama, Japan",
    "Lviv, Ukraine",
    "Ansan-si, South Korea",
    "Denver, United States",
    "Ribeirao Preto, Brazil",
    "Zaporizhzhya, Ukraine",
    "Saltillo, Mexico",
    "Latakia, Syria",
    "Subang Jaya, Malaysia",
    "Tolyatti, Russia",
    "Jaboatao, Brazil",
    "Santo Domingo Oeste, Dominican Republic",
    "Battagram, Pakistan",
    "Santo Domingo Este, Dominican Republic",
    "Suez, Egypt",
    "Changzhi, China",
    "Agadir, Morocco",
    "Sarajevo, Bosnia and Herzegovina",
    "Balikpapan, Indonesia",
    "Bauchi, Nigeria",
    "Tunis, Tunisia",
    "Zhangjiakou, China",
    "Serang, Indonesia",
    "Shizuoka, Japan",
    "Paranaque City, Philippines",
    "Washington, United States",
    "Nashville, United States",
    "Fuxin, China",
    "Enugu, Nigeria",
    "Ta'if, Saudi Arabia",
    "Tangier, Morocco",
    "Huangshi, China",
    "Liaoyang, China",
    "Sorocaba, Brazil",
    "Baise, China",
    "Situbondo, Indonesia",
    "Sevilla, Spain",
    "Binzhou, China",
    "El Paso, United States",
    "Oklahoma City, United States",
    "Yuncheng, China",
    "Raipur, India",
    "General Santos, Philippines",
    "Dezhou, China",
    "Dushanbe, Tajikistan",
    "Osasco, Brazil",
    "Detroit, United States",
    "Boston, United States",
    "Zaragoza, Spain",
    "Gorakhpur, India",
    "Guadalupe, Mexico",
    "Acapulco de Juarez, Mexico",
    "Sanmenxia, China",
    "E'zhou, China",
    "Mississauga, Canada",
    "Madinat an Nasr, Egypt",
    "Tabuk, Saudi Arabia",
    "Cheonan, South Korea",
    "Mudanjiang, China",
    "Aracaju, Brazil",
    "Athens, Greece",
    "Zagreb, Croatia",
    "Leshan, China",
    "Santo Andre, Brazil",
    "Rizhao, China",
    "Nouakchott, Mauritania",
    "Pointe-Noire, Congo",
    "Helsinki, Finland",
    "Pontianak, Indonesia",
    "Banjarmasin, Indonesia",
    "Puducherry, India",
    "Suining, China",
    "Puyang, China",
    "Tlalnepantla, Mexico",
    "Portland, United States",
    "Jeonju, South Korea",
    "Frankfurt am Main, Germany",
    "Macau, Macau, China",
    "Palermo, Italy",
    "Izhevsk, Russia",
    "Colombo, Sri Lanka",
    "Maturin, Venezuela",
    "Lilongwe, Malawi",
    "Oran, Algeria",
    "Honcho, Japan",
    "Taguig, Philippines",
    "New South Memphis, United States",
    "Hwaseong-si, South Korea",
    "Gold Coast, Australia",
    "Kotli, Pakistan",
    "Al Ahmadi, Kuwait",
    "Cuenca, Ecuador",
    "Chisinau, Moldova, Republic of",
    "Wroclaw, Poland",
    "Hebi, China",
    "Tebessa, Algeria",
    "Memphis, United States",
    "Jingmen, China",
    "Barnaul, Russia",
    "Dandong, China",
    "Stuttgart, Germany",
    "Jaboatao dos Guararapes, Brazil",
    "Cancun, Mexico",
    "Contagem, Brazil",
    "Ulyanovsk, Russia",
    "Glasgow, United Kingdom",
    "Bhilai, India",
    "Panshan, China",
    "Djibouti, Djibouti",
    "Irkutsk, Russia",
    "Las Vegas, United States",
    "Al Mansurah, Egypt",
    "Kermanshah, Iran",
    "Duesseldorf, Germany",
    "Coyoacan, Mexico",
    "Feira de Santana, Brazil",
    "Jiaozhou, China",
    "Suizhou, China",
    "Villa Nueva, Guatemala",
    "Khabarovsk, Russia",
    "Cuiaba, Brazil",
    "Al Hudaydah, Yemen",
    "Pasig City, Philippines",
    "Chizhou, China",
    "Taiz, Yemen",
    "Santa Maria Chimalhuacan, Mexico",
    "Ya'an, China",
    "Borivli, India",
    "Yaroslavl, Russia",
    "Kawaguchi, Japan",
    "Jambi City, Indonesia",
    "Ha'il, Saudi Arabia",
    "Bhavnagar, India",
    "Benoni, South Africa",
    "Vladivostok, Russia",
    "Cochin, India",
    "Jinzhou, China",
    "Tuxtla, Mexico",
    "Kryvyy Rih, Ukraine",
    "Amravati, India",
    "Sanming, China",
    "Islamabad, Pakistan",
    "Sangli, India",
    "Milwaukee, United States",
    "Vancouver, Canada",
    "Shuangyashan, China",
    "Rotterdam, Netherlands",
    "Kleinzschocher, Germany",
    "Grosszschocher, Germany",
    "Luancheng, China",
    "Makhachkala, Russia",
    "Anyang-si, South Korea",
    "Kagoshima, Japan",
    "Rasht, Iran",
    "Brampton, Canada",
    "Mar del Plata, Argentina",
    "Abeokuta, Nigeria",
    "Essen, Germany",
    "Al Mahallah al Kubra, Egypt",
    "Yingkou, China",
    "Las Pinas, Philippines",
    "Zhangzhou, China",
    "Reynosa, Mexico",
    "Thuan An, Viet Nam",
    "Dortmund, Germany",
    "Londrina, Brazil",
    "Goeteborg, Sweden",
    "Blantyre, Malawi",
    "New Kingston, Jamaica",
    "UEskuedar, Turkey",
    "Bucaramanga, Colombia",
    "Genoa, Italy",
    "Cuttack, India",
    "Oslo, Norway",
    "Malacca, Malaysia",
    "Malaga, Spain",
    "Khabarovsk Vtoroy, Russia",
    "Libreville, Gabon",
    "Kerman, Iran",
    "Orumiyeh, Iran",
    "Bahcelievler, Turkey",
    "Tanta, Egypt",
    "Baltimore, United States",
    "Bikaner, India",
    "Tlaquepaque, Mexico",
    "Tlalpan, Mexico",
    "Herat, Afghanistan",
    "Tomsk, Russia",
    "Juiz de Fora, Brazil",
    "Umraniye, Turkey",
    "Shihezi, China",
    "South Boston, United States",
    "Poznan, Poland",
    "Irbid, Jordan",
    "Kota Bharu, Malaysia",
    "Cimahi, Indonesia",
    "Puente Alto, Chile",
    "Mukalla, Yemen",
    "Nyala, Sudan",
    "Orenburg, Russia",
    "Bokaro, India",
    "Asmara, Eritrea",
    "Sokoto, Nigeria",
    "Uberlandia, Brazil",
    "Hachioji, Japan",
    "Wenchang, China",
    "Albuquerque, United States",
    "Hamhung, Korea, Dem. People's Rep. of",
    "Kemerovo, Russia",
    "Nasiriyah, Iraq",
    "Warangal, India",
    "Sheffield, United Kingdom",
    "Dresden, Germany",
    "Bloemfontein, South Africa",
    "Santiago de Cuba, Cuba",
    "Siping, China",
    "Huaihua, China",
    "Bahawalpur, Pakistan",
    "Chiclayo, Peru",
    "Zahedan, Iran",
    "Kimhae, South Korea",
    "Nanded, India",
    "Kozhikode, India",
    "Pristina, ",
    "Changwon, South Korea",
    "Jiamusi, China",
    "Antipolo, Philippines",
    "Korla, China",
    "Porto Velho, Brazil",
    "San Miguel de Tucuman, Argentina",
    "Kuantan, Malaysia",
    "Sevastopol, Ukraine",
    "Bremen, Germany",
    "Wanning, China",
    "Meknes, Morocco",
    "Xinzhou, China",
    "Banqiao, Taiwan, China",
    "Sargodha, Pakistan",
    "Bangui, Central African Republic",
    "Vilnius, Lithuania",
    "Pingdu, China",
    "Calamba, Philippines",
    "Novokuznetsk, Russia",
    "Kisangani, Congo",
    "Ryazan', Russia",
    "Ji'an, China",
    "Mersin, Turkey",
    "Raurkela, India",
    "Warri, Nigeria",
    "Guli, China",
    "Aksu, China",
    "Ebute Ikorodu, Nigeria",
    "Tanggu, China",
    "Astrakhan, Russia",
    "Beira, Mozambique",
    "Ar Raqqah, Syria",
    "Quebec, Canada",
    "Cuauhtemoc, Mexico",
    "Shangluo, China",
    "Tucson, United States",
    "Guntur, India",
    "Ibague, Colombia",
    "Antwerpen, Belgium",
    "Touba, Senegal",
    "Asyut, Egypt",
    "Hamadan, Iran",
    "Qionghai, China",
    "Cangzhou, China",
    "Homyel', Belarus",
    "San Salvador, El Salvador",
    "Himeji, Japan",
    "Beihai, China",
    "Van, Turkey",
    "Penza, Russia",
    "Mazar-e Sharif, Afghanistan",
    "Kandahar, Afghanistan",
    "Lyon, France",
    "Surakarta, Indonesia",
    "Hengshui, China",
    "Dehra Dun, India",
    "Erode, India",
    "Salta, Argentina",
    "Bhayandar, India",
    "Esenler, Turkey",
    "Fresno, United States",
    "Hamilton, Canada",
    "Al Fayyum, Egypt",
    "Durgapur, India",
    "Victoria de Durango, Mexico",
    "Ajmer, India",
    "Lisbon, Portugal",
    "Ulhasnagar, India",
    "Guangyuan, China",
    "Leeds, United Kingdom",
    "Kolhapur, India",
    "Siliguri, India",
    "Nuernberg, Germany",
    "Hannover, Germany",
    "Azadshahr, Iran",
    "Samarkand, Uzbekistan",
    "Macapa, Brazil",
    "Xianning, China",
    "Tembisa, South Africa",
    "Campos dos Goytacazes, Brazil",
    "Bilimora, India",
    "Aparecida de Goiania, Brazil",
    "Makati City, Philippines",
    "Mianzhu, Deyang, Sichuan, China",
    "Tonghua, China",
    "Naberezhnyye Chelny, Russia",
    "Matsuyama, Japan",
    "Lipetsk, Russia",
    "Florianopolis, Brazil",
    "Banan, China",
    "Tuen Mun, Hong Kong, China",
    "Zhangye, China",
    "Kirov, Russia",
    "Kashgar, China",
    "Edinburgh, United Kingdom",
    "Najran, Saudi Arabia",
    "Karol Bagh, India",
    "Zhoukou, China",
    "Leipzig, Germany",
    "Pingliang, China",
    "Kalininskiy, Russia",
    "Duisburg, Germany",
    "Asansol, India",
    "Arak, Iran",
    "Aktobe, Kazakhstan",
    "Kota Kinabalu, Malaysia",
    "Pohang, South Korea",
    "Zhucheng, China",
    "Santa Marta, Colombia",
    "Matsudo, Japan",
    "Karagandy, Kazakhstan",
    "Loudi, China",
    "Sha Tin, Hong Kong, China",
    "Toulouse, France",
    "Cheboksary, Russia",
    "Jamnagar, India",
    "Shanwei, China",
    "Monteria, Colombia",
    "Sacramento, United States",
    "Ruiru, Kenya",
    "Valledupar, Colombia",
    "Ajman City, United Arab Emirates",
    "Jianshui, China",
    "Port Sudan, Sudan",
    "Toluca, Mexico",
    "Ciudad Lopez Mateos, Mexico",
    "Higashi-osaka, Japan",
    "Al Khusus, Egypt",
    "Jeju City, South Korea",
    "Omaha, United States",
    "Masina, Congo",
    "Saharanpur, India",
    "Vellore, India",
    "Cuautitlan Izcalli, Mexico",
    "Piura, Peru",
    "Kalaburagi, India",
    "Kurashiki, Japan",
    "Bhatpara, India",
    "Jijiga, Ethiopia",
    "Tula, Russia",
    "Najaf, Iraq",
    "Xichang, China",
    "Okene, Nigeria",
    "Uijeongbu-si, South Korea",
    "East London, South Africa",
    "Yazd, Iran",
    "Hargeysa, Somalia",
    "Oita, Japan",
    "Maua, Brazil",
    "Sialkot, Pakistan",
    "Jincheng, China",
    "Eldoret, Kenya",
    "Kansas City, United States",
    "Yan'an, China",
    "Kaliningrad, Russia",
    "Skopje, Macedonia, The former Yugoslav Rep. of",
    "Di An, Viet Nam",
    "Vereeniging, South Africa",
    "The Hague, Netherlands",
    "Long Beach, United States",
    "Shouguang, China",
    "Jingdezhen, China",
    "Shizuishan, China",
    "Mesa, United States",
    "Seeb, Oman",
    "Jiaojiang, China",
    "Mykolayiv, Ukraine",
    "Nishinomiya-hama, Japan",
    "Fukuyama, Japan",
    "Staten Island, United States",
    "Nanping, China",
    "Pereira, Colombia",
    "Ciudad Apodaca, Mexico",
    "Belford Roxo, Brazil",
    "Kanazawa, Japan",
    "Bristol, United Kingdom",
    "Mixco, Guatemala",
    "Jammu, India",
    "Longshan, China",
    "Heyuan, China",
    "Atlanta, United States",
    "Kananga, Congo",
    "Gdansk, Poland",
    "Calabar, Nigeria",
    "Joinville, Brazil",
    "Huangshan, China",
    "Hamah, Syria",
    "Murcia, Spain",
    "Kurnool, India",
    "Santo Domingo de los Colorados, Ecuador",
    "Tartus, Syria",
    "Karabaglar, Turkey",
    "Zamboanga, Philippines",
    "Ujjain, India",
    "Colorado Springs, United States",
    "Niteroi, Brazil",
    "Huancayo, Peru",
    "Al Hillah, Iraq",
    "Namp'o, Korea, Dem. People's Rep. of",
    "Bacolod City, Philippines",
    "Sao Joao de Meriti, Brazil",
    "Mansilingan, Philippines",
    "Virginia Beach, United States",
    "Ramgundam, India",
    "Batman, Turkey",
    "Manado, Indonesia",
    "Lishui, China",
    "Ndola, Zambia",
    "Raleigh, United States",
    "Constantine, Algeria",
    "Eslamshahr, Iran",
    "Muratpasa, Turkey",
    "Wenshan City, China",
    "Juba, South Sudan",
    "Utsunomiya, Japan",
    "Heroica Matamoros, Mexico",
    "Sanliurfa, Turkey",
    "Chengde, China",
    "Kursk, Russia",
    "Maheshtala, India",
    "Andijon, Uzbekistan",
    "Cardiff, United Kingdom",
    "Boksburg, South Africa",
    "Cagayan de Oro, Philippines",
    "Basuo, China",
    "Elazig, Turkey",
    "San Nicolas de los Garza, Mexico",
    "Gondar, Ethiopia",
    "Kupang, Indonesia",
    "Amagasaki, Japan",
    "Carrefour, Haiti",
    "Shyamnagar, India",
    "Dasmarinas, Philippines",
    "Zhangjiajie, China",
    "Miami, United States",
    "Korhogo, Côte d'Ivoire",
    "Halifax, Canada",
    "Fujisawa, Japan",
    "Sandakan, Malaysia",
    "Mawlamyine, Myanmar",
    "Sha Tin Wai, Hong Kong, China",
    "Sunch'on, Korea, Dem. People's Rep. of",
    "Sultangazi, Turkey",
    "Mek'ele, Ethiopia",
    "Garoua, Cameroon",
    "Mwanza, Tanzania",
    "Uyo, Nigeria",
    "Ciudad Obregon, Mexico",
    "Bei'an, China",
    "Kousseri, Cameroon",
    "Malegaon, India",
    "Nazret, Ethiopia",
    "Davangere, India",
    "Manizales, Colombia",
    "Masan, South Korea",
    "Ananindeua, Brazil",
    "Stavropol', Russia",
    "Santos, Brazil",
    "Kashiwa, Japan",
    "Ogbomoso, Nigeria",
    "Tel Aviv, Israel",
    "Buenaventura, Colombia",
    "Katsina, Nigeria",
    "Welkom, South Africa",
    "Mariupol, Ukraine",
    "Venustiano Carranza, Mexico",
    "Zagazig, Egypt",
    "Mataram, Indonesia",
    "Ismailia, Egypt",
    "Jalgaon, India",
    "Ningde, China",
    "Akola, India",
    "Belagavi, India",
    "Cusco, Peru",
    "Jiuquan, China",
    "Veracruz, Mexico",
    "East Jerusalem, West Bank and Gaza Strip",
    "Gumi, South Korea",
    "Bryansk, Russia",
    "Maltepe, Turkey",
    "Kuala Terengganu, Malaysia",
    "Toyota, Japan",
    "Mandaluyong City, Philippines",
    "Al Kharj, Saudi Arabia",
    "Xalapa de Enriquez, Mexico",
    "Rajpur Sonarpur, India",
    "Ado-Ekiti, Nigeria",
    "Bratislava, Slovakia",
    "Gaya, India",
    "Laval, Canada",
    "Udaipur, India",
    "Al Hasakah, Syria",
    "Likasi, Congo",
    "Luxor, Egypt",
    "Daloa, Côte d'Ivoire",
    "Dingxi, China",
    "Akure, Nigeria",
    "Tver, Russia",
    "Boa Vista, Brazil",
    "Rio Branco, Brazil",
    "Oakland, United States",
    "Korba, India",
    "Takamatsu, Japan",
    "Tirana, Albania",
    "San Juan, Puerto Rico",
    "Kolwezi, Congo",
    "Auckland, New Zealand",
    "Alor Setar, Malaysia",
    "Sukkur, Pakistan",
    "Tongchuan, China",
    "Mangalore, India",
    "Pasay, Philippines",
    "Ajdabiya, Libyan Arab Jamahiriya",
    "Toyama, Japan",
    "Azcapotzalco, Mexico",
    "Magnitogorsk, Russia",
    "Tulsa, United States",
    "Jhansi, India",
    "Tseung Kwan O, Hong Kong, China",
    "Ciudad Bolivar, Venezuela",
    "Guyuan, China",
    "Wandsbek, Germany",
    "Minneapolis, United States",
    "Thoothukudi, India",
    "Ardabil, Iran",
    "Chaoyang, China",
    "Gaza, West Bank and Gaza Strip",
    "Palma, Spain",
    "Yokosuka, Japan",
    "Hotan, China",
    "Tonala, Mexico",
    "Panama, Panama",
    "Xochimilco, Mexico",
    "Szczecin, Poland",
    "Nagasaki, Japan",
    "Piracicaba, Brazil",
    "Ribeirao das Neves, Brazil",
    "Hirakata, Japan",
    "Ivanovo, Russia",
    "Cumana, Venezuela",
    "Oujda-Angad, Morocco",
    "Newcastle, South Africa",
    "Nellore, India",
    "Hsinchu, Taiwan, China",
    "Jayapura, Indonesia",
    "Jixi, China",
    "Zanzibar, Tanzania",
    "Hawassa, Ethiopia",
    "Kuching, Malaysia",
    "Tongling, China",
    "Taoyuan City, Taiwan, China",
    "Kassala, Sudan",
    "Lekki, Nigeria",
    "Kitwe, Zambia",
    "Gifu-shi, Japan",
    "West Jerusalem, Israel",
    "Machida, Japan",
    "Miyazaki, Japan",
    "Luhansk, Ukraine",
    "Keelung, Taiwan, China",
    "Barinas, Venezuela",
    "Al Hoceima, Morocco",
    "Manchester, United Kingdom",
    "Surrey, Canada",
    "Vila Velha, Brazil",
    "Bologna, Italy",
    "Serra, Brazil",
    "Samsun, Turkey",
    "Tallinn, Estonia",
    "Bamenda, Cameroon",
    "El Obeid, Sudan",
    "Bello, Colombia",
    "Pasto, Colombia",
    "Gaomi, China",
    "Santa Fe, Argentina",
    "San-Pedro, Côte d'Ivoire",
    "Diadema, Brazil",
    "Wichita, United States",
    "New Orleans, United States",
    "Samut Prakan, Thailand",
    "Arlington, United States",
    "Cleveland, United States",
    "Bissau, Guinea-Bissau",
    "Iloilo, Philippines",
    "Khamis Mushait, Saudi Arabia",
    "Ambato, Ecuador",
    "Misratah, Libyan Arab Jamahiriya",
    "Abomey-Calavi, Benin",
    "Bochum, Germany",
    "Sector 3, Romania",
    "Benito Juarez, Mexico",
    "Tampa, United States",
    "Kahramanmaras, Turkey",
    "Chongzuo, China",
    "Toyonaka, Japan",
    "Iztacalco, Mexico",
    "Betim, Brazil",
    "Bawshar, Oman",
    "N'dalatando, Angola",
    "Delmas 73, Haiti",
    "Wellington, New Zealand",
    "Mazatlan, Mexico",
    "Caxias do Sul, Brazil",
    "Nizhny Tagil, Russia",
    "Irapuato, Mexico",
    "Bochum-Hordel, Germany",
    "Aswan, Egypt",
    "Ichinomiya, Japan",
    "Brno, Czech Republic",
    "Bauru, Brazil",
    "Iasi, Romania",
    "Krugersdorp, South Africa",
    "Panihati, India",
    "Shibganj, Bangladesh",
    "Las Palmas de Gran Canaria, Spain",
    "Iquitos, Peru",
    "Qingyang, China",
    "Toyohashi, Japan",
    "Hechuan, China",
    "Rajahmundry, India",
    "Minato, Japan",
    "Sao Jose do Rio Preto, Brazil",
    "Nuevo Laredo, Mexico",
    "Bakersfield, United States",
    "Bengkulu, Indonesia",
    "Yogyakarta, Indonesia",
    "Hrodna, Belarus",
    "Xuanhua, China",
    "Palu, Indonesia",
    "Seremban, Malaysia",
    "Miguel Hidalgo, Mexico",
    "Chimoio, Mozambique",
    "Tawau, Malaysia",
    "Takasaki, Japan",
    "Honolulu, United States",
    "Chitungwiza, Zimbabwe",
    "Abadan, Iran",
    "Fenghuang, China",
    "Puerto La Cruz, Venezuela",
    "Vinnytsya, Ukraine",
    "Usak, Turkey",
    "Bharatpur, Nepal",
    "Mahilyow, Belarus",
    "Natore, Bangladesh",
    "Madinat Sittah Uktubar, Egypt",
    "Leicester, United Kingdom",
    "Sector 6, Romania",
    "Canberra, Australia",
    "Nara-shi, Japan",
    "Florence, Italy",
    "Ahmadnagar, India",
    "Kollam, India",
    "Dhulia, India",
    "Huanggang, China",
    "Olinda, Brazil",
    "Kenitra, Morocco",
    "Bydgoszcz, Poland",
    "Bradford, United Kingdom",
    "Pyeongtaek, South Korea",
    "Petare, Venezuela",
    "Anqiu, China",
    "Larkana, Pakistan",
    "Al Qadarif, Sudan",
    "Christchurch, New Zealand",
    "Cibinong, Indonesia",
    "Brest, Belarus",
    "Jizhou, China",
    "Manukau City, New Zealand",
    "Blumenau, Brazil",
    "Utrecht, Netherlands",
    "Atasehir, Turkey",
    "Bhagalpur, India",
    "Shekhupura, Pakistan",
    "Carapicuiba, Brazil",
    "Wuppertal, Germany",
    "Tamale, Ghana",
    "Ulan-Ude, Russia",
    "Nagano, Japan",
    "Lublin, Poland",
    "Huocheng, China",
    "Aurora, United States",
    "Sumqayit, Azerbaijan",
    "Franca, Brazil",
    "Taraz, Kazakhstan",
    "San Jose del Monte, Philippines",
    "Gwangmyeong, South Korea",
    "Zanjan, Iran",
    "Neiva, Colombia",
    "Iwaki, Japan",
    "Vladimir, Russia",
    "Bacoor, Philippines",
    "Uruapan, Mexico",
    "Xico, Mexico",
    "Benito Juarez, Mexico",
    "Kyzylorda, Kazakhstan",
    "Takatsuki, Japan",
    "Villahermosa, Mexico",
    "Antofagasta, Chile",
    "Ciudad General Escobedo, Mexico",
    "Okazaki, Japan",
    "Bandar Abbas, Iran",
    "Ras Al Khaimah City, United Arab Emirates",
    "Malmoe, Sweden",
    "Suita, Japan",
    "Hue, Viet Nam",
    "Sejong, South Korea",
    "Wakayama, Japan",
    "Anaheim, United States",
    "Hualien City, Taiwan, China",
    "Lapu-Lapu City, Philippines",
    "Diepsloot, South Africa",
    "Punasa, India",
    "Quelimane, Mozambique",
    "Arkhangel'sk, Russia",
    "Muzaffarnagar, India",
    "Hulunbuir, China",
    "Sanandaj, Iran",
    "Chita, Russia",
    "Latur, India",
    "Campina Grande, Brazil",
    "Newcastle, Australia",
    "Camagueey, Cuba",
    "Ambon, Indonesia",
    "Plovdiv, Bulgaria",
    "London, Canada",
    "Tarsus, Turkey",
    "Chifeng, China",
    "Corrientes, Argentina",
    "Hungnam, Korea, Dem. People's Rep. of",
    "Bilbao, Spain",
    "Astana, Kazakhstan",
    "Belfast, United Kingdom",
    "Yunlong, China",
    "Sector 2, Romania",
    "Koshigaya, Japan",
    "Coventry, United Kingdom",
    "Belgorod, Russia",
    "Kendari, Indonesia",
    "Kosti, Sudan",
    "Qitaihe, China",
    "Doha, Qatar",
    "Nakano, Japan",
    "Turmero, Venezuela",
    "Kisumu, Kenya",
    "Tokorozawa, Japan",
    "Otsu, Japan",
    "Pizhou, China",
    "Bhimber, Pakistan",
    "Vitebsk, Belarus",
    "Nice, France",
    "Iligan, Philippines",
    "Soledad, Colombia",
    "Shiqi, China",
    "Zurich, Switzerland",
    "Kukatpalli, India",
    "Vung Tau, Viet Nam",
    "Laixi, China",
    "Jhang Sadr, Pakistan",
    "Arusha, Tanzania",
    "Ambattur, India",
    "Dihok, Iraq",
    "Kaluga, Russia",
    "Celaya, Mexico",
    "Serekunda, Gambia",
    "Makiivka, Ukraine",
    "West Raleigh, United States",
    "Cuernavaca, Mexico",
    "Kaesong, Korea, Dem. People's Rep. of",
    "Kawagoe, Japan",
    "Tungi, Bangladesh",
    "Krasnogvargeisky, Russia",
    "Randburg, South Africa",
    "Bellary, India",
    "Itaquaquecetuba, Brazil",
    "Simferopol, Ukraine",
    "Ganja, Azerbaijan",
    "Santa Ana, United States",
    "San Jose, Costa Rica",
    "Alicante, Spain",
    "Tieling, China",
    "Qazvin, Iran",
    "Asahikawa, Japan",
    "Cirebon, Indonesia",
    "Muzaffarpur, India",
    "Kamarhati, India",
    "Tepic, Mexico",
    "Bahir Dar, Ethiopia",
    "Wonju, South Korea",
    "Wad Medani, Sudan",
    "Nukus, Uzbekistan",
    "Montes Claros, Brazil",
    "Ciudad Victoria, Mexico",
    "Soledad de Graciano Sanchez, Mexico",
    "Kochi, Japan",
    "Bielefeld, Germany",
    "Kwai Chung, Hong Kong, China",
    "Mandaue City, Philippines",
    "Khorramshahr, Iran",
    "Bonn, Germany",
    "Mathura, India",
    "Hechi, China",
    "Smolensk, Russia",
    "Oral, Kazakhstan",
    "Khorramabad, Iran",
    "Soyapango, El Salvador",
    "Tongshan, China",
    "Patiala, India",
    "Wonsan, Korea, Dem. People's Rep. of",
    "Brent, United Kingdom",
    "Pavlodar, Kazakhstan",
    "Markham, Canada",
    "Chanda, India",
    "Canoas, Brazil",
    "Sochi, Russia",
    "Cabanatuan City, Philippines",
    "Chipata, Zambia",
    "Chongjin, Korea, Dem. People's Rep. of",
    "Yanji, China",
    "Logan City, Australia",
    "Corpus Christi, United States",
    "Bhilwara, India",
    "Roodepoort, South Africa",
    "Tetouan, Morocco",
    "Pucallpa, Peru",
    "Toamasina, Madagascar",
    "Mogi das Cruzes, Brazil",
    "Cordoba, Spain",
    "Birkenhead, United Kingdom",
    "Cotabato, Philippines",
    "Brahmapur, India",
    "Sao Vicente, Brazil",
    "Kikuyu, Kenya",
    "Lincang, China",
    "Nottingham, United Kingdom",
    "Al 'Amarah, Iraq",
    "Volzhsky, Russia",
    "Koriyama, Japan",
    "Xingyi, China",
    "Guaruja, Brazil",
    "Riverside, United States",
    "Ixtapaluca, Mexico",
    "Osh, Kyrgyzstan",
    "Portoviejo, Ecuador",
    "Villavicencio, Colombia",
    "Jundiai, Brazil",
    "San Miguelito, Panama",
    "Pelotas, Brazil",
    "Shahjanpur, India",
    "Thies, Senegal",
    "Maroua, Cameroon",
    "Anapolis, Brazil",
    "Shimoga, India",
    "Islington, United Kingdom",
    "Holguin, Cuba",
    "Ust-Kamenogorsk, Kazakhstan",
    "Saransk, Russia",
    "Nantes, France",
    "Ad Diwaniyah, Iraq",
    "Damanhur, Egypt",
    "Popayan, Colombia",
    "Reading, United Kingdom",
    "Taubate, Brazil",
    "Constanta, Romania",
    "New Delhi, India",
    "Thessaloniki, Greece",
    "Naha, Japan",
    "Katowice, Poland",
    "Rohtak, India",
    "Baicheng, China",
    "Chimbote, Peru",
    "Cluj-Napoca, Romania",
    "Bari, Italy",
    "Thrissur, India",
    "Cherepovets, Russia",
    "Eloy Alfaro, Ecuador",
    "St. Louis, United States",
    "Hamburg-Nord, Germany",
    "Al Kut, Iraq",
    "Timisoara, Romania",
    "Sisli, Turkey",
    "Lexington-Fayette, United States",
    "Kingston upon Hull, United Kingdom",
    "Soacha, Colombia",
    "Temara, Morocco",
    "Preston, United Kingdom",
    "Lianshan, China",
    "Denizli, Turkey",
    "Ikeja, Nigeria",
    "New Cairo, Egypt",
    "Varna, Bulgaria",
    "Vitoria, Brazil",
    "Palmira, Colombia",
    "Vologda, Russia",
    "Iligan City, Philippines",
    "Maringa, Brazil",
    "Catania, Italy",
    "Percut, Indonesia",
    "Sariwon, Korea, Dem. People's Rep. of",
    "Botshabelo, South Africa",
    "Butuan, Philippines",
    "Cincinnati, United States",
    "Kurgan, Russia",
    "Tampico, Mexico",
    "Cabuyao, Philippines",
    "Biskra, Algeria",
    "Mannheim, Germany",
    "Orlando, United States",
    "Tumkur, India",
    "Chinju, South Korea",
    "Pekalongan, Indonesia",
    "Iksan, South Korea",
    "Newport, United Kingdom",
    "Firozabad, India",
    "Palmas, Brazil",
    "Vladikavkaz, Russia",
    "Vaughan, Canada",
    "Port-de-Paix, Haiti",
    "Damietta, Egypt",
    "Posadas, Argentina",
    "Tete, Mozambique",
    "Brakpan, South Africa",
    "Craiova, Romania",
    "Stockton, United States",
    "Akita, Japan",
    "Nizamabad, India",
    "Kulti, India",
    "Tlahuac, Mexico",
    "Pittsburgh, United States",
    "Armenia, Colombia",
    "Orel, Russia",
    "Kurume, Japan",
    "Changyi, China",
    "Ciudad del Este, Paraguay",
    "Barddhaman, India",
    "Gujrat, Pakistan",
    "Hamburg-Mitte, Germany",
    "Miri, Malaysia",
    "Xinyi, China",
    "Mardan, Pakistan",
    "Surgut, Russia",
    "Swansea, United Kingdom",
    "Newcastle upon Tyne, United Kingdom",
    "Batikent, Turkey",
    "Merida, Venezuela",
    "Yangshuo, China",
    "Malir Cantonment, Pakistan",
    "Binan, Philippines",
    "Winejok, South Sudan",
    "Linqu, China",
    "Tsuen Wan, Hong Kong, China",
    "Angeles City, Philippines",
    "Patan, Nepal",
    "Valladolid, Spain",
    "Fergana, Uzbekistan",
    "Bahia Blanca, Argentina",
    "Santol, Philippines",
    "Kasugai, Japan",
    "Jember, Indonesia",
    "Aomori, Japan",
    "Barasat, India",
    "Mulugu, India",
    "Okanagan, Canada",
    "Vigo, Spain",
    "Akashi, Japan",
    "Groznyy, Russia",
    "Bali, India",
    "Simao, China",
    "Hisar, India",
    "Rampur, India",
    "Yokkaichi, Japan",
    "Yeosu, South Korea",
    "Graz, Austria",
    "Murmansk, Russia",
    "Southend-on-Sea, United Kingdom",
    "Vina del Mar, Chile",
    "Baiyin, China",
    "Fukushima, Japan",
    "Galati, Romania",
    "Greater Noida, India",
    "Noida, India",
    "Tambov, Russia",
    "Palangkaraya, Indonesia",
    "Al Hufuf, Saudi Arabia",
    "Kakinada, India",
    "Panipat, India",
    "Semey, Kazakhstan",
    "Makurdi, Nigeria",
    "Dindigul, India",
    "Ponta Grossa, Brazil",
    "Gaozhou, China",
    "Minna, Nigeria",
    "Bialystok, Poland",
    "Mbeya, Tanzania",
    "Anchorage, United States",
    "Cabimas, Venezuela",
    "Thanjavur, India",
    "Al Mubarraz, Saudi Arabia",
    "Resistencia, Argentina",
    "Bafoussam, Cameroon",
    "Morioka, Japan",
    "Atyrau, Kazakhstan",
    "Kasur, Pakistan",
    "Xiuying, China",
    "Paulista, Brazil",
    "San Cristobal, Venezuela",
    "Limeira, Brazil",
    "Parbhani, India",
    "Victoria, Canada",
    "Kaunas, Lithuania",
    "Machala, Ecuador",
    "Zeytinburnu, Turkey",
    "Ironville, United States",
    "Meads, United States",
    "Setif, Algeria",
    "Cileungsir, Indonesia",
    "Safi, Morocco",
    "Sinuiju, Korea, Dem. People's Rep. of",
    "Sector 4, Romania",
    "Darbhanga, India",
    "Marienthal, Germany",
    "Adapazari, Turkey",
    "Sultanbeyli, Turkey",
    "Gunpo, South Korea",
    "Tacna, Peru",
    "Sumare, Brazil",
    "Bergen, Norway",
    "Henderson, United States",
    "Greensboro, United States",
    "Haifa, Israel",
    "Arhus, Denmark",
    "Viamao, Brazil",
    "Chernihiv, Ukraine",
    "Saint Paul, United States",
    "Dengzhou, China",
    "Alto Barinas, Venezuela",
    "Karlsruhe, Germany",
    "Port Moresby, Papua New Guinea",
    "Santa Maria, Brazil",
    "Kherson, Ukraine",
    "Al Minya, Egypt",
    "Plano, United States",
    "Ichihara, Japan",
    "Nha Trang, Viet Nam",
    "Poltava, Ukraine",
    "Suzano, Brazil",
    "Alwar, India",
    "Cainta, Philippines",
    "Petionville, Haiti",
    "Xinyuan, China",
    "Maebashi, Japan",
    "Bilaspur, India",
    "Valparaiso, Chile",
    "Ica, Peru",
    "Tin Shui Wai, Hong Kong, China",
    "Newark, United States",
    "Itaguei, Colombia",
    "Nicolas Romero, Mexico",
    "Gebze, Turkey",
    "Narsingdi, Bangladesh",
    "Batna, Algeria",
    "Ibaraki, Japan",
    "Merkezefendi, Turkey",
    "Bukhara, Uzbekistan",
    "Wollongong, Australia",
    "Mingora, Pakistan",
    "Ostrava, Czech Republic",
    "Toledo, United States",
    "Ensenada, Mexico",
    "Efon-Alaaye, Nigeria",
    "Binjai, Indonesia",
    "Petrozavodsk, Russia",
    "Enshi, China",
    "Taganrog, Russia",
    "Santa Teresa del Tuy, Venezuela",
    "South Dublin, Ireland",
    "Wiesbaden, Germany",
    "Qarshi, Uzbekistan",
    "Binhe, China",
    "Windsor, Canada",
    "Coacalco, Mexico",
    "Ilesa, Nigeria",
    "Sincelejo, Colombia",
    "Gijon, Spain",
    "Zoucheng, China",
    "Lincoln, United States",
    "Khomeyni Shahr, Iran",
    "Kostroma, Russia",
    "Sfax, Tunisia",
    "Brighton, United Kingdom",
    "Gagnoa, Côte d'Ivoire",
    "Dire Dawa, Ethiopia",
    "Owo, Nigeria",
    "Sukabumi, Indonesia",
    "Fangchenggang, China",
    "Komsomolsk-on-Amur, Russia",
    "Kaili, China",
    "Hakodate, Japan",
    "Bab Ezzouar, Algeria",
    "Mokolo, Cameroon",
    "Caucaia, Brazil",
    "Strasbourg, France",
    "Khmelnytskyi, Ukraine",
    "Xingning, China",
    "Linxia Chengguanzhen, China",
    "Ichalkaranji, India",
    "Loja, Ecuador",
    "Tai Po, Hong Kong, China",
    "Isfara, Tajikistan",
    "Pasarkemis, Indonesia",
    "Istaravshan, Tajikistan",
    "Salamanca, Mexico",
    "Turpan, China",
    "Yao, Japan",
    "Bani Suwayf, Egypt",
    "Guantanamo, Cuba",
    "Baguio, Philippines",
    "Petropolis, Brazil",
    "Cherkasy, Ukraine",
    "Ar Rayyan, Qatar",
    "Long Xuyen, Viet Nam",
    "Ljubljana, Slovenia",
    "Jalalabad, Afghanistan",
    "Deir ez-Zor, Syria",
    "Hafar Al-Batin, Saudi Arabia",
    "Kakogawacho-honmachi, Japan",
    "Neihu, Taiwan, China",
    "Sector 5, Romania",
    "Tasikmalaya, Indonesia",
    "Bijapur, India",
    "Derby, United Kingdom",
    "San Pedro, Philippines",
    "Muenster, Germany",
    "Gelsenkirchen, Germany",
    "Longling County, China",
    "Southampton, United Kingdom",
    "Corum, Turkey",
    "Yili, China",
    "Eimsbuettel, Germany",
    "Geelong, Australia",
    "Merlo, Argentina",
    "Fuling, China",
    "Mokpo, South Korea",
    "Santa Catarina, Mexico",
    "Birganj, Nepal",
    "Yoshkar-Ola, Russia",
    "Pematangsiantar, Indonesia",
    "Russeifa, Jordan",
    "Windhoek, Namibia",
    "Shibin al Kawm, Egypt",
    "Floridablanca, Colombia",
    "Tshikapa, Congo",
    "Kafr ad Dawwar, Egypt",
    "Tokushima, Japan",
    "Sterlitamak, Russia",
    "Adiyaman, Turkey",
    "Parnamirim, Brazil",
    "Sohag, Egypt",
    "Eixample, Spain",
    "Bagerhat, Bangladesh",
    "Chengzhong, China",
    "Chula Vista, United States",
    "Shimonoseki, Japan",
    "Chernivtsi, Ukraine",
    "Hulan Ergi, China",
    "Aizawl, India",
    "Aachen, Germany",
    "Gent, Belgium",
    "Mantampay, Philippines",
    "Antsirabe, Madagascar",
    "El Daein, Sudan",
    "Donghai, China",
    "Umuahia, Nigeria",
    "Ranipet, India",
    "Jersey City, United States",
    "Manta, Ecuador",
    "Riobamba, Ecuador",
    "Sivas, Turkey",
    "Wolverhampton, United Kingdom",
    "Fanling, Hong Kong, China",
    "Emalahleni, South Africa",
    "Quilmes, Argentina",
    "Jalna, India",
    "Lal Bahadur Nagar, India",
    "Moenchengladbach, Germany",
    "Zhytomyr, Ukraine",
    "Karamay, China",
    "Dewas, India",
    "Tongliao, China",
    "Bordeaux, France",
    "Uberaba, Brazil",
    "Chandler, United States",
    "Yei, South Sudan",
    "Fuchu, Japan",
    "Fort Wayne, United States",
    "Plymouth, United Kingdom",
    "Nianbo, China",
    "Baranagar, India",
    "Aral, China",
    "Nakuru, Kenya",
    "Qo'qon, Uzbekistan",
    "Sumy, Ukraine",
    "Augsburg, Germany",
    "Changle, China",
    "Gajuwaka, India",
    "Jining, China",
    "Saint-Louis, Senegal",
    "Rishon LeTsiyyon, Israel",
    "Stoke-on-Trent, United Kingdom",
    "Foz do Iguacu, Brazil",
    "Buffalo, United States",
    "Verona, Italy",
    "San Salvador de Jujuy, Argentina",
    "Satna, India",
    "Durham, United States",
    "Etawah, India",
    "Rugao, China",
    "Gomez Palacio, Mexico",
    "Hiratsuka, Japan",
    "Cascavel, Brazil",
    "Dese, Ethiopia",
    "St. Petersburg, United States",
    "L'Hospitalet de Llobregat, Spain",
    "Ondo, Nigeria",
    "Cilacap, Indonesia",
    "Irvine, United States",
    "Kitchener, Canada",
    "Espoo, Finland",
    "Suicheng, China",
    "Nada, China",
    "Latina, Spain",
    "Los Mochis, Mexico",
    "Bratsk, Russia",
    "Pachuca de Soto, Mexico",
    "Milton Keynes, United Kingdom",
    "Indaiatuba, Brazil",
    "Laredo, United States",
    "Sari, Iran",
    "Durg, India",
    "Banda Aceh, Indonesia",
    "Oaxaca, Mexico",
    "Ikot Ekpene, Nigeria",
    "Yanzhou, China",
    "Hanam, South Korea",
    "Mueang Nonthaburi, Thailand",
    "Sorong, Indonesia",
    "Novo Hamburgo, Brazil",
    "Cox's Bazar, Bangladesh",
    "Carabanchel, Spain",
    "Cotia, Brazil",
    "Petah Tiqva, Israel",
    "Naihati, India",
    "Brasov, Romania",
    "Vitoria da Conquista, Brazil",
    "Laohekou, China",
    "Richards Bay, South Africa",
    "Talcahuano, Chile",
    "Qina, Egypt",
    "El Fasher, Sudan",
    "Woodlands, Singapore",
    "Thies Nones, Senegal",
    "Suihua, China",
    "Los Teques, Venezuela",
    "Santiago del Estero, Argentina",
    "Barueri, Brazil",
    "Borujerd, Iran",
    "Qarchak, Iran",
    "Afyonkarahisar, Turkey",
    "Santiago de Surco, Peru",
    "San Fernando, Philippines",
    "Morogoro, Tanzania",
    "Governador Valadares, Brazil",
    "Tirupati, India",
    "Wafangdian, China",
    "Sonipat, India",
    "Santa Clara, Cuba",
    "Iwo, Nigeria",
    "Libertad, Philippines",
    "Gombe, Nigeria",
    "Altona, Germany",
    "La Paz, Mexico",
    "Avadi, India",
    "Praia Grande, Brazil",
    "Singa, Sudan",
    "Colonia del Valle, Mexico",
    "Nis, Serbia",
    "San Bernardo, Chile",
    "Varzea Grande, Brazil",
    "Soka, Japan",
    "Porto, Portugal",
    "Volta Redonda, Brazil",
    "Navotas, Philippines",
    "Gasteiz / Vitoria, Spain",
    "Lubbock, United States",
    "Thai Nguyen, Viet Nam",
    "Madison, United States",
    "Yamagata, Japan",
    "Tehuacan, Mexico",
    "Fukui-shi, Japan",
    "Guarenas, Venezuela",
    "Montpellier, France",
    "Jimeta, Nigeria",
    "Czestochowa, Poland",
    "Tiruvottiyur, India",
    "Beibei, China",
    "City of Westminster, United Kingdom",
    "Gilbert, United States",
    "Saugor, India",
    "Chemnitz, Germany",
    "Aksaray, Turkey",
    "Parana, Argentina",
    "San Miguel, El Salvador",
    "Hobart, Australia",
    "Orsk, Russia",
    "Vanderbijlpark, South Africa",
    "Coro, Venezuela",
    "Kiel, Germany",
    "Colombo, Brazil",
    "Mito, Japan",
    "Norfolk, United States",
    "Hami, China",
    "Al 'Ashir min Ramadan, Egypt",
    "Kyain Seikgyi Township, Myanmar",
    "A Coruna, Spain",
    "Mau, India",
    "Mahajanga, Madagascar",
    "Puerto Montt, Chile",
    "Northampton, United Kingdom",
    "Juliaca, Peru",
    "Rivne, Ukraine",
    "Sinfra, Côte d'Ivoire",
    "Syktyvkar, Russia",
    "Fuji, Japan",
    "Gdynia, Poland",
    "Gorgan, Iran",
    "Nizhnevartovsk, Russia",
    "Biratnagar, Nepal",
    "Braunschweig, Germany",
    "Valera, Venezuela",
    "Bago, Myanmar",
    "Tampere, Finland",
    "Bihar Sharif, India",
    "Baruta, Venezuela",
    "Puente de Vallecas, Spain",
    "Trabzon, Turkey",
    "Tri-Cities, United States",
    "Jessore, Bangladesh",
    "Manisa, Turkey",
    "Louisville, United States",
    "Santa Luzia, Brazil",
    "Gunsan, South Korea",
    "Musaffah, United Arab Emirates",
    "Angarsk, Russia",
    "Mar'ino, Russia",
    "Hapur, India",
    "Bathinda, India",
    "Chigasaki, Japan",
    "Tarakan, Indonesia",
    "Marg'ilon, Uzbekistan",
    "Huayin, China",
    "Berbera, Somalia",
    "Ojo de Agua, Mexico",
    "Jiyuan, China",
    "Gatineau, Canada",
    "Tacloban, Philippines",
    "Yamato, Japan",
    "Man, Côte d'Ivoire",
    "Novorossiysk, Russia",
    "Reno, United States",
    "Winston-Salem, United States",
    "Probolinggo, Indonesia",
    "Farrukhabad, India",
    "Sancaktepe, Turkey",
    "Alappuzha, India",
    "Itapevi, Brazil",
    "Ninh Hoa, Viet Nam",
    "Marilia, Brazil",
    "Anantapur, India",
    "Buenaventura, Colombia",
    "Lankaran, Azerbaijan",
    "Glendale, United States",
    "Golestan, Iran",
    "Khimki, Russia",
    "Horlivka, Ukraine",
    "Matsumoto, Japan",
    "Xiantao, China",
    "Nalchik, Russia",
    "Hachinohe, Japan",
    "Jimma, Ethiopia",
    "Gravatai, Brazil",
    "Neyagawa, Japan",
    "Magdalena Contreras, Mexico",
    "Nagarpur, Bangladesh",
    "Ivano-Frankivsk, Ukraine",
    "Balikesir, Turkey",
    "Temuco, Chile",
    "Krefeld, Germany",
    "Halle (Saale), Germany",
    "Sasebo, Japan",
    "Batangas, Philippines",
    "Al Jubayl, Saudi Arabia",
    "Oldham, United Kingdom",
    "Pathein, Myanmar",
    "Tegal, Indonesia",
    "Hialeah, United States",
    "Djougou, Benin",
    "Sylhet, Bangladesh",
    "Garland, United States",
    "Podgorica, Montenegro",
    "Ratlam, India",
    "Scottsdale, United States",
    "Irving, United States",
    "Centurion, South Africa",
    "Qingzhou, China",
    "Dera Ghazi Khan, Pakistan",
    "Coatzacoalcos, Mexico",
    "Yuci, China",
    "Magdeburg, Germany",
    "Sant Marti, Spain",
    "Sahiwal, Pakistan",
    "Yakutsk, Russia",
    "Bole, China",
    "Chesapeake, United States",
    "Caruaru, Brazil",
    "Dali, China",
    "Kediri, Indonesia",
    "Singkawang, Indonesia",
    "Georgetown, Guyana",
    "Ramagundam, India",
    "Kismayo, Somalia",
    "Ibb, Yemen",
    "Turkmenabat, Turkmenistan",
    "North Las Vegas, United States",
    "Sivakasi, India",
    "Rouen, France",
    "Lille, France",
    "Granada, Spain",
    "Nizhnekamsk, Russia",
    "Hortolandia, Brazil",
    "Karur, India",
    "Porto-Novo, Benin",
    "Lubuklinggau, Indonesia",
    "Yongkang, Taiwan, China",
    "Ooty, India",
    "Magugpo Poblacion, Philippines",
    "Groningen, Netherlands",
    "Dzerzhinsk, Russia",
    "Takoradi, Ghana",
    "Saga, Japan",
    "Fremont, United States",
    "Beni, Congo",
    "Jiayuguan, China",
    "Ganganagar, India",
    "Taytay, Philippines",
    "Ngaoundere, Cameroon",
    "Neuquen, Argentina",
    "Suncheon, South Korea",
    "Pasir Mas, Malaysia",
    "Purwokerto, Indonesia",
    "Jose C. Paz, Argentina",
    "Elche, Spain",
    "Marka, Somalia",
    "Atani, Nigeria",
    "Halwan, Egypt",
    "Rio Verde, Brazil",
    "Hosur, India",
    "Nawabshah, Pakistan",
    "Tripoli, Lebanon",
    "Bharatpur, India",
    "Longueuil, Canada",
    "Ordu, Turkey",
    "San Fernando de Apure, Venezuela",
    "Croix-des-Bouquets, Haiti",
    "Uitenhage, South Africa",
    "Ploiesti, Romania",
    "Sungai Petani, Malaysia",
    "Lucena, Philippines",
    "Ipatinga, Brazil",
    "Karimnagar, India",
    "Baton Rouge, United States",
    "Jinchang, China",
    "Rach Gia, Viet Nam",
    "Kosice, Slovakia",
    "Netanya, Israel",
    "Ciudad Lineal, Spain",
    "Bexley, United Kingdom",
    "Nassau, Bahamas",
    "San Lorenzo, Paraguay",
    "Guatire, Venezuela",
    "Tanjung Pinang, Indonesia",
    "Sosnowiec, Poland",
    "Diez de Octubre, Cuba",
    "Turkestan, Kazakhstan",
    "Staryy Oskol, Russia",
    "Gusau, Nigeria",
    "Neue Neustadt, Germany",
    "Kamyanske, Ukraine",
    "Ashdod, Israel",
    "Radom, Poland",
    "Richmond, United States",
    "Shrirampur, India",
    "Fendou, China",
    "Sabzevar, Iran",
    "Huambo, Angola",
    "Raichur, India",
    "Huanggang, China",
    "Quthbullapur, India",
    "Mubi, Nigeria",
    "Sector 1, Romania",
    "Bukavu, Congo",
    "Lexington, United States",
    "Luton, United Kingdom",
    "Ternopil, Ukraine",
    "Juazeiro do Norte, Brazil",
    "Bitung, Indonesia",
    "Mymensingh, Bangladesh",
    "Padangsidempuan, Indonesia",
    "Blagoveshchensk, Russia",
    "Karnal, India",
    "Nacala, Mozambique",
    "Tanga, Tanzania",
    "Jingling, China",
    "Nagercoil, India",
    "Sucre, Bolivia",
    "Mutare, Zimbabwe",
    "Ota, Japan",
    "Minamirinkan, Japan",
    "Atsugi, Japan",
    "Paramaribo, Suriname",
    "Arrah, India",
    "Okara, Pakistan",
    "Narayanganj, Bangladesh",
    "Tongchuanshi, China",
    "Concepcion, Chile",
    "Imphal, India",
    "Ramadi, Iraq",
    "Najafabad, Iran",
    "Paradise, United States",
    "Velikiy Novgorod, Russia",
    "Gosaba, India",
    "Puerto Princesa, Philippines",
    "Xintai, China",
    "El Tigre, Venezuela",
    "Haeju, Korea, Dem. People's Rep. of",
    "Formosa, Argentina",
    "Ikire, Nigeria",
    "La Ceiba, Honduras",
    "Embu, Brazil",
    "Kanchipuram, India",
    "Tulua, Colombia",
    "Shakhty, Russia",
    "Olongapo, Philippines",
    "Ibarra, Ecuador",
    "Banja Luka, Bosnia and Herzegovina",
    "Neyshabur, Iran",
    "Wuxue, China",
    "Rennes, France",
    "Chi Linh, Viet Nam",
    "Campeche, Mexico",
    "Fuencarral-El Pardo, Spain",
    "Mirzapur, India",
    "Oviedo, Spain",
    "Messina, Italy",
    "Dehiwala-Mount Lavinia, Sri Lanka",
    "Takarazuka, Japan",
    "Kropyvnytskyy, Ukraine",
    "Kharagpur, India",
    "Badalona, Spain",
    "Ageoshimo, Japan",
    "Binangonan, Philippines",
    "Oberhausen, Germany",
    "Chon Buri, Thailand",
    "Prokop'yevsk, Russia",
    "Esmeraldas, Ecuador",
    "Terrassa, Spain",
    "Wuhai, China",
    "Bertoua, Cameroon",
    "Imperatriz, Brazil",
    "San Pedro de Macoris, Dominican Republic",
    "Amarnath, India",
    "Kremenchuk, Ukraine",
    "Mokotow, Poland",
    "Taiping, Malaysia",
    "Yintai, China",
    "Mainz, Germany",
    "Pangkalpinang, Indonesia",
    "Jamaica, United States",
    "Rybinsk, Russia",
    "Santa Rosa, Philippines",
    "Porlamar, Venezuela",
    "San Bernardino, United States",
    "Imus, Philippines",
    "Vykhino-Zhulebino, Russia",
    "Lutsk, Ukraine",
    "Freiburg, Germany",
    "Zelenograd, Russia",
    "Archway, United Kingdom",
    "Mirpur Khas, Pakistan",
    "Biysk, Russia",
    "Novi Sad, Serbia",
    "Jinzhou, China",
    "Monclova, Mexico",
    "Tan An, Viet Nam",
    "Ma On Shan, Hong Kong, China",
    "Owerri, Nigeria",
    "Huntsville, United States",
    "Guadalupe, Mexico",
    "Kulob, Tajikistan",
    "Citeureup, Indonesia",
    "Centralniy, Russia",
    "Shagamu, Nigeria",
    "Taboao da Serra, Brazil",
    "Bnei Brak, Israel",
    "Yingtan, China",
    "Marcory, Côte d'Ivoire",
    "Quevedo, Ecuador",
    "Erfurt, Germany",
    "Braila, Romania",
    "Nazarabad, Iran",
    "Lichinga, Mozambique",
    "Bukan, Iran",
    "Spokane, United States",
    "Jacarei, Brazil",
    "Batu, Indonesia",
    "Chofu, Japan",
    "Zitong, China",
    "Loa Janan, Indonesia",
    "Rancagua, Chile",
    "Mallawi, Egypt",
    "Nogales, Mexico",
    "Birmingham, United States",
    "Bukit Mertajam, Malaysia",
    "Lipa City, Philippines",
    "Luebeck, Germany",
    "Luojiang, China",
    "Sepang, Malaysia",
    "Coban, Guatemala",
    "Cartagena, Spain",
    "Alvorada, Brazil",
    "Esenyurt, Turkey",
    "Modesto, United States",
    "Lijiang, China",
    "Konibodom, Tajikistan",
    "Hailar, China",
    "Abha, Saudi Arabia",
    "Tarhuna, Libyan Arab Jamahiriya",
    "Idku, Egypt",
    "Pskov, Russia",
    "Daliang, China",
    "Qui Nhon, Viet Nam",
    "Des Moines, United States",
    "Madiun, Indonesia",
    "Pali, India",
    "Arroyo Naranjo, Cuba",
    "Kostanay, Kazakhstan",
    "Bogra, Bangladesh",
    "Antakya, Turkey",
    "Rochester, United States",
    "Chuncheon, South Korea",
    "Babruysk, Belarus",
    "Pamplona, Spain",
    "Oulu, Finland",
    "Eindhoven, Netherlands",
    "Kanggye, Korea, Dem. People's Rep. of",
    "Presidente Prudente, Brazil",
    "Chungju, South Korea",
    "Sao Leopoldo, Brazil",
    "Ijebu-Ode, Nigeria",
    "Bade, Taiwan, China",
    "Yamunanagar, India",
    "Oruro, Bolivia",
    "Kielce, Poland",
    "Bachuan, China",
    "Rewa, India",
    "La Romana, Dominican Republic",
    "Gaborone, Botswana",
    "Fontana, United States",
    "Hangu, China",
    "Shashemene, Ethiopia",
    "Maryvale, United States",
    "Portsmouth, United Kingdom",
    "Gunan, China",
    "Pasuruan, Indonesia",
    "Tacoma, United States",
    "Lobito, Angola",
    "North Shore, New Zealand",
    "Ciputat, Indonesia",
    "Sirjan, Iran",
    "Arlington, United States",
    "San Pablo, Philippines",
    "Jerez de la Frontera, Spain",
    "Toledo, Philippines",
    "Bila Tserkva, Ukraine",
    "Oxnard, United States",
    "Ciampea, Indonesia",
    "Hurghada, Egypt",
    "Columbus, United States",
    "Kasukabe, Japan",
    "Dosquebradas, Colombia",
    "Oradea, Romania",
    "Annaba, Algeria",
    "Worcester, United States",
    "Sabadell, Spain",
    "Mostoles, Spain",
    "San Felipe, Venezuela",
    "Dinajpur, Bangladesh",
    "Buenavista, Mexico",
    "Itabuna, Brazil",
    "Latacunga, Ecuador",
    "Kishiwada, Japan",
    "Jinghong, China",
    "Sao Carlos, Brazil",
    "Ternate, Indonesia",
    "Santa Cruz de Tenerife, Spain",
    "Linz, Austria",
    "Dhangadhi, Nepal",
    "Nanchuan, China",
    "Bishan, China",
    "Xizhi, Taiwan, China",
    "Alcala de Henares, Spain",
    "Gaoping, China",
    "Trieste, Italy",
    "Moreno Valley, United States",
    "Secunderabad, India",
    "Bidar, India",
    "Padova, Italy",
    "Las Tunas, Cuba",
    "Sa Dec, Viet Nam",
    "Bayamon, Puerto Rico",
    "Puerto Vallarta, Mexico",
    "Agartala, India",
    "Monghyr, India",
    "Edea, Cameroon",
    "Deoghar, India",
    "Vasyl'evsky Ostrov, Russia",
    "Atsiaman, Ghana",
    "Osmaniye, Turkey",
    "Burnaby, Canada",
    "Babol, Iran",
    "Burhanpur, India",
    "Tapachula, Mexico",
    "Corlu, Turkey",
    "Debrecen, Hungary",
    "Barishal, Bangladesh",
    "Nadiad, India",
    "Mossoro, Brazil",
    "Fayetteville, United States",
    "Al Khums, Libyan Arab Jamahiriya",
    "Huntington Beach, United States",
    "Favoriten, Austria",
    "Pemba, Mozambique",
    "Chiniot, Pakistan",
    "Swindon, United Kingdom",
    "Chapra, India",
    "Rengasdengklok, Indonesia",
    "Sete Lagoas, Brazil",
    "Cajamarca, Peru",
    "La Pintana, Chile",
    "Yonkers, United States",
    "Glendale, United States",
    "Banjar, Indonesia",
    "Petropavl, Kazakhstan",
    "Aurora, United States",
    "Montgomery, United States",
    "Nicosia, Cyprus",
    "Brescia, Italy",
    "Ugep, Nigeria",
    "Yanbu, Saudi Arabia",
    "Nagareyama, Japan",
    "Charleroi, Belgium",
    "Sumedang, Indonesia",
    "Az Zawiyah, Libyan Arab Jamahiriya",
    "Shahkot, Pakistan",
    "Gyanpur, India",
    "Severnyy, Russia",
    "Ladner, Canada",
    "Sale Al Jadida, Morocco",
    "Panchkula, India",
    "Thika, Kenya",
    "Pokhara, Nepal",
    "Chakwama, Nigeria",
    "Sao Jose, Brazil",
    "Sikar, India",
    "Beiliu, China",
    "Tilburg, Netherlands",
    "Balakovo, Russia",
    "Armavir, Russia",
    "Kamoke, Pakistan",
    "Saidpur, Bangladesh",
    "Amol, Iran",
    "Arish, Egypt",
    "Dudley, United Kingdom",
    "Yuzhno-Sakhalinsk, Russia",
    "Hagen, Germany",
    "Saskatoon, Canada",
    "Guacara, Venezuela",
    "Gliwice, Poland",
    "Changji, China",
    "Malolos, Philippines",
    "Amarillo, United States",
    "Torun, Poland",
    "Bulandshahr, India",
    "Aberdeen, United Kingdom",
    "Taranto, Italy",
    "Capiata, Paraguay",
    "Gorontalo, Indonesia",
    "Duyun, China",
    "Purnia, India",
    "Kaiyuan, China",
    "Richmond, Canada",
    "Rostock, Germany",
    "Parma, Italy",
    "Sibu, Malaysia",
    "Arnavutkoey, Turkey",
    "Little Rock, United States",
    "Hospet, India",
    "Fuenlabrada, Spain",
    "Mentougou, China",
    "Bishoftu, Ethiopia",
    "Akron, United States",
    "Talca, Chile",
    "Gurgaon, India",
    "Itami, Japan",
    "Ciudad Madero, Mexico",
    "Shreveport, United States",
    "Da Lat, Viet Nam",
    "Birjand, Iran",
    "Jijiang, China",
    "Vientiane, Lao People's Dem. Rep.",
    "Huanuco, Peru",
    "Kure, Japan",
    "Izmit, Turkey",
    "Reims, France",
    "Hanfeng, China",
    "Laayoune, Western Sahara",
    "Holon, Israel",
    "Parauapebas, Brazil",
    "Icheon-si, South Korea",
    "Sambhal, India",
    "Americana, Brazil",
    "Engels, Russia",
    "Gandhinagar, India",
    "Burgas, Bulgaria",
    "Hetauda, Nepal",
    "Murwara, India",
    "Ar Rifa', Bahrain",
    "La Plata, Argentina",
    "Koronadal, Philippines",
    "Peicheng, China",
    "Maharagama, Sri Lanka",
    "Nagaoka, Japan",
    "Turku, Finland",
    "Liege, Belgium",
    "Kumagaya, Japan",
    "Guri-si, South Korea",
    "Nishi-Tokyo-shi, Japan",
    "Grand Rapids, United States",
    "Prato, Italy",
    "Kumarapalayam, India",
    "Richmond Hill, Canada",
    "Sodo, Ethiopia",
    "Petrolina, Brazil",
    "Kafr ash Shaykh, Egypt",
    "Yamoussoukro, Côte d'Ivoire",
    "Kassel, Germany",
    "Nangloi Jat, India",
    "Severodvinsk, Russia",
    "Mobile, United States",
    "Duezce, Turkey",
    "Nnewi, Nigeria",
    "Divinopolis, Brazil",
    "Oakville, Canada",
    "Basaksehir, Turkey",
    "Ezhou, China",
    "Maracanau, Brazil",
    "Zhengding, China",
    "Nam GJinh, Viet Nam",
    "Yongchuan, China",
    "Uji, Japan",
    "Machilipatnam, India",
    "Hepu, China",
    "Hyesan, Korea, Dem. People's Rep. of",
    "Salt Lake City, United States",
    "Bayamo, Cuba",
    "Katihar, India",
    "Par Naogaon, Bangladesh",
    "Fuding, China",
    "Salatiga, Indonesia",
    "Zabrze, Poland",
    "Arba Minch, Ethiopia",
    "Bojnurd, Iran",
    "Humen, China",
    "Fianarantsoa, Madagascar",
    "Sidi Bel Abbes, Algeria",
    "Hat Yai, Thailand",
    "Haicheng, China",
    "Suzuka, Japan",
    "Iquique, Chile",
    "Zinder, Niger",
    "Curug, Indonesia",
    "Barrancabermeja, Colombia",
    "Zlatoust, Russia",
    "Angren, Uzbekistan",
    "Bago City, Philippines",
    "Ormoc, Philippines",
    "Nova Friburgo, Brazil",
    "Khujand, Tajikistan",
    "Peoria, United States",
    "Providence, United States",
    "Bhiwani, India",
    "Cork, Ireland",
    "Al Fallujah, Iraq",
    "Altay, China",
    "Ise-Ekiti, Nigeria",
    "Vantaa, Finland",
    "Paarl, South Africa",
    "Tallahassee, United States",
    "Saddiqabad, Pakistan",
    "Numazu, Japan",
    "Joetsu, Japan",
    "Planaltina, Brazil",
    "Sunrise Manor, United States",
    "Syzran, Russia",
    "Bytom, Poland",
    "Mwene-Ditu, Congo",
    "Panalanoy, Philippines",
    "Santarem, Brazil",
    "Guixi, China",
    "Kabwe, Zambia",
    "Almeria, Spain",
    "Camacari, Brazil",
    "Labuan Bajo, Indonesia",
    "Lhokseumawe, Indonesia",
    "Anjo, Japan",
    "Boyeros, Cuba",
    "Odawara, Japan",
    "Acarigua, Venezuela",
    "Mabalacat City, Philippines",
    "Riohacha, Colombia",
    "Laizhou, China",
    "Santa Barbara d'Oeste, Brazil",
    "Kofu, Japan",
    "Rio Grande, Brazil",
    "Grand Prairie, United States",
    "Mohammedia, Morocco",
    "Sutton, United Kingdom",
    "Chilpancingo, Mexico",
    "Hino, Japan",
    "Cachoeiro de Itapemirim, Brazil",
    "Donaustadt, Austria",
    "Kikwit, Congo",
    "Pinar del Rio, Cuba",
    "Huixing, China",
    "Kirikkale, Turkey",
    "Pagadian, Philippines",
    "Pabna, Bangladesh",
    "Changyuan, China",
    "Cienfuegos, Cuba",
    "Beersheba, Israel",
    "Overland Park, United States",
    "Raebareli, India",
    "Springs, South Africa",
    "Yangcheng, China",
    "Zawiya, Libyan Arab Jamahiriya",
    "Haridwar, India",
    "Leganes, Spain",
    "Ad-Damazin, Sudan",
    "Dunhuang, China",
    "Arica, Chile",
    "Le Havre, France",
    "Langsa, Indonesia",
    "Singrauli, India",
    "Phusro, India",
    "San Sebastian, Spain",
    "Knoxville, United States",
    "Poza Rica de Hidalgo, Mexico",
    "Bilbays, Egypt",
    "Kutahya, Turkey",
    "Meycauayan, Philippines",
    "Modena, Italy",
    "Bolu, Turkey",
    "Palopo, Indonesia",
    "Izumi, Japan",
    "Santa Anita - Los Ficus, Peru",
    "Zaoyang, China",
    "Paltan, Bangladesh",
    "Junagadh, India",
    "Mbandaka, Congo",
    "Padalarang, Indonesia",
    "Abakan, Russia",
    "Pemalang, Indonesia",
    "Geneve, Switzerland",
    "Tarlac City, Philippines",
    "Vinh Chau, Viet Nam",
    "Burewala, Pakistan",
    "Brownsville, United States",
    "Baishan, China",
    "Sujangarh, India",
    "Cergy-Pontoise, France",
    "Ellore, India",
    "Burlington, Canada",
    "St Helens, United Kingdom",
    "Lembang, Indonesia",
    "Sants-Montjuic, Spain",
    "Bhusaval, India",
    "Pak Kret, Thailand",
    "Khandwa, India",
    "Tirmiz, Uzbekistan",
    "Cua, Venezuela",
    "Kamensk-Ural'skiy, Russia",
    "Itaborai, Brazil",
    "Reggio Calabria, Italy",
    "Blida, Algeria",
    "Newport News, United States",
    "Santa Clarita, United States",
    "Carletonville, South Africa",
    "Banha, Egypt",
    "Bahraigh, India",
    "Potsdam, Germany",
    "Tsing Yi Town, Hong Kong, China",
    "Monywa, Myanmar",
    "Karaikkudi, India",
    "Araure, Venezuela",
    "Sirsa, India",
    "Zhenping Chengguanzhen, China",
    "Anda, China",
    "Petropavlovsk-Kamchatsky, Russia",
    "Wadi as Sir, Jordan",
    "Chattanooga, United States",
    "Odense, Denmark",
    "Townsville, Australia",
    "Sacaba, Bolivia",
    "Quetzaltenango, Guatemala",
    "Chandannagar, India",
    "Gangneung, South Korea",
    "Baharampur, India",
    "Dodoma, Tanzania",
    "Berazategui, Argentina",
    "Crawley, United Kingdom",
    "Shahuwadi, India",
    "Rio Claro, Brazil",
    "Tangail, Bangladesh",
    "Matadi, Congo",
    "Singosari, Indonesia",
    "Shulin, Taiwan, China",
    "Castello de la Plana, Spain",
    "Edirne, Turkey",
    "Yasenevo, Russia",
    "Nepean, Canada",
    "Pingwu County, China",
    "Yangju, South Korea",
    "Praga Poludnie, Poland",
    "Hosa'ina, Ethiopia",
    "Surendranagar, India",
    "Varamin, Iran",
    "Passo Fundo, Brazil",
    "Legaspi, Philippines",
    "Phu Quoc, Viet Nam",
    "Podolsk, Russia",
    "Vizianagaram, India",
    "Saarbruecken, Germany",
    "Purwakarta, Indonesia",
    "Jizzax, Uzbekistan",
    "Ila Orangun, Nigeria",
    "Neyveli, India",
    "Qianjiang, China",
    "Baranovichi, Belarus",
    "Hamm, Germany",
    "Burgos, Spain",
    "Klerksdorp, South Africa",
    "Bontang, Indonesia",
    "Si Racha, Thailand",
    "Tiaret, Algeria",
    "La Rioja, Argentina",
    "Amadora, Portugal",
    "Ipswich, United Kingdom",
    "Kamirenjaku, Japan",
    "Saki, Nigeria",
    "Chlef, Algeria",
    "Fort Lauderdale, United States",
    "Seogwipo, South Korea",
    "Hamilton, New Zealand",
    "East Flatbush, United States",
    "Spring Valley, United States",
    "Ciudad Camilo Cienfuegos, Cuba",
    "Proddatur, India",
    "Sittwe, Myanmar",
    "Meiktila, Myanmar",
    "Loum, Cameroon",
    "Tovuz, Azerbaijan",
    "Uppsala, Sweden",
    "Hugli, India",
    "San Luis Rio Colorado, Mexico",
    "Santa Ana, El Salvador",
    "Malayer, Iran",
    "Bielsko-Biala, Poland",
    "Mersa Matruh, Egypt",
    "Almere Stad, Netherlands",
    "Tachikawa, Japan",
    "Saint-Etienne, France",
    "Amroha, India",
    "Regina, Canada",
    "Tanjungbalai, Indonesia",
    "Zhenzhou, China",
    "Tempe, United States",
    "Xindi, China",
    "Oceanside, United States",
    "Bella Vista, Dominican Republic",
    "Tsukuba, Japan",
    "Fengcheng, China",
    "Saveh, Iran",
    "Wigan, United Kingdom",
    "Garden Grove, United States",
    "Karaman, Turkey",
    "Khowy, Iran",
    "Mzuzu, Malawi",
    "Siverek, Turkey",
    "Palhoca, Brazil",
    "Rancho Cucamonga, United States",
    "Cape Coral, United States",
    "Jiutai, China",
    "Santa Rosa, United States",
    "Naga, Philippines",
    "Tomakomai, Japan",
    "Tambaram, India",
    "Cianjur, Indonesia",
    "Pamulang, Indonesia",
    "Bo, Sierra Leone",
    "Pathankot, India",
    "Cikupa, Indonesia",
    "Bhind, India",
    "Puerto Cabello, Venezuela",
    "Hitachi, Japan",
    "Sakura, Japan",
    "Ube, Japan",
    "Daule, Ecuador",
    "Cuddalore, India",
    "Shimla, India",
    "Croydon, United Kingdom",
    "Myeik, Myanmar",
    "East New York, United States",
    "Khan Yunis, West Bank and Gaza Strip",
    "Dharan, Nepal",
    "Bata, Equatorial Guinea",
    "Kamakura, Japan",
    "Chicoloapan, Mexico",
    "Ongole, India",
    "Vancouver, United States",
    "Gadag-Betageri, India",
    "Licheng, China",
    "Kon Tum, Viet Nam",
    "Tunja, Colombia",
    "Quillacollo, Bolivia",
    "Isparta, Turkey",
    "Warrington, United Kingdom",
    "Kaolack, Senegal",
    "Klaipeda, Lithuania",
    "Walsall, United Kingdom",
    "Herne, Germany",
    "Batumi, Georgia",
    "Santander, Spain",
    "Izumo, Japan",
    "Lyublino, Russia",
    "Mansfield, United Kingdom",
    "Reggio nell'Emilia, Italy",
    "Olsztyn, Poland",
    "Francisco Morato, Brazil",
    "Bida, Nigeria",
    "Keng Tung, Myanmar",
    "Sioux Falls, United States",
    "Prizren, ",
    "Dongling, China",
    "Bacau, Romania",
    "Ungaran, Indonesia",
    "Phra Pradaeng, Thailand",
    "Dagupan, Philippines",
    "Ontario, United States",
    "Navsari, India",
    "Muelheim, Germany",
    "Kulim, Malaysia",
    "Puri, India",
    "Ramat Gan, Israel",
    "Haldia, India",
    "Jackson, United States",
    "Temirtau, Kazakhstan",
    "Jacobabad, Pakistan",
    "Khammam, India",
    "Carolina, Puerto Rico",
    "Uvira, Congo",
    "Raiganj, India",
    "Sunderland, United Kingdom",
    "Urayasu, Japan",
    "Takaoka, Japan",
    "Ingraj Bazar, India",
    "Aracatuba, Brazil",
    "Nishio, Japan",
    "San Luis, Argentina",
    "Kluang, Malaysia",
    "Albacete, Spain",
    "Yuen Long Kau Hui, Hong Kong, China",
    "Laiyang, China",
    "Jaunpur, India",
    "Ciudad del Carmen, Mexico",
    "Harburg, Germany",
    "Jaffna, Sri Lanka",
    "Arad, Romania",
    "Chetumal, Mexico",
    "GJong Hoi, Viet Nam",
    "Toliara, Madagascar",
    "Hirosaki, Japan",
    "Pilsen, Czech Republic",
    "Chalco, Mexico",
    "Toulon, France",
    "Pocos de Caldas, Brazil",
    "Calabozo, Venezuela",
    "Metro, Indonesia",
    "Jabalya, West Bank and Gaza Strip",
    "Araraquara, Brazil",
    "Yopal, Colombia",
    "Florencia, Colombia",
    "BordjBouArreridj, Algeria",
    "Moratuwa, Sri Lanka",
    "Angers, France",
    "Ilford, United Kingdom",
    "Horta-Guinardo, Spain",
    "Patra, Greece",
    "Alcorcon, Spain",
    "Ech Chettia, Algeria",
    "Ferraz de Vasconcelos, Brazil",
    "Jamalpur, Bangladesh",
    "Kushiro, Japan",
    "Berezniki, Russia",
    "Awka, Nigeria",
    "Volgodonsk, Russia",
    "Khouribga, Morocco",
    "Breda, Netherlands",
    "Pitesti, Romania",
    "Hollywood, United States",
    "Oyama, Japan",
    "Ijero-Ekiti, Nigeria",
    "Miass, Russia",
    "Carupano, Venezuela",
    "Getafe, Spain",
    "Kumbakonam, India",
    "Plumbon, Indonesia",
    "Novocherkassk, Russia",
    "Elk Grove, United States",
    "Springfield, United States",
    "Sampit, Indonesia",
    "Clarksville, United States",
    "Linkoeping, Sweden",
    "Pembroke Pines, United States",
    "Maicao, Colombia",
    "Arapiraca, Brazil",
    "Dasoguz, Turkmenistan",
    "Fatehpur, India",
    "Osnabrueck, Germany",
    "Beni Mellal, Morocco",
    "Gandhidham, India",
    "Tuguegarao, Philippines",
    "Nou Barris, Spain",
    "Nepalgunj, Nepal",
    "Ocumare del Tuy, Venezuela",
    "Oshawa, Canada",
    "Obihiro, Japan",
    "Ulanhot, China",
    "Khobar, Saudi Arabia",
    "Songcheng, China",
    "Deer Valley, United States",
    "Sawangan, Indonesia",
    "Bushehr, Iran",
    "Nandyal, India",
    "Tam Ky, Viet Nam",
    "Muzaffargarh, Pakistan",
    "Udupi, India",
    "Banjaran, Indonesia",
    "Hengshan, China",
    "Loni, India",
    "Slough, United Kingdom",
    "Lages, Brazil",
    "Neukoelln, Germany",
    "Port Saint Lucie, United States",
    "Salem, United States",
    "Basel, Switzerland",
    "Sitapur, India",
    "Abengourou, Côte d'Ivoire",
    "Zenica, Bosnia and Herzegovina",
    "Solingen, Germany",
    "Kigoma, Tanzania",
    "Muridke, Pakistan",
    "Corona, United States",
    "Shivpuri, India",
    "Hawalli, Kuwait",
    "Inisa, Nigeria",
    "Nazran', Russia",
    "Sousse, Tunisia",
    "Bejaia, Algeria",
    "Barra Mansa, Brazil",
    "Nossa Senhora do Socorro, Brazil",
    "Bhadravati, India",
    "Hadano, Japan",
    "Vinh, Viet Nam",
    "Parakou, Benin",
    "Piraeus, Greece",
    "Adoni, India",
    "Bournemouth, United Kingdom",
    "Sidon, Lebanon",
    "Maradi, Niger",
    "'Ibri, Oman",
    "Tsu, Japan",
    "Eugene, United States",
    "Peterborough, United Kingdom",
    "Piedecuesta, Colombia",
    "Veraval, India",
    "Tongzhou, China",
    "Ludwigshafen am Rhein, Germany",
    "Salalah, Oman",
    "Bueyuekcekmece, Turkey",
    "Aydin, Turkey",
    "Envigado, Colombia",
    "Geneina, Sudan",
    "Anbu, China",
    "McKinney, United States",
    "Sambalpur, India",
    "Chirchiq, Uzbekistan",
    "Floridsdorf, Austria",
    "Leverkusen, Germany",
    "Skikda, Algeria",
    "Gadag, India",
    "Boma, Congo",
    "Mahabad, Iran",
    "Jiutepec, Mexico",
    "Dourados, Brazil",
    "Kitale, Kenya",
    "Suleja, Nigeria",
    "Oxford, United Kingdom",
    "Al Matariyah, Egypt",
    "Criciuma, Brazil",
    "Kunduz, Afghanistan",
    "Fengcheng, China",
    "Sapele, Nigeria",
    "Unnao, India",
    "Hortaleza, Spain",
    "Budaun, India",
    "Ogaki, Japan",
    "Coquimbo, Chile",
    "Jind, India",
    "GJuc Trong, Viet Nam",
    "Fort Collins, United States",
    "Miyakonojo, Japan",
    "Madhyamgram, India",
    "Lancaster, United States",
    "Rubtsovsk, Russia",
    "Tacheng, China",
    "San Juan de los Morros, Venezuela",
    "Sayama, Japan",
    "Sullana, Peru",
    "Szeged, Hungary",
    "Malakal, South Sudan",
    "La Gi, Viet Nam",
    "Phan Thiet, Viet Nam",
    "Anderlecht, Belgium",
    "Mytishchi, Russia",
    "Delegacion Cuajimalpa de Morelos, Mexico",
    "Hebron, West Bank and Gaza Strip",
    "Mejicanos, El Salvador",
    "Jamuria, India",
    "Koudougou, Burkina Faso",
    "Chapeco, Brazil",
    "Taunggyi, Myanmar",
    "Dhamar, Yemen",
    "Iskenderun, Turkey",
    "Salavat, Russia",
    "Manzanillo, Mexico",
    "Jalapa, Guatemala",
    "Ziguinchor, Senegal",
    "Cary, United States",
    "Skudai, Malaysia",
    "Alexandria, United States",
    "Puthia, Bangladesh",
    "Zhicheng, China",
    "Lawang, Indonesia",
    "Limuru, Kenya",
    "San Miguel del Padron, Cuba",
    "Tarija, Bolivia",
    "Baubau, Indonesia",
    "Oldenburg, Germany",
    "Catamarca, Argentina",
    "Tochigi, Japan",
    "Bibirevo, Russia",
    "Khoroshevo-Mnevniki, Russia",
    "Osan, South Korea",
    "Andong, South Korea",
    "Nijmegen, Netherlands",
    "Jaigaon, India",
    "Grenoble, France",
    "Samarra', Iraq",
    "Rzeszow, Poland",
    "Tempe Junction, United States",
    "Palmdale, United States",
    "George Town, Malaysia",
    "El Achir, Algeria",
    "Barreiras, Brazil",
    "Hayward, United States",
    "Orai, India",
    "Thot Not, Viet Nam",
    "Gweru, Zimbabwe",
    "Centro Habana, Cuba",
    "Hoshiarpur, India",
    "San Cristobal de las Casas, Mexico",
    "Dijon, France",
    "Gol'yanovo, Russia",
    "Sobral, Brazil",
    "Sunggal, Indonesia",
    "Admiralteisky, Russia",
    "Greater Sudbury, Canada",
    "Talkha, Egypt",
    "Ueda, Japan",
    "Aberdeen, Hong Kong, China",
    "George, South Africa",
    "Salinas, United States",
    "San Blas-Canillejas, Spain",
    "Cuautla, Mexico",
    "Salzburg, Austria",
    "Perbaungan, Indonesia",
    "Pallavaram, India",
    "Ussuriysk, Russia",
    "Nanpiao, China",
    "Livorno, Italy",
    "Rio Cuarto, Argentina",
    "Tiraspol, Moldova, Republic of",
    "Moshi, Tanzania",
    "Ciudad Ojeda, Venezuela",
    "Shikarpur, Pakistan",
    "Enfield Town, United Kingdom",
    "Westonaria, South Africa",
    "Matsue, Japan",
    "Lizhi, China",
    "Souk Ahras, Algeria",
    "Osogbo, Nigeria",
    "Qalyub, Egypt",
    "Escuintla, Guatemala",
    "Batu Pahat, Malaysia",
    "San Pablo de las Salinas, Mexico",
    "Lampang, Thailand",
    "York, United Kingdom",
    "Fes al Bali, Morocco",
    "OErebro, Sweden",
    "Katsuta, Japan",
    "Malabo, Equatorial Guinea",
    "Yizhou, China",
    "Sunnyvale, United States",
    "Tema, Ghana",
    "Yichun, China",
    "Itajai, Brazil",
    "Ar Ramtha, Jordan",
    "Hanumangarh, India",
    "Salamanca, Spain",
    "Zemun, Serbia",
    "Telford, United Kingdom",
    "Kisi, Nigeria",
    "Ilheus, Brazil",
    "Villa Canales, Guatemala",
    "Guyong, Philippines",
    "Gyeongju, South Korea",
    "Port Louis, Mauritius",
    "Tauranga, New Zealand",
    "Kawanishi, Japan",
    "Bima, Indonesia",
    "Al Hawamidiyah, Egypt",
    "Tetuan de las Victorias, Spain",
    "Lianghu, China",
    "Beipiao, China",
    "Guangshui, China",
    "Middelburg, South Africa",
    "Lyubertsy, Russia",
    "Hitachi-Naka, Japan",
    "Butembo, Congo",
    "La Serena, Chile",
    "Miskolc, Hungary",
    "Abu Kabir, Egypt",
    "Fu'an, China",
    "Gandajika, Congo",
    "Frisco, United States",
    "Zhaodong, China",
    "Springfield, United States",
    "Djelfa, Algeria",
    "Kovrov, Russia",
    "Viransehir, Turkey",
    "Noda, Japan",
    "Tottori, Japan",
    "San Cristobal, Dominican Republic",
    "East Chattanooga, United States",
    "Limassol, Cyprus",
    "Pasadena, United States",
    "Alandur, India",
    "Mit Ghamr, Egypt",
    "Sanshui, China",
    "Guna, India",
    "Hafizabad, Pakistan",
    "Enschede, Netherlands",
    "Angra dos Reis, Brazil",
    "Bahadurgarh, India",
    "Boshan, China",
    "Hazaribagh, India",
    "Yangchun, China",
    "Bharuch, India",
    "Segou, Mali",
    "Medinipur, India",
    "Pomona, United States",
    "Valle de La Pascua, Venezuela",
    "Kreuzberg, Germany",
    "Chincha Alta, Peru",
    "Cairns, Australia",
    "Fyzabad, India",
    "Harar, Ethiopia",
    "Dinapore, India",
    "Dingzhou, China",
    "Rondonopolis, Brazil",
    "As Samawah, Iraq",
    "Washington Heights, United States",
    "Lakewood, United States",
    "Baqubah, Iraq",
    "Logrono, Spain",
    "Neuss, Germany",
    "Chenghua, China",
    "Songnim-ni, Korea, Dem. People's Rep. of",
    "Silchar, India",
    "Banda, India",
    "Can Giuoc, Viet Nam",
    "Balvanera, Argentina",
    "Longfeng, China",
    "Morena, India",
    "Strogino, Russia",
    "Bao Loc, Viet Nam",
    "Sibiu, Romania",
    "Ciudad Benito Juarez, Mexico",
    "Bintulu, Malaysia",
    "Itapecerica da Serra, Brazil",
    "Parepare, Indonesia",
    "Escondido, United States",
    "Akhmim, Egypt",
    "Kohat, Pakistan",
    "Tonk, India",
    "Kansas City, United States",
    "Girga, Egypt",
    "Saqqez, Iran",
    "Benguela, Angola",
    "Guarapuava, Brazil",
    "Raj-Nandgaon, India",
    "Erzincan, Turkey",
    "Kirsehir, Turkey",
    "Ursynow, Poland",
    "La Laguna, Spain",
    "Kokshetau, Kazakhstan",
    "Serilingampalle, India",
    "Chillan, Chile",
    "Blitar, Indonesia",
    "Virar, India",
    "Piedras Negras, Mexico",
    "Kiziltepe, Turkey",
    "Astoria, United States",
    "Balashikha, Russia",
    "Poole, United Kingdom",
    "Vryheid, South Africa",
    "Malkajgiri, India",
    "Dili, Timor-Leste",
    "Gimcheon, South Korea",
    "Tordher, Pakistan",
    "Zhulebino, Russia",
    "Mascara, Algeria",
    "Shantipur, India",
    "Playa del Carmen, Mexico",
    "Split, Croatia",
    "Hollywood, United States",
    "Madrid Centro, Spain",
    "Burnley, United Kingdom",
    "Rehovot, Israel",
    "Watampone, Indonesia",
    "Disuq, Egypt",
    "Cagliari, Italy",
    "Borough Park, United States",
    "Harrow, United Kingdom",
    "Huddersfield, United Kingdom",
    "Kerch, Ukraine",
    "Prenzlauer Berg Bezirk, Germany",
    "Komaki, Japan",
    "Marvdasht, Iran",
    "Melitopol, Ukraine",
    "Dunhua, China",
    "Arganzuela, Spain",
    "Yonago, Japan",
    "Rimini, Italy",
    "Chingola, Zambia",
    "Arar, Saudi Arabia",
    "Valencia, United States",
    "Gyumri, Armenia",
    "Badajoz, Spain",
    "Tama, Japan",
    "Rockford, United States",
    "Higashimurayama, Japan",
    "El Limon, Venezuela",
    "Nimes, France",
    "Dundee, United Kingdom",
    "Barakpur, India",
    "Botucatu, Brazil",
    "Sujiatun, China",
    "Caxias, Brazil",
    "Ha Long, Viet Nam",
    "Astanajapura, Indonesia",
    "Jijel, Algeria",
    "East Hampton, United States",
    "Honggang, China",
    "Sarria-Sant Gervasi, Spain",
    "Clermont-Ferrand, France",
    "Joliet, United States",
    "Savannah, United States",
    "Paterson, United States",
    "Sant Andreu, Spain",
    "Salamanca, Spain",
    "Medea, Algeria",
    "Kolomna, Russia",
    "Rafsanjan, Iran",
    "Mestre, Italy",
    "As Salimiyah, Kuwait",
    "Bridgeport, United States",
    "District of Taher, Algeria",
    "Haarlem, Netherlands",
    "El Jadid, Morocco",
    "Musashino, Japan",
    "Kragujevac, Serbia",
    "Shevchenko, Kazakhstan",
    "Nilopolis, Brazil",
    "Darwin, Australia",
    "Iruma, Japan",
    "Kramatorsk, Ukraine",
    "Trondheim, Norway",
    "Naperville, United States",
    "Manama, Bahrain",
    "Buon Ma Thuot, Viet Nam",
    "Kalemie, Congo",
    "Colima, Mexico",
    "Larisa, Greece",
    "Nakhodka, Russia",
    "Targu-Mures, Romania",
    "Gulu, Uganda",
    "Aix-en-Provence, France",
    "Ambala, India",
    "Cam Ranh, Viet Nam",
    "Matanzas, Cuba",
    "Saint-Quentin-en-Yvelines, France",
    "Blackburn, United Kingdom",
    "Ruda Slaska, Poland",
    "Puerto Plata, Dominican Republic",
    "Boise, United States",
    "Chamberi, Spain",
    "Krishnanagar, India",
    "Xinji, China",
    "Jutiapa, Guatemala",
    "Maraba, Brazil",
    "Saharsa, India",
    "Cambridge, United Kingdom",
    "Kampung Pasir Gudang Baru, Malaysia",
    "Cikampek, Indonesia",
    "Yamaguchi, Japan",
    "Batala, India",
    "Taishan, China",
    "Pecs, Hungary",
    "Tabora, Tanzania",
    "Concordia, Argentina",
    "Fujieda, Japan",
    "Spanish Town, Jamaica",
    "Blackpool, United Kingdom",
    "Urganch, Uzbekistan",
    "Jhelum, Pakistan",
    "Barysaw, Belarus",
    "Brest, France",
    "Stavanger, Norway",
    "Basildon, United Kingdom",
    "Mesquite, United States",
    "Sikasso, Mali",
    "Huyen Lam Ha, Viet Nam",
    "Acheng, China",
    "Leon, Nicaragua",
    "Le Mans, France",
    "Jyvaeskylae, Finland",
    "Kumba, Cameroon",
    "Elektrostal', Russia",
    "Yong'an, China",
    "Huelva, Spain",
    "Navoiy, Uzbekistan",
    "Syracuse, United States",
    "Goma, Congo",
    "Atibaia, Brazil",
    "Ashkelon, Israel",
    "Pinetown, South Africa",
    "Teshi Old Town, Ghana",
    "Orekhovo-Borisovo, Russia",
    "Biryulevo, Russia",
    "Tizi Ouzou, Algeria",
    "Nanjin, China",
    "Dumai, Indonesia",
    "Qianjiang, China",
    "Saguenay, Canada",
    "Ashikaga, Japan",
    "Marawi City, Philippines",
    "Canakkale, Turkey",
    "Luziania, Brazil",
    "Torrance, United States",
    "Stara Zagora, Bulgaria",
    "Ruse, Bulgaria",
    "Levis, Canada",
    "Bechar, Algeria",
    "Heidelberg, Germany",
    "Surprise, United States",
    "Kenema, Sierra Leone",
    "Norwich, United Kingdom",
    "Cabo, Brazil",
    "Amiens, France",
    "Calama, Chile",
    "Macae, Brazil",
    "Cape Coast, Ghana",
    "Novyye Kuz'minki, Russia",
    "Kuz'minki, Russia",
    "Bhimavaram, India",
    "Aalborg, Denmark",
    "Pyatigorsk, Russia",
    "Middlesbrough, United Kingdom",
    "Shahrisabz, Uzbekistan",
    "Moriguchi, Japan",
    "Rybnik, Poland",
    "Metairie Terrace, United States",
    "Tuzla, Bosnia and Herzegovina",
    "Khanpur, Pakistan",
    "Nawabganj, Bangladesh",
    "Pasadena, United States",
    "Paderborn, Germany",
    "Kelowna, Canada",
    "Kimberley, South Africa",
    "Ciego de Avila, Cuba",
    "Samalut, Egypt",
    "Chertanovo Yuzhnoye, Russia",
    "Jieshou, China",
    "Maykop, Russia",
    "Kisaran, Indonesia",
    "Taza, Morocco",
    "Facatativa, Colombia",
    "eMbalenhle, South Africa",
    "Punto Fijo, Venezuela",
    "Myingyan, Myanmar",
    "Arnhem, Netherlands",
    "Zheleznodorozhnyy, Russia",
    "Zamora, Mexico",
    "Tours, France",
    "Bondoukou, Côte d'Ivoire",
    "Chichicastenango, Guatemala",
    "Barrie, Canada",
    "Balurghat, India",
    "Abbotsford, Canada",
    "Ibirite, Brazil",
    "Bolton, United Kingdom",
    "Lauro de Freitas, Brazil",
    "Potosi, Bolivia",
    "Khuzdar, Pakistan",
    "Usera, Spain",
    "Limoges, France",
    "Gainesville, United States",
    "Paranagua, Brazil",
    "Ghazni, Afghanistan",
    "Orange, United States",
    "Wola, Poland",
    "Sanhe, China",
    "Ilam, Iran",
    "Viet Tri, Viet Nam",
    "Gongzhuling, China",
    "Cordoba, Mexico",
    "Comodoro Rivadavia, Argentina",
    "Kampung Sungai Ara, Malaysia",
    "Fullerton, United States",
    "Killeen, United States",
    "Norilsk, Russia",
    "Nigel, South Africa",
    "Kaithal, India",
    "Shuizhai, China",
    "Al'met'yevsk, Russia",
    "Darmstadt, Germany",
    "Toowoomba, Australia",
    "McAllen, United States",
    "Lakhimpur, India",
    "Mpumalanga, South Africa",
    "Zaanstad, Netherlands",
    "Kuwana, Japan",
    "Vidisha, India",
    "Ayacucho, Peru",
    "Coquitlam, Canada",
    "Chamartin, Spain",
    "Shanhaiguan, China",
    "Saham, Oman",
    "Peristeri, Greece",
    "Amersfoort, Netherlands",
    "Jeongeup, South Korea",
    "Bellevue, United States",
    "Korolev, Russia",
    "Dadu, Pakistan",
    "Gojra, Pakistan",
    "Debre Birhan, Ethiopia",
    "Sollentuna, Sweden",
    "Yaizu, Japan",
    "Payakumbuh, Indonesia",
    "Haldwani, India",
    "Gbongan, Nigeria",
    "Siem Reap, Cambodia",
    "Anaco, Venezuela",
    "Purwodadi, Indonesia",
    "Habra, India",
    "Sidoarjo, Indonesia",
    "Sabah as Salim, Kuwait",
    "Kariya, Japan",
    "Lausanne, Switzerland",
    "Choloma, Honduras",
    "Stockport, United Kingdom",
    "Budapest XI. keruelet, Hungary",
    "Huadian, China",
    "Weru, Indonesia",
    "Al-'Ubur, Egypt",
    "Kolpino, Russia",
    "Belgrano, Argentina",
    "San Juan del Rio, Mexico",
    "Sekondi-Takoradi, Ghana",
    "Porbandar, India",
    "Liuzhi, China",
    "Fuyu, China",
    "Chitradurga, India",
    "Ishinomaki, Japan",
    "Metairie, United States",
    "Chaohu, China",
    "Koga, Japan",
    "Tariba, Venezuela",
    "Ejigbo, Nigeria",
    "Thanesar, India",
    "Tiruvannamalai, India",
    "Dabou, Côte d'Ivoire",
    "Anand, India",
    "Parnaiba, Brazil",
    "Jacmel, Haiti",
    "Vinh Long, Viet Nam",
    "Lleida, Spain",
    "Obuase, Ghana",
    "Dayton, United States",
    "Liangping, China",
    "Kirdasah, Egypt",
    "Itu, Brazil",
    "Dikirnis, Egypt",
    "Ponce, Puerto Rico",
    "Jieshi, China",
    "Hejiang, China",
    "Castanhal, Brazil",
    "Bhisho, South Africa",
    "Negombo, Sri Lanka",
    "Saint-Denis, Réunion",
    "Irakleion, Greece",
    "Wuyishan, China",
    "Miramar, United States",
    "Jiashan, China",
    "Bilqas, Egypt",
    "Rangkasbitung, Indonesia",
    "Odintsovo, Russia",
    "Foggia, Italy",
    "Kanchrapara, India",
    "San Juan Sacatepequez, Guatemala",
    "Gisenyi, Rwanda",
    "Funtua, Nigeria",
    "Dawei, Myanmar",
    "Igboho, Nigeria",
    "Gunungsitoli, Indonesia",
    "Apeldoorn, Netherlands",
    "Shizuishan, China",
    "Baia Mare, Romania",
    "Kabankalan, Philippines",
    "Hampton, United States",
    "Sao Caetano do Sul, Brazil",
    "Van Nuys, United States",
    "Bush, Egypt",
    "Ebina, Japan",
    "West Valley City, United States",
    "Gejiu, China",
    "Mahbubnagar, India",
    "Kisarazu, Japan",
    "Dimapur, India",
    "Tasek Glugor, Malaysia",
    "Osorno, Chile",
    "Jiagedaqi, China",
    "Queimados, Brazil",
    "Tumxuk, China",
    "Kushtia, Bangladesh",
    "Pitalito, Colombia",
    "Kindu, Congo",
    "Baliuag, Philippines",
    "West Bromwich, United Kingdom",
    "Isahaya, Japan",
    "Cam Pha Mines, Viet Nam",
    "Cam Pha, Viet Nam",
    "Petapa, Guatemala",
    "Buguma, Nigeria",
    "Shiqiao, China",
    "Pyay, Myanmar",
    "Rabak, Sudan",
    "Tay Ninh, Viet Nam",
    "Robertsonpet, India",
    "Tyre, Lebanon",
    "Kutaisi, Georgia",
    "Moundou, Chad",
    "Cartago, Colombia",
    "Hinthada, Myanmar",
    "Bielany, Poland",
    "Chhindwara, India",
    "Mandya, India",
    "Cap-Haitien, Haiti",
    "Baturaja, Indonesia",
    "El Oued, Algeria",
    "Marbella, Spain",
    "'s-Hertogenbosch, Netherlands",
    "Rodriguez, Philippines",
    "Nevinnomyssk, Russia",
    "San Mateo, Philippines",
    "Tahta, Egypt",
    "Srodmiescie, Poland",
    "Olathe, United States",
    "Leon, Spain",
    "Ikirun, Nigeria",
    "Ciudad Acuna, Mexico",
    "Shenglilu, China",
    "San Nicolas de los Arroyos, Argentina",
    "Leogane, Haiti",
    "Adiwerna, Indonesia",
    "Abakaliki, Nigeria",
    "Warren, United States",
    "Bankura, India",
    "Inegol, Turkey",
    "Ebetsu, Japan",
    "Jiawang, China",
    "Debre Mark'os, Ethiopia",
    "Columbia, United States",
    "Singaraja, Indonesia",
    "Wuerzburg, Germany",
    "Randfontein, South Africa",
    "Hajipur, India",
    "Pervouralsk, Russia",
    "Gonda City, India",
    "Sinnuris, Egypt",
    "Milagro, Ecuador",
    "Bhuj, India",
    "Thornton, United States",
    "Hastings, United Kingdom",
    "Valdivia, Chile",
    "Olmaliq, Uzbekistan",
    "Dunedin, New Zealand",
    "Hindupur, India",
    "Mojokerto, Indonesia",
    "Okrika, Nigeria",
    "High Wycombe, United Kingdom",
    "Carrollton, United States",
    "Talisay, Philippines",
    "St. Catharines, Canada",
    "Idfu, Egypt",
    "Milton, Canada",
    "Midland, United States",
    "Puqi, China",
    "Tondabayashicho, Japan",
    "Sandacho, Japan",
    "Shillong, India",
    "Miandoab, Iran",
    "Kislovodsk, Russia",
    "Schaerbeek, Belgium",
    "Hoofddorp, Netherlands",
    "Palakkad, India",
    "Nzerekore, Guinea",
    "Charleston, United States",
    "Fujinomiya, Japan",
    "Innsbruck, Austria",
    "Wugang, China",
    "Gloucester, United Kingdom",
    "Waco, United States",
    "Cerro, Cuba",
    "Tlemcen, Algeria",
    "Tarragona, Spain",
    "Kakamigahara, Japan",
    "Dimitrovgrad, Russia",
    "Zhalantun, China",
    "Pindamonhangaba, Brazil",
    "Muricay, Philippines",
    "Sterling Heights, United States",
    "Surat Thani, Thailand",
    "Ferrara, Italy",
    "Quan GJuc Thinh, Viet Nam",
    "Omuta, Japan",
    "Fuji, China",
    "Godhra, India",
    "Ome, Japan",
    "Shahrud, Iran",
    "Dawukou, China",
    "Bulaon, Philippines",
    "Abiko, Japan",
    "Kishangarh, India",
    "Kadoma, Japan",
    "Majie, China",
    "Martapura, Indonesia",
    "Villeurbanne, France",
    "Gonbad-e Kavus, Iran",
    "Raniganj, India",
    "Hepo, China",
    "Iranshahr, Iran",
    "Denton, United States",
    "Lashio, Myanmar",
    "Pilibhit, India",
    "Novomoskovsk, Russia",
    "Buzau, Romania",
    "Sapucaia, Brazil",
    "Matsubara, Japan",
    "Beawar, India",
    "Pinsk, Belarus",
    "Al Bab, Syria",
    "Shuangcheng, China",
    "Exeter, United Kingdom",
    "Isesaki, Japan",
    "Zama, Japan",
    "Abohar, India",
    "Moga, India",
    "Al Ajaylat, Libyan Arab Jamahiriya",
    "Udon Thani, Thailand",
    "Petrogradka, Russia",
    "Zipaquira, Colombia",
    "Cedar Rapids, United States",
    "Dongyang, China",
    "Nablus, West Bank and Gaza Strip",
    "New Haven, United States",
    "Jiazi, China",
    "Roseville, United States",
    "Quilpue, Chile",
    "Umea, Sweden",
    "Songjiang, China",
    "Jaragua do Sul, Brazil",
    "Sinnar, Sudan",
    "Masaya, Nicaragua",
    "Visalia, United States",
    "Rustavi, Georgia",
    "Relizane, Algeria",
    "Tychy, Poland",
    "Ramenki, Russia",
    "Mostaganem, Algeria",
    "Zuglo, Hungary",
    "San Martin, Peru",
    "Sonargaon, Bangladesh",
    "Shangri-La, China",
    "Sabha, Libyan Arab Jamahiriya",
    "Tottenham, United Kingdom",
    "Dehri, India",
    "Wuda, China",
    "Cambridge, Canada",
    "Gyeongsan-si, South Korea",
    "Mardin, Turkey",
    "Baidoa, Somalia",
    "Salford, United Kingdom",
    "Mandi Bahauddin, Pakistan",
    "Tokat, Turkey",
    "O Mon, Viet Nam",
    "Bimbo, Central African Republic",
    "Deoria, India",
    "Coral Springs, United States",
    "Sherbrooke, Canada",
    "Newcastle under Lyme, United Kingdom",
    "Ouargla, Algeria",
    "Acilia-Castel Fusano-Ostia Antica, Italy",
    "Charlottenburg, Germany",
    "Thousand Oaks, United States",
    "Antsiranana, Madagascar",
    "Gyor, Hungary",
    "Copiapo, Chile",
    "Nador, Morocco",
    "Quibdo, Colombia",
    "Charallave, Venezuela",
    "Shahr-e Kord, Iran",
    "Regensburg, Germany",
    "Malambo, Colombia",
    "Mino, Japan",
    "Leiyang, China",
    "Bialoleka, Poland",
    "Cadiz, Philippines",
    "Bat Yam, Israel",
    "Zacatecas, Mexico",
    "Elizabeth, United States",
    "Zyablikovo, Russia",
    "Bingoel, Turkey",
    "Satkhira, Bangladesh",
    "Palo Negro, Venezuela",
    "Stamford, United States",
    "Mogi Guacu, Brazil",
    "Idlib, Syria",
    "Sinah, Iraq",
    "Osaki, Japan",
    "Concord, United States",
    "Puno, Peru",
    "Kamyshin, Russia",
    "Shuanglonghu, China",
    "Hassan, India",
    "Novocheboksarsk, Russia",
    "Besancon, France",
    "El Eulma, Algeria",
    "Khardah, India",
    "Sakakah, Saudi Arabia",
    "Al Manaqil, Sudan",
    "Teluknaga, Indonesia",
    "Manzanillo, Cuba",
    "Yavatmal, India",
    "Halisahar, India",
    "Serpukhov, Russia",
    "Rionegro, Colombia",
    "Shache, China",
    "Sirte, Libyan Arab Jamahiriya",
    "Asaka, Japan",
    "Panvel, India",
    "Orekhovo-Borisovo Severnoye, Russia",
    "Ivanovskoye, Russia",
    "Buon Ho, Viet Nam",
    "Muar, Malaysia",
    "Divo, Côte d'Ivoire",
    "Batang, Indonesia",
    "Vaesteras, Sweden",
    "Alhambra, United States",
    "Titagarh, India",
    "Kusatsu, Japan",
    "Opole, Poland",
    "Seto, Japan",
    "Lafayette, United States",
    "Worcester, South Africa",
    "Elblag, Poland",
    "Saida, Algeria",
    "Sirajganj, Bangladesh",
    "Jequie, Brazil",
    "Plock, Poland",
    "Walbrzych, Poland",
    "Yevlakh, Azerbaijan",
    "Al Manzalah, Egypt",
    "Wau, South Sudan",
    "Xai-Xai, Mozambique",
    "Soedermalm, Sweden",
    "Athens, United States",
    "Amaigbo, Nigeria",
    "Topeka, United States",
    "Chiang Mai, Thailand",
    "Lafia, Nigeria",
    "Jiangyou, China",
    "Tando Allahyar, Pakistan",
    "Tengyue, China",
    "Isiro, Congo",
    "Xiaogang, China",
    "Sancti Spiritus, Cuba",
    "Kadapa, India",
    "Bettiah, India",
    "Kent, United States",
    "Pakokku, Myanmar",
    "Murom, Russia",
    "Daska Kalan, Pakistan",
    "Hathras, India",
    "Klaten, Indonesia",
    "Khasavyurt, Russia",
    "Neftekamsk, Russia",
    "Villaverde, Spain",
    "Simi Valley, United States",
    "Cadiz, Spain",
    "Santa Cruz, Philippines",
    "La Victoria, Venezuela",
    "Pakpattan, Pakistan",
    "Jingzhi, China",
    "Danshui, China",
    "Bahawalnagar, Pakistan",
    "Bahawalnagar, Pakistan",
    "Solihull, United Kingdom",
    "East Los Angeles, United States",
    "Lalitpur, India",
    "Jombang, Indonesia",
    "Songea, Tanzania",
    "Kolar, India",
    "Nakhon Ratchasima, Thailand",
    "Chinandega, Nicaragua",
    "Al Jadid, Libyan Arab Jamahiriya",
    "Lambare, Paraguay",
    "Macheng, China",
    "Rafah, West Bank and Gaza Strip",
    "Ampang, Malaysia",
    "Itapetininga, Brazil",
    "Patos de Minas, Brazil",
    "Santa Clara, United States",
    "Paseh, Indonesia",
    "Murfreesboro, United States",
    "Retiro, Spain",
    "Sunset Park, United States",
    "Huicheng, China",
    "Puerto Ayacucho, Venezuela",
    "Gashua, Nigeria",
    "Les Cayes, Haiti",
    "Salerno, Italy",
    "Rosetta, Egypt",
    "Savannakhet, Lao People's Dem. Rep.",
    "Munuf, Egypt",
    "Watford, United Kingdom",
    "Giresun, Turkey",
    "Xiazhen, China",
    "Kombolcha, Ethiopia",
    "Torbat-e Heydariyeh, Iran",
    "Kastamonu, Turkey",
    "Tando Adam, Pakistan",
    "'Ajlun, Jordan",
    "Derince, Turkey",
    "Okinawa, Japan",
    "Los Angeles, Chile",
    "Haimen, China",
    "Karabuek, Turkey",
    "Tsuruoka, Japan",
    "Saint Peters, United Kingdom",
    "Orsha, Belarus",
    "Abilene, United States",
    "Shahecheng, China",
    "Braganca Paulista, Brazil",
    "Bohicon, Benin",
    "Willemstad, ",
    "Balti, Moldova, Republic of",
    "Tyoply Stan, Russia",
    "Az Zulfi, Saudi Arabia",
    "Soran, Iraq",
    "Mandsaur, India",
    "Bet Shemesh, Israel",
    "Gondia, India",
    "Norman, United States",
    "Huixquilucan, Mexico",
    "Semnan, Iran",
    "San Francisco de Macoris, Dominican Republic",
    "Yushu, China",
    "Yuanlin, Taiwan, China",
    "Santa Tecla, El Salvador",
    "Koidu, Sierra Leone",
    "Ciudad Valles, Mexico",
    "Khairpur Mir's, Pakistan",
    "Ouahigouya, Burkina Faso",
    "Kashihara-shi, Japan",
    "Ashmun, Egypt",
    "Ben Tre, Viet Nam",
    "Gorzow Wielkopolski, Poland",
    "Timon, Brazil",
    "Monza, Italy",
    "New Mirpur, Pakistan",
    "Agadez, Niger",
    "Koreatown, United States",
    "Targowek, Poland",
    "Lecheng, China",
    "Sao Jose dos Pinhais, Brazil",
    "Nancun, China",
    "Marand, Iran",
    "Rajapalaiyam, India",
    "Rustenburg, South Africa",
    "As Sinbillawayn, Egypt",
    "Rudnyy, Kazakhstan",
    "Magangue, Colombia",
    "Teresopolis, Brazil",
    "Metz, France",
    "Maxixe, Mozambique",
    "Jiupu, China",
    "Darjiling, India",
    "Baoshan, China",
    "Salvaleon de Higueey, Dominican Republic",
    "Polokwane, South Africa",
    "Thaton, Myanmar",
    "Budapest III. keruelet, Hungary",
    "Potchefstroom, South Africa",
    "Lumajang, Indonesia",
    "Guelma, Algeria",
    "Ise, Japan",
    "Hagonoy, Philippines",
    "Mohali, India",
    "Uruguaiana, Brazil",
    "Caraguatatuba, Brazil",
    "Palanpur, India",
    "Indramayu, Indonesia",
    "Epworth, Zimbabwe",
    "Bordj el Kiffan, Algeria",
    "Jalal-Abad, Kyrgyzstan",
    "Kirishima, Japan",
    "Mackay, Australia",
    "Porto Seguro, Brazil",
    "Luoyang, China",
    "Parung, Indonesia",
    "Wolfsburg, Germany",
    "Niihama, Japan",
    "Jiaohe, China",
    "Tsaritsyno, Russia",
    "Tatui, Brazil",
    "Dos Hermanas, Spain",
    "Bayawan, Philippines",
    "Dam Dam, India",
    "Alagoinhas, Brazil",
    "Az Zubayr, Iraq",
    "Schoeneberg, Germany",
    "Beppu, Japan",
    "Moron, Argentina",
    "Hardoi, India",
    "San Pedro Garza Garcia, Mexico",
    "Itatiba, Brazil",
    "Huangzhou, China",
    "Sheepshead Bay, United States",
    "Puruliya, India",
    "Ubon Ratchathani, Thailand",
    "Virginia, South Africa",
    "Brits, South Africa",
    "Beylikduezue, Turkey",
    "Recklinghausen, Germany",
    "Xiulin, China",
    "Zhoucun, China",
    "Cherkessk, Russia",
    "Maastricht, Netherlands",
    "Amherst, United States",
    "My Tho, Viet Nam",
    "Tekirdag, Turkey",
    "Victorville, United States",
    "Barbacena, Brazil",
    "Burton upon Trent, United Kingdom",
    "Chishtian, Pakistan",
    "Dibrugarh, India",
    "Goettingen, Germany",
    "Phasi Charoen, Thailand",
    "Durres, Albania",
    "Presnenskiy, Russia",
    "Veshnyaki, Russia",
    "Zabol, Iran",
    "Kaya, Burkina Faso",
    "Palwal, India",
    "Xiangcheng, China",
    "Colchester, United Kingdom",
    "Nalgonda, India",
    "Carora, Venezuela",
    "Mataro, Spain",
    "Vallejo, United States",
    "Bhadreswar, India",
    "Bern, Switzerland",
    "Vejalpur, India",
    "Longshui, China",
    "Siracusa, Italy",
    "Kuopio, Finland",
    "Alberton, South Africa",
    "Magelang, Indonesia",
    "Gracia, Spain",
    "Chikmagalur, India",
    "Ekibastuz, Kazakhstan",
    "San Juan, Philippines",
    "Braga, Portugal",
    "Lianhe, China",
    "Chico, United States",
    "Raigarh, India",
    "North Stamford, United States",
    "Bergamo, Italy",
    "Musoma, Tanzania",
    "Cachoeirinha, Brazil",
    "Hartford, United States",
    "Tobruk, Libyan Arab Jamahiriya",
    "Bukittinggi, Indonesia",
    "Zyuzino, Russia",
    "Ejido, Venezuela",
    "Berkeley, United States",
    "Xilin Hot, China",
    "Guntakal Junction, India",
    "Fresnillo, Mexico",
    "Orizaba, Mexico",
    "Ikoma, Japan",
    "Heilbronn, Germany",
    "Trento, Italy",
    "Ingolstadt, Germany",
    "Lichuan, China",
    "Bangil, Indonesia",
    "Toyokawa, Japan",
    "Fengcheng, China",
    "Mufulira, Zambia",
    "Ulm, Germany",
    "Rawang, Malaysia",
    "Agri, Turkey",
    "San Carlos, Venezuela",
    "Wloclawek, Poland",
    "Allentown, United States",
    "Fernando de la Mora, Paraguay",
    "Perugia, Italy",
    "Gangapur, India",
    "Lahti, Finland",
    "Zhaoyuan, China",
    "Deoli, India",
    "Abbottabad, Pakistan",
    "Orekhovo-Zuyevo, Russia",
    "Cabudwaaq, Somalia",
    "Rustaq, Oman",
    "Solntsevo, Russia",
    "Nabatiye et Tahta, Lebanon",
    "Gereida, Sudan",
    "Madhyapur Thimi, Nepal",
    "Evansville, United States",
    "Bottrop, Germany",
    "Santa Rita, Brazil",
    "Kwekwe, Zimbabwe",
    "Rize, Turkey",
    "Kairouan, Tunisia",
    "Jaranwala, Pakistan",
    "Salto, Brazil",
    "Leiden, Netherlands",
    "Trois-Rivieres, Canada",
    "Ajax, Canada",
    "Bergedorf, Germany",
    "Pescara, Italy",
    "Pattaya, Thailand",
    "Modakeke, Nigeria",
    "Nobeoka, Japan",
    "Silang, Philippines",
    "Nazilli, Turkey",
    "Daito, Japan",
    "Lira, Uganda",
    "Toledo, Brazil",
    "Pforzheim, Germany",
    "Dordrecht, Netherlands",
    "Battambang, Cambodia",
    "Jau, Brazil",
    "Offenbach, Germany",
    "Siwan, India",
    "Debre Tabor, Ethiopia",
    "Kitami, Japan",
    "Columbia, United States",
    "Settat, Morocco",
    "Cagua, Venezuela",
    "Odessa, United States",
    "Mandimba, Mozambique",
    "Reykjavik, Iceland",
    "Huaraz, Peru",
    "Santa Coloma de Gramenet, Spain",
    "San Jose, Philippines",
    "Damoh, India",
    "Shimotoda, Japan",
    "Lhasa, China",
    "Ain Oussera, Algeria",
    "Kokubunji, Japan",
    "Xunchang, China",
    "Miramar, Mexico",
    "Nanbin, China",
    "Khlong Luang, Thailand",
    "Adilabad, India",
    "Fargo, United States",
    "Brugge, Belgium",
    "Iguala de la Independencia, Mexico",
    "Sepatan, Indonesia",
    "Zielona Gora, Poland",
    "Cubatao, Brazil",
    "Maseru, Lesotho",
    "Srikakulam, India",
    "Dabrowa Gornicza, Poland",
    "Malindi, Kenya",
    "Uppal Kalan, India",
    "Maghaghah, Egypt",
    "Eastbourne, United Kingdom",
    "Bandundu, Congo",
    "Pita Kotte, Sri Lanka",
    "Torrejon de Ardoz, Spain",
    "Bama, Nigeria",
    "Ilobu, Nigeria",
    "Ciudad Delicias, Mexico",
    "Jetpur, India",
    "Morbi, India",
    "San Rafael, Argentina",
    "Troparevo, Russia",
    "Nakhon Pathom, Thailand",
    "Kakegawa, Japan",
    "Manfalut, Egypt",
    "Wushan, China",
    "Patan, India",
    "Friedrichshain Bezirk, Germany",
    "Sokode, Togo",
    "Tarnow, Poland",
    "Jalingo, Nigeria",
    "Nyiregyhaza, Hungary",
    "Sano, Japan",
    "Karatsu, Japan",
    "Gemena, Congo",
    "Achinsk, Russia",
    "Shunyi, China",
    "Rotherham, United Kingdom",
    "Ciudad de Villa de Alvarez, Mexico",
    "Banyuwangi, Indonesia",
    "Tebingtinggi, Indonesia",
    "Tigwav, Haiti",
    "Banfora, Burkina Faso",
    "Punta Arenas, Chile",
    "Pyin Oo Lwin, Myanmar",
    "Independence, United States",
    "Kedungwuni, Indonesia",
    "Longjing, China",
    "Khanna, India",
    "Remscheid, Germany",
    "Setubal, Portugal",
    "Cacak, Serbia",
    "Ann Arbor, United States",
    "Nkongsamba, Cameroon",
    "Kindia, Guinea",
    "Botad, India",
    "Rishra, India",
    "Khenchela, Algeria",
    "Pinhais, Brazil",
    "Kyzyl, Russia",
    "Faqus, Egypt",
    "Hofu, Japan",
    "Soreang, Indonesia",
    "Manp'o, Korea, Dem. People's Rep. of",
    "El Monte, United States",
    "Forli, Italy",
    "Simoes Filho, Brazil",
    "Azamgarh, India",
    "Ahmadpur East, Pakistan",
    "Co GJo, Viet Nam",
    "Varginha, Brazil",
    "Springfield, United States",
    "Taldykorgan, Kazakhstan",
    "Moncloa-Aravaca, Spain",
    "Gulin, China",
    "Yelahanka, India",
    "Cheltenham, United Kingdom",
    "Chenggu, China",
    "Harlem, United States",
    "Westminster, United States",
    "Dasha, China",
    "Kadoma, Zimbabwe",
    "Kawm Umbu, Egypt",
    "Yakeshi, China",
    "Orleans, France",
    "Algeciras, Spain",
    "Ballarat, Australia",
    "Adigrat, Ethiopia",
    "Gudivada, India",
    "Mariara, Venezuela",
    "Digos, Philippines",
    "Handa, Japan",
    "Sinop, Brazil",
    "Taganskiy, Russia",
    "Round Rock, United States",
    "Noginsk, Russia",
    "Tinaquillo, Venezuela",
    "Wilmington, United States",
    "Cavite City, Philippines",
    "East Harlem, United States",
    "Tandil, Argentina",
    "Zoetermeer, Netherlands",
    "Sri Jayewardenepura Kotte, Sri Lanka",
    "Nasushiobara, Japan",
    "Baisha, China",
    "Guelph, Canada",
    "Bani Mazar, Egypt",
    "Yelets, Russia",
    "Parla, Spain",
    "Anliu, China",
    "Novo-Peredelkino, Russia",
    "Baidyabati, India",
    "Okigwe, Nigeria",
    "Uzhgorod, Ukraine",
    "Lengshuijiang, China",
    "Tianfu, China",
    "Arvada, United States",
    "Otaru, Japan",
    "Vacoas, Mauritius",
    "Beaumont, United States",
    "Bizerte, Tunisia",
    "Provo, United States",
    "Pouso Alegre, Brazil",
    "Sanxia, Taiwan, China",
    "Basti, India",
    "Peoria, United States",
    "Lansing, United States",
    "Amasya, Turkey",
    "Mendoza, Argentina",
    "Balkh, Afghanistan",
    "Mingshui, China",
    "Botosani, Romania",
    "Carlsbad, United States",
    "Qiaotou, China",
    "Mary, Turkmenistan",
    "Ichinoseki, Japan",
    "Khon Kaen, Thailand",
    "Iriga City, Philippines",
    "Soc Trang, Viet Nam",
    "Balasore, India",
    "Guadalajara de Buga, Colombia",
    "Pamanukan, Indonesia",
    "Eunapolis, Brazil",
    "Ambovombe, Madagascar",
    "Pleiku, Viet Nam",
    "Downey, United States",
    "Kingston, Canada",
    "Yuyao, China",
    "Tung Chung, Hong Kong, China",
    "Dharmavaram, India",
    "Siirt, Turkey",
    "Kankan, Guinea",
    "Cuauhtemoc, Mexico",
    "Ochakovo-Matveyevskoye, Russia",
    "Villa Lugano, Argentina",
    "Punta Cardon, Venezuela",
    "Laghouat, Algeria",
    "Maler Kotla, India",
    "Navojoa, Mexico",
    "Offa, Nigeria",
    "Tobolsk, Russia",
    "Wardha, India",
    "Wansheng, China",
    "Wayaobu, China",
    "Pingshan, China",
    "Cuito, Angola",
    "Songyuan, China",
    "Maba, China",
    "Jandira, Brazil",
    "Doncaster, United Kingdom",
    "Binh Thuy, Viet Nam",
    "Bremerhaven, Germany",
    "Dumaguete, Philippines",
    "Budapest XIII. keruelet, Hungary",
    "Nippes, Germany",
    "Jaen, Spain",
    "Chorzow, Poland",
    "Porz am Rhein, Germany",
    "Luanshya, Zambia",
    "Praia, Cape Verde",
    "Elmhurst, United States",
    "Kamsar, Guinea",
    "Yaritagua, Venezuela",
    "Santa Maria, Argentina",
    "Costa Mesa, United States",
    "Jhunjhunun, India",
    "Miami Gardens, United States",
    "Heroica Guaymas, Mexico",
    "Chesterfield, United Kingdom",
    "North Peoria, United States",
    "Clearwater, United States",
    "Shchyolkovo, Russia",
    "Al Fashn, Egypt",
    "Fairfield, United States",
    "Alanya, Turkey",
    "Guanabacoa, Cuba",
    "Akishima, Japan",
    "Vihari, Pakistan",
    "Taonan, China",
    "Satara, India",
    "Kazo, Japan",
    "Santa Lucia Cotzumalguapa, Guatemala",
    "Haikou, China",
    "Chanduasi, India",
    "Nefteyugansk, Russia",
    "Reutlingen, Germany",
    "Bushwick, United States",
    "Upata, Venezuela",
    "Shaowu, China",
    "Mengmao, China",
    "Lower Hutt, New Zealand",
    "Satu Mare, Romania",
    "Thanh Hoa, Viet Nam",
    "Kaiyuan, China",
    "Kamalia, Pakistan",
    "Kisii, Kenya",
    "Mazyr, Belarus",
    "Guanare, Venezuela",
    "Wuzhishan, China",
    "Gravesend, United States",
    "Rochester, United States",
    "Faridpur, Bangladesh",
    "Apopa, El Salvador",
    "Mothihari, India",
    "Chas, India",
    "Pudukkottai, India",
    "Elgin, United States",
    "Dharashiv, India",
    "Rewari, India",
    "Xigang, China",
    "Port Blair, India",
    "Minatitlan, Mexico",
    "Esuk Oron, Nigeria",
    "Furth, Germany",
    "Paulinia, Brazil",
    "Vicenza, Italy",
    "Ciudad Guzman, Mexico",
    "West Jordan, United States",
    "Ca Mau, Viet Nam",
    "Ribeirao Pires, Brazil",
    "Duma, Syria",
    "Iringa, Tanzania",
    "Zwolle, Netherlands",
    "Novokuybyshevsk, Russia",
    "Abnub, Egypt",
    "Quchan, Iran",
    "Kuningan, Indonesia",
    "Suriapet, India",
    "Kandy, Sri Lanka",
    "Zefta, Egypt",
    "Bangaon, India",
    "Inglewood, United States",
    "Kilis, Turkey",
    "Conselheiro Lafaiete, Brazil",
    "Chhatarpur, India",
    "Terrebonne, Canada",
    "Daugavpils, Latvia",
    "Resende, Brazil",
    "Chelmsford, United Kingdom",
    "Masjed Soleyman, Iran",
    "Marzahn, Germany",
    "Chiquimula, Guatemala",
    "Cileunyi, Indonesia",
    "Ashoknagar Kalyangarh, India",
    "Mulhouse, France",
    "Beau Bassin-Rose Hill, Mauritius",
    "Sogamoso, Colombia",
    "Mbouda, Cameroon",
    "Araucaria, Brazil",
    "Achalpur, India",
    "Montreuil, France",
    "Terni, Italy",
    "Navadwip, India",
    "Nsukka, Nigeria",
    "Nguru, Nigeria",
    "Dongtai, China",
    "Barsi, India",
    "Mabopane, South Africa",
    "Namur, Belgium",
    "Varzea Paulista, Brazil",
    "Hikone, Japan",
    "Encheng, China",
    "Turhal, Turkey",
    "Bandar-e Anzali, Iran",
    "Richardson, United States",
    "Bokhtar, Tajikistan",
    "Hadejia, Nigeria",
    "Zhuji, China",
    "Perpignan, France",
    "Lowell, United States",
    "East Independence, United States",
    "Caen, France",
    "Gresham, United States",
    "Yenangyaung, Myanmar",
    "Antioch, United States",
    "Manzini, Swaziland",
    "St. John's, Canada",
    "Delicias, Spain",
    "Kfar Saba, Israel",
    "Cambridge, United States",
    "Sultanpur, India",
    "High Point, United States",
    "Billings, United States",
    "Datun, China",
    "Manchester, United States",
    "Geylang, Singapore",
    "Kresek, Indonesia",
    "Bender, Moldova, Republic of",
    "Nelspruit, South Africa",
    "Rodenkirchen, Germany",
    "Garanhuns, Brazil",
    "Gabes, Tunisia",
    "Qingnian, China",
    "Temecula, United States",
    "An Nu'maniyah, Iraq",
    "Noyabrsk, Russia",
    "Mendip, United Kingdom",
    "Kufa, Iraq",
    "Ressano Garcia, Mozambique",
    "Zliten, Libyan Arab Jamahiriya",
    "Bataysk, Russia",
    "Pisa, Italy",
    "Linshui, China",
    "Hailun, China",
    "Ambur, India",
    "Kecskemet, Hungary",
    "Seversk, Russia",
    "Ciamis, Indonesia",
    "Murrieta, United States",
    "Brovary, Ukraine",
    "Wakefield, United Kingdom",
    "Centennial, United States",
    "Shilong, China",
    "Richmond, United States",
    "Kandi, Benin",
    "Corona, United States",
    "Thoi Lai, Viet Nam",
    "Araruama, Brazil",
    "Tieli, China",
    "Catanduva, Brazil",
    "Didao, China",
    "Pelabuhanratu, Indonesia",
    "Habikino, Japan",
    "Arzamas, Russia",
    "Phalaborwa, South Africa",
    "Heihe, China",
    "Walthamstow, United Kingdom",
    "Pueblo, United States",
    "Narmadapuram, India",
    "Larache, Morocco",
    "Ijebu-Igbo, Nigeria",
    "Sergiyev Posad, Russia",
    "Livingstone, Zambia",
    "Port-Gentil, Gabon",
    "San Juan, Argentina",
    "Jianchang, China",
    "Hulan, China",
    "Matagalpa, Nicaragua",
    "Leninsk-Kuznetsky, Russia",
    "Kiryu, Japan",
    "Zhongxiang, China",
    "Franco da Rocha, Brazil",
    "Iwatsuki, Japan",
    "Pearland, United States",
    "Dehui, China",
    "Waterbury, United States",
    "Boulogne-Billancourt, France",
    "Kalisz, Poland",
    "Ksar El Kebir, Morocco",
    "El Vigia, Venezuela",
    "Moncton, Canada",
    "Sawai Madhopur, India",
    "Uromi, Nigeria",
    "Madanapalle, India",
    "Katumba, Tanzania",
    "Komatsu, Japan",
    "West Covina, United States",
    "Enterprise, United States",
    "Bansbaria, India",
    "Giron, Colombia",
    "Mopti, Mali",
    "Cuautitlan, Mexico",
    "Baghlan, Afghanistan",
    "Hanjia, China",
    "Trincomalee, Sri Lanka",
    "Santiago, Philippines",
    "Taibai, China",
    "Alto Hospicio, Chile",
    "Dagenham, United Kingdom",
    "Xuyong, China",
    "North Charleston, United States",
    "Sohar, Oman",
    "Nehe, China",
    "Cabo Frio, Brazil",
    "Oktyabrsky, Russia",
    "Tadepallegudem, India",
    "Birnin Kebbi, Nigeria",
    "Santa Cruz, Philippines",
    "Everett, United States",
    "An Nuhud, Sudan",
    "Navapolatsk, Belarus",
    "Rijeka, Croatia",
    "Atbara, Sudan",
    "Bac Lieu, Viet Nam",
    "Thunder Bay, Canada",
    "College Station, United States",
    "Palm Bay, United States",
    "As Salt, Jordan",
    "Mishima, Japan",
    "Jalpaiguri, India",
    "Jalai Nur, China",
    "Tajimi, Japan",
    "Pompano Beach, United States",
    "Ji Parana, Brazil",
    "Basingstoke, United Kingdom",
    "Mandurah, Australia",
    "Bandirma, Turkey",
    "Maidstone, United Kingdom",
    "Umm Qasr, Iraq",
    "Butterworth, Malaysia",
    "Shaping, China",
    "Ramnicu Valcea, Romania",
    "Alcobendas, Spain",
    "Araras, Brazil",
    "Koszalin, Poland",
    "South Fulton, United States",
    "Bolzano, Italy",
    "Poa, Brazil",
    "Sherpur, Bangladesh",
    "Obninsk, Russia",
    "Vitoria de Santo Antao, Brazil",
    "Shinyanga, Tanzania",
    "Boulder, United States",
    "Manokwari, Indonesia",
    "Girardot City, Colombia",
    "Umuarama, Brazil",
    "Koblenz, Germany",
    "Siegen, Germany",
    "As Suwayq, Oman",
    "Norwalk, United States",
    "Reus, Spain",
    "Ilebo, Congo",
    "Apucarana, Brazil",
    "Yen Vinh, Viet Nam",
    "Mitrovice, ",
    "Sutton Coldfield, United Kingdom",
    "Pati, Indonesia",
    "Khemisset, Morocco",
    "Sloviansk, Ukraine",
    "Elista, Russia",
    "Taungoo, Myanmar",
    "Bedford, United Kingdom",
    "Bagong Silangan, Philippines",
    "Catia La Mar, Venezuela",
    "Anning, China",
    "West Palm Beach, United States",
    "Rayong, Thailand",
    "Chittaurgarh, India",
    "Salihorsk, Belarus",
    "Fukayacho, Japan",
    "Cheremushki, Russia",
    "Coimbra, Portugal",
    "Broken Arrow, United States",
    "Daly City, United States",
    "Cikarang, Indonesia",
    "Longjiang, China",
    "Hwado, South Korea",
    "Buhe, China",
    "Pindiga, Nigeria",
    "Berdyansk, Ukraine",
    "Gangawati, India",
    "Yevpatoriya, Ukraine",
    "Novotroitsk, Russia",
    "Bergisch Gladbach, Germany",
    "Santa Cruz do Sul, Brazil",
    "Alchevsk, Ukraine",
    "Mingelchaur, Azerbaijan",
    "Legnica, Poland",
    "Sydney, Canada",
    "Derbent, Russia",
    "Xinghua, China",
    "Khurja, India",
    "Mati, Philippines",
    "Guaratingueta, Brazil",
    "Arjawinangun, Indonesia",
    "Suceava, Romania",
    "Ain Beida, Algeria",
    "Azare, Nigeria",
    "Nantou, Taiwan, China",
    "Lahad Datu, Malaysia",
    "Ponnani, India",
    "Tanza, Philippines",
    "Inzai, Japan",
    "Bhairab Bazar, Bangladesh",
    "Chengtangcun, China",
    "Abu Tij, Egypt",
    "Coatepeque, Guatemala",
    "Baraki, Algeria",
    "Sandy Springs, United States",
    "Burbank, United States",
    "Queenstown, South Africa",
    "Hoa Binh, Viet Nam",
    "Ourense, Spain",
    "Green Bay, United States",
    "Jizan, Saudi Arabia",
    "Texcoco de Mora, Mexico",
    "Nikopol, Ukraine",
    "Santa Maria, United States",
    "Assis, Brazil",
    "San Francisco De Borja, Peru",
    "Linhares, Brazil",
    "Yidu, China",
    "Nancy, France",
    "Araguaina, Brazil",
    "Barnala, India",
    "Zhonghe, China",
    "Universal City, United States",
    "Puyang Chengguanzhen, China",
    "Waterloo, Canada",
    "Chinautla, Guatemala",
    "Moratalaz, Spain",
    "Daxing, China",
    "Yezhou, China",
    "Parral, Mexico",
    "Baneh, Iran",
    "Douliu, Taiwan, China",
    "Jena, Germany",
    "Wichita Falls, United States",
    "Gera, Germany",
    "Badlapur, India",
    "Ottakring, Austria",
    "Al Faw, Iraq",
    "Depok, Indonesia",
    "Mostar, Bosnia and Herzegovina",
    "Lakeland, United States",
    "Zhezqazghan, Kazakhstan",
    "Pailou, China",
    "Lo Prado, Chile",
    "Pulandian, China",
    "Helsingborg, Sweden",
    "Kot Addu, Pakistan",
    "Kara, Togo",
    "Clovis, United States",
    "Wuchuan, China",
    "Lewisville, United States",
    "Iwakuni, Japan",
    "Izmaylovo, Russia",
    "Soyibug, India",
    "Kiselevsk, Russia",
    "Kroonstad, South Africa",
    "Jishu, China",
    "Seguela, Côte d'Ivoire",
    "Totonicapan, Guatemala",
    "Ed Damer, Sudan",
    "Woking, United Kingdom",
    "Dolisie, Congo",
    "Roorkee, India",
    "Salzgitter, Germany",
    "Bagaha, India",
    "Yuxi, China",
    "Lincoln, United Kingdom",
    "Bashan, China",
    "Nkpor, Nigeria",
    "Tyler, United States",
    "Male, Maldives",
    "El Cajon, United States",
    "Piacenza, Italy",
    "Gardez, Afghanistan",
    "Zhanaozen, Kazakhstan",
    "Gangu Chengguanzhen, China",
    "San Mateo, United States",
    "Churu, India",
    "Nagda, India",
    "Moers, Germany",
    "Brandon, United States",
    "Prabumulih, Indonesia",
    "Isehara, Japan",
    "Queluz, Portugal",
    "Akademicheskoe, Russia",
    "Turgutlu, Turkey",
    "Saint-Paul, Réunion",
    "Esmeraldas, Brazil",
    "Velikiye Luki, Russia",
    "Kashipur, India",
    "Rialto, United States",
    "Itanhaem, Brazil",
    "Ghazipur, India",
    "Tanjung Pandan, Indonesia",
    "Begusarai, India",
    "Ikere-Ekiti, Nigeria",
    "Hildesheim, Germany",
    "Bendigo, Australia",
    "Rantauprapat, Indonesia",
    "Drammen, Norway",
    "Amaravati, India",
    "Bogorodskoye, Russia",
    "Lahan, Nepal",
    "Liberec, Czech Republic",
    "Qurayyat, Saudi Arabia",
    "Portmore, Jamaica",
    "Santa Rosa, Argentina",
    "Palma Soriano, Cuba",
    "Pushkino, Russia",
    "Khushab, Pakistan",
    "Lafiagi, Nigeria",
    "Hengelo, Netherlands",
    "Kuqa, China",
    "Lyon 03, France",
    "Belawan, Indonesia",
    "Lida, Belarus",
    "Piatra Neamt, Romania",
    "Roxas City, Philippines",
    "Cabudare, Venezuela",
    "Erlangen, Germany",
    "Tepexpan, Mexico",
    "Davenport, United States",
    "Dayrut, Egypt",
    "Edison, United States",
    "Lubango, Angola",
    "Sarh, Chad",
    "Dhaulpur, India",
    "Wazirabad, Pakistan",
    "Curico, Chile",
    "Concepcion de La Vega, Dominican Republic",
    "Shahzadpur, Bangladesh",
    "Tulancingo, Mexico",
    "Bemowo, Poland",
    "Yono, Japan",
    "Merauke, Indonesia",
    "Ciutat Vella, Spain",
    "Hillsboro, United States",
    "Drobeta-Turnu Severin, Romania",
    "Mormugao, India",
    "Mitte, Germany",
    "Sabanalarga, Colombia",
    "Qianzhou, China",
    "Artem, Russia",
    "Tsuyama, Japan",
    "Birigui, Brazil",
    "Ukhta, Russia",
    "Nakhon Si Thammarat, Thailand",
    "Xiangjiaba, China",
    "Kani, Japan",
    "Inazawa, Japan",
    "Ferozepore, India",
    "Laoag, Philippines",
    "Sabratah, Libyan Arab Jamahiriya",
    "Brateyevo, Russia",
    "Al Jumayl, Libyan Arab Jamahiriya",
    "Shchukino, Russia",
    "Mairipora, Brazil",
    "Novara, Italy",
    "Pulong Santa Cruz, Philippines",
    "Wilmersdorf, Germany",
    "Hedong, China",
    "Tuxtepec, Mexico",
    "Gondal, India",
    "Torrevieja, Spain",
    "Zonguldak, Turkey",
    "Igdir, Turkey",
    "Delta, Canada",
    "Parsabad, Iran",
    "Worcester, United Kingdom",
    "Las Cruces, United States",
    "Dera Ismail Khan, Pakistan",
    "Venlo, Netherlands",
    "Szekesfehervar, Hungary",
    "Bath, United Kingdom",
    "Iida, Japan",
    "South Bend, United States",
    "Kansk, Russia",
    "Argenteuil, France",
    "Khanty-Mansiysk, Russia",
    "Pavlohrad, Ukraine",
    "Simmering, Austria",
    "Mustafakemalpasa, Turkey",
    "Malappuram, India",
    "Seoni, India",
    "Dartmouth, Canada",
    "Jagadhri, India",
    "Maladziecna, Belarus",
    "Sangju, South Korea",
    "Witten, Germany",
    "Mirialguda, India",
    "Barretos, Brazil",
    "Medina Estates, Ghana",
    "Chakwal, Pakistan",
    "Colatina, Brazil",
    "Gillingham, United Kingdom",
    "Teofilo Otoni, Brazil",
    "Huacheng, China",
    "Ocana, Colombia",
    "Daoukro, Côte d'Ivoire",
    "Tokuyama, Japan",
    "Tima, Egypt",
    "Donghae City, South Korea",
    "Vanadzor, Armenia",
    "Mokopane, South Africa",
    "New Bedford, United States",
    "Udgir, India",
    "Urdaneta, Philippines",
    "Leuven, Belgium",
    "Mezhdurechensk, Russia",
    "Guaiba, Brazil",
    "Jolo, Philippines",
    "Novyye Cheremushki, Russia",
    "Ryazanskiy, Russia",
    "Pleven, Bulgaria",
    "Guarapari, Brazil",
    "Coronel Fabriciano, Brazil",
    "Elbasan, Albania",
    "Vista, United States",
    "Greeley, United States",
    "Davie, United States",
    "Funchal, Portugal",
    "Oum el Bouaghi, Algeria",
    "Solikamsk, Russia",
    "El Progreso, Honduras",
    "Chirmiri, India",
    "Jamalpur, India",
    "Itaguai, Brazil",
    "Sungailiat, Indonesia",
    "Arezzo, Italy",
    "Nandurbar, India",
    "Glazov, Russia",
    "Anyama, Côte d'Ivoire",
    "Narita, Japan",
    "Kallithea, Greece",
    "Juan Diaz, Panama",
    "Grogol, Indonesia",
    "Puerto Barrios, Guatemala",
    "Itarsi, India",
    "Chinatown, United States",
    "Los Rastrojos, Venezuela",
    "Vasco da Gama, India",
    "San Angelo, United States",
    "Chikushino-shi, Japan",
    "Lianjiang, China",
    "Klagenfurt am Woerthersee, Austria",
    "Linxi, China",
    "Tiebo, Senegal",
    "Ust'-Ilimsk, Russia",
    "Renton, United States",
    "Kuhdasht, Iran",
    "Kalmunai, Sri Lanka",
    "Udine, Italy",
    "Xinzhai, China",
    "Phagwara, India",
    "Uman, Ukraine",
    "Trier, Germany",
    "Punta Cana, Dominican Republic",
    "Telde, Spain",
    "Colonia Lindavista, Mexico",
    "Rio das Ostras, Brazil",
    "Alamar, Cuba",
    "Becontree, United Kingdom",
    "M'sila, Algeria",
    "Jaffa, Israel",
    "Subotica, Serbia",
    "Sumedang Utara, Indonesia",
    "P'yongsong, Korea, Dem. People's Rep. of",
    "Tajura', Libyan Arab Jamahiriya",
    "Airoli, India",
    "Kangding, China",
    "Sabt Alalayah, Saudi Arabia",
    "Tekstil'shchiki, Russia",
    "Qincheng, China",
    "Chikusei, Japan",
    "Menghuan, China",
    "Roanoke, United States",
    "Xixiang, China",
    "Kenosha, United States",
    "Salto, Uruguay",
    "Chanthaburi, Thailand",
    "Dongsheng, China",
    "Andria, Italy",
    "Tucupita, Venezuela",
    "Clinton Township, United States",
    "Amreli, India",
    "Shikohabad, India",
    "Villa de Cura, Venezuela",
    "Columbia, United States",
    "Zelenodolsk, Russia",
    "Dhahran, Saudi Arabia",
    "Manbij, Syria",
    "Olomouc, Czech Republic",
    "Itabira, Brazil",
    "Messaad, Algeria",
    "Novoshakhtinsk, Russia",
    "Ikeda, Japan",
    "Erie, United States",
    "Kasganj, India",
    "Siauliai, Lithuania",
    "Meihekou, China",
    "Votorantim, Brazil",
    "Santander de Quilichao, Colombia",
    "Koutiala, Mali",
    "Xinle, China",
    "Acharnes, Greece",
    "Sakata, Japan",
    "Sehore, India",
    "Burao, Somalia",
    "Bam, Iran",
    "Manavgat, Turkey",
    "Sertaozinho, Brazil",
    "Saku, Japan",
    "Worthing, United Kingdom",
    "Ranibennur, India",
    "Bhola, Bangladesh",
    "Talara, Peru",
    "Riberalta, Bolivia",
    "Linghe, China",
    "Sievierodonetsk, Ukraine",
    "Zarate, Argentina",
    "Portsmouth Heights, United States",
    "Jagtial, India",
    "Apatzingan, Mexico",
    "Santana de Parnaiba, Brazil",
    "Richmond Hill, United States",
    "Xingcheng, China",
    "Bage, Brazil",
    "Barika, Algeria",
    "Izumisano, Japan",
    "Sarapul, Russia",
    "Roubaix, France",
    "Zwickau, Germany",
    "Kontagora, Nigeria",
    "Passos, Brazil",
    "Kaiserslautern, Germany",
    "Alief, United States",
    "Thimphu, Bhutan",
    "Tourcoing, France",
    "Bocaue, Philippines",
    "Bhadrakh, India",
    "Votkinsk, Russia",
    "Spring Hill, United States",
    "Slupsk, Poland",
    "Bundi, India",
    "Renqiu, China",
    "Cardenas, Cuba",
    "Bridgetown, Barbados",
    "Albany, United States",
    "Compton, United States",
    "Barakaldo, Spain",
    "Serov, Russia",
    "Habbouch, Lebanon",
    "Phong GJien, Viet Nam",
    "Xinshi, China",
    "Vinh Thanh, Viet Nam",
    "Budapest IV. keruelet, Hungary",
    "Akbarabad, Iran",
    "Tuscaloosa, United States",
    "League City, United States",
    "Flint, United States",
    "Al Qatif, Saudi Arabia",
    "Xinyi, China",
    "Guelmim, Morocco",
    "Lagos de Moreno, Mexico",
    "Chengzihe, China",
    "Thayetmyo, Myanmar",
    "Xishan, China",
    "Allen, United States",
    "Balashov, Russia",
    "Dukuhturi, Indonesia",
    "Fasa, Iran",
    "Mughal Sarai, India",
    "Saint-Jean-sur-Richelieu, Canada",
    "Dar'a, Syria",
    "Shamli, India",
    "Yeyuan, China",
    "Trelew, Argentina",
    "Iserlohn, Germany",
    "Kamianets-Podilskyi, Ukraine",
    "Konan, Japan",
    "Zheleznogorsk, Russia",
    "Santiago de Compostela, Spain",
    "Uba, Brazil",
    "Erdenet, Mongolia",
    "Ourinhos, Brazil",
    "Hyesan-dong, Korea, Dem. People's Rep. of",
    "Temixco, Mexico",
    "Sungai Penuh, Indonesia",
    "Asan, South Korea",
    "Go Cong, Viet Nam",
    "Guanhu, China",
    "Ariana, Tunisia",
    "Meidling, Austria",
    "Jiudian, China",
    "Hammamet, Tunisia",
    "Hinganghat, India",
    "Rochdale, United Kingdom",
    "Comitan, Mexico",
    "Aguachica, Colombia",
    "Trindade, Brazil",
    "Arapongas, Brazil",
    "Jeremie, Haiti",
    "Mbarara, Uganda",
    "Xinxing, China",
    "Herzliya, Israel",
    "Al Muharraq, Bahrain",
    "Pyinmana, Myanmar",
    "Villupuram, India",
    "Amalner, India",
    "Kuje, Nigeria",
    "Lujan, Argentina",
    "Swabi, Pakistan",
    "Hadera, Israel",
    "Deventer, Netherlands",
    "Villa Alemana, Chile",
    "Save, Benin",
    "Bagalkot, India",
    "Lodhran, Pakistan",
    "Tellicherry, India",
    "Zhukovsky, Russia",
    "Narasaraopet, India",
    "Kameoka, Japan",
    "Targu Jiu, Romania",
    "Mission Viejo, United States",
    "Cesena, Italy",
    "Molenbeek-Saint-Jean, Belgium",
    "Fangshan, China",
    "Maribor, Slovenia",
    "Chimaltenango, Guatemala",
    "Magway, Myanmar",
    "Tumbes, Peru",
    "Bekobod, Uzbekistan",
    "Jinniu, China",
    "Nagaur, India",
    "Vacaville, United States",
    "Yasuj, Iran",
    "Villa Mercedes, Argentina",
    "Wabu, South Korea",
    "Ventura, United States",
    "Nowshera Cantonment, Pakistan",
    "Bunia, Congo",
    "Houzhen, China",
    "Nawada, India",
    "Sumber, Indonesia",
    "Highlands Ranch, United States",
    "Lugo, Spain",
    "Sakado, Japan",
    "Lawton, United States",
    "Schwerin, Germany",
    "Azimpur, Bangladesh",
    "Mtwara, Tanzania",
    "Beaverton, United States",
    "Araguari, Brazil",
    "Jaworzno, Poland",
    "Yen Bai, Viet Nam",
    "Corumba, Brazil",
    "Gongguan, China",
    "Chitose, Japan",
    "Esteli, Nicaragua",
    "South Gate, United States",
    "Jahanabad, India",
    "Chalisgaon, India",
    "San Fernando, Spain",
    "Jounieh, Lebanon",
    "Ceyhan, Turkey",
    "Koforidua, Ghana",
    "Shibata, Japan",
    "Portsmouth, United States",
    "Muroran, Japan",
    "Girona, Spain",
    "Guetersloh, Germany",
    "Kashmar, Iran",
    "Limpio, Paraguay",
    "Saint-Denis, France",
    "Khost, Afghanistan",
    "Buxar, India",
    "Dschang, Cameroon",
    "Sparks, United States",
    "Erechim, Brazil",
    "Nanxi, China",
    "General Trias, Philippines",
    "Yola, Nigeria",
    "Ramenskoye, Russia",
    "Japekrom, Ghana",
    "Queenstown Estate, Singapore",
    "Aurangabad, India",
    "Sahibganj, India",
    "Lubu, China",
    "Jastrzebie Zdroj, Poland",
    "Rio Gallegos, Argentina",
    "Los Banos, Philippines",
    "Pandharpur, India",
    "Jinsha, China",
    "Ciudad Rio Bravo, Mexico",
    "Shimada, Japan",
    "Hindaun, India",
    "Las Rozas de Madrid, Spain",
    "Ducheng, China",
    "Bumba, Congo",
    "Burdur, Turkey",
    "Shenjiamen, China",
    "San Carlos de Bariloche, Argentina",
    "La Habana Vieja, Cuba",
    "Charsadda, Pakistan",
    "Brockton, United States",
    "Mons, Belgium",
    "Yotsukaido, Japan",
    "Boli, China",
    "Xiaoshan, China",
    "Saint-Michel de l'Atalaye, Haiti",
    "Xingguo, China",
    "Royal Leamington Spa, United Kingdom",
    "Federal Way, United States",
    "Dearborn, United States",
    "Omura, Japan",
    "Labuan, Malaysia",
    "Japeri, Brazil",
    "Lee's Summit, United States",
    "Delft, Netherlands",
    "Zaxo, Iraq",
    "Ushirombo, Tanzania",
    "Aqaba, Jordan",
    "Grudziadz, Poland",
    "Frederiksberg, Denmark",
    "Kutloanong, South Africa",
    "Biu, Nigeria",
    "Olupona, Nigeria",
    "Romford, United Kingdom",
    "Ozerki, Russia",
    "Novogireyevo, Russia",
    "Vostochnoe Degunino, Russia",
    "Khargone, India",
    "Onojo, Japan",
    "Cerete, Colombia",
    "Spokane Valley, United States",
    "Kanuma, Japan",
    "As Salamiyah, Syria",
    "Parli Vaijnath, India",
    "Binh Minh, Viet Nam",
    "Alkmaar, Netherlands",
    "Kishanganj, India",
    "Wuchang, China",
    "Leskovac, Serbia",
    "Vespasiano, Brazil",
    "Hanamaki, Japan",
    "Fordham, United States",
    "Campo Largo, Brazil",
    "Nemby, Paraguay",
    "Dogonbadan, Iran",
    "Livonia, United States",
    "Mainpuri, India",
    "Khamgaon, India",
    "Kazerun, Iran",
    "Roswell, United States",
    "Yonezawa, Japan",
    "Orem, United States",
    "Bayan Hot, China",
    "Dunhou, China",
    "Harlow, United Kingdom",
    "Ahar, Iran",
    "Ximeicun, China",
    "Stellenbosch, South Africa",
    "Negapatam, India",
    "Daxi, Taiwan, China",
    "Novyy Urengoy, Russia",
    "Limerick, Ireland",
    "Yilan, Taiwan, China",
    "Gjakove, ",
    "Yuma, United States",
    "Yishui, China",
    "Ruwa, Zimbabwe",
    "Song Cau, Viet Nam",
    "Chilakalurupet, India",
    "Fall River, United States",
    "Cacem, Portugal",
    "Gudiyatham, India",
    "Berrechid, Morocco",
    "Zhongduo, China",
    "Lawrence, United States",
    "Makrana, India",
    "Jalalpur Jattan, Pakistan",
    "Gesundbrunnen, Germany",
    "Novoural'sk, Russia",
    "The Woodlands, United States",
    "Zheleznogorsk, Russia",
    "Fangcheng Chengguanzhen, China",
    "Joenkoeping, Sweden",
    "West Albany, United States",
    "Janakpur, Nepal",
    "Norrkoeping, Sweden",
    "Daotian, China",
    "Yakima, United States",
    "Baijiantan, China",
    "Yunnanyi, China",
    "Shuangliao, China",
    "Banswara, India",
    "Garcia, Mexico",
    "Quincy, United States",
    "Yala, Thailand",
    "Dipolog, Philippines",
    "Baraut, India",
    "Closepet, India",
    "Behshahr, Iran",
    "Michurinsk, Russia",
    "Dueren, Germany",
    "Ceske Budejovice, Czech Republic",
    "Ghardaia, Algeria",
    "Xiva, Uzbekistan",
    "Flatbush, United States",
    "Lysychansk, Ukraine",
    "Dalai, China",
    "Hesperia, United States",
    "Lere, Nigeria",
    "La Spezia, Italy",
    "Carson, United States",
    "Barletta, Italy",
    "Modi'in Makkabbim Re'ut, Israel",
    "Polatli, Turkey",
    "Tangwu, China",
    "Boca Raton, United States",
    "Jiayue, China",
    "Budapest XVIII. keruelet, Hungary",
    "Santa Monica, United States",
    "Kristiansand, Norway",
    "Chilliwack, Canada",
    "Rubio, Venezuela",
    "Ochota, Poland",
    "Praga Polnoc, Poland",
    "Kalyani, India",
    "Changping, China",
    "Caceres, Spain",
    "Galle, Sri Lanka",
    "Stilfontein, South Africa",
    "Heerlen, Netherlands",
    "Ozamiz City, Philippines",
    "Jinja, Uganda",
    "Noumea, New Caledonia",
    "Tadpatri, India",
    "Wonosobo, Indonesia",
    "Kineshma, Russia",
    "Bafra, Turkey",
    "Coronel, Chile",
    "Wukari, Nigeria",
    "Krasnogorsk, Russia",
    "San Marcos, United States",
    "Pushkin, Russia",
    "Moa, Cuba",
    "Lianzhou, China",
    "Malacatan, Guatemala",
    "Boyle Heights, United States",
    "Magadan, Russia",
    "Banco Filipino Homes, Philippines",
    "Beni Mered, Algeria",
    "Igbo-Ora, Nigeria",
    "Nuneaton, United Kingdom",
    "Foumban, Cameroon",
    "Kapurthala, India",
    "High Peak, United Kingdom",
    "Kaizuka, Japan",
    "Palimanan, Indonesia",
    "Patos, Brazil",
    "Yingqiu, China",
    "Plantation, United States",
    "Yashio, Japan",
    "Rahlstedt, Germany",
    "Semenyih, Malaysia",
    "Tulcea, Romania",
    "Maizuru, Japan",
    "Selong, Indonesia",
    "Lynn, United States",
    "Villa Maria, Argentina",
    "Ratchaburi, Thailand",
    "Pamekasan, Indonesia",
    "Al Harithah, Iraq",
    "Esslingen, Germany",
    "Errachidia, Morocco",
    "Darlington, United Kingdom",
    "Mugla, Turkey",
    "Pandeglang, Indonesia",
    "Miami Beach, United States",
    "Kitakami, Japan",
    "Dongcun, China",
    "Disa, India",
    "Sakai, Japan",
    "Arden-Arcade, United States",
    "Pariaman, Indonesia",
    "Kudus, Indonesia",
    "Ballalpur, India",
    "Eregli, Turkey",
    "Westminster, United States",
    "Kohima, India",
    "Alessandria, Italy",
    "Longmont, United States",
    "Delmas, South Africa",
    "Nasinu, Fiji",
    "Duitama, Colombia",
    "Danjiangkou, China",
    "Tepatitlan de Morelos, Mexico",
    "Betul, India",
    "Bai'anba, China",
    "Ercis, Turkey",
    "Winterthur, Switzerland",
    "Lorca, Spain",
    "Bugulma, Russia",
    "Sassari, Italy",
    "Kilosa, Tanzania",
    "Caringin, Indonesia",
    "Santa Barbara, United States",
    "Ubatuba, Brazil",
    "Calumpit, Philippines",
    "Ginowan, Japan",
    "Nisshin, Japan",
    "Pickering, Canada",
    "Zhongshu, China",
    "Butwal, Nepal",
    "Timoteo, Brazil",
    "Southport, United Kingdom",
    "Huazhou, China",
    "Marivan, Iran",
    "Ratingen, Germany",
    "Redding, United States",
    "Xingren, China",
    "Cardenas, Mexico",
    "Grahamstown, South Africa",
    "Longquan, China",
    "Phan Rang-Thap Cham, Viet Nam",
    "Kalamaria, Greece",
    "Yatou, China",
    "Tanfang, China",
    "Kars, Turkey",
    "Leeuwarden, Netherlands",
    "Lingyuan, China",
    "Tartu, Estonia",
    "Pergamino, Argentina",
    "Marl, Germany",
    "Bagaha, India",
    "Macon, United States",
    "Balangir, India",
    "Muriae, Brazil",
    "Aruja, Brazil",
    "Cambe, Brazil",
    "Bayeux, Brazil",
    "Kasama, Zambia",
    "Aligudarz, Iran",
    "Nigde, Turkey",
    "Luenen, Germany",
    "Thu Dau Mot, Viet Nam",
    "Lefortovo, Russia",
    "Yaroslavskiy, Russia",
    "Ga-Rankuwa, South Africa",
    "Onomichi, Japan",
    "Gaoyou, China",
    "Luleburgaz, Turkey",
    "Myitkyina, Myanmar",
    "Chauk, Myanmar",
    "Yulinshi, China",
    "Bento Goncalves, Brazil",
    "Mogok, Myanmar",
    "Chinhoyi, Zimbabwe",
    "Namyangju, South Korea",
    "'Amran, Yemen",
    "Meridian, United States",
    "San Leandro, United States",
    "Kishorganj, Bangladesh",
    "Emure-Ekiti, Nigeria",
    "Hanting, China",
    "Datia, India",
    "Kattaqo'rg'on Shahri, Uzbekistan",
    "Hastings, New Zealand",
    "Greenville, United States",
    "Hradec Kralove, Czech Republic",
    "Tongren, China",
    "Korfez, Turkey",
    "Cankiri, Turkey",
    "Chester, United Kingdom",
    "Retalhuleu, Guatemala",
    "Nanaimo, Canada",
    "Palmerston North, New Zealand",
    "Kuznetsk, Russia",
    "Montelibano, Colombia",
    "Xihe, China",
    "Guliston, Uzbekistan",
    "San Juan Bautista, Venezuela",
    "Usti nad Labem, Czech Republic",
    "Valparai, India",
    "Kovilpatti, India",
    "Vaniyambadi, India",
    "Masvingo, Zimbabwe",
    "Kamloops, Canada",
    "Coslada, Spain",
    "Duobao, China",
    "Kirovo-Chepetsk, Russia",
    "Berdsk, Russia",
    "Stevenage, United Kingdom",
    "Bela Bela, South Africa",
    "Reconquista, Argentina",
    "Bhandara, India",
    "Huddinge, Sweden",
    "Sishui, China",
    "Wajir, Kenya",
    "Marudi, Malaysia",
    "Edmond, United States",
    "Wembley, United Kingdom",
    "Gantang, China",
    "Roha, India",
    "Chinatown, United States",
    "Alvand, Iran",
    "Kanata, Canada",
    "Luomen, China",
    "Fort-de-France, Martinique",
    "Ancona, Italy",
    "Isieke, Nigeria",
    "Francistown, Botswana",
    "Huaidian, China",
    "Nampa, United States",
    "Muktsar, India",
    "Point Pedro, Sri Lanka",
    "Avignon, France",
    "Gatchina, Russia",
    "Baisha, China",
    "Grays, United Kingdom",
    "Olavarria, Argentina",
    "Santana do Livramento, Brazil",
    "Lillestrom, Norway",
    "Nyaunglebin, Myanmar",
    "Koka, Japan",
    "Butare, Rwanda",
    "Sibolga, Indonesia",
    "Sandy Hills, United States",
    "Mianwali, Pakistan",
    "Vlore, Albania",
    "Luckeesarai, India",
    "Almirante Tamandare, Brazil",
    "Barmer, India",
    "Muradnagar, India",
    "Fujin, China",
    "Granada, Nicaragua",
    "Nikaia, Greece",
    "Tinsukia, India",
    "San Carlos del Zulia, Venezuela",
    "Marseille 13, France",
    "Huaicheng, China",
    "Shahdol, India",
    "Kwangyang, South Korea",
    "Miragoane, Haiti",
    "Manjeri, India",
    "Port Dickson, Malaysia",
    "Hongseong, South Korea",
    "Mahoba, India",
    "Sumbawanga, Tanzania",
    "Micheng, China",
    "Kalibo (poblacion), Philippines",
    "Mudon, Myanmar",
    "Lucheng, China",
    "Machiques, Venezuela",
    "Hede, China",
    "Chengxiang, China",
    "Harrogate, United Kingdom",
    "Sokcho, South Korea",
    "Banjar, Indonesia",
    "Bibir Hat, Bangladesh",
    "Biryulevo Zapadnoye, Russia",
    "Tual, Indonesia",
    "Nusaybin, Turkey",
    "Cicurug, Indonesia",
    "Nandajie, China",
    "Lengshuitan, China",
    "Kartasura, Indonesia",
    "Shwebo, Myanmar",
    "Salihli, Turkey",
    "Iga, Japan",
    "Talavera de la Reina, Spain",
    "Hartlepool, United Kingdom",
    "Planaltina, Brazil",
    "Eregli, Turkey",
    "Fusagasuga, Colombia",
    "Mangaldan, Philippines",
    "Newton, United States",
    "Toms River, United States",
    "Crato, Brazil",
    "Habiganj, Bangladesh",
    "Wangqing, China",
    "Budapest II. keruelet, Hungary",
    "Rafaela, Argentina",
    "Carmel, United States",
    "Xiazhuang, China",
    "Contai, India",
    "Hanau am Main, Germany",
    "Valinhos, Brazil",
    "Montero, Bolivia",
    "Sangrur, India",
    "Dongxing, China",
    "Chosica, Peru",
    "Ashiya, Japan",
    "Chaman, Pakistan",
    "Alaghsas, Niger",
    "Pardubice, Czech Republic",
    "Asheville, United States",
    "Linjiacun, China",
    "Iwata, Japan",
    "Zhoujiaba, China",
    "Konosu, Japan",
    "Norwalk, United States",
    "Waukegan, United States",
    "Deltona, United States",
    "Takayama, Japan",
    "Kandhkot, Pakistan",
    "Honghe, China",
    "Hawthorne, United States",
    "Targoviste, Romania",
    "Al Bahah, Saudi Arabia",
    "Sao Lourenco da Mata, Brazil",
    "Nova Lima, Brazil",
    "Fukuroi, Japan",
    "Mahendranagar, Nepal",
    "Klangenan, Indonesia",
    "El Puerto de Santa Maria, Spain",
    "Pakse, Lao People's Dem. Rep.",
    "Mobara, Japan",
    "Cienaga, Colombia",
    "Brusque, Brazil",
    "Ifo, Nigeria",
    "Baixi, China",
    "Shkoder, Albania",
    "Pollachi, India",
    "Fort Smith, United States",
    "Deoband, India",
    "Suffolk, United States",
    "Sugar Land, United States",
    "Livermore, United States",
    "Lidu, China",
    "Kozan, Turkey",
    "Neyyattinkara, India",
    "Tanda, India",
    "Hasilpur, Pakistan",
    "Sarov, Russia",
    "Arayat, Philippines",
    "Nashua, United States",
    "Yingchuan, China",
    "Qorveh, Iran",
    "Sassandra, Côte d'Ivoire",
    "Yingge, Taiwan, China",
    "Cholula, Mexico",
    "Gusong, China",
    "Yozgat, Turkey",
    "Ath Thawrah, Syria",
    "Reading, United States",
    "Ebolowa, Cameroon",
    "Surigao, Philippines",
    "Balkanabat, Turkmenistan",
    "Yeysk, Russia",
    "Brantford, Canada",
    "Arecibo, Puerto Rico",
    "Hanchuan, China",
    "Maumere, Indonesia",
    "Buzuluk, Russia",
    "Concord, United States",
    "Tiefu, China",
    "Makeni, Sierra Leone",
    "Velbert, Germany",
    "Barra do Pirai, Brazil",
    "Kadugli, Sudan",
    "Qiongshan, China",
    "Gitarama, Rwanda",
    "Ludwigsburg, Germany",
    "Xiangxiang, China",
    "Deqing, China",
    "Indio, United States",
    "Enchanted Hills, United States",
    "Rio Rancho, United States",
    "Junin, Argentina",
    "Baran, India",
    "Sandy, United States",
    "Xujiang, China",
    "Wonosari, Indonesia",
    "Patnos, Turkey",
    "Whittier, United States",
    "Midrand, South Africa",
    "Canarsie, United States",
    "Arifwala, Pakistan",
    "Launceston, Australia",
    "Jelenia Gora, Poland",
    "Glyfada, Greece",
    "New Plymouth, New Zealand",
    "Igede-Ekiti, Nigeria",
    "Kirkland, United States",
    "Ende, Indonesia",
    "Mishan, China",
    "Lund, Sweden",
    "Nenjiang, China",
    "Alegrete, Brazil",
    "Angono, Philippines",
    "Caieiras, Brazil",
    "Menifee, United States",
    "Cornella de Llobregat, Spain",
    "Fulham, United Kingdom",
    "Londonderry County Borough, United Kingdom",
    "Dhamtari, India",
    "Hounde, Burkina Faso",
    "Brindisi, Italy",
    "Newport Beach, United States",
    "Tiruchengode, India",
    "Tracy, United States",
    "Ad Douiem, Sudan",
    "Citrus Heights, United States",
    "Malanje, Angola",
    "Norton, Zimbabwe",
    "Bend, United States",
    "Chirala, India",
    "Lerik, Azerbaijan",
    "Saatli, Azerbaijan",
    "Gubkin, Russia",
    "Gao, Mali",
    "Lokossa, Benin",
    "Effium, Nigeria",
    "Jharia, India",
    "Hacienda Santa Fe, Mexico",
    "Akot, India",
    "Serra Talhada, Brazil",
    "Mianke Mor, Pakistan",
    "Ban Mai, Thailand",
    "Menglang, China",
    "Campana, Argentina",
    "Louis Trichardt, South Africa",
    "Canton, United States",
    "Rajpura, India",
    "Madgaon, India",
    "Caguas, Puerto Rico",
    "Lehigh Acres, United States",
    "Greenburgh, United States",
    "Shizilu, China",
    "Batticaloa, Sri Lanka",
    "Asnieres-sur-Seine, France",
    "Nanterre, France",
    "Tumaco, Colombia",
    "Chaykovskiy, Russia",
    "Atlixco, Mexico",
    "Musanze, Rwanda",
    "Sardarshahr, India",
    "Ixelles, Belgium",
    "Barra do Corda, Brazil",
    "Mayiladuthurai, India",
    "Jiangshan, China",
    "Bojonegoro, Indonesia",
    "Zhaogezhuang, China",
    "Al Farwaniyah, Kuwait",
    "Al Fujairah City, United Arab Emirates",
    "Victorias, Philippines",
    "Hamakita, Japan",
    "Tulcan, Ecuador",
    "Idanre, Nigeria",
    "Igarassu, Brazil",
    "Apartado, Colombia",
    "Bloomington, United States",
    "West Town, United States",
    "Anlong, China",
    "Tagbilaran City, Philippines",
    "Puli, Taiwan, China",
    "Germantown, United States",
    "Melilla, Spain",
    "Mbanza-Ngungu, Congo",
    "Gyoda, Japan",
    "Clifton, United States",
    "Nitra, Slovakia",
    "Qamdo, China",
    "Cai Rang, Viet Nam",
    "Bangkalan, Indonesia",
    "Chigorodo, Colombia",
    "Kasihan, Indonesia",
    "Viacha, Bolivia",
    "Kamthi, India",
    "Kashiwazaki, Japan",
    "Orihuela, Spain",
    "Lyon 08, France",
    "Grimsby, United Kingdom",
    "Nanfeng, China",
    "Cannock, United Kingdom",
    "Duluth, United States",
    "Champaign, United States",
    "Kitanagoya, Japan",
    "Borazjan, Iran",
    "Volos, Greece",
    "Potiskum, Nigeria",
    "Lianozovo, Russia",
    "Babushkin, Russia",
    "Novi Pazar, Serbia",
    "Poitiers, France",
    "Keffi, Nigeria",
    "Usol'ye-Sibirskoye, Russia",
    "Panevezys, Lithuania",
    "Lichterfelde, Germany",
    "Zhonggulou, China",
    "Paradip Garh, India",
    "Makoko, Nigeria",
    "Flensburg, Germany",
    "Longnan, China",
    "Ryugasaki, Japan",
    "Treviso, Italy",
    "Helong, China",
    "Near North Side, United States",
    "Paramagudi, India",
    "Tecoman, Mexico",
    "San Antonio, Chile",
    "Hemel Hempstead, United Kingdom",
    "Obruchevo, Russia",
    "Chino, United States",
    "Arauca, Colombia",
    "Hailin, China",
    "Mukachevo, Ukraine",
    "Krasnaya Glinka, Russia",
    "Alhambra, United States",
    "Svetlanovskiy, Russia",
    "Nanding, China",
    "Moriyama, Japan",
    "Attock City, Pakistan",
    "Ogden, United States",
    "Versailles, France",
    "Guozhen, China",
    "Sandnes, Norway",
    "Khalifah A City, United Arab Emirates",
    "Tarut, Saudi Arabia",
    "Aruppukkottai, India",
    "Los Reyes Acaquilpan, Mexico",
    "Hawsh `Isa, Egypt",
    "Paulo Afonso, Brazil",
    "Ituiutaba, Brazil",
    "Torre del Greco, Italy",
    "Tagaytay, Philippines",
    "Al Marj, Libyan Arab Jamahiriya",
    "Lido di Ostia, Italy",
    "Redwood City, United States",
    "Dengzhou, China",
    "Wedding, Germany",
    "Gharyan, Libyan Arab Jamahiriya",
    "Dapitan, Philippines",
    "Yegor'yevsk, Russia",
    "Courbevoie, France",
    "Bellingham, United States",
    "Qufu, China",
    "Ceuta, Spain",
    "Liepaja, Latvia",
    "Iwamizawa, Japan",
    "Madrid, Colombia",
    "O'Fallon, United States",
    "Puerto Maldonado, Peru",
    "Rukban, Jordan",
    "Thakhek, Lao People's Dem. Rep.",
    "Repentigny, Canada",
    "Gonaives, Haiti",
    "Oued Zem, Morocco",
    "Esteio, Brazil",
    "Baruni, India",
    "Hoover, United States",
    "Creteil, France",
    "Amsterdam-Zuidoost, Netherlands",
    "Como, Italy",
    "Ilion, Greece",
    "Madaripur, Bangladesh",
    "Konotop, Ukraine",
    "Ciudad Mante, Mexico",
    "Laojunmiao, China",
    "Cottbus, Germany",
    "Tuxpam de Rodriguez Cano, Mexico",
    "Panabo, Philippines",
    "Epe, Nigeria",
    "El Ejido, Spain",
    "Nakatsu, Japan",
    "Sarandi, Brazil",
    "Aflou, Algeria",
    "Gamboru, Nigeria",
    "Tlaxcala, Mexico",
    "Akhisar, Turkey",
    "Danbury, United States",
    "Sumenep, Indonesia",
    "Guider, Cameroon",
    "Katunayaka, Sri Lanka",
    "Yeongju, South Korea",
    "Dacheng, China",
    "Mianyang, China",
    "Bijnor, India",
    "Mariveles, Philippines",
    "St Albans, United Kingdom",
    "East Norwalk, United States",
    "Edinburg, United States",
    "Sunrise, United States",
    "Itaperuna, Brazil",
    "Dundee, South Africa",
    "Wilhelmshaven, Germany",
    "Nowy Sacz, Poland",
    "Ogaminana, Nigeria",
    "Anakapalle, India",
    "Karakol, Kyrgyzstan",
    "Piet Retief, South Africa",
    "Dazhong, China",
    "Heroica Zitacuaro, Mexico",
    "Liaolan, China",
    "Redditch, United Kingdom",
    "Toledo, Spain",
    "Trinidad, Bolivia",
    "Phuthaditjhaba, South Africa",
    "Cerkezkoey, Turkey",
    "Jinxiang, China",
    "Trenton, United States",
    "Newmarket, Canada",
    "Yurga, Russia",
    "Hellersdorf, Germany",
    "Santa Fe, United States",
    "Dongxia, China",
    "Al Fqih Ben Calah, Morocco",
    "Bloomington, United States",
    "Foumbot, Cameroon",
    "Komendantsky aerodrom, Russia",
    "Sambava, Madagascar",
    "Feni, Bangladesh",
    "Wanju, South Korea",
    "Najibabad, India",
    "Binonga, Philippines",
    "Reinickendorf, Germany",
    "Dohad, India",
    "Degan, China",
    "Xiannue, China",
    "Santana, Brazil",
    "Presov, Slovakia",
    "Chita, Japan",
    "Cicero, United States",
    "Darhan, Mongolia",
    "Yinma, China",
    "Hemet, United States",
    "Ramgarh, India",
    "Cianorte, Brazil",
    "Gotenba, Japan",
    "Ocotlan, Mexico",
    "Fredrikstad, Norway",
    "El Khroub, Algeria",
    "Sliven, Bulgaria",
    "Jardim Paulista, Brazil",
    "South Shields, United Kingdom",
    "Bethlehem, South Africa",
    "Derry, United Kingdom",
    "Hilversum, Netherlands",
    "Lingdong, China",
    "Nanzhang Chengguanzhen, China",
    "NIA Valencia, Philippines",
    "Ouidah, Benin",
    "Pori, Finland",
    "Ahwatukee Foothills, United States",
    "Galway, Ireland",
    "Tuebingen, Germany",
    "Busto Arsizio, Italy",
    "Yangp'yong, South Korea",
    "Ungsang, South Korea",
    "Kavali, India",
    "Johns Creek, United States",
    "La Piedad, Mexico",
    "Mission, United States",
    "Codo, Brazil",
    "Troy, United States",
    "Marilao, Philippines",
    "Buena Park, United States",
    "Xucheng, China",
    "Ihiala, Nigeria",
    "Fatehpur, India",
    "Margahayukencana, Indonesia",
    "San Jose de Guanipa, Venezuela",
    "Amakusa, Japan",
    "Kimitsu, Japan",
    "Galesong, Indonesia",
    "Guadalajara, Spain",
    "Hecun, China",
    "San Fernando, Philippines",
    "Kamigyo-ku, Japan",
    "Ningyang, China",
    "Lodwar, Kenya",
    "Uccle, Belgium",
    "Jinshi, China",
    "Weston-super-Mare, United Kingdom",
    "Palm Coast, United States",
    "Sanjo, Japan",
    "Yuanping, China",
    "Minden, Germany",
    "Montego Bay, Jamaica",
    "Kraljevo, Serbia",
    "Fayetteville, United States",
    "Sioux City, United States",
    "Yacuiba, Bolivia",
    "Shirvan, Iran",
    "Havirov, Czech Republic",
    "La Villa del Rosario, Venezuela",
    "Chichawatni, Pakistan",
    "Ash Shatrah, Iraq",
    "Sorsogon, Philippines",
    "Aoxi, China",
    "Liping, China",
    "Pau, France",
    "Roquetas de Mar, Spain",
    "Palencia, Spain",
    "Tanjay, Philippines",
    "Algorta, Spain",
    "Halifax, United Kingdom",
    "Changqing, China",
    "Araxa, Brazil",
    "Lyon 07, France",
    "Dali Old Town, China",
    "Sinhyeon, South Korea",
    "Xindian, China",
    "San Martin, Argentina",
    "Jangipur, India",
    "Chiguayante, Chile",
    "Mus, Turkey",
    "Anzhero-Sudzhensk, Russia",
    "Taourirt, Morocco",
    "Yima, China",
    "Lake Forest, United States",
    "Qiaoguan, China",
    "Pernik, Bulgaria",
    "Redencao, Brazil",
    "Dhar, India",
    "Merced, United States",
    "Pozuelo de Alarcon, Spain",
    "Sant Boi de Llobregat, Spain",
    "Bagamoyo, Tanzania",
    "Cukai, Malaysia",
    "Shangzhi, China",
    "Hansi, India",
    "Nangong, China",
    "Sabaneta, Colombia",
    "Lueshun, China",
    "Troitsk, Russia",
    "Kanoya, Japan",
    "Madaba, Jordan",
    "Reef Al Fujairah City, United Arab Emirates",
    "Nizip, Turkey",
    "Nakhon Sawan, Thailand",
    "Colombes, France",
    "Laksham, Bangladesh",
    "Longview, United States",
    "Les Corts, Spain",
    "Polatsk, Belarus",
    "Maple Ridge, Canada",
    "Caldas, Colombia",
    "Omihachiman, Japan",
    "Budapest VIII. keruelet, Hungary",
    "Atambua, Indonesia",
    "Anguo, China",
    "Fatsa, Turkey",
    "Abomey, Benin",
    "Azov, Russia",
    "Bryan, United States",
    "Ipoti, Nigeria",
    "Tanashicho, Japan",
    "Marugame, Japan",
    "Shiwan, China",
    "Westland, United States",
    "Beckenham, United Kingdom",
    "Edmonton, United Kingdom",
    "Niagara Falls, Canada",
    "Ishurdi, Bangladesh",
    "Kasserine, Tunisia",
    "Tamworth, United Kingdom",
    "Abreu e Lima, Brazil",
    "David, Panama",
    "Donghe, China",
    "Bhakkar, Pakistan",
    "La Dorada, Colombia",
    "Zilina, Slovakia",
    "Brena, Peru",
    "Presidencia Roque Saenz Pena, Argentina",
    "Itajuba, Brazil",
    "Huaiyang, China",
    "Qal`at Bishah, Saudi Arabia",
    "Sesto San Giovanni, Italy",
    "Gwangju, South Korea",
    "Agboville, Côte d'Ivoire",
    "Villingen-Schwenningen, Germany",
    "Kaspiysk, Russia",
    "Lucca, Italy",
    "Tangping, China",
    "Ar Rass, Saudi Arabia",
    "Warwick, United States",
    "Bartin, Turkey",
    "Luzern, Switzerland",
    "Barka', Oman",
    "Lakewood, United States",
    "Salmas, Iran",
    "Pontevedra, Spain",
    "Scunthorpe, United Kingdom",
    "Kadiri, India",
    "Lavras, Brazil",
    "Dhoraji, India",
    "Kharian, Pakistan",
    "Rayachoti, India",
    "Yudong, China",
    "Balqash, Kazakhstan",
    "Guaynabo, Puerto Rico",
    "Farmington Hills, United States",
    "Khambhat, India",
    "Sarishabari, Bangladesh",
    "San Tan Valley, United States",
    "Bistrita, Romania",
    "Mount Pleasant, United States",
    "Avare, Brazil",
    "Konstanz, Germany",
    "Tatebayashi, Japan",
    "Konin, Poland",
    "Nangen, South Korea",
    "Changning, China",
    "Gafsa, Tunisia",
    "Formosa, Brazil",
    "Pozzuoli, Italy",
    "Resita, Romania",
    "Leme, Brazil",
    "Xiugu, China",
    "Lalupon, Nigeria",
    "Pilar, Argentina",
    "Worms, Germany",
    "Cranston, United States",
    "Peterborough, Canada",
    "Ozersk, Russia",
    "Moabit, Germany",
    "Yessentuki, Russia",
    "Vitry-sur-Seine, France",
    "Largo, United States",
    "Cizre, Turkey",
    "Naeso, South Korea",
    "Nirmal, India",
    "Emmiganur, India",
    "Zomba, Malawi",
    "Feicheng, China",
    "Natitingou, Benin",
    "Ravenna, Italy",
    "Shanting, China",
    "Jeypore, India",
    "Rouissat, Algeria",
    "Klin, Russia",
    "Getxo, Spain",
    "Kotkapura, India",
    "Homestead, United States",
    "Berkane, Morocco",
    "Toride, Japan",
    "Junlian, China",
    "Lecce, Italy",
    "South Suffolk, United States",
    "Bafang, Cameroon",
    "Avondale, United States",
    "Atakpame, Togo",
    "Gueiguee, Venezuela",
    "Nianzhuang, China",
    "Aulnay-sous-Bois, France",
    "Varese, Italy",
    "Tustin, United States",
    "Santa Catarina Pinula, Guatemala",
    "Berrouaghia, Algeria",
    "Bakhmut, Ukraine",
    "Bani Yas City, United Arab Emirates",
    "Kouvola, Finland",
    "Yingjiang, China",
    "Elbistan, Turkey",
    "Udhampur, India",
    "Mountain View, United States",
    "Napa, United States",
    "Kairana, India",
    "Tahoua, Niger",
    "Mihara, Japan",
    "Zhuanghe, China",
    "Balaghat, India",
    "Bonab, Iran",
    "Loushanguan, China",
    "Tallaght, Ireland",
    "Somerville, United States",
    "Baoying, China",
    "Giugliano in Campania, Italy",
    "Kedungwaru, Indonesia",
    "Kendall, United States",
    "Yagoua, Cameroon",
    "Lawrence, United States",
    "Dashiqiao, China",
    "Budapest XV. keruelet, Hungary",
    "Mbalmayo, Cameroon",
    "Panshi, China",
    "Neumuenster, Germany",
    "Serpong, Indonesia",
    "Mocamedes, Angola",
    "Piotrkow Trybunalski, Poland",
    "Melbourne, United States",
    "Purmerend, Netherlands",
    "Vinhedo, Brazil",
    "Meiganga, Cameroon",
    "Qunghirot, Uzbekistan",
    "Xichang, China",
    "Gamagori, Japan",
    "Huanglou, China",
    "Dushan, China",
    "Vorkuta, Russia",
    "El Cafetal, Venezuela",
    "Gandia, Spain",
    "Ushiku, Japan",
    "Mansa, India",
    "Rikaze, China",
    "Hajiawa, Iraq",
    "Fili, Russia",
    "Shahre Jadide Andisheh, Iran",
    "Cung Kiem, Viet Nam",
    "Ughelli, Nigeria",
    "Dorsten, Germany",
    "Stockton-on-Tees, United Kingdom",
    "Parma, United States",
    "New Rochelle, United States",
    "San Jose Pinula, Guatemala",
    "Paco, Philippines",
    "Le Tampon, Réunion",
    "Anantnag, India",
    "Cruzeiro do Sul, Brazil",
    "Lynchburg, United States",
    "Medford, United States",
    "La Goulette, Tunisia",
    "Zrenjanin, Serbia",
    "Deerfield Beach, United States",
    "Songlou, China",
    "Bella Vista, Argentina",
    "Inhambane, Mozambique",
    "Ciudad Lerdo, Mexico",
    "Taixing, China",
    "Karaikal, India",
    "Kidapawan, Philippines",
    "Genteng, Indonesia",
    "Amstelveen, Netherlands",
    "Bende, Nigeria",
    "Kropotkin, Russia",
    "Itumbiara, Brazil",
    "Calarca, Colombia",
    "Oke Mesi, Nigeria",
    "Marica, Brazil",
    "Khrustalnyi, Ukraine",
    "Kafanchan, Nigeria",
    "Pleasanton, United States",
    "Norzagaray, Philippines",
    "Jharsuguda, India",
    "Tangjiazhuang, China",
    "Shadrinsk, Russia",
    "Willowdale, Canada",
    "Sidi Slimane, Morocco",
    "Huehuetenango, Guatemala",
    "Brajarajnagar, India",
    "Luedenscheid, Germany",
    "Behbahan, Iran",
    "Zarzis, Tunisia",
    "Phuket, Thailand",
    "Dobrich, Bulgaria",
    "Sant Cugat del Valles, Spain",
    "Kottagudem, India",
    "Nawabganj, India",
    "Gedangan, Indonesia",
    "Ciudad Lazaro Cardenas, Mexico",
    "Yanta, China",
    "Belmont Cragin, United States",
    "Bwizibwera, Uganda",
    "Brooklyn Park, United States",
    "Gueckedou, Guinea",
    "Hanno, Japan",
    "Zhuangyuan, China",
    "Ikom, Nigeria",
    "Arakkonam, India",
    "Wuyang, China",
    "Gapan, Philippines",
    "Kashihara, Japan",
    "Xiongzhou, China",
    "Chaigou, China",
    "Ponorogo, Indonesia",
    "Agulu, Nigeria",
    "Netrakona, Bangladesh",
    "Goodyear, United States",
    "Paoy Paet, Cambodia",
    "Slatina, Romania",
    "Catanzaro, Italy",
    "Layyah, Pakistan",
    "Nakatsugawa, Japan",
    "Kennewick, United States",
    "Marburg an der Lahn, Germany",
    "Marseille 08, France",
    "Tambacounda, Senegal",
    "Darnah, Libyan Arab Jamahiriya",
    "Xinzhou, China",
    "Shitanjing, China",
    "Chiang Rai, Thailand",
    "Deurne, Belgium",
    "Sagaing, Myanmar",
    "Koch Bihar, India",
    "Mooka, Japan",
    "Tumen, China",
    "Aviles, Spain",
    "Tres Lagoas, Brazil",
    "Doddaballapura, India",
    "Gualeguaychu, Argentina",
    "Mthatha, South Africa",
    "Dondo, Mozambique",
    "Vyborg, Russia",
    "Alameda, United States",
    "Curepipe, Mauritius",
    "Arona, Spain",
    "Rockhampton, Australia",
    "Sao Joao del Rei, Brazil",
    "Qinggang, China",
    "Hengbei, China",
    "Bhadohi, India",
    "Torrent, Spain",
    "Lins, Brazil",
    "Carlisle, United Kingdom",
    "Town 'n' Country, United States",
    "Bellflower, United States",
    "Kobanya, Hungary",
    "Katoya, India",
    "Yishan, China",
    "Kayes, Mali",
    "San Pedro, United States",
    "Youhao, China",
    "Reutov, Russia",
    "Toyooka, Japan",
    "Putatan, Malaysia",
    "Chino Hills, United States",
    "Bang Kruai, Thailand",
    "Denov, Uzbekistan",
    "Bloomington, United States",
    "Santa Cruz del Quiche, Guatemala",
    "Daura, Nigeria",
    "Al Hayy, Iraq",
    "Pingyi, China",
    "Budapest XVII. keruelet, Hungary",
    "Mogi Mirim, Brazil",
    "Wulong, China",
    "Iizuka, Japan",
    "Valvedditturai, Sri Lanka",
    "Rheinhausen, Germany",
    "Jamui, India",
    "Linping, China",
    "San Juan, Peru",
    "Ilioupoli, Greece",
    "Zahle, Lebanon",
    "Daet, Philippines",
    "Alafaya, United States",
    "Wa, Ghana",
    "La Concepcion, Venezuela",
    "Abaetetuba, Brazil",
    "Harihar, India",
    "Szombathely, Hungary",
    "Alekseyevka, Russia",
    "Kuacjok, South Sudan",
    "Mancheral, India",
    "Castrop-Rauxel, Germany",
    "Marsala, Italy",
    "Honjo, Japan",
    "Tome, Japan",
    "Obu, Japan",
    "Springdale, United States",
    "North Hollywood, United States",
    "Kadayanallur, India",
    "Marseille 15, France",
    "Ciranjang-hilir, Indonesia",
    "Huankou, China",
    "Linkou, China",
    "Racine, United States",
    "Ipiales, Colombia",
    "Roosendaal, Netherlands",
    "Daltonganj, India",
    "Brikama, Gambia",
    "Hakkari, Turkey",
    "Guiberoua, Côte d'Ivoire",
    "Hasselt, Belgium",
    "Keningau, Malaysia",
    "Gateshead, United Kingdom",
    "Huangmei, China",
    "Numan, Nigeria",
    "Hammond, United States",
    "Feicheng, China",
    "Milpitas, United States",
    "Inowroclaw, Poland",
    "Sao Bento do Sul, Brazil",
    "UEnye, Turkey",
    "Songling, China",
    "Bogenhausen, Germany",
    "Jishui, China",
    "Aalst, Belgium",
    "Lubin, Poland",
    "Mechelen, Belgium",
    "Shushtar, Iran",
    "Lisburn, United Kingdom",
    "Kambar, Pakistan",
    "Barracas, Argentina",
    "Faridkot, India",
    "Nazareth, Israel",
    "Wako, Japan",
    "Mazatenango, Guatemala",
    "Khomeyn, Iran",
    "Shulan, China",
    "Apalit, Philippines",
    "Itauna, Brazil",
    "Balrampur, India",
    "Saint-Laurent, Canada",
    "Tsubame, Japan",
    "Suva, Fiji",
    "Hatsukaichi, Japan",
    "Matehuala, Mexico",
    "Focsani, Romania",
    "Quatre Bornes, Mauritius",
    "Mungyeong, South Korea",
    "Chiclana de la Frontera, Spain",
    "Baramula, India",
    "Paisley, United Kingdom",
    "Joensuu, Finland",
    "Agua Prieta, Mexico",
    "Pesaro, Italy",
    "Kanasin, Mexico",
    "Cozumel, Mexico",
    "Baicheng, China",
    "Lod, Israel",
    "Wawer, Poland",
    "Pulilan, Philippines",
    "Siedlce, Poland",
    "Damiao, China",
    "Jiexiu, China",
    "Ksar el Boukhari, Algeria",
    "Santa Cruz de Barahona, Dominican Republic",
    "Gary, United States",
    "Putney, United Kingdom",
    "Ovalle, Chile",
    "Polangui, Philippines",
    "Scranton, United States",
    "Sao Mateus, Brazil",
    "Amozoc de Mota, Mexico",
    "Coyah, Guinea",
    "Pok Fu Lam, Hong Kong, China",
    "Voskresensk, Russia",
    "Keratsini, Greece",
    "Baldwin Park, United States",
    "Dhulian, India",
    "Hunchun, China",
    "Nabari, Japan",
    "Sasolburg, South Africa",
    "Kagoro, Nigeria",
    "Auburn, United States",
    "Yalta, Ukraine",
    "Mawana, India",
    "Tsurusaki, Japan",
    "Jozsefvaros, Hungary",
    "Son Tay, Viet Nam",
    "Gladbeck, Germany",
    "Sarqant, Kazakhstan",
    "Marseille 09, France",
    "Puerto Padre, Cuba",
    "Budge Budge, India",
    "Arraijan, Panama",
    "La Rochelle, France",
    "Kundla, India",
    "Fishers, United States",
    "Shrewsbury, United Kingdom",
    "Saint Joseph, United States",
    "Moro, Pakistan",
    "Sandu, China",
    "Mmabatho, South Africa",
    "Liangxiang, China",
    "Champigny-sur-Marne, France",
    "Manmad, India",
    "Kannauj, India",
    "Zhaodun, China",
    "Luxembourg, Luxembourg",
    "Yenakiieve, Ukraine",
    "La Louviere, Belgium",
    "Saint-Pierre, Réunion",
    "Pancevo, Serbia",
    "Fenghua, China",
    "Colon, Panama",
    "Rueil-Malmaison, France",
    "Malaut, India",
    "Guiguinto, Philippines",
    "Arnsberg, Germany",
    "Salina Cruz, Mexico",
    "Nagina, India",
    "Biruaca, Venezuela",
    "Jatai, Brazil",
    "Sao Joao da Boa Vista, Brazil",
    "Pharr, United States",
    "Fuorigrotta, Italy",
    "Lorena, Brazil",
    "Fylde, United Kingdom",
    "Mbale, Uganda",
    "Badagara, India",
    "Rheine, Germany",
    "Jagdalpur, India",
    "Bunbury, Australia",
    "Santa Cruz do Capibaribe, Brazil",
    "Shiki, Japan",
    "Upland, United States",
    "Oss, Netherlands",
    "Yaxing, China",
    "Yuktae-dong, Korea, Dem. People's Rep. of",
    "Ciudad Satelite, Peru",
    "Antibes, France",
    "Yangliuqing, China",
    "Folsom, United States",
    "Modiin Ilit, Israel",
    "Sao Sebastiao, Brazil",
    "Qiuji, China",
    "Jhumri Telaiya, India",
    "Budapest XXI. keruelet, Hungary",
    "Tucurui, Brazil",
    "Baytown, United States",
    "Furukawa, Japan",
    "Embu Guacu, Brazil",
    "Latina, Italy",
    "Mineralnye Vody, Russia",
    "Babahoyo, Ecuador",
    "Midyat, Turkey",
    "Lae, Papua New Guinea",
    "Matara, Sri Lanka",
    "Manresa, Spain",
    "Nahavand, Iran",
    "Ramla, Israel",
    "Tuban, Indonesia",
    "Sakon Nakhon, Thailand",
    "Mian Channun, Pakistan",
    "Mbabane, Swaziland",
    "Qingquan, China",
    "Viersen, Germany",
    "San Ramon, United States",
    "Camden, United States",
    "Bracknell, United Kingdom",
    "Shibukawa, Japan",
    "Oleksandriya, Ukraine",
    "Konnagar, India",
    "Zhlobin, Belarus",
    "Yurihonjo, Japan",
    "Lake Charles, United States",
    "Sapiranga, Brazil",
    "Kalamazoo, United States",
    "Fukuchiyama, Japan",
    "Shouxian, China",
    "Paradsasvar, Hungary",
    "Nagornyy, Russia",
    "Ratnagiri, India",
    "Kebomas, Indonesia",
    "Cinisello Balsamo, Italy",
    "Arlington Heights, United States",
    "San Sebastian de los Reyes, Spain",
    "Plymouth, United States",
    "Maymana, Afghanistan",
    "Delmenhorst, Germany",
    "Lintong, China",
    "La Libertad, Ecuador",
    "Hingoli, India",
    "South Ozone Park, United States",
    "Doral, United States",
    "Ciudad Choluteca, Honduras",
    "Khemis Miliana, Algeria",
    "Karangsembung, Indonesia",
    "Kamensk-Shakhtinsky, Russia",
    "Bayramaly, Turkmenistan",
    "Para de Minas, Brazil",
    "Belovo, Russia",
    "Takefu, Japan",
    "Tarub, Indonesia",
    "Waterford, United States",
    "Muan, South Korea",
    "Turbat, Pakistan",
    "Bodinayakkanur, India",
    "Kanjia, China",
    "Yinzhu, China",
    "Khadki, India",
    "Battersea, United Kingdom",
    "Chekhov, Russia",
    "Bihac, Bosnia and Herzegovina",
    "Jamjamal, Iraq",
    "Battaramulla South, Sri Lanka",
    "Dhrangadhra, India",
    "Crewe, United Kingdom",
    "Osijek, Croatia",
    "Pila, Poland",
    "Nevsehir, Turkey",
    "Evanston, United States",
    "San Martin Texmelucan de Labastida, Mexico",
    "Chatham, United Kingdom",
    "Ode, Nigeria",
    "Miki, Japan",
    "Manteca, United States",
    "Schiedam, Netherlands",
    "Ra'anana, Israel",
    "Shahdad Kot, Pakistan",
    "Saint-Maur-des-Fosses, France",
    "Campo Mourao, Brazil",
    "Bariq, Saudi Arabia",
    "Ulu Tiram, Malaysia",
    "Chaoyang, China",
    "Banska Bystrica, Slovakia",
    "Azzaba, Algeria",
    "Silifke, Turkey",
    "Wyoming, United States",
    "Krusevac, Serbia",
    "Hongch'on, South Korea",
    "Kampong Chhnang, Cambodia",
    "Trujillo Alto, Puerto Rico",
    "Shangmei, China",
    "Paloncha, India",
    "Igbo-Ukwu, Nigeria",
    "Loveland, United States",
    "Cheektowaga, United States",
    "Hove, United Kingdom",
    "Fuyu, China",
    "Kings Bridge, United States",
    "Pedro Juan Caballero, Paraguay",
    "Kpalime, Togo",
    "Cipolletti, Argentina",
    "Okegawa, Japan",
    "Bayreuth, Germany",
    "Kumanovo, Macedonia, The former Yugoslav Rep. of",
    "Gela, Italy",
    "Cu Chi, Viet Nam",
    "Dakhla, Western Sahara",
    "Spijkenisse, Netherlands",
    "Encarnacion, Paraguay",
    "Aprilia, Italy",
    "Perris, United States",
    "Haninge, Sweden",
    "Ben Arous, Tunisia",
    "Bethlehem, United States",
    "Alvand, Iran",
    "Gaevle, Sweden",
    "Gudur, India",
    "Albany, United States",
    "El Tocuyo, Venezuela",
    "Juchitan de Zaragoza, Mexico",
    "Huilong, China",
    "Keren, Eritrea",
    "Ubud, Indonesia",
    "Silopi, Turkey",
    "Beidao, China",
    "Sidi Qacem, Morocco",
    "Troisdorf, Germany",
    "Aylesbury, United Kingdom",
    "Bhalwal, Pakistan",
    "Ankleshwar, India",
    "Helmond, Netherlands",
    "East Kilbride, United Kingdom",
    "Yanggu, China",
    "Tikamgarh, India",
    "Cachoeira do Sul, Brazil",
    "Schaumburg, United States",
    "Evosmos, Greece",
    "Changnyeong, South Korea",
    "Gobernador Galvez, Argentina",
    "Dipalpur, Pakistan",
    "Antonio Enes, Mozambique",
    "Longgang, China",
    "Al Bayda', Libyan Arab Jamahiriya",
    "Myslowice, Poland",
    "Asbest, Russia",
    "Cannes, France",
    "Gastonia, United States",
    "Brownsville, United States",
    "Union City, United States",
    "Bismil, Turkey",
    "Aramoko-Ekiti, Nigeria",
    "Katori-shi, Japan",
    "Shancheng, China",
    "Bilecik, Turkey",
    "Calais, France",
    "Zhangzhai, China",
    "Giessen, Germany",
    "Nanma, China",
    "Srivilliputhur, India",
    "Huicheng, China",
    "Asti, Italy",
    "Bolingbrook, United States",
    "Bosaso, Somalia",
    "Ferrol, Spain",
    "Yuanshang, China",
    "Ragusa, Italy",
    "Silao, Mexico",
    "Iowa City, United States",
    "Pilkhua, India",
    "Seosan, South Korea",
    "Khalandrion, Greece",
    "Velez-Malaga, Spain",
    "Abeche, Chad",
    "Suixi, China",
    "Layton, United States",
    "Xinmin, China",
    "Appleton, United States",
    "Missouri City, United States",
    "Igbara-Odo, Nigeria",
    "Ambajogai, India",
    "Zhangzhuang, China",
    "Mbake, Senegal",
    "Shelby, United States",
    "Fuentes del Valle, Mexico",
    "Beziers, France",
    "La Haute-Saint-Charles, Canada",
    "San Ramon de la Nueva Oran, Argentina",
    "Gucheng Chengguanzhen, China",
    "Xianshuigu, China",
    "Standerton, South Africa",
    "Zaraza, Venezuela",
    "Ciudad Real, Spain",
    "Fort Myers, United States",
    "Prince George, Canada",
    "Inuyama, Japan",
    "Bela, India",
    "Ostrowiec Swietokrzyski, Poland",
    "Boynton Beach, United States",
    "Santo Antonio de Jesus, Brazil",
    "Bocholt, Germany",
    "Bodhan, India",
    "Yunyang, China",
    "Casoria, Italy",
    "Jonesboro, United States",
    "Khenifra, Morocco",
    "Majene, Indonesia",
    "Kortrijk, Belgium",
    "Rabkavi, India",
    "Pistoia, Italy",
    "Chimbas, Argentina",
    "South Lawndale, United States",
    "Prilep, Macedonia, The former Yugoslav Rep. of",
    "Ozubulu, Nigeria",
    "Vlaardingen, Netherlands",
    "Mijas, Spain",
    "Ifrane, Morocco",
    "Grand-Bassam, Côte d'Ivoire",
    "Izumiotsu, Japan",
    "Aku, Nigeria",
    "Guixi, China",
    "Kafr az Zayyat, Egypt",
    "Karauli, India",
    "Lanxi, China",
    "Logan Square, United States",
    "Savarkundla, India",
    "Drohobych, Ukraine",
    "Detmold, Germany",
    "Al Buraymi, Oman",
    "Kasama, Japan",
    "Genhe, China",
    "Gresik, Indonesia",
    "Kai, Japan",
    "Birobidzhan, Russia",
    "Oyan, Nigeria",
    "Shahabad, India",
    "Red Deer, Canada",
    "Sewon, Indonesia",
    "Luocheng, China",
    "Kabanjahe, Indonesia",
    "Rapid City, United States",
    "Badin, Pakistan",
    "Necochea, Argentina",
    "Kamina, Congo",
    "Chandpur, India",
    "Cao Bang, Viet Nam",
    "Naqadeh, Iran",
    "Nurabad, Iran",
    "El Hamma, Tunisia",
    "Jega, Nigeria",
    "Warner Robins, United States",
    "Gokak, India",
    "Heguan, China",
    "Solok, Indonesia",
    "Rochester Hills, United States",
    "Saint-Leonard, Canada",
    "Canary Wharf, United Kingdom",
    "Sikandarabad, India",
    "Asaba, Nigeria",
    "Kukichuo, Japan",
    "Chilca, Peru",
    "Ohafia-Ifigh, Nigeria",
    "Mpanda, Tanzania",
    "Juegang, China",
    "Virudunagar, India",
    "Bonao, Dominican Republic",
    "Cuamba, Mozambique",
    "Yoshikawa, Japan",
    "Belek, Turkey",
    "Decatur, United States",
    "Paysandu, Uruguay",
    "Kadiyivka, Ukraine",
    "Tatvan, Turkey",
    "General Roca, Argentina",
    "Kawit, Philippines",
    "Fada N'gourma, Burkina Faso",
    "Peterhof, Russia",
    "Shostka, Ukraine",
    "Taman Senai, Malaysia",
    "Southfield, United States",
    "Rugby, United Kingdom",
    "Satsumasendai, Japan",
    "Tamanrasset, Algeria",
    "Siemianowice Slaskie, Poland",
    "Kahta, Turkey",
    "Mayagueez, Puerto Rico",
    "Nea Smyrni, Greece",
    "Joypur Hat, Bangladesh",
    "Majalengka, Indonesia",
    "Berdychiv, Ukraine",
    "Sihanoukville, Cambodia",
    "Dausa, India",
    "Jatibarang, Indonesia",
    "Beled Hawo, Somalia",
    "Shixing, China",
    "Koch'ang, South Korea",
    "San Miguel, Argentina",
    "Qaracuxur, Azerbaijan",
    "Rubi, Spain",
    "Zlin, Czech Republic",
    "Mong Cai, Viet Nam",
    "Dongdu, China",
    "Balanga, Philippines",
    "Msaken, Tunisia",
    "San Juan de la Maguana, Dominican Republic",
    "Valsad, India",
    "Ostrow Wielkopolski, Poland",
    "Saint George, United States",
    "Rosh Ha'Ayin, Israel",
    "Pano Aqil, Pakistan",
    "Paranavai, Brazil",
    "Caserta, Italy",
    "Yanjia, China",
    "Dachnoye, Russia",
    "Ambikapur, India",
    "Krathum Baen, Thailand",
    "New Britain, United States",
    "Dongkan, China",
    "Minusinsk, Russia",
    "Joao Monlevade, Brazil",
    "Tosu, Japan",
    "Almelo, Netherlands",
    "Selva Alegre, Peru",
    "Amparo, Brazil",
    "Kotri, Pakistan",
    "Dingcheng, China",
    "Tando Muhammad Khan, Pakistan",
    "Georgiyevsk, Russia",
    "Lappeenranta, Finland",
    "Yelabuga, Russia",
    "Franklin, United States",
    "Naro-Fominsk, Russia",
    "Bali, Cameroon",
    "Port Area, Philippines",
    "Wusu, China",
    "Lanxi, China",
    "Miyang, China",
    "Matao, Brazil",
    "Steglitz Bezirk, Germany",
    "Jiangzhuang, China",
    "Beichengqu, China",
    "Gongju, South Korea",
    "Harunabad, Pakistan",
    "Bacabal, Brazil",
    "Ilagan, Philippines",
    "Socopo, Venezuela",
    "Shumen, Bulgaria",
    "Cacapava, Brazil",
    "Venado Tuerto, Argentina",
    "Nouadhibou, Mauritania",
    "Palghar, India",
    "Marousi, Greece",
    "Xinzhi, China",
    "Turlock, United States",
    "Finlyandskiy, Russia",
    "Dharapuram, India",
    "Ain Touta, Algeria",
    "Montepuez, Mozambique",
    "Abancay, Peru",
    "Temple, United States",
    "Licheng, China",
    "Guanajuato, Mexico",
    "Dazaifu, Japan",
    "Suva Reka, ",
    "West Ridge, United States",
    "Ash Shafa, Saudi Arabia",
    "Apple Valley, United States",
    "Sagay, Philippines",
    "Sarnia, Canada",
    "Kyosai, South Korea",
    "Limbe, Cameroon",
    "Cheria, Algeria",
    "Nizwa, Oman",
    "Zhoucheng, China",
    "Capas, Philippines",
    "Sault Ste. Marie, Canada",
    "Cruzeiro, Brazil",
    "Mariano Roque Alonso, Paraguay",
    "Larnaca, Cyprus",
    "Purley, United Kingdom",
    "Lynwood, United States",
    "Waukesha, United States",
    "Patrocinio, Brazil",
    "Gouda, Netherlands",
    "Wilmington, United States",
    "Tres Rios, Brazil",
    "Yangsan, South Korea",
    "Bothaville, South Africa",
    "Caidian, China",
    "Canton, United States",
    "Guildford, United Kingdom",
    "Afikpo, Nigeria",
    "Santa Lucia, Spain",
    "Bebedouro, Brazil",
    "Gulfport, United States",
    "Panzos, Guatemala",
    "Sunzha, Russia",
    "Amatitlan, Guatemala",
    "Chintamani, India",
    "Faridpur, India",
    "Yuksekova, Turkey",
    "Birkhadem, Algeria",
    "Zaandam, Netherlands",
    "Boras, Sweden",
    "Shakhtarsk, Ukraine",
    "Esbjerg, Denmark",
    "Chelghoum el Aid, Algeria",
    "Yawata, Japan",
    "Apomu, Nigeria",
    "Minami-Alps, Japan",
    "Xiuyan, China",
    "Darayya, Syria",
    "Delgado, El Salvador",
    "Pawtucket, United States",
    "Pusad, India",
    "Donggongon, Malaysia",
    "Chernogorsk, Russia",
    "Madhubani, India",
    "Lauderhill, United States",
    "Peckham, United Kingdom",
    "Rock Hill, United States",
    "Monastir, Tunisia",
    "Sao Cristovao, Brazil",
    "Khanabad, Afghanistan",
    "Sragen, Indonesia",
    "Luohuang, China",
    "Sirsilla, India",
    "Takestan, Iran",
    "Fuengirola, Spain",
    "Solana, Philippines",
    "Shangsi, China",
    "Fiditi, Nigeria",
    "Silver Spring, United States",
    "Barnsley, United Kingdom",
    "Tokmok, Kyrgyzstan",
    "Norderstedt, Germany",
    "Yumbo, Colombia",
    "Alfenas, Brazil",
    "Triyuga, Nepal",
    "Vapi, India",
    "Upington, South Africa",
    "Trujillo, Venezuela",
    "Don Torcuato, Argentina",
    "Zelenogorsk, Russia",
    "West Gulfport, United States",
    "Parys, South Africa",
    "Agios Dimitrios, Greece",
    "Yalova, Turkey",
    "Dunkerque, France",
    "Szolnok, Hungary",
    "Changtu, China",
    "Huashan, China",
    "Bulacan, Philippines",
    "Lueneburg, Germany",
    "El Pueblito, Mexico",
    "Flower Mound, United States",
    "Stargard, Poland",
    "Cremona, Italy",
    "Ijui, Brazil",
    "Anlu, China",
    "Guasave, Mexico",
    "Yilan, China",
    "Bismarck, United States",
    "Sur, Oman",
    "Linjiang, China",
    "Carpi Centro, Italy",
    "San Francisco del Rincon, Mexico",
    "Centreville, United States",
    "Taroudant, Morocco",
    "Grazhdanka, Russia",
    "Lafayette, United States",
    "Thakurgaon, Bangladesh",
    "Passaic, United States",
    "Billstedt, Germany",
    "Muyinga, Burundi",
    "Guiping, China",
    "Gemlik, Turkey",
    "Tenri, Japan",
    "Riverview, United States",
    "Kamareddi, India",
    "Redlands, United States",
    "Benidorm, Spain",
    "Orita-Eruwa, Nigeria",
    "Zografos, Greece",
    "Missoula, United States",
    "Rancho Cordova, United States",
    "Celle, Germany",
    "Bolpur, India",
    "Ranaghat, India",
    "Tanabe, Japan",
    "Lowestoft, United Kingdom",
    "Hongwon, Korea, Dem. People's Rep. of",
    "Aubervilliers, France",
    "Altamira, Brazil",
    "Fazilka, India",
    "Kuandian, China",
    "Vila Nova de Gaia, Portugal",
    "Ejura, Ghana",
    "Gosport, United Kingdom",
    "Minab, Iran",
    "Poyang, China",
    "Yongfeng, China",
    "Plaridel, Philippines",
    "Kopeysk, Russia",
    "Eha Amufu, Nigeria",
    "Soedertaelje, Sweden",
    "Gongchangling, China",
    "Paracatu, Brazil",
    "Beypore, India",
    "Lelystad, Netherlands",
    "Jimo, China",
    "Malumfashi, Nigeria",
    "Carpina, Brazil",
    "Essaouira, Morocco",
    "Bukoba, Tanzania",
    "Antanifotsy, Madagascar",
    "Sherwood Park, Canada",
    "Lethbridge, Canada",
    "Mindelo, Cape Verde",
    "Dadri, India",
    "Yukuhashi, Japan",
    "Byumba, Rwanda",
    "Kurortnyy, Russia",
    "Dinslaken, Germany",
    "Sankt Gallen, Switzerland",
    "Novomoskovsk, Ukraine",
    "New Braunfels, United States",
    "Pabianice, Poland",
    "Altamura, Italy",
    "Mariano, Philippines",
    "Auraiya, India",
    "Lamezia Terme, Italy",
    "Vol'sk, Russia",
    "Nungua, Ghana",
    "Halmstad, Sweden",
    "Cherry Hill, United States",
    "Baiquan, China",
    "Beloretsk, Russia",
    "Contramaestre, Cuba",
    "Pattoki, Pakistan",
    "Ishimbay, Russia",
    "Pagar Alam, Indonesia",
    "Iguatu, Brazil",
    "Jiangyan, China",
    "Maidenhead, United Kingdom",
    "Shiguai, China",
    "Maebaru-chuo, Japan",
    "Flagstaff, United States",
    "Ritto, Japan",
    "Sunyani, Ghana",
    "Chivacoa, Venezuela",
    "Anan, Japan",
    "Danao, Philippines",
    "Gniezno, Poland",
    "Alphen aan den Rijn, Netherlands",
    "Kadirli, Turkey",
    "Goya, Argentina",
    "Chau GJoc, Viet Nam",
    "Sirvan, Azerbaijan",
    "Schweizer-Reneke, South Africa",
    "Adler, Russia",
    "Dimbokro, Côte d'Ivoire",
    "Fuyang, China",
    "Haining, China",
    "Dogubayazit, Turkey",
    "Alcala de Guadaira, Spain",
    "Shankou, China",
    "Chapayevsk, Russia",
    "Stafford, United Kingdom",
    "Bapatla, India",
    "Taungdwingyi, Myanmar",
    "Inaruwa, Nepal",
    "Muncie, United States",
    "Lisala, Congo",
    "Pan'an, China",
    "Payyannur, India",
    "Bamberg, Germany",
    "Khalis, Iraq",
    "Tiflet, Morocco",
    "Ayvalik, Turkey",
    "Southall, United Kingdom",
    "Fordon, Poland",
    "Woodland Hills, United States",
    "Quan GJuc Thanh, Viet Nam",
    "Mira Mesa, United States",
    "Chenab Nagar, Pakistan",
    "Dainava (Kaunas), Lithuania",
    "Uxbridge, United Kingdom",
    "Baikonur, Kazakhstan",
    "Ho, Ghana",
    "Raha, Indonesia",
    "Nikki, Benin",
    "Weston, United States",
    "Imola, Italy",
    "Aigaleo, Greece",
    "Izmail, Ukraine",
    "Mengcheng Chengguanzhen, China",
    "Wangkui, China",
    "San Joaquin, Venezuela",
    "Abhar, Iran",
    "Xinhe, China",
    "Moquegua, Peru",
    "Shima, China",
    "Gueira de Melena, Cuba",
    "Talipparamba, India",
    "Mertoyudan, Indonesia",
    "Votuporanga, Brazil",
    "Consolacion del Sur, Cuba",
    "Tindivanam, India",
    "Segamat, Malaysia",
    "San Miguel de Allende, Mexico",
    "Rajbiraj, Nepal",
    "Merignac, France",
    "San Jose del Cabo, Mexico",
    "Bondowoso, Indonesia",
    "Santa Cruz de Mara, Venezuela",
    "Kahror Pakka, Pakistan",
    "Sidi Aissa, Algeria",
    "Dehdasht, Iran",
    "Kaura Namoda, Nigeria",
    "Techiman, Ghana",
    "Planeta Rica, Colombia",
    "Las Piedras, Uruguay",
    "Chalus, Iran",
    "Tacarigua, Venezuela",
    "Paragominas, Brazil",
    "Khash, Iran",
    "Tuy Hoa, Viet Nam",
    "Fengping, China",
    "Brossard, Canada",
    "Tournai, Belgium",
    "Shirpur, India",
    "Linares, Chile",
    "Frederick, United States",
    "Fuefuki, Japan",
    "Frontera, Mexico",
    "Villa Carlos Paz, Argentina",
    "Pasco, United States",
    "Syriam, Myanmar",
    "Chengxian Chengguanzhen, China",
    "Pittsburg, United States",
    "Sayyan, Yemen",
    "Jaboticabal, Brazil",
    "Gujar Khan, Pakistan",
    "Kungsholmen, Sweden",
    "Dushanzi, China",
    "Kot Malik Barkhurdar, Pakistan",
    "Isna, Egypt",
    "Chuhar Kana, Pakistan",
    "Ridgewood, United States",
    "Palatine, United States",
    "Kampong Baharu Balakong, Malaysia",
    "Larbaa, Algeria",
    "Bitola, Macedonia, The former Yugoslav Rep. of",
    "Basoda, India",
    "Bafia, Cameroon",
    "Anseong, South Korea",
    "Xuantan, China",
    "Jiehu, China",
    "Vaslui, Romania",
    "Suwalki, Poland",
    "North Richland Hills, United States",
    "Union City, United States",
    "Kissimmee, United States",
    "Linjiang, China",
    "Hunedoara, Romania",
    "Las Cumbres, Panama",
    "San Carlo All'Arena, Italy",
    "Giurgiu, Romania",
    "Toba Tek Singh, Pakistan",
    "Tantou, China",
    "Jorhat, India",
    "Nganjuk, Indonesia",
    "Ostend, Belgium",
    "Sint-Niklaas, Belgium",
    "Xiaoshi, China",
    "Bocono, Venezuela",
    "Magalang, Philippines",
    "Bogale, Myanmar",
    "Gueines, Cuba",
    "Walnut Creek, United States",
    "Royal Tunbridge Wells, United Kingdom",
    "Koson, Uzbekistan",
    "Buenavista, Philippines",
    "Cantaura, Venezuela",
    "Hekinan, Japan",
    "Hoorn, Netherlands",
    "Libon, Philippines",
    "Wum, Cameroon",
    "Chulucanas, Peru",
    "Mila, Algeria",
    "Tuymazy, Russia",
    "Sindhnur, India",
    "Sukagawa, Japan",
    "Enugu-Ukwu, Nigeria",
    "Cordova, United States",
    "Moriya, Japan",
    "Ito, Japan",
    "Yachimata, Japan",
    "Ponferrada, Spain",
    "Jinjiang, China",
    "Bir el Ater, Algeria",
    "Idah, Nigeria",
    "Yunmen, China",
    "Vicosa, Brazil",
    "Bargarh, India",
    "Lys'va, Russia",
    "Knysna, South Africa",
    "Sao Sebastiao do Paraiso, Brazil",
    "Sougueur, Algeria",
    "Wushan, China",
    "Mount Vernon, United States",
    "Washim, India",
    "Visnagar, India",
    "Heishan, China",
    "Conroe, United States",
    "Borisoglebsk, Russia",
    "Obonoma, Nigeria",
    "Dothan, United States",
    "Sosnovyy Bor, Russia",
    "Feodosiya, Ukraine",
    "Palwancha, India",
    "Aschaffenburg, Germany",
    "Keshod, India",
    "Chinchina, Colombia",
    "Budapest XVI. keruelet, Hungary",
    "Tsuruga, Japan",
    "Northridge, United States",
    "Cabo San Lucas, Mexico",
    "Waterloo, United States",
    "Tanauan, Philippines",
    "Kannangad, India",
    "Pengcheng, China",
    "Wenjiang, China",
    "Ain Fakroun, Algeria",
    "Rivas-Vaciamadrid, Spain",
    "Sheki, Azerbaijan",
    "Al Qusiyah, Egypt",
    "Maple Grove, United States",
    "Baranoa, Colombia",
    "Al Jammaliyah, Egypt",
    "Shima, China",
    "Bisalpur, India",
    "Mingguang, China",
    "Ninghai, China",
    "Framingham, United States",
    "San Isidro, Peru",
    "Turkmenbasy, Turkmenistan",
    "Narowal, Pakistan",
    "Al Fahahil, Kuwait",
    "Tanuku, India",
    "Saldanha, South Africa",
    "Dolgoprudnyy, Russia",
    "Tolga, Algeria",
    "Lodja, Congo",
    "Soeke, Turkey",
    "Altagracia de Orituco, Venezuela",
    "Nghia Lo, Viet Nam",
    "Attili, India",
    "Wimbledon, United Kingdom",
    "Ina, Japan",
    "Redondo Beach, United States",
    "Matsuto, Japan",
    "Langarud, Iran",
    "Balotra, India",
    "Cadereyta Jimenez, Mexico",
    "Majadahonda, Spain",
    "Bossier City, United States",
    "Moron, Venezuela",
    "Es Senia, Algeria",
    "Neubrandenburg, Germany",
    "Catbalogan, Philippines",
    "Artemisa, Cuba",
    "Valle de Santiago, Mexico",
    "Balsas, Brazil",
    "Pithampur, India",
    "Stamford Hill, United Kingdom",
    "Sanlucar de Barrameda, Spain",
    "Bir el Djir, Algeria",
    "Munnar, India",
    "Dorogomilovo, Russia",
    "Balanbale, Somalia",
    "Cadereyta, Mexico",
    "Haemeenlinna, Finland",
    "Chamrajnagar, India",
    "Bourges, France",
    "Pattukkottai, India",
    "Yorba Linda, United States",
    "Ardesen, Turkey",
    "Chaihe, China",
    "Budyonnovsk, Russia",
    "Minggang, China",
    "Tangzhai, China",
    "Xunyang, China",
    "Barberton, South Africa",
    "Calbayog City, Philippines",
    "Belogorsk, Russia",
    "Hashima, Japan",
    "Most, Czech Republic",
    "Strovolos, Cyprus",
    "Campina, Spain",
    "Yanghe, China",
    "Concepcion del Uruguay, Argentina",
    "San Mateo Atenco, Mexico",
    "Garissa, Kenya",
    "Woodbury, United States",
    "San Fernando, Peru",
    "Dongfeng, China",
    "Roman, Romania",
    "Barlad, Romania",
    "Blagoevgrad, Bulgaria",
    "Deva, Romania",
    "Kaga, Japan",
    "Tamano, Japan",
    "Quillota, Chile",
    "Eau Claire, United States",
    "Jiang'an, China",
    "Saraburi, Thailand",
    "Ishim, Russia",
    "Zhenlai, China",
    "Velsen-Zuid, Netherlands",
    "Waldorf, United States",
    "Dessau, Germany",
    "Shuangfengqiao, China",
    "Forest Hills, United States",
    "Apac, Uganda",
    "Al Kharjah, Egypt",
    "Damghan, Iran",
    "Songjianghe, China",
    "Ede, Netherlands",
    "Davis, United States",
    "Subic, Philippines",
    "Sirnak, Turkey",
    "Tsushima, Japan",
    "Palang, Bangladesh",
    "Glen Burnie, United States",
    "Yafran, Libyan Arab Jamahiriya",
    "Arenella, Italy",
    "Vaasa, Finland",
    "Banepa, Nepal",
    "Agbor, Nigeria",
    "Camarillo, United States",
    "Jaisalmer, India",
    "Tukh, Egypt",
    "Luorong, China",
    "Kovel, Ukraine",
    "Saint John, Canada",
    "Victoria, United States",
    "Bislig, Philippines",
    "Trapani, Italy",
    "Guidonia Montecelio, Italy",
    "Lueeyang Chengguanzhen, China",
    "Atlantis, South Africa",
    "Soio, Angola",
    "Araria, India",
    "Gaithersburg, United States",
    "Shorkot, Pakistan",
    "Chaguanas, Trinidad and Tobago",
    "Wenling, China",
    "Montemorelos, Mexico",
    "Santa Ines, Brazil",
    "Ezza-Ohu, Nigeria",
    "Ixtapa-Zihuatanejo, Mexico",
    "Rossendale, United Kingdom",
    "Fuqing, China",
    "Harda Khas, India",
    "Caohe, China",
    "Eskilstuna, Sweden",
    "Jacksonville, United States",
    "Kostiantynivka, Ukraine",
    "Gaoliu, China",
    "City of Isabela, Philippines",
    "Glogow, Poland",
    "Xinzhou, China",
    "Zadar, Croatia",
    "Sangamner, India",
    "Tejen, Turkmenistan",
    "San Luis, Cuba",
    "Mukono, Uganda",
    "Tolanaro, Madagascar",
    "Caicara del Orinoco, Venezuela",
    "Pingzhuang, China",
    "South San Francisco, United States",
    "Kasese, Uganda",
    "Shahdadpur, Pakistan",
    "Tubarao, Brazil",
    "Kstovo, Russia",
    "Lippstadt, Germany",
    "Jaora, India",
    "Tomaszow Mazowiecki, Poland",
    "Tianliu, China",
    "Shiojiri, Japan",
    "Louga, Senegal",
    "Lushar, China",
    "OEdemis, Turkey",
    "Koepenick, Germany",
    "Wanggou, China",
    "Fengkou, China",
    "Nea Ionia, Greece",
    "Maitland, Australia",
    "Mbaiki, Central African Republic",
    "Kenner, United States",
    "Melati, Indonesia",
    "Santa Cruz Xoxocotlan, Mexico",
    "Haskovo, Bulgaria",
    "Aalen, Germany",
    "Jackson Heights, United States",
    "Chiryu, Japan",
    "Soja, Japan",
    "Svyetlahorsk, Belarus",
    "Saint-Nazaire, France",
    "Przemysl, Poland",
    "Notre-Dame-de-Grace, Canada",
    "Buldana, India",
    "Nizhyn, Ukraine",
    "Rockville, United States",
    "Liuhe, China",
    "Jackson, United States",
    "Lincoln Park, United States",
    "Mettupalayam, India",
    "Yuba City, United States",
    "Hanyin Chengguanzhen, China",
    "Solna, Sweden",
    "Kladno, Czech Republic",
    "Anjar, India",
    "Aleksin, Russia",
    "Portland, United States",
    "Palo Alto, United States",
    "Wacheng Neighborhood, China",
    "Los Puertos de Altagracia, Venezuela",
    "Oulad Teima, Morocco",
    "Neuwied, Germany",
    "Casas Adobes, United States",
    "Bajos de Haina, Dominican Republic",
    "Point Cook, Australia",
    "Marysville, United States",
    "Duoba, China",
    "Unna, Germany",
    "Shaozhuang, China",
    "Dambulla, Sri Lanka",
    "Trang, Thailand",
    "Bani, Dominican Republic",
    "Sayhat, Saudi Arabia",
    "Napier, New Zealand",
    "Parnas, Russia",
    "Bakixanov, Azerbaijan",
    "Karlskrona, Sweden",
    "Dinalupihan, Philippines",
    "Bellampalli, India",
    "Akbou, Algeria",
    "South Jordan, United States",
    "Channapatna, India",
    "Ratangarh, India",
    "Tailai, China",
    "Lishi, China",
    "Quartu Sant'Elena, Italy",
    "La Mohammedia, Tunisia",
    "Chengyang, China",
    "Sanchazi, China",
    "Kalulushi, Zambia",
    "Fort McMurray, Canada",
    "Kurihara, Japan",
    "Daxu, China",
    "Firuzabad, Iran",
    "Oshkosh, United States",
    "Ramos Arizpe, Mexico",
    "Aflao, Ghana",
    "Qaraqash, China",
    "Shabqadar, Pakistan",
    "Taihe, China",
    "North Little Rock, United States",
    "Caledon, Canada",
    "Lianyuan, China",
    "Stalowa Wola, Poland",
    "Mansehra, Pakistan",
    "Sokaraja, Indonesia",
    "Smila, Ukraine",
    "Bayside, United States",
    "White Rock, Canada",
    "Wuxi, China",
    "Vicalvaro, Spain",
    "Folkestone, United Kingdom",
    "Krishnagiri, India",
    "Qipan, China",
    "Plauen, Germany",
    "San Antonio del Tachira, Venezuela",
    "Rishikesh, India",
    "Kungur, Russia",
    "Goyerkata, India",
    "Klintsy, Russia",
    "Togane, Japan",
    "Tangxiang, China",
    "Neryungri, Russia",
    "Huinan, China",
    "Bayonne, United States",
    "Brixton, United Kingdom",
    "Zamora, Spain",
    "Hounslow, United Kingdom",
    "Sabae, Japan",
    "Besbes, Algeria",
    "Eagan, United States",
    "Beimeng, China",
    "Leninogorsk, Russia",
    "Bergen op Zoom, Netherlands",
    "Delray Beach, United States",
    "Chaibasa, India",
    "Algueirao, Portugal",
    "Loures, Portugal",
    "Sosnovka, Russia",
    "Saint-Marc, Haiti",
    "Rajin, Korea, Dem. People's Rep. of",
    "Granby, Canada",
    "Veliko Turnovo, Bulgaria",
    "Chingford, United Kingdom",
    "Marondera, Zimbabwe",
    "Luoyang, China",
    "Mandamarri, India",
    "Gabrovo, Bulgaria",
    "Jesus Maria, Peru",
    "Muling, China",
    "Viladecans, Spain",
    "Castellammare di Stabia, Italy",
    "Huanren, China",
    "Qionghu, China",
    "I`zaz, Syria",
    "Gamping Lor, Indonesia",
    "Kashima-shi, Japan",
    "Beruniy, Uzbekistan",
    "Huanan, China",
    "Palani, India",
    "Alba Iulia, Romania",
    "Gukovo, Russia",
    "Sagunto, Spain",
    "Moron, Cuba",
    "Narnaul, India",
    "Zamosc, Poland",
    "Xiaolingwei, China",
    "Johnson City, United States",
    "Cabinda, Angola",
    "Dharmapuri, India",
    "Fatehabad, India",
    "Calapan, Philippines",
    "Beidaihehaibin, China",
    "Ganshui, China",
    "Dale City, United States",
    "Silvan, Turkey",
    "Shujaabad, Pakistan",
    "Cedar Park, United States",
    "Lesosibirsk, Russia",
    "Soma, Turkey",
    "Jablah, Syria",
    "Waterfront Communities-The Island, Canada",
    "Niitsu-honcho, Japan",
    "Lahat, Indonesia",
    "Tanabe, Japan",
    "Rotorua, New Zealand",
    "Yaoji, China",
    "Qiantang, China",
    "Vilanova i la Geltru, Spain",
    "Mengyin, China",
    "Parkchester, United States",
    "Palin, Guatemala",
    "Lichtenburg, South Africa",
    "Tatabanya, Hungary",
    "Atascocita, United States",
    "Jietou, China",
    "Saint Cloud, United States",
    "Ellicott City, United States",
    "Palasa, India",
    "Xiangcheng Chengguanzhen, China",
    "Bayiji, China",
    "Finchley, United Kingdom",
    "Laguna Niguel, United States",
    "Basavakalyan, India",
    "Chegutu, Zimbabwe",
    "Polewali, Indonesia",
    "Saint Charles, United States",
    "Harlingen, United States",
    "Polevskoy, Russia",
    "Shangkou, China",
    "Pato Branco, Brazil",
    "Pavia, Italy",
    "Montenegro, Brazil",
    "Ligezhuang, China",
    "Mitoyo, Japan",
    "Wrexham, United Kingdom",
    "Chengalpattu, India",
    "Dashitou, China",
    "Pazardzhik, Bulgaria",
    "San Ildefonso, Philippines",
    "San Miguel, Philippines",
    "Huanghua, China",
    "Al Marsa, Tunisia",
    "Kedzierzyn-Kozle, Poland",
    "Qingyang, China",
    "Babu, China",
    "Pyapon, Myanmar",
    "Chopda, India",
    "Bais, Philippines",
    "Estepona, Spain",
    "Panaji, India",
    "Ioannina, Greece",
    "Wufeng, Taiwan, China",
    "Yebaishou, China",
    "Huaiyuan Chengguanzhen, China",
    "San Clemente, United States",
    "West Lynchburg, United States",
    "Thenkasi, India",
    "Penaflor, Chile",
    "Middletown, United States",
    "Dar Kulayb, Bahrain",
    "Afgooye, Somalia",
    "Horad Zhodzina, Belarus",
    "Torremolinos, Spain",
    "Sattahip, Thailand",
    "Sokhumi, Georgia",
    "Framingham Center, United States",
    "Xincheng, China",
    "Lajeado, Brazil",
    "Colmar, France",
    "Rechytsa, Belarus",
    "Tendo, Japan",
    "Torquay, United Kingdom",
    "Bangangte, Cameroon",
    "Vaexjoe, Sweden",
    "Bianzhuang, China",
    "Yadgir, India",
    "Pankow, Germany",
    "Masaka, Uganda",
    "Meleuz, Russia",
    "Tultepec, Mexico",
    "Suyangshan, China",
    "Kumertau, Russia",
    "Gangarampur, India",
    "Ramanathapuram, India",
    "Herten, Germany",
    "Jagraon, India",
    "Schenectady, United States",
    "Perai, Malaysia",
    "Hepingjie, China",
    "Haveli Lakha, Pakistan",
    "Rosarito, Mexico",
    "Salama, Guatemala",
    "Cruz Alta, Brazil",
    "Talisay, Philippines",
    "Tulungagung, Indonesia",
    "Liuxin, China",
    "Loughborough, United Kingdom",
    "Capelle aan den IJssel, Netherlands",
    "Gushikawa, Japan",
    "Caoqiao, China",
    "Alipur Duar, India",
    "Lala Musa, Pakistan",
    "Slavyansk-na-Kubani, Russia",
    "Craigieburn, Australia",
    "Irpin, Ukraine",
    "Villa de Vallecas, Spain",
    "Sefrou, Morocco",
    "Fengrun, China",
    "Lalmonirhat, Bangladesh",
    "Kopargaon, India",
    "Aquiraz, Brazil",
    "Kalush, Ukraine",
    "Broomfield, United States",
    "Ames, United States",
    "Park Slope, United States",
    "Shawnee, United States",
    "Kunyang, China",
    "Bazarak, Afghanistan",
    "Bazar-e Yakawlang, Afghanistan",
    "Cricklewood, United Kingdom",
    "Itaugua, Paraguay",
    "Pampatar, Venezuela",
    "Conway, United States",
    "Embu, Kenya",
    "Chust, Uzbekistan",
    "Tianzhuang, China",
    "Villa del Rosario, Colombia",
    "East Orange, United States",
    "Vratsa, Bulgaria",
    "Itacoatiara, Brazil",
    "Khulm, Afghanistan",
    "Youssoufia, Morocco",
    "Bongaigaon, India",
    "Kolonnawa, Sri Lanka",
    "Herford, Germany",
    "Hita, Japan",
    "Lingao, China",
    "Portage Park, United States",
    "Skokie, United States",
    "Shikang, China",
    "Malkapur, India",
    "Kingswood, United Kingdom",
    "Gurupi, Brazil",
    "Massa, Italy",
    "Grevenbroich, Germany",
    "Cair, Macedonia, The former Yugoslav Rep. of",
    "Tecate, Mexico",
    "Itaituba, Brazil",
    "Nakhchivan, Azerbaijan",
    "Seinaejoki, Finland",
    "Daytona Beach, United States",
    "Karanja, India",
    "Khatauli, India",
    "Weimar, Germany",
    "Bertioga, Brazil",
    "Ashoknagar, India",
    "Hwawon, South Korea",
    "Fenggang, China",
    "Salaqi, China",
    "Ahlat, Turkey",
    "Asahi, Japan",
    "West Bloomfield Township, United States",
    "Tamarac, United States",
    "Siuri, India",
    "Raipur, Bangladesh",
    "Meulaboh, Indonesia",
    "Nabha, India",
    "El Bayadh, Algeria",
    "Rivera, Uruguay",
    "Youngstown, United States",
    "Taunton, United Kingdom",
    "Talavera, Philippines",
    "Chacao, Venezuela",
    "Guasdualito, Venezuela",
    "Santo Angelo, Brazil",
    "Crotone, Italy",
    "Lodi, United States",
    "La Linea de la Concepcion, Spain",
    "Picheng, China",
    "Greenville, United States",
    "Chia, Colombia",
    "Renzhao, China",
    "Kotikawatta, Sri Lanka",
    "Siddipet, India",
    "Puerto Madryn, Argentina",
    "Mailsi, Pakistan",
    "Odate, Japan",
    "Fujioka, Japan",
    "Muncar, Indonesia",
    "Sidi Khaled, Algeria",
    "Changli, China",
    "Afragola, Italy",
    "Bhawanipatna, India",
    "Khopoli, India",
    "Karwar, India",
    "Tromso, Norway",
    "Aksehir, Turkey",
    "Sahuayo de Morelos, Mexico",
    "Parintins, Brazil",
    "Bandar Seri Begawan, Brunei Darussalam",
    "Kaihua, China",
    "Concepcion, Philippines",
    "Yunmeng Chengguanzhen, China",
    "Tikhoretsk, Russia",
    "Bantul, Indonesia",
    "Waterlooville, United Kingdom",
    "Yangzhou, China",
    "Sandefjord, Norway",
    "Rossosh', Russia",
    "Imaichi, Japan",
    "Shakargarh, Pakistan",
    "Chervonohrad, Ukraine",
    "Ghotki, Pakistan",
    "Kaposvar, Hungary",
    "Mansfield, United States",
    "Taloqan, Afghanistan",
    "Ongjin, Korea, Dem. People's Rep. of",
    "Tuapse, Russia",
    "Kerpen, Germany",
    "Skanes, Tunisia",
    "Santa Cruz, United States",
    "Pico Rivera, United States",
    "Madera, United States",
    "Heidelberg, South Africa",
    "Rovaniemi, Finland",
    "Kayankulam, India",
    "Qinggang, China",
    "Uga, Nigeria",
    "Janesville, United States",
    "Boryspil, Ukraine",
    "West Des Moines, United States",
    "Villa Elisa, Paraguay",
    "Aleksandrov, Russia",
    "Narutocho-mitsuishi, Japan",
    "Santiago, Peru",
    "Sibi, Pakistan",
    "Molina de Segura, Spain",
    "Chinnachowk, India",
    "Bishnupur, India",
    "Nurabad, Iran",
    "Paterna, Spain",
    "Palaio Faliro, Greece",
    "Mazabuka, Zambia",
    "Kotlovka, Russia",
    "Polomolok, Philippines",
    "Markapur, India",
    "Basmat, India",
    "Curvelo, Brazil",
    "Macclesfield, United Kingdom",
    "Baheri, India",
    "Chelm, Poland",
    "Montebello, United States",
    "Tamworth, Australia",
    "Kunnamkulam, India",
    "Tulangan Utara, Indonesia",
    "Itabaiana, Brazil",
    "Bognor Regis, United Kingdom",
    "Cyangugu, Rwanda",
    "Colon, Cuba",
    "San Antonio de Los Altos, Venezuela",
    "Raghogarh, India",
    "Kangar, Malaysia",
    "Valence, France",
    "Awsim, Egypt",
    "Newtownabbey, United Kingdom",
    "Cosenza, Italy",
    "Quimper, France",
    "Thanh Pho Uong Bi, Viet Nam",
    "Dengbu, China",
    "Jampur, Pakistan",
    "Cao Lanh, Viet Nam",
    "Kulai, Malaysia",
    "Fulda, Germany",
    "Yangcun, China",
    "Zhicheng, China",
    "Guamuchil, Mexico",
    "Cacador, Brazil",
    "Gopalganj, India",
    "Lomza, Poland",
    "Georgetown, United States",
    "As Safirah, Syria",
    "Lulou, China",
    "Kanchanaburi, Thailand",
    "Chokwe, Mozambique",
    "Alpharetta, United States",
    "Ouro Preto, Brazil",
    "Karvina, Czech Republic",
    "Kettering, United Kingdom",
    "Caldas Novas, Brazil",
    "Genk, Belgium",
    "Shimodate, Japan",
    "Yambol, Bulgaria",
    "Kilju, Korea, Dem. People's Rep. of",
    "Lorain, United States",
    "Tura, India",
    "Baihecun, China",
    "Irece, Brazil",
    "Dedougou, Burkina Faso",
    "Bowling Green, United States",
    "Nong Khai, Thailand",
    "Guanyin, China",
    "Flatlands, United States",
    "Dundalk, United States",
    "Dormagen, Germany",
    "Zarechnyy, Russia",
    "Uzice, Serbia",
    "Buckley, United Kingdom",
    "Mabai, China",
    "Leszno, Poland",
    "Bergheim, Germany",
    "Catalao, Brazil",
    "Samut Sakhon, Thailand",
    "Eden Prairie, United States",
    "Jitra, Malaysia",
    "North Bergen, United States",
    "Zayed City, United Arab Emirates",
    "Tres Coracoes, Brazil",
    "Korydallos, Greece",
    "Great Yarmouth, United Kingdom",
    "Ain Sefra, Algeria",
    "El Prat de Llobregat, Spain",
    "Rajsamand, India",
    "Nanlong, China",
    "Mitcham, United Kingdom",
    "Florence-Graham, United States",
    "Rio Largo, Brazil",
    "Youxi, China",
    "Waltham, United States",
    "Budapest XX. keruelet, Hungary",
    "Siddharthanagar, Nepal",
    "Garbsen, Germany",
    "Teluk Intan, Malaysia",
    "Pavlovo, Russia",
    "Cheyenne, United States",
    "Frenda, Algeria",
    "Darab, Iran",
    "Fuwwah, Egypt",
    "Xiazhuang, China",
    "Mercedes, Argentina",
    "West Hartford, United States",
    "Molepolole, Botswana",
    "Tandur, India",
    "Zalau, Romania",
    "Valenca, Brazil",
    "Vilhena, Brazil",
    "Gorno-Altaysk, Russia",
    "Changcheng, China",
    "Shanwang, China",
    "Trnava, Slovakia",
    "Ambarawa, Indonesia",
    "Kusadasi, Turkey",
    "Kalamassery, India",
    "Tetovo, Macedonia, The former Yugoslav Rep. of",
    "Hod HaSharon, Israel",
    "Zory, Poland",
    "Godean, Indonesia",
    "Rogers, United States",
    "Bougara, Algeria",
    "Suratgarh, India",
    "Mannargudi, India",
    "Medicine Hat, Canada",
    "Fredericton, Canada",
    "Linares, Mexico",
    "Melipilla, Chile",
    "Novaya Balakhna, Russia",
    "Limon, Costa Rica",
    "Collado-Villalba, Spain",
    "Botou, China",
    "Sopur, India",
    "Carol City, United States",
    "Peruibe, Brazil",
    "Pubal, South Korea",
    "Liuku, China",
    "Hamma Bouziane, Algeria",
    "Florida, Cuba",
    "Lugano, Switzerland",
    "Baoqing, China",
    "Nkwerre, Nigeria",
    "Entebbe, Uganda",
    "Itapeva, Brazil",
    "Shizhai, China",
    "Encinitas, United States",
    "Jinggou, China",
    "Auchi, Nigeria",
    "Graaff-Reinet, South Africa",
    "Belchatow, Poland",
    "Sambrial, Pakistan",
    "Runcorn, United Kingdom",
    "Sorgun, Turkey",
    "Fanlou, China",
    "Arni, India",
    "Nasugbu, Philippines",
    "Kannur, India",
    "East Village, United States",
    "Randers, Denmark",
    "Caltanissetta, Italy",
    "Ashford, United Kingdom",
    "Haverhill, United States",
    "Changle, China",
    "Sumbawa Besar, Indonesia",
    "Robat Karim, Iran",
    "Valdemoro, Spain",
    "Umm Al Quwain City, United Arab Emirates",
    "Gus'-Khrustal'nyy, Russia",
    "Jupiter, United States",
    "Dovzhansk, Ukraine",
    "Buynaksk, Russia",
    "Tiquipaya, Bolivia",
    "Semporna, Malaysia",
    "Cataguases, Brazil",
    "Cubuk, Turkey",
    "Krasnoturinsk, Russia",
    "Council Bluffs, United States",
    "Phitsanulok, Thailand",
    "Houmt El Souk, Tunisia",
    "Belebey, Russia",
    "Tataouine, Tunisia",
    "Wellington, United States",
    "Tonypandy, United Kingdom",
    "Miaojie, China",
    "Kaitong, China",
    "West Coon Rapids, United States",
    "Dhuburi, India",
    "Colon, Venezuela",
    "Zhangjiachuan, China",
    "Puliyangudi, India",
    "Drancy, France",
    "Pingnan, China",
    "Kosong, South Korea",
    "North Miami, United States",
    "Al Khankah, Egypt",
    "Singida, Tanzania",
    "Pervomaysk, Ukraine",
    "Noisy-le-Grand, France",
    "Sheopur, India",
    "Fochville, South Africa",
    "Renukut, India",
    "Erd, Hungary",
    "Hamilton, United States",
    "Villeneuve-d'Ascq, France",
    "Deqen, China",
    "Tesistan, Mexico",
    "Songyang, China",
    "Zhaozhou, China",
    "Grao de Murviedro, Spain",
    "Yucheng, China",
    "Berekum, Ghana",
    "North Port, United States",
    "Kipushi, Congo",
    "La Seyne-sur-Mer, France",
    "Shuikou, China",
    "Tulare, United States",
    "Kogon Shahri, Uzbekistan",
    "Abepura, Indonesia",
    "Sopron, Hungary",
    "Rzhev, Russia",
    "Coon Rapids, United States",
    "Assen, Netherlands",
    "Dhenkanal, India",
    "Tungipara, Bangladesh",
    "Chistopol', Russia",
    "Reguiba, Algeria",
    "Levallois-Perret, France",
    "Huaral, Peru",
    "Boituva, Brazil",
    "Viareggio, Italy",
    "Tanba, Japan",
    "Tinongan, Philippines",
    "Zarechnyy, Russia",
    "Millcreek, United States",
    "Shuangyang, China",
    "Nianzishan, China",
    "La Habra, United States",
    "Blaine, United States",
    "Dabwali, India",
    "Sibsagar, India",
    "Nagahama, Japan",
    "Castelldefels, Spain",
    "Tikhvin, Russia",
    "Sagua la Grande, Cuba",
    "Auburn, United States",
    "Minglanilla, Philippines",
    "Saquarema, Brazil",
    "Pingyin, China",
    "Boryeong, South Korea",
    "Tupa, Brazil",
    "Sanghar, Pakistan",
    "Bin Xian, China",
    "Ferkessedougou, Côte d'Ivoire",
    "Angyalfoeld, Hungary",
    "Qingshuping, China",
    "Erzsebetvaros, Hungary",
    "Smederevo, Serbia",
    "Rossosh', Russia",
    "Lake Elsinore, United States",
    "Zhaobaoshan, China",
    "Hushitai, China",
    "Piranshahr, Iran",
    "Petrodvorets, Russia",
    "Njeru, Uganda",
    "Labinsk, Russia",
    "Tenkodogo, Burkina Faso",
    "Fernandopolis, Brazil",
    "Zhangji, China",
    "Tan-Tan, Morocco",
    "Marseille 14, France",
    "Asker, Norway",
    "Huoqiu Chengguanzhen, China",
    "Mudu, China",
    "Zalaegerszeg, Hungary",
    "Fengyi, China",
    "Adzope, Côte d'Ivoire",
    "Nandu, China",
    "Pisco, Peru",
    "Bamyan, Afghanistan",
    "Longfeng, China",
    "Taoluo, China",
    "Daye, China",
    "Bassar, Togo",
    "Moca, Dominican Republic",
    "Iskitim, Russia",
    "Kiryat Gat, Israel",
    "Berberati, Central African Republic",
    "Slutsk, Belarus",
    "Senador Canedo, Brazil",
    "Jelgava, Latvia",
    "Revda, Russia",
    "Lobnya, Russia",
    "Yingshang Chengguanzhen, China",
    "San Andres Tuxtla, Mexico",
    "Tempelhof, Germany",
    "Ellesmere Port Town, United Kingdom",
    "Carmichael, United States",
    "Songkhla, Thailand",
    "Kampong Cham, Cambodia",
    "Scarborough, United Kingdom",
    "Xiangzhou, China",
    "Al Qurayn, Egypt",
    "Rath, India",
    "Al Shamkhah City, United Arab Emirates",
    "Medenine, Tunisia",
    "Lakshmipur, Bangladesh",
    "Yangiyul, Uzbekistan",
    "Yongning, China",
    "Jamkhandi, India",
    "Wesel, Germany",
    "Vyksa, Russia",
    "Cortazar, Mexico",
    "Trikala, Greece",
    "Kamyaran, Iran",
    "Chambery, France",
    "Kolding, Denmark",
    "Hancheng, China",
    "Budapest XIX. keruelet, Hungary",
    "Sirsi, India",
    "Dmitrov, Russia",
    "Itapira, Brazil",
    "Porirua, New Zealand",
    "Ueno-ebisumachi, Japan",
    "Sibay, Russia",
    "Lishu, China",
    "Oroqen Zizhiqi, China",
    "Taylor, United States",
    "Alcoy, Spain",
    "Cayenne, French Guiana",
    "Hujra Shah Muqim, Pakistan",
    "Hasuda, Japan",
    "Xianju, China",
    "Pogradec, Albania",
    "Malaybalay, Philippines",
    "Oudtshoorn, South Africa",
    "Vriddhachalam, India",
    "Korosten, Ukraine",
    "Karlstad, Sweden",
    "Nieuwegein, Netherlands",
    "Burnsville, United States",
    "Jianguang, China",
    "Monterey Park, United States",
    "Widnes, United Kingdom",
    "Djibo, Burkina Faso",
    "Belize City, Belize",
    "Kispest, Hungary",
    "Issy-les-Moulineaux, France",
    "Dongning, China",
    "Dashi, China",
    "Gia Nghia, Viet Nam",
    "Stryi, Ukraine",
    "Kempten (Allgaeu), Germany",
    "Castro Valley, United States",
    "Tunasan, Philippines",
    "San Salvador Tizatlalli, Mexico",
    "Savona, Italy",
    "Aldershot, United Kingdom",
    "Linares, Spain",
    "Vinukonda, India",
    "Irvington, United States",
    "Sindelfingen, Germany",
    "Vyronas, Greece",
    "Neuilly-sur-Seine, France",
    "Tianchang, China",
    "Mizusawa, Japan",
    "Veenendaal, Netherlands",
    "Gravata, Brazil",
    "La Chorrera, Panama",
    "Kuroiso, Japan",
    "Schwaebisch Gmuend, Germany",
    "Rocklin, United States",
    "Slawi, Indonesia",
    "Gaalkacyo, Somalia",
    "Apatity, Russia",
    "Abu Qurqas, Egypt",
    "Chichibu, Japan",
    "Tissemsilt, Algeria",
    "Valenca, Brazil",
    "Al Minshah, Egypt",
    "Utica, United States",
    "Dar Naim, Mauritania",
    "Xifeng, China",
    "Datong, China",
    "Punta de Mata, Venezuela",
    "Horsens, Denmark",
    "Malden, United States",
    "Hashimoto, Japan",
    "National City, United States",
    "Novoaltaysk, Russia",
    "Pecangaan, Indonesia",
    "Bury, United Kingdom",
    "Valjevo, Serbia",
    "Nanhu, China",
    "Yicheng, China",
    "Koshi, Japan",
    "Svobodnyy, Russia",
    "Bangor, United Kingdom",
    "Barking, United Kingdom",
    "Bromma, Sweden",
    "Sal'sk, Russia",
    "Stupino, Russia",
    "Fier-Cifci, Albania",
    "Mielec, Poland",
    "Granollers, Spain",
    "Fano, Italy",
    "Financial District, United States",
    "Chinch'on, South Korea",
    "Bouafle, Côte d'Ivoire",
    "Tiptur, India",
    "Sahaswan, India",
    "Irun, Spain",
    "Zeist, Netherlands",
    "Anuradhapura, Sri Lanka",
    "Tarnowskie Gory, Poland",
    "Xiema, China",
    "Grosseto, Italy",
    "Kirikhan, Turkey",
    "Yanliang, China",
    "Candelaria, Philippines",
    "Tahe, China",
    "Barabai, Indonesia",
    "Al Mithnab, Saudi Arabia",
    "Springfield, United States",
    "Koppal, India",
    "Bethesda, United States",
    "Uyovu, Tanzania",
    "Sirhind, India",
    "Norfolk County, Canada",
    "Dabra, India",
    "Erdaojiang, China",
    "Cumbum, India",
    "Terre Haute, United States",
    "Kolomyia, Ukraine",
    "Tartagal, Argentina",
    "Vineland, United States",
    "West Hollywood, United States",
    "Prokhladnyy, Russia",
    "Tianpeng, China",
    "Troyes, France",
    "Kertosono, Indonesia",
    "Kabirwala, Pakistan",
    "Bethal, South Africa",
    "Xixiang, China",
    "Seraing, Belgium",
    "San Marcos, Colombia",
    "Chicacao, Guatemala",
    "Shuya, Russia",
    "Ban Lam Luk Ka, Thailand",
    "Shchekino, Russia",
    "Towada, Japan",
    "San Marcos, United States",
    "Sfantu Gheorghe, Romania",
    "Yaowan, China",
    "Brentwood, United States",
    "Bor, Russia",
    "Givatayim, Israel",
    "Arambagh, India",
    "Himatnagar, India",
    "Lakeville, United States",
    "Mujiayingzi, China",
    "West Allis, United States",
    "Dubna, Russia",
    "Akim Oda, Ghana",
    "Redmond, United States",
    "Kinokawa, Japan",
    "Dingjia, China",
    "Mene Grande, Venezuela",
    "Lokoja, Nigeria",
    "Canoga Park, United States",
    "Cupertino, United States",
    "Ualog, Philippines",
    "Ciudad Hidalgo, Mexico",
    "Langzhong, China",
    "Taylorsville, United States",
    "Takaishi, Japan",
    "Castleford, United Kingdom",
    "Liancheng, China",
    "Ladnun, India",
    "Landshut, Germany",
    "Jiangbei, China",
    "Bristol, United States",
    "Moore, United States",
    "Gardena, United States",
    "Longgang, China",
    "Petaluma, United States",
    "Fethiye, Turkey",
    "Bensalem, United States",
    "Zacapa, Guatemala",
    "Hereford, United Kingdom",
    "Pirassununga, Brazil",
    "Matera, Italy",
    "Adelaide Hills, Australia",
    "Grand Junction, United States",
    "Taitou, China",
    "Swidnica, Poland",
    "Nipani, India",
    "Olbia, Italy",
    "Gushu, China",
    "Fenshui, China",
    "Casper, United States",
    "Motril, Spain",
    "Shimotsuke, Japan",
    "Borsad, India",
    "Wujing, China",
    "Opava, Czech Republic",
    "Rowlett, United States",
    "Vejle, Denmark",
    "Koratla, India",
    "Kiratpur, India",
    "Wucheng, China",
    "Cristalina, Brazil",
    "Yuxia, China",
    "Tahara, Japan",
    "Trinidad, Cuba",
    "Unai, Brazil",
    "Runing, China",
    "Parkent, Uzbekistan",
    "Vrindavan, India",
    "Douane, Tunisia",
    "Qus, Egypt",
    "Vavuniya, Sri Lanka",
    "Zhijiang, China",
    "Rosenheim, Germany",
    "Haveri, India",
    "Stroud, United Kingdom",
    "Gucheng, China",
    "Taozhuang, China",
    "Margate, United Kingdom",
    "Tczew, Poland",
    "Sandaohezi, China",
    "Pokrovsk, Ukraine",
    "Laascaanood, Somalia",
    "Yongbei, China",
    "La Mesa, United States",
    "Pine Hills, United States",
    "Martinez de la Torre, Mexico",
    "Caratinga, Brazil",
    "Kuwait City, Kuwait",
    "Melbourne City Centre, Australia",
    "Zushi, Japan",
    "Pavlovskiy Posad, Russia",
    "Hyuga, Japan",
    "Dazeshan, China",
    "Supaul, India",
    "Sao Borja, Brazil",
    "Uige, Angola",
    "Villa Vicente Guerrero, Mexico",
    "Putra Heights, Malaysia",
    "Zhujiajiao, China",
    "Mbale, Kenya",
    "Coney Island, United States",
    "Chelsea, United Kingdom",
    "Gamonal, Spain",
    "Bangaon, India",
    "Wuzhen, China",
    "Rancho Penasquitos, United States",
    "Colonia Nativitas, Mexico",
    "Valley Glen, United States",
    "Santutxu, Spain",
    "Bensonhurst, United States",
    "Nema, Mauritania",
    "Ostankinskiy, Russia",
    "Hurlingham, Argentina",
    "Sviblovo, Russia",
    "Giridih, India",
    "Rades, Tunisia",
    "Meftah, Algeria",
    "Meriden, United States",
    "Rayadrug, India",
    "Tanay, Philippines",
    "Obando, Philippines",
    "Uki, Japan",
    "Heroica Caborca, Mexico",
    "Pontiac, United States",
    "Temerluh, Malaysia",
    "Hwasun, South Korea",
    "Acerra, Italy",
    "Welwyn Garden City, United Kingdom",
    "Jiangkou, China",
    "Farnborough, United Kingdom",
    "Tuz Khurmatu, Iraq",
    "Yamethin, Myanmar",
    "Port Orange, United States",
    "Oued Rhiou, Algeria",
    "Boyolali, Indonesia",
    "Hamden, United States",
    "Antony, France",
    "North Lakhimpur, India",
    "Pucheng, China",
    "Lakewood, United States",
    "Balagtas, Philippines",
    "Brandenburg an der Havel, Germany",
    "Kosai, Japan",
    "Fountainebleau, United States",
    "Piekary Slaskie, Poland",
    "Tohana, India",
    "Boufarik, Algeria",
    "Bekescsaba, Hungary",
    "Ruesselsheim, Germany",
    "Itapetinga, Brazil",
    "Zvishavane, Zimbabwe",
    "Saint Clair Shores, United States",
    "Agia Paraskevi, Greece",
    "Springfield, United States",
    "Esfarayen, Iran",
    "Pampan, Venezuela",
    "Mococa, Brazil",
    "Katiola, Côte d'Ivoire",
    "Great Falls, United States",
    "Date, Japan",
    "Ekpoma, Nigeria",
    "Asadabad, Iran",
    "Nago, Japan",
    "Baimajing, China",
    "Tuanbao, China",
    "San Cristobal, Cuba",
    "Den Helder, Netherlands",
    "Chapel Hill, United States",
    "Berlin Koepenick, Germany",
    "Molfetta, Italy",
    "Altamira, Mexico",
    "Canyon Country, United States",
    "Sengkang, Indonesia",
    "Gangoh, India",
    "Ferizaj, ",
    "Dayr al Balah, West Bank and Gaza Strip",
    "Drummondville, Canada",
    "Comitancillo, Guatemala",
    "Sonsonate, El Salvador",
    "Wenshang, China",
    "Rhondda, United Kingdom",
    "Moelndal, Sweden",
    "Huntington Park, United States",
    "Carazinho, Brazil",
    "Frydek-Mistek, Czech Republic",
    "La Roche-sur-Yon, France",
    "Shirakawa, Japan",
    "Mangalagiri, India",
    "Chikuma, Japan",
    "Zhaoyuan, China",
    "Mingshui, China",
    "Merida, Spain",
    "Galatsi, Greece",
    "Lancaster, United States",
    "Huiqu, China",
    "Narasapur, India",
    "Coconut Creek, United States",
    "San Felipe, Chile",
    "Dolores Hidalgo, Mexico",
    "Gannan, China",
    "Offenburg, Germany",
    "Craigavon, United Kingdom",
    "Lamongan, Indonesia",
    "Hongjiang, China",
    "Sangareddi, India",
    "Mikhaylovsk, Russia",
    "Sahagun, Colombia",
    "Jian'ou, China",
    "Idaho Falls, United States",
    "Kotlas, Russia",
    "Fundacion, Colombia",
    "Kodar, India",
    "Chibuto, Mozambique",
    "Tashan, China",
    "San Rafael, United States",
    "Jixian, China",
    "Hezuo, China",
    "Azua, Dominican Republic",
    "Wanchaq, Peru",
    "Arrecife, Spain",
    "Chalkida, Greece",
    "Oltinko`l, Uzbekistan",
    "Abadeh, Iran",
    "Ullal, India",
    "Manaoag, Philippines",
    "Langenfeld, Germany",
    "Haizhou, China",
    "Noblesville, United States",
    "Longgu, China",
    "Yigou, China",
    "Santo Tome, Argentina",
    "Marietta, United States",
    "San Francisco, Argentina",
    "Verkhnyaya Pyshma, Russia",
    "Fairfield, United States",
    "As-Suwayda, Syria",
    "Talnakh, Russia",
    "Langtoucun, China",
    "Owensboro, United States",
    "Eastvale, United States",
    "Mossel Bay, South Africa",
    "Qiryat Ata, Israel",
    "Royal Oak, United States",
    "Minami-Soma, Japan",
    "Kabinda, Congo",
    "Mangrol, India",
    "Gola Gokarannath, India",
    "Zarand, Iran",
    "Petroupolis, Greece",
    "Stralsund, Germany",
    "Uwajima, Japan",
    "Modasa, India",
    "Brentwood, United States",
    "Santa Rosa, Brazil",
    "Torbat-e Jam, Iran",
    "Fraijanes, Guatemala",
    "Kitahiroshima, Japan",
    "Xiaoweizhai, China",
    "Baki, Indonesia",
    "Cerro de Pasco, Peru",
    "Mikhaylovka, Russia",
    "Solnechnogorsk, Russia",
    "Otawara, Japan",
    "Villach, Austria",
    "Telemaco Borba, Brazil",
    "Sig, Algeria",
    "Guanambi, Brazil",
    "Antu, China",
    "Banga, Philippines",
    "Benalmadena, Spain",
    "Tezpur, India",
    "Shahriar, Iran",
    "Ardakan, Iran",
    "Saijo, Japan",
    "Kolda, Senegal",
    "Dubuque, United States",
    "Wallasey, United Kingdom",
    "Kamphaeng Phet, Thailand",
    "Comayagua, Honduras",
    "Thiruvarur, India",
    "Suozhen, China",
    "Ishikari, Japan",
    "Cerdanyola del Valles, Spain",
    "Vinto, Bolivia",
    "Kayes, Congo",
    "Marseille 12, France",
    "Brookline, United States",
    "Novi, United States",
    "Jaguariuna, Brazil",
    "Littlehampton, United Kingdom",
    "Attur, India",
    "Arsen'yev, Russia",
    "Teziutlan, Mexico",
    "Hanyu, Japan",
    "Ouezzane, Morocco",
    "Des Plaines, United States",
    "Kampong Dungun, Malaysia",
    "Carrara, Italy",
    "Hameln, Germany",
    "Taifu, China",
    "Los Patios, Colombia",
    "Chidambaram, India",
    "Labe, Guinea",
    "Orland Park, United States",
    "San Pedro Ayampuc, Guatemala",
    "Tarn Taran, India",
    "Glogovac, ",
    "Bartlett, United States",
    "Udumalaippettai, India",
    "Toki, Japan",
    "Woodland, United States",
    "Lonavla, India",
    "New Westminster, Canada",
    "Shiqiaozi, China",
    "Kelibia, Tunisia",
    "Jidong, China",
    "Lehi, United States",
    "Ksar Chellala, Algeria",
    "Fenyi, China",
    "Raciborz, Poland",
    "White Plains, United States",
    "Vasastan, Sweden",
    "Uzlovaya, Russia",
    "Weleri, Indonesia",
    "Chonglong, China",
    "Xinglongshan, China",
    "San Pedro de Jujuy, Argentina",
    "Fort Beaufort, South Africa",
    "Benevento, Italy",
    "Arcadia, United States",
    "Reston, United States",
    "Friedrichshafen, Germany",
    "Shahada, India",
    "Bunda, Tanzania",
    "Sakurai, Japan",
    "Chaiyaphum, Thailand",
    "Nawalgarh, India",
    "Tongchuan, China",
    "Shibuzi, China",
    "Uttaradit, Thailand",
    "Lixian, China",
    "Murakami, Japan",
    "Hongqiao, China",
    "Serres, Greece",
    "Faraskur, Egypt",
    "Nedumangad, India",
    "Trencin, Slovakia",
    "Aracruz, Brazil",
    "Zhutuo, China",
    "Korce, Albania",
    "San Andres, Colombia",
    "Patzun, Guatemala",
    "Bedzin, Poland",
    "la Nova Esquerra de l'Eixample, Spain",
    "Kirklareli, Turkey",
    "Ocala, United States",
    "Kisela Voda, Macedonia, The former Yugoslav Rep. of",
    "Niksic, Montenegro",
    "Clay, United States",
    "Dingtao, China",
    "Rundu, Namibia",
    "Dongxi, China",
    "Los Dos Caminos, Venezuela",
    "Central City, United States",
    "Kanbe, Myanmar",
    "Ar Rumaythiyah, Kuwait",
    "Taby, Sweden",
    "South Vineland, United States",
    "Lorient, France",
    "Sanford, United States",
    "Juye, China",
    "Beitbridge, Zimbabwe",
    "Nahariyya, Israel",
    "Ariquemes, Brazil",
    "Koga, Japan",
    "Nimbahera, India",
    "Kadi, India",
    "Felgueiras, Portugal",
    "Streatham, United Kingdom",
    "Hancheng, China",
    "Goeppingen, Germany",
    "Zgierz, Poland",
    "Caucasia, Colombia",
    "Hai Duong, Viet Nam",
    "Bowie, United States",
    "Simpang Empat, Malaysia",
    "Volzhsk, Russia",
    "Port Coquitlam, Canada",
    "Santangpu, China",
    "Kokomo, United States",
    "Chornomors'k, Ukraine",
    "Sarcelles, France",
    "Jatiwangi, Indonesia",
    "Acambaro, Mexico",
    "Vigevano, Italy",
    "Kawthoung, Myanmar",
    "Pessac, France",
    "Imaricho-ko, Japan",
    "Santa Rosa de Cabal, Colombia",
    "Sira, India",
    "Sidi Moussa, Algeria",
    "Baekrajan, Indonesia",
    "Wayne, United States",
    "Kraaifontein, South Africa",
    "Hardenberg, Netherlands",
    "Ivry-sur-Seine, France",
    "Cogan, Philippines",
    "Myaydo, Myanmar",
    "Kokubu-matsuki, Japan",
    "San Francisco El Alto, Guatemala",
    "Veles, Macedonia, The former Yugoslav Rep. of",
    "Tabaco, Philippines",
    "Chik Ballapur, India",
    "Jing'an, China",
    "Belen, Peru",
    "Pianura, Italy",
    "Bardoli, India",
    "Kogalym, Russia",
    "Jhargram, India",
    "Evreux, France",
    "Bootle, United Kingdom",
    "Santee, United States",
    "Emin, China",
    "Orito, Colombia",
    "Longtan, China",
    "Anxiang, China",
    "Lop Buri, Thailand",
    "Goerlitz, Germany",
    "Dublin, United States",
    "St. Albert, Canada",
    "Weymouth, United Kingdom",
    "Ash Shamiyah, Iraq",
    "Farroupilha, Brazil",
    "Maocun, China",
    "Mongagua, Brazil",
    "Harsin, Iran",
    "Francisco Beltrao, Brazil",
    "Sundsvall, Sweden",
    "El Hatillo, Venezuela",
    "Legnano, Italy",
    "Venissieux, France",
    "Cergy, France",
    "Samastipur, India",
    "Albu Kamal, Syria",
    "Ban Pong, Thailand",
    "Krymsk, Russia",
    "Huangpi, China",
    "Shanhecun, China",
    "Hernals, Austria",
    "Guercif, Morocco",
    "Biala Podlaska, Poland",
    "Gobichettipalayam, India",
    "Yantongshan, China",
    "Picos, Brazil",
    "Al Hindiyah, Iraq",
    "Hasanpur, India",
    "Clichy, France",
    "Guiren, China",
    "Maulavi Bazar, Bangladesh",
    "Palm Harbor, United States",
    "Santo Tomas, Philippines",
    "Poprad, Slovakia",
    "Kentau, Kazakhstan",
    "Tra Vinh, Viet Nam",
    "Medford, United States",
    "Marinilla, Colombia",
    "Cheremkhovo, Russia",
    "Fareham, United Kingdom",
    "Morley, United Kingdom",
    "Lencois Paulista, Brazil",
    "Cheshunt, United Kingdom",
    "Jahangirabad, India",
    "Sherkot, India",
    "Ain Temouchent, Algeria",
    "Katras, India",
    "Minbu, Myanmar",
    "Palakollu, India",
    "Zhongxing, China",
    "Halabja, Iraq",
    "Chililabombwe, Zambia",
    "Langxiang, China",
    "Chunian, Pakistan",
    "Catacaos, Peru",
    "Garoowe, Somalia",
    "Wani, India",
    "Naksalbari, India",
    "Midwest City, United States",
    "Ouarzazat, Morocco",
    "Center City, United States",
    "Margate, United States",
    "Beja, Tunisia",
    "Tiruvalla, India",
    "Villa Hayes, Paraguay",
    "Punta Alta, Argentina",
    "Bergama, Turkey",
    "Itoman, Japan",
    "Ponnuru, India",
    "Braganca, Brazil",
    "Arcoverde, Brazil",
    "South Whittier, United States",
    "Bageqi, China",
    "Tinley Park, United States",
    "Hamada, Japan",
    "Anamur, Turkey",
    "Suiling, China",
    "Pflugerville, United States",
    "Mafraq, Jordan",
    "Wenxing, China",
    "Diphu, India",
    "Sitamarhi, India",
    "Zhigulevsk, Russia",
    "Yingli, China",
    "Sakaidecho, Japan",
    "Chhibramau, India",
    "Kidderminster, United Kingdom",
    "Tilhar, India",
    "New Brunswick, United States",
    "General Pico, Argentina",
    "Frankfurt (Oder), Germany",
    "Annaka, Japan",
    "Grand Forks, United States",
    "Emmen, Netherlands",
    "Phra Phutthabat, Thailand",
    "Sangla Hill, Pakistan",
    "Colegiales, Argentina",
    "Sokol'niki, Russia",
    "Sokol, Russia",
    "Invercargill, New Zealand",
    "Reyhanli, Turkey",
    "Baishishan, China",
    "Fountain Valley, United States",
    "Hendala, Sri Lanka",
    "Haripur, Pakistan",
    "Minokamo, Japan",
    "Roslavl', Russia",
    "Minyat an Nasr, Egypt",
    "North Hills, United States",
    "Ninghai, China",
    "Ilkal, India",
    "Jacona de Plancarte, Mexico",
    "Manfredonia, Italy",
    "Veszprem, Hungary",
    "Neu-Hohenschoenhausen, Germany",
    "Bayt Lahya, West Bank and Gaza Strip",
    "Foligno, Italy",
    "Zhuzhai, China",
    "Purbalingga, Indonesia",
    "Diamond Bar, United States",
    "Kitgum, Uganda",
    "Shibin al Qanatir, Egypt",
    "Bada Barabil, India",
    "Hattingen, Germany",
    "Caacupe, Paraguay",
    "Muko, Japan",
    "Los Andes, Chile",
    "Avila, Spain",
    "La Dolorita, Venezuela",
    "Livingston, United Kingdom",
    "Bawku, Ghana",
    "Ushuaia, Argentina",
    "Nogata, Japan",
    "Barri de Sant Andreu, Spain",
    "Jinji, China",
    "Corby, United Kingdom",
    "Marseille 11, France",
    "Taunton, United States",
    "Fussa, Japan",
    "Ambalangoda, Sri Lanka",
    "Oak Lawn, United States",
    "Union, United States",
    "Vacaria, Brazil",
    "Ankeny, United States",
    "Puerto Penasco, Mexico",
    "Battir, West Bank and Gaza Strip",
    "Malard, Iran",
    "Weining, China",
    "Mettur, India",
    "Chicopee, United States",
    "Asaka, Uzbekistan",
    "Ras el Oued, Algeria",
    "Kuchaman, India",
    "Lakhdaria, Algeria",
    "San Vicent del Raspeig, Spain",
    "Pare, Indonesia",
    "Dartford, United Kingdom",
    "Polichni, Greece",
    "Cajamar, Brazil",
    "Castlereagh, United Kingdom",
    "Segovia, Spain",
    "Qingfu, China",
    "Dewsbury, United Kingdom",
    "Daokou, China",
    "Okcheon, South Korea",
    "Yashan, China",
    "Panruti, India",
    "Woluwe-Saint-Lambert, Belgium",
    "Lingayen, Philippines",
    "Janauba, Brazil",
    "Borovichi, Russia",
    "Tanjung Selor, Indonesia",
    "Hilden, Germany",
    "Sarpsborg, Norway",
    "Una, India",
    "Budapest XII. keruelet, Hungary",
    "Obera, Argentina",
    "Kaga Bandoro, Central African Republic",
    "Irving Park, United States",
    "Doertyol, Turkey",
    "Urena, Venezuela",
    "Ponnur, India",
    "Kollegal, India",
    "Nantai, China",
    "Balai Pungut, Indonesia",
    "Shegaon, India",
    "Magong, Taiwan, China",
    "Potenza, Italy",
    "Chernaya Rechka, Russia",
    "Changleng, China",
    "Formiga, Brazil",
    "Soroti, Uganda",
    "Mocoa, Colombia",
    "Nabeul, Tunisia",
    "Winterhude, Germany",
    "Mon Repos, Trinidad and Tobago",
    "Stolberg, Germany",
    "Hutang, China",
    "Tarneit, Australia",
    "Pul-e Khumri, Afghanistan",
    "Berwyn, United States",
    "Nankana Sahib, Pakistan",
    "Upleta, India",
    "Sanchuan, China",
    "Ujhani, India",
    "Manhattan, United States",
    "Al Qanatir al Khayriyah, Egypt",
    "Honiara, Solomon Islands",
    "Fier, Albania",
    "Sitou, China",
    "Stourbridge, United Kingdom",
    "Bankra, India",
    "Mendaha, Indonesia",
    "Forest, Belgium",
    "Dabhoi, India",
    "Zibihu, China",
    "Mutsu, Japan",
    "Baidi, China",
    "Sleman, Indonesia",
    "Gaogou, China",
    "Espinal, Colombia",
    "Huauchinango, Mexico",
    "Hohoe, Ghana",
    "Vranje, Serbia",
    "Kirishi, Russia",
    "Goelcuek, Turkey",
    "Khartsyzk, Ukraine",
    "Linghai, China",
    "Turbaco, Colombia",
    "Paita, Peru",
    "Kendale Lakes, United States",
    "Smyrna, United States",
    "Dearborn Heights, United States",
    "Longshi, China",
    "Bari, India",
    "Moncalieri, Italy",
    "Rodos, Greece",
    "Obra, India",
    "Umm el Fahm, Israel",
    "Xuzhuang, China",
    "Sankt Augustin, Germany",
    "Gohana, India",
    "Ayapel, Colombia",
    "Porterville, United States",
    "Piscataway, United States",
    "Pandak, Indonesia",
    "Gongyi, China",
    "Hendersonville, United States",
    "Roeselare, Belgium",
    "Eisen, South Korea",
    "Jatani, India",
    "Kapenguria, Kenya",
    "Ambilobe, Madagascar",
    "Ezhva, Russia",
    "Namakkal, India",
    "Lang Son, Viet Nam",
    "Cuneo, Italy",
    "Qinnan, China",
    "Bungoma, Kenya",
    "Gadwal, India",
    "Liski, Russia",
    "Gelan, China",
    "Morningside Heights, United States",
    "Zviahel, Ukraine",
    "San Francisco, Costa Rica",
    "Tiko, Cameroon",
    "Palitana, India",
    "Nokha, India",
    "Laharpur, India",
    "Kuala Selangor, Malaysia",
    "Carmona, Philippines",
    "Bolvadin, Turkey",
    "Cuenca, Spain",
    "Narwana, India",
    "Dalianwan, China",
    "Changling, China",
    "Chumphon, Thailand",
    "Tirhanimine, Morocco",
    "San Rafael, Venezuela",
    "Rocky Mount, United States",
    "Itapipoca, Brazil",
    "Corvallis, United States",
    "Eschweiler, Germany",
    "Tsevie, Togo",
    "Elk, Poland",
    "Ponta Pora, Brazil",
    "San Antonio, Paraguay",
    "Umm Ruwaba, Sudan",
    "Valdosta, United States",
    "Tomigusuku, Japan",
    "Islampur, India",
    "Sale, United Kingdom",
    "Bonan, China",
    "Zile, Turkey",
    "Chino, Japan",
    "Karad, India",
    "Hanford, United States",
    "Kampung Bukit Baharu, Malaysia",
    "Estancia, Brazil",
    "Lubao, Philippines",
    "Shibirghan, Afghanistan",
    "Zhuyi, China",
    "Evora, Portugal",
    "Klaeng, Thailand",
    "Dakhla, Morocco",
    "Swietochlowice, Poland",
    "Yongqing, China",
    "Castle Rock, United States",
    "Aplerbeck, Germany",
    "Snizhne, Ukraine",
    "Linqiong, China",
    "Greenwood, United States",
    "Arua, Uganda",
    "At Tall, Syria",
    "Amuntai, Indonesia",
    "Cacoal, Brazil",
    "Nuevo Casas Grandes, Mexico",
    "Chicago Lawn, United States",
    "Eminoenue, Turkey",
    "Hempstead, United States",
    "Wellington, South Africa",
    "Novato, United States",
    "Kettering, United States",
    "Basyun, Egypt",
    "Bellevue, United States",
    "Vyaz'ma, Russia",
    "Qishan, China",
    "Batac City, Philippines",
    "Maldonado, Uruguay",
    "Besuki, Indonesia",
    "Ribnita, Moldova, Republic of",
    "Baden-Baden, Germany",
    "Aurora, Canada",
    "Lanshan, China",
    "Buin, Chile",
    "Shoreline, United States",
    "Decatur, United States",
    "Sao Gabriel, Brazil",
    "Friedrichsfelde, Germany",
    "Anjiang, China",
    "San Fernando, Trinidad and Tobago",
    "Xinqing, China",
    "Gapyeong, South Korea",
    "Paramount, United States",
    "Beledweyne, Somalia",
    "Placetas, Cuba",
    "Saint-Quentin, France",
    "Bisceglie, Italy",
    "Kaedi, Mauritania",
    "Kottayam, India",
    "Pruszkow, Poland",
    "Concordia, Brazil",
    "Bozuyuk, Turkey",
    "Minamiuonuma, Japan",
    "Taliwang, Indonesia",
    "Port Arthur, United States",
    "Takizawa, Japan",
    "Dorbod, China",
    "Shiroi, Japan",
    "Sesvete, Croatia",
    "Yutan, China",
    "Abington, United States",
    "Anderson, United States",
    "Wedi, Indonesia",
    "Jalaun, India",
    "Ahu, China",
    "Patzcuaro, Mexico",
    "Torrelavega, Spain",
    "Hidaka, Japan",
    "Pacatuba, Brazil",
    "Karimganj, India",
    "Satte, Japan",
    "Ahlen, Germany",
    "Tamiami, United States",
    "Halesowen, United Kingdom",
    "Nazarovo, Russia",
    "Rubizhne, Ukraine",
    "Villa Bruzual, Venezuela",
    "Ramganj, Bangladesh",
    "Canterbury, United Kingdom",
    "Kribi, Cameroon",
    "Mora, Cameroon",
    "Klimovsk, Russia",
    "Mokameh, India",
    "South Croydon, United Kingdom",
    "Towson, United States",
    "Naic, Philippines",
    "Bayan, China",
    "Etawa, India",
    "Wanparti, India",
    "North Chicopee, United States",
    "Elda, Spain",
    "Drean, Algeria",
    "Licha, China",
    "Pingjin, China",
    "Uptown, United States",
    "Can Gio, Viet Nam",
    "Abyek, Iran",
    "Viana, Brazil",
    "Kasongo, Congo",
    "Sarasota, United States",
    "Sabac, Serbia",
    "Narail, Bangladesh",
    "Kalasin, Thailand",
    "Lesnoy, Russia",
    "Samalkot, India",
    "Tiznit, Morocco",
    "Rybatskoye, Russia",
    "Cepu, Indonesia",
    "Uritsk, Russia",
    "Sao Pedro da Aldeia, Brazil",
    "Shilin, China",
    "Yeongam, South Korea",
    "Berezovskiy, Russia",
    "'Ali Sabieh, Djibouti",
    "Pokrovskoye-Streshnevo, Russia",
    "Waterloo, Sierra Leone",
    "Jinotega, Nicaragua",
    "Zamoskvorech'ye, Russia",
    "Anjangaon, India",
    "Dompu, Indonesia",
    "Samannud, Egypt",
    "Dholka, India",
    "Reghaia, Algeria",
    "Liguo, China",
    "Huangnihe, China",
    "Viljoenskroon, South Africa",
    "Saint-Jerome, Canada",
    "Cypress Hills, United States",
    "Caico, Brazil",
    "Tirur, India",
    "West Haven, United States",
    "Miyakojima, Japan",
    "Rosemead, United States",
    "Sabinas, Mexico",
    "Bad Salzuflen, Germany",
    "Euskirchen, Germany",
    "Edgewater, United States",
    "Al Khafji, Saudi Arabia",
    "Jackson, United States",
    "Highland, United States",
    "Kandi, India",
    "San Jose de las Lajas, Cuba",
    "Seropedica, Brazil",
    "Jonquiere, Canada",
    "Cabedelo, Brazil",
    "Mamoudzou, ",
    "Meerbusch, Germany",
    "Manzhouli, China",
    "Caaguazu, Paraguay",
    "Huguo, China",
    "Liuji, China",
    "Asenovgrad, Bulgaria",
    "Bandar Labuan, Malaysia",
    "Mount Prospect, United States",
    "Liangzhai, China",
    "Wolfenbuettel, Germany",
    "Huyton, United Kingdom",
    "Nelson, New Zealand",
    "Leytonstone, United Kingdom",
    "Rio de Mouro, Portugal",
    "Liaozhong, China",
    "Mizuho, Japan",
    "Huerth, Germany",
    "Shirbin, Egypt",
    "Barry, United Kingdom",
    "San Josecito, Venezuela",
    "Boghni, Algeria",
    "Niort, France",
    "Ghatal, India",
    "Tacurong, Philippines",
    "Goba, Ethiopia",
    "Odivelas, Portugal",
    "Hamura, Japan",
    "Colton, United States",
    "Unjha, India",
    "Martin, Slovakia",
    "Kotka, Finland",
    "San Marcos, El Salvador",
    "Encanto, United States",
    "Manhuacu, Brazil",
    "Konan, Japan",
    "Turda, Romania",
    "Wangji, China",
    "Belfort, France",
    "Huacho, Peru",
    "Menzel Bourguiba, Tunisia",
    "Al Mayadin, Syria",
    "Sendhwa, India",
    "Belorechensk, Russia",
    "El Banco, Colombia",
    "Carson City, United States",
    "San Bernardino Tlaxcalancingo, Mexico",
    "Chivilcoy, Argentina",
    "Himimachi, Japan",
    "Tiruttangal, India",
    "Hamilton, United Kingdom",
    "Penapolis, Brazil",
    "Ad Dawadimi, Saudi Arabia",
    "Modica, Italy",
    "Nanzhou, China",
    "Pocatello, United States",
    "Bradenton, United States",
    "Bolgatanga, Ghana",
    "Funza, Colombia",
    "Pirojpur, Bangladesh",
    "Narva, Estonia",
    "Rogers Park, United States",
    "Weymouth, United States",
    "Port Charlotte, United States",
    "Tharyarwady, Myanmar",
    "Yunjin, China",
    "Normal, United States",
    "Ajaccio, France",
    "Jagueey Grande, Cuba",
    "Teramo, Italy",
    "Bahla', Oman",
    "Zehlendorf, Germany",
    "Krasnokamensk, Russia",
    "Mozambique, Mozambique",
    "Siddhapur, India",
    "Dhuri, India",
    "Spring, United States",
    "Presidente Franco, Paraguay",
    "Allapattah, United States",
    "Okaya, Japan",
    "Hietzing, Austria",
    "Gravesend, United Kingdom",
    "Zuerich (Kreis 11), Switzerland",
    "Sangmelima, Cameroon",
    "Richland, United States",
    "Sidhi, India",
    "Eastleigh, United Kingdom",
    "Zinacantepec, Mexico",
    "Euless, United States",
    "Kupchino, Russia",
    "Dishna, Egypt",
    "Puerto Eldorado, Argentina",
    "Aveiro, Portugal",
    "Blue Springs, United States",
    "Pakenham, Australia",
    "Sabya, Saudi Arabia",
    "East Pensacola Heights, United States",
    "Kalamata, Greece",
    "Jurmala, Latvia",
    "Nirgua, Venezuela",
    "Meikle Earnock, United Kingdom",
    "Cerignola, Italy",
    "Aranjuez, Spain",
    "Hacienda Heights, United States",
    "Fangcun, China",
    "Kavala, Greece",
    "Lozova, Ukraine",
    "Dar Chioukh, Algeria",
    "Suzaka, Japan",
    "Nuevitas, Cuba",
    "Vannes, France",
    "Schweinfurt, Germany",
    "Wokha, India",
    "Altuf'yevskiy, Russia",
    "Ozone Park, United States",
    "Neustadt an der Weinstrasse, Germany",
    "Trani, Italy",
    "Sartrouville, France",
    "Druzhkivka, Ukraine",
    "Yongjian, China",
    "Kumbo, Cameroon",
    "Maisons-Alfort, France",
    "Kalna, India",
    "Xiazhuang, China",
    "Barbosa, Colombia",
    "Azul, Argentina",
    "Timashyovsk, Russia",
    "Mingcun, China",
    "Severomorsk, Russia",
    "Baramati, India",
    "Chania, Greece",
    "Siena, Italy",
    "Domodedovo, Russia",
    "Briarwood, United States",
    "Eger, Hungary",
    "Bijar, Iran",
    "Pishva, Iran",
    "Farshut, Egypt",
    "Lingcheng, China",
    "Cathedral City, United States",
    "Meaux, France",
    "Danane, Côte d'Ivoire",
    "Lakewood, United States",
    "Portici, Italy",
    "Vyshniy Volochek, Russia",
    "Elyria, United States",
    "Pulheim, Germany",
    "Bim Son, Viet Nam",
    "Jardines de la Silla (Jardines), Mexico",
    "Ostroleka, Poland",
    "Starachowice, Poland",
    "Markala, Mali",
    "Bac Giang, Viet Nam",
    "Wheaton, United States",
    "Commerce City, United States",
    "Acton, United Kingdom",
    "Santiago Teyahualco, Mexico",
    "Charikar, Afghanistan",
    "Arao, Japan",
    "Jinding, China",
    "Kandukur, India",
    "Blois, France",
    "Sunam, India",
    "Hoboken, United States",
    "Watsonville, United States",
    "Kongoussi, Burkina Faso",
    "Gaddi Annaram, India",
    "Coatepec, Mexico",
    "Miyoshi, Japan",
    "Sattenapalle, India",
    "Anapa, Russia",
    "San Bartolome de Tirajana, Spain",
    "Pamplona, Colombia",
    "Goshogawara, Japan",
    "Ercolano, Italy",
    "Shendi, Sudan",
    "Fnidek, Morocco",
    "Lake Havasu City, United States",
    "Qingxichang, China",
    "Papantla de Olarte, Mexico",
    "Hvidovre, Denmark",
    "Washington, United Kingdom",
    "Jinhua, China",
    "Hiriyur, India",
    "Les Abymes, Guadeloupe",
    "Buguruslan, Russia",
    "Morondava, Madagascar",
    "Challakere, India",
    "Waterford, Ireland",
    "El Viejo, Nicaragua",
    "Menemen, Turkey",
    "Woburn, Canada",
    "Braintree, United Kingdom",
    "Ilo, Peru",
    "Buzhuang, China",
    "Rampur Hat, India",
    "Brive-la-Gaillarde, France",
    "Chystyakove, Ukraine",
    "Masaurhi Buzurg, India",
    "Arles, France",
    "Revere, United States",
    "Shiquan, China",
    "Yangtun, China",
    "Kangavar, Iran",
    "Porto Feliz, Brazil",
    "Suphan Buri, Thailand",
    "Beauvais, France",
    "Nuzvid, India",
    "Rio Tercero, Argentina",
    "Zhangfeng, China",
    "West New York, United States",
    "Pasrur, Pakistan",
    "Ipojuca, Brazil",
    "Los Polvorines, Argentina",
    "Shajapur, India",
    "Tokamachi, Japan",
    "Puttur, India",
    "Chaeryong-up, Korea, Dem. People's Rep. of",
    "Yucaipa, United States",
    "Sedrata, Algeria",
    "Kasaragod, India",
    "Chateauroux, France",
    "Sao Tome, Sao Tome and Principe",
    "Katerini, Greece",
    "Ayodhya, India",
    "Iwade, Japan",
    "Sampang, Indonesia",
    "Mubarakpur, India",
    "Oami, Japan",
    "Yousuo, China",
    "Gilroy, United States",
    "Fryazevo, Russia",
    "Phaltan, India",
    "Pensacola, United States",
    "Zhaozhuang, China",
    "Poinciana, United States",
    "Sandila, India",
    "Tingo Maria, Peru",
    "Camaqua, Brazil",
    "Silivri, Turkey",
    "Cholet, France",
    "Zawiercie, Poland",
    "Ar Rastan, Syria",
    "Sinan, South Korea",
    "Sagar, India",
    "Bagheria, Italy",
    "Naka, Japan",
    "Gummersbach, Germany",
    "Cava De Tirreni, Italy",
    "Rioverde, Mexico",
    "Baruipur, India",
    "Tanjungagung, Indonesia",
    "Gulariya, Nepal",
    "Oosterhout, Netherlands",
    "Banes, Cuba",
    "Miryang, South Korea",
    "Xankandi, Azerbaijan",
    "Frejus, France",
    "Thai Binh, Viet Nam",
    "Wilhelmsburg, Germany",
    "Hatogaya-honcho, Japan",
    "Ciudad de Huajuapan de Leon, Mexico",
    "Medias, Romania",
    "Lohardaga, India",
    "Bartolome Maso, Cuba",
    "Bitlis, Turkey",
    "Kingsport, United States",
    "Makumbako, Tanzania",
    "Hassi Messaoud, Algeria",
    "Prievidza, Slovakia",
    "Tangzhang, China",
    "Levittown, United States",
    "Alexandroupoli, Greece",
    "Aversa, Italy",
    "Deolali, India",
    "Shahabad, India",
    "Waiblingen, Germany",
    "Crateus, Brazil",
    "Nueva Guinea, Nicaragua",
    "Palm Beach Gardens, United States",
    "Pantin, France",
    "Velletri, Italy",
    "Tha Maka, Thailand",
    "Enerhodar, Ukraine",
    "Patuto, Philippines",
    "Djamaa, Algeria",
    "Buseresere, Tanzania",
    "Lingwu, China",
    "Lyon 06, France",
    "Taoyuan, China",
    "Bobbili, India",
    "Chikhli, India",
    "Verviers, Belgium",
    "Limay, Philippines",
    "San Jose del Guaviare, Colombia",
    "Zacapu, Mexico",
    "Nordhorn, Germany",
    "Cocorote, Venezuela",
    "Port Shepstone, South Africa",
    "Adilcevaz, Turkey",
    "Saint-Brieuc, France",
    "Konch, India",
    "Kudamatsu, Japan",
    "Milford, United States",
    "Beisu, China",
    "Bandar-e Genaveh, Iran",
    "Kota Tinggi, Malaysia",
    "Mariendorf, Germany",
    "Delano, United States",
    "Colcapirhua, Bolivia",
    "Greifswald, Germany",
    "Lunglei, India",
    "West Sacramento, United States",
    "Madinat Hamad, Bahrain",
    "Wulingyuan, China",
    "Malingshan, China",
    "Slobozia, Romania",
    "Pithapuram, India",
    "Yartsevo, Russia",
    "Huntersville, United States",
    "Amalapuram, India",
    "Krasnokamsk, Russia",
    "Ergani, Turkey",
    "Perth Amboy, United States",
    "Rio Grande, Argentina",
    "Cuxhaven, Germany",
    "Sherman Oaks, United States",
    "Ridder, Kazakhstan",
    "Shiogama, Japan",
    "Hualong, China",
    "Tuni, India",
    "Wetzlar, Germany",
    "Borujen, Iran",
    "Alesund, Norway",
    "Dianbu, China",
    "Sihor, India",
    "Diglur, India",
    "Zabid, Yemen",
    "Southaven, United States",
    "Barbil, India",
    "Brentwood, United Kingdom",
    "Amora, Portugal",
    "Saint Peters, United States",
    "Daxing, China",
    "Terbanggi Besar, Indonesia",
    "Maiquetia, Venezuela",
    "Puji, China",
    "Caucagueito, Venezuela",
    "Pryluky, Ukraine",
    "Harrisonburg, United States",
    "Mongu, Zambia",
    "Corroios, Portugal",
    "Biswan, India",
    "Peabody, United States",
    "Bouira, Algeria",
    "Lautoka, Fiji",
    "Thongwa, Myanmar",
    "Placentia, United States",
    "Jaen, Peru",
    "Kosi, India",
    "Lenexa, United States",
    "Jette, Belgium",
    "DeSoto, United States",
    "Mollet del Valles, Spain",
    "Pursat, Cambodia",
    "Ciudad Bolivia, Venezuela",
    "Burlington, United States",
    "Manacapuru, Brazil",
    "Menden, Germany",
    "Montauban, France",
    "Tomiya, Japan",
    "South Hill, United States",
    "Kyaiklat, Myanmar",
    "Charleville-Mezieres, France",
    "Albi, France",
    "Shahr-e Babak, Iran",
    "Andradina, Brazil",
    "Campos do Jordao, Brazil",
    "Barra do Garcas, Brazil",
    "Esher, United Kingdom",
    "Cascavel, Brazil",
    "Hannan, Japan",
    "Elkhart, United States",
    "Bergkamen, Germany",
    "Kamifukuoka, Japan",
    "Ropar, India",
    "Obidos, Brazil",
    "La Crosse, United States",
    "Ishioka, Japan",
    "Eilat, Israel",
    "Dandeli, India",
    "Welland, Canada",
    "Oak Park, United States",
    "Mansoura, Algeria",
    "Shush, Iran",
    "Ponticelli, Italy",
    "Noshiro, Japan",
    "Songling, China",
    "Ain Defla, Algeria",
    "Florissant, United States",
    "Fryazino, Russia",
    "Sammamish, United States",
    "Yunshan, China",
    "Malungun, Philippines",
    "Matamoros, Mexico",
    "Hervey Bay, Australia",
    "Pula, Croatia",
    "Dapeng, China",
    "Taxco de Alarcon, Mexico",
    "Sanzhuang, China",
    "Zarinsk, Russia",
    "Wakefield, United States",
    "Ban Talat Yai, Thailand",
    "Erbaa, Turkey",
    "Tavsanli, Turkey",
    "Arandas, Mexico",
    "Albany, United States",
    "Zhawa, China",
    "Sandwip, Bangladesh",
    "Antratsyt, Ukraine",
    "Jitai, China",
    "Nonoichi, Japan",
    "Crosby, United Kingdom",
    "Hoffman Estates, United States",
    "Nyagan, Russia",
    "Reigate, United Kingdom",
    "Mikkeli, Finland",
    "Sar-e Pul, Afghanistan",
    "Lichtenrade, Germany",
    "Tarbes, France",
    "Parvatipuram, India",
    "Albany Park, United States",
    "Fontenay-sous-Bois, France",
    "Oume, Côte d'Ivoire",
    "Kimry, Russia",
    "Mouscron, Belgium",
    "Ar Riqqah, Kuwait",
    "Ta Khmau, Cambodia",
    "Zizhuang, China",
    "Huesca, Spain",
    "Walvis Bay, Namibia",
    "Decin, Czech Republic",
    "Liangji, China",
    "Methuen, United States",
    "Thomazeau, Haiti",
    "Kariya, Japan",
    "Glendora, United States",
    "Lamia, Greece",
    "Satkania, Bangladesh",
    "Levoberezhnyy, Russia",
    "Huamantla, Mexico",
    "Biguacu, Brazil",
    "Lyon 09, France",
    "Busia, Kenya",
    "Batatais, Brazil",
    "Dunstable, United Kingdom",
    "Ciudad Piar, Venezuela",
    "Rio do Sul, Brazil",
    "Arcot, India",
    "San Severo, Italy",
    "Kepanjen, Indonesia",
    "Queens Village, United States",
    "Roskilde, Denmark",
    "Dashahe, China",
    "Gjilan, ",
    "Brookhaven, United States",
    "Usulutan, El Salvador",
    "Gwadar, Pakistan",
    "Evry, France",
    "La Tirana, Chile",
    "Duzhou, China",
    "Jhalawar, India",
    "Corralillo, Cuba",
    "Levittown, United States",
    "Meybod, Iran",
    "Sa'dah, Yemen",
    "Palm Desert, United States",
    "Zhongxin, China",
    "Bad Homburg vor der Hoehe, Germany",
    "Tacuarembo, Uruguay",
    "Willich, Germany",
    "Puertollano, Spain",
    "Diyarb Najm, Egypt",
    "Ostuncalco, Guatemala",
    "Weimiao, China",
    "Petlad, India",
    "Joplin, United States",
    "Blora, Indonesia",
    "Umred, India",
    "Orhangazi, Turkey",
    "Enid, United States",
    "Calvia, Spain",
    "Talagante, Chile",
    "Bhatapara, India",
    "Qishan, China",
    "Kaliyaganj, India",
    "Nasirabad, India",
    "Leo, Burkina Faso",
    "Ijebu-Jesa, Nigeria",
    "Vidnoye, Russia",
    "Qoryooley, Somalia",
    "Telerghma, Algeria",
    "Bonita Springs, United States",
    "Irondequoit, United States",
    "Zepu, China",
    "Caldwell, United States",
    "Minnetonka, United States",
    "Bitonto, Italy",
    "Liuquan, China",
    "Pirapora, Brazil",
    "Futtsu, Japan",
    "Morecambe, United Kingdom",
    "Idappadi, India",
    "Pedregal, Panama",
    "Gohadi, India",
    "Shepparton, Australia",
    "Sagrada Familia, Spain",
    "Pinellas Park, United States",
    "Sarpol-e Zahab, Iran",
    "Cumbernauld, United Kingdom",
    "Tagawa, Japan",
    "Battle Creek, United States",
    "Maha Sarakham, Thailand",
    "Khagaul, India",
    "At Tall al Kabir, Egypt",
    "Redhill, United Kingdom",
    "Barinitas, Venezuela",
    "Tachilek, Myanmar",
    "Maubin, Myanmar",
    "Takab, Iran",
    "KwaDukuza, South Africa",
    "Mazara del Vallo, Italy",
    "Emden, Germany",
    "Amparafaravola, Madagascar",
    "Tala, Egypt",
    "Arganda, Spain",
    "Miura, Japan",
    "Agustin Codazzi, Colombia",
    "Zhefang, China",
    "Horsham, United Kingdom",
    "Casa Grande, United States",
    "Icara, Brazil",
    "South Shore, United States",
    "Mott Haven, United States",
    "The Villages, United States",
    "Grand Island, United States",
    "Chiplun, India",
    "Bezerros, Brazil",
    "Slonim, Belarus",
    "Changanacheri, India",
    "Sanhui, China",
    "Jendouba, Tunisia",
    "Grapevine, United States",
    "Clamart, France",
    "Tuytepa, Uzbekistan",
    "Monkayo, Philippines",
    "Neu-Ulm, Germany",
    "Stratford, United States",
    "Uriangato, Mexico",
    "Quibor, Venezuela",
    "Patiya, Bangladesh",
    "Kentwood, United States",
    "Tarma, Peru",
    "Tulun, Russia",
    "Lingen, Germany",
    "Marseille 10, France",
    "Venice, Italy",
    "Coronel Oviedo, Paraguay",
    "Barreiro, Portugal",
    "City of Milford (balance), United States",
    "Dianzi, China",
    "Chalons-en-Champagne, France",
    "Teoloyucan, Mexico",
    "Tigard, United States",
    "East Hartford, United States",
    "Ramnagar, India",
    "Nichinan, Japan",
    "Kabbasin, Syria",
    "Remchi, Algeria",
    "Teplice, Czech Republic",
    "Apple Valley, United States",
    "Plainfield, United States",
    "Susono, Japan",
    "Qulsary, Kazakhstan",
    "Leesburg, United States",
    "Birine, Algeria",
    "Erftstadt, Germany",
    "Panlong, China",
    "Ibshaway, Egypt",
    "Horizonte, Brazil",
    "Miyako, Japan",
    "El Bagre, Colombia",
    "Parsippany, United States",
    "Porvoo, Finland",
    "Coral Gables, United States",
    "Gangshang, China",
    "Neubrueck, Germany",
    "Reservoir, Australia",
    "Comal, Indonesia",
    "Seydisehir, Turkey",
    "Guanshan, China",
    "Ivanteyevka, Russia",
    "Nyeri, Kenya",
    "Puerto Berrio, Colombia",
    "Sillod, India",
    "Staines, United Kingdom",
    "Hikari, Japan",
    "Macherla, India",
    "Datong, China",
    "Khalkhal, Iran",
    "Melo, Uruguay",
    "Tadmur, Syria",
    "Rolandia, Brazil",
    "The Hammocks, United States",
    "Jesus Menendez, Cuba",
    "Kardzhali, Bulgaria",
    "Gostivar, Macedonia, The former Yugoslav Rep. of",
    "Shiraoka, Japan",
    "Tundla, India",
    "Zoliborz, Poland",
    "Acayucan, Mexico",
    "la Vila de Gracia, Spain",
    "Mannarakkat, India",
    "Shuijiang, China",
    "Ottappalam, India",
    "Whangarei, New Zealand",
    "Cimitarra, Colombia",
    "Ha GJong, Viet Nam",
    "Buckeye, United States",
    "Vittoria, Italy",
    "Donetsk, Russia",
    "Tocumen, Panama",
    "Pontian Kechil, Malaysia",
    "Flagami, United States",
    "Phra Nakhon Si Ayutthaya, Thailand",
    "Guanzhuang, China",
    "Barranqueras, Argentina",
    "Batley, United Kingdom",
    "Catalina Foothills, United States",
    "Legionowo, Poland",
    "Yerba Buena, Argentina",
    "Narbonne, France",
    "Goalpara, India",
    "Kankanhalli, India",
    "Fuchucho, Japan",
    "Chinu, Colombia",
    "Zumpango, Mexico",
    "Belleville, Canada",
    "Gelendzhik, Russia",
    "Al Miqdadiyah, Iraq",
    "Yasu, Japan",
    "Bingerville, Côte d'Ivoire",
    "Kobryn, Belarus",
    "Rajanpur, Pakistan",
    "Saint-Malo, France",
    "Mweka, Congo",
    "Madhupur, India",
    "Karanganom, Indonesia",
    "Utrera, Spain",
    "Oshnaviyeh, Iran",
    "Merzifon, Turkey",
    "Lakewood, United States",
    "Phetchabun, Thailand",
    "Rohri, Pakistan",
    "Lytkarino, Russia",
    "Shanji, China",
    "Saint Croix, Virgin Islands (US)",
    "Skien, Norway",
    "Oi, Japan",
    "Wellingborough, United Kingdom",
    "Vila-real, Spain",
    "Ibbenbueren, Germany",
    "Marechal Deodoro, Brazil",
    "Madhipura, India",
    "Kingisepp, Russia",
    "Herning, Denmark",
    "Kizlyar, Russia",
    "Passau, Germany",
    "Charkhi Dadri, India",
    "Clacton-on-Sea, United Kingdom",
    "Kawalu, Indonesia",
    "Sao Roque, Brazil",
    "Zhob, Pakistan",
    "Khorramdarreh, Iran",
    "Dumraon, India",
    "Turbo, Colombia",
    "Kavakli, Turkey",
    "Decan, ",
    "Budapest XXII. keruelet, Hungary",
    "Gbadolite, Congo",
    "Coari, Brazil",
    "Laval, France",
    "Hyeres, France",
    "Kyaukse, Myanmar",
    "North La Crosse, United States",
    "Burien, United States",
    "Tarnobrzeg, Poland",
    "Carsamba, Turkey",
    "Tafo, Ghana",
    "Hua Hin, Thailand",
    "Mahasamund, India",
    "Hanyuan, China",
    "Langenhagen, Germany",
    "Gallarate, Italy",
    "Willowdale East, Canada",
    "Arjona, Colombia",
    "San Mateo, Venezuela",
    "Bindura, Zimbabwe",
    "Schwerte, Germany",
    "Matli, Pakistan",
    "North Bay, Canada",
    "Dunfermline, United Kingdom",
    "Gajraula, India",
    "Logan, United States",
    "Yanghe, China",
    "Khagrachhari, Bangladesh",
    "Ness Ziona, Israel",
    "Ma'an, Jordan",
    "Speyer, Germany",
    "Nkoteng, Cameroon",
    "Saint-Hyacinthe, Canada",
    "Issia, Côte d'Ivoire",
    "Torbali, Turkey",
    "Olympia, United States",
    "Berwick, Australia",
    "Swedru, Ghana",
    "South Peabody, United States",
    "Yuki, Japan",
    "Rovigo, Italy",
    "Djemmal, Tunisia",
    "Midori, Japan",
    "Abu Road, India",
    "Chomutov, Czech Republic",
    "Nanjangud, India",
    "Al Khaburah, Oman",
    "Saint-Gilles, Belgium",
    "Salur, India",
    "Chusovoy, Russia",
    "Sour el Ghozlane, Algeria",
    "Gombong, Indonesia",
    "Anju, Korea, Dem. People's Rep. of",
    "Aliso Viejo, United States",
    "Bletchley, United Kingdom",
    "Villa Francisca, Dominican Republic",
    "Galveston, United States",
    "Keighley, United Kingdom",
    "Kasaoka, Japan",
    "Poway, United States",
    "Bundaberg, Australia",
    "Edina, United States",
    "Buta, Congo",
    "Otradnyy, Russia",
    "Minnetonka Mills, United States",
    "Metlili Chaamba, Algeria",
    "Balayan, Philippines",
    "Zacapu, Mexico",
    "Jihlava, Czech Republic",
    "Taquaritinga, Brazil",
    "Bhongir, India",
    "Snezhinsk, Russia",
    "Dunaujvaros, Hungary",
    "Nanjian, China",
    "Uiju, Korea, Dem. People's Rep. of",
    "Inda Silase, Ethiopia",
    "Moyobamba, Peru",
    "Alt-Hohenschoenhausen, Germany",
    "Heidenheim an der Brenz, Germany",
    "Tateyama, Japan",
    "Jatiroto, Indonesia",
    "Werribee, Australia",
    "Naxi, China",
    "Zhenxi, China",
    "Aonla, India",
    "Tipitapa, Nicaragua",
    "Novokhovrino, Russia",
    "Merelani, Tanzania",
    "Ghazieh, Lebanon",
    "Kozhukhovo, Russia",
    "Xo`jayli Shahri, Uzbekistan",
    "Juigalpa, Nicaragua",
    "Nikol'skoye, Russia",
    "Vatutino, Russia",
    "Stonecrest, United States",
    "Semenovskoye, Russia",
    "Berlin Treptow, Germany",
    "Moanda, Congo",
    "guengoeren merter, Turkey",
    "Merter Keresteciler, Turkey",
    "Monsanto, Portugal",
    "Untolovo, Russia",
    "Rawalakot, Pakistan",
    "Ko Samui, Thailand",
    "Hayes, United Kingdom",
    "Vagonoremont, Russia",
    "Kozeyevo, Russia",
    "Akademgorodok, Russia",
    "Putrajaya, Malaysia",
    "Arashiyama, Japan",
    "Al-Hamdaniya, Iraq",
    "Polanco, Mexico",
    "Ayn al 'Arab, Syria",
    "Hpa-An, Myanmar",
    "Novovladykino, Russia",
    "Likhobory, Russia",
    "Metrogorodok, Russia",
    "Ciudad Sandino, Nicaragua",
    "Novokuz'minki, Russia",
    "Badakhshān, Afghanistan",
    "Bādghīs, Afghanistan",
    "Baghlān, Afghanistan",
    "Balkh, Afghanistan",
    "Bāmyān, Afghanistan",
    "Dāykundī, Afghanistan",
    "Farāh, Afghanistan",
    "Fāryāb, Afghanistan",
    "Ghaznī, Afghanistan",
    "Ghōr, Afghanistan",
    "Helmand, Afghanistan",
    "Herāt, Afghanistan",
    "Jowzjān, Afghanistan",
    "Kābul, Afghanistan",
    "Kandahār, Afghanistan",
    "Kāpīsā, Afghanistan",
    "Khōst, Afghanistan",
    "Kunaṟ, Afghanistan",
    "Kunduz, Afghanistan",
    "Laghmān, Afghanistan",
    "Lōgar, Afghanistan",
    "Nangarhār, Afghanistan",
    "Nīmrōz, Afghanistan",
    "Nūristān, Afghanistan",
    "Paktīkā, Afghanistan",
    "Paktiyā, Afghanistan",
    "Panjshayr, Afghanistan",
    "Parwān, Afghanistan",
    "Samangān, Afghanistan",
    "Sar-e Pul, Afghanistan",
    "Takhār, Afghanistan",
    "Uruzgān, Afghanistan",
    "Wardak, Afghanistan",
    "Zābul, Afghanistan",
    "Berat, Albania",
    "Dibër, Albania",
    "Durrës, Albania",
    "Elbasan, Albania",
    "Fier, Albania",
    "Gjirokastër, Albania",
    "Korçë, Albania",
    "Kukës, Albania",
    "Lezhë, Albania",
    "Shkodër, Albania",
    "Tiranë, Albania",
    "Vlorë, Albania",
    "Andorra la Vella, Andorra",
    "Canillo, Andorra",
    "Encamp, Andorra",
    "Escaldes-Engordany, Andorra",
    "La Massana, Andorra",
    "Ordino, Andorra",
    "Sant Julià de Lòria, Andorra",
    "Bengo, Angola",
    "Benguela, Angola",
    "Bié, Angola",
    "Cabinda, Angola",
    "Cuando Cubango, Angola",
    "Cuanza-Norte, Angola",
    "Cuanza-Sul, Angola",
    "Cunene, Angola",
    "Huambo, Angola",
    "Huíla, Angola",
    "Luanda, Angola",
    "Lunda-Norte, Angola",
    "Lunda-Sul, Angola",
    "Malange, Angola",
    "Moxico, Angola",
    "Namibe, Angola",
    "Uíge, Angola",
    "Zaire, Angola",
    "Saint George, Antigua and Barbuda",
    "Saint John, Antigua and Barbuda",
    "Saint Mary, Antigua and Barbuda",
    "Saint Paul, Antigua and Barbuda",
    "Saint Peter, Antigua and Barbuda",
    "Saint Philip, Antigua and Barbuda",
    "Barbuda, Antigua and Barbuda",
    "Redonda, Antigua and Barbuda",
    "Ciudad Autónoma de Buenos Aires, Argentina",
    "Buenos Aires, Argentina",
    "Catamarca, Argentina",
    "Chaco, Argentina",
    "Chubut, Argentina",
    "Córdoba, Argentina",
    "Corrientes, Argentina",
    "Entre Ríos, Argentina",
    "Formosa, Argentina",
    "Jujuy, Argentina",
    "La Pampa, Argentina",
    "La Rioja, Argentina",
    "Mendoza, Argentina",
    "Misiones, Argentina",
    "Neuquén, Argentina",
    "Río Negro, Argentina",
    "Salta, Argentina",
    "San Juan, Argentina",
    "San Luis, Argentina",
    "Santa Cruz, Argentina",
    "Santa Fe, Argentina",
    "Santiago del Estero, Argentina",
    "Tierra del Fuego, Argentina",
    "Tucumán, Argentina",
    "Aragac̣otn, Armenia",
    "Ararat, Armenia",
    "Armavir, Armenia",
    "Erevan, Armenia",
    "Geġark'unik', Armenia",
    "Kotayk', Armenia",
    "Loṙi, Armenia",
    "Širak, Armenia",
    "Syunik', Armenia",
    "Tavuš, Armenia",
    "Vayoć Jor, Armenia",
    "New South Wales, Australia",
    "Queensland, Australia",
    "South Australia, Australia",
    "Tasmania, Australia",
    "Victoria, Australia",
    "Western Australia, Australia",
    "Australian Capital Territory, Australia",
    "Northern Territory, Australia",
    "Burgenland, Austria",
    "Kärnten, Austria",
    "Niederösterreich, Austria",
    "Oberösterreich, Austria",
    "Salzburg, Austria",
    "Steiermark, Austria",
    "Tirol, Austria",
    "Vorarlberg, Austria",
    "Wien, Austria",
    "Naxçıvan, Azerbaijan",
    "Acklins, Bahamas",
    "Berry Islands, Bahamas",
    "Bimini, Bahamas",
    "Black Point, Bahamas",
    "Cat Island, Bahamas",
    "Central Abaco, Bahamas",
    "Central Andros, Bahamas",
    "Central Eleuthera, Bahamas",
    "City of Freeport, Bahamas",
    "Crooked Island and Long Cay, Bahamas",
    "East Grand Bahama, Bahamas",
    "Exuma, Bahamas",
    "Grand Cay, Bahamas",
    "Harbour Island, Bahamas",
    "Hope Town, Bahamas",
    "Inagua, Bahamas",
    "Long Island, Bahamas",
    "Mangrove Cay, Bahamas",
    "Mayaguana, Bahamas",
    "Moore's Island, Bahamas",
    "New Providence, Bahamas",
    "North Abaco, Bahamas",
    "North Andros, Bahamas",
    "North Eleuthera, Bahamas",
    "Ragged Island, Bahamas",
    "Rum Cay, Bahamas",
    "San Salvador, Bahamas",
    "South Abaco, Bahamas",
    "South Andros, Bahamas",
    "South Eleuthera, Bahamas",
    "Spanish Wells, Bahamas",
    "West Grand Bahama, Bahamas",
    "Al ‘Āşimah, Bahrain",
    "Al Janūbīyah, Bahrain",
    "Al Muḩarraq, Bahrain",
    "Ash Shamālīyah, Bahrain",
    "Barishal, Bangladesh",
    "Chattogram, Bangladesh",
    "Dhaka, Bangladesh",
    "Khulna, Bangladesh",
    "Mymensingh, Bangladesh",
    "Rajshahi, Bangladesh",
    "Rangpur, Bangladesh",
    "Sylhet, Bangladesh",
    "Christ Church, Barbados",
    "Saint Andrew, Barbados",
    "Saint George, Barbados",
    "Saint James, Barbados",
    "Saint John, Barbados",
    "Saint Joseph, Barbados",
    "Saint Lucy, Barbados",
    "Saint Michael, Barbados",
    "Saint Peter, Barbados",
    "Saint Philip, Barbados",
    "Saint Thomas, Barbados",
    "Brestskaya voblasts', Belarus",
    "Homyel'skaya voblasts', Belarus",
    "Horad Minsk, Belarus",
    "Hrodzyenskaya voblasts', Belarus",
    "Mahilyowskaya voblasts', Belarus",
    "Minskaya voblasts', Belarus",
    "Vitsyebskaya voblasts', Belarus",
    "Brussels Hoofdstedelijk Gewest, Belgium",
    "Vlaams Gewest, Belgium",
    "Waals Gewest[note 3], Belgium",
    "Belize, Belize",
    "Cayo, Belize",
    "Corozal, Belize",
    "Orange Walk, Belize",
    "Stann Creek, Belize",
    "Toledo, Belize",
    "Alibori, Benin",
    "Atacora, Benin",
    "Atlantique, Benin",
    "Borgou, Benin",
    "Collines, Benin",
    "Couffo, Benin",
    "Donga, Benin",
    "Littoral, Benin",
    "Mono, Benin",
    "Ouémé, Benin",
    "Plateau, Benin",
    "Zou, Benin",
    "Bumthang, Bhutan",
    "Chhukha, Bhutan",
    "Dagana, Bhutan",
    "Gasa, Bhutan",
    "Haa, Bhutan",
    "Lhuentse, Bhutan",
    "Monggar, Bhutan",
    "Paro, Bhutan",
    "Pema Gatshel, Bhutan",
    "Punakha, Bhutan",
    "Samdrup Jongkhar, Bhutan",
    "Samtse, Bhutan",
    "Sarpang, Bhutan",
    "Thimphu, Bhutan",
    "Trashigang, Bhutan",
    "Trashi Yangtse, Bhutan",
    "Trongsa, Bhutan",
    "Tsirang, Bhutan",
    "Wangdue Phodrang, Bhutan",
    "Zhemgang, Bhutan",
    "Cochabamba, Bolivia ",
    "Chuquisaca, Bolivia ",
    "El Beni, Bolivia ",
    "La Paz, Bolivia ",
    "Oruro, Bolivia ",
    "Pando, Bolivia ",
    "Potosí, Bolivia ",
    "Santa Cruz, Bolivia ",
    "Tarija, Bolivia ",
    "Federacija Bosne i Hercegovine, Bosnia and Herzegovina",
    "Republika Srpska, Bosnia and Herzegovina",
    "Brčko distrikt, Bosnia and Herzegovina",
    "Central, Botswana",
    "Chobe, Botswana",
    "Francistown, Botswana",
    "Gaborone, Botswana",
    "Ghanzi, Botswana",
    "Jwaneng, Botswana",
    "Kgalagadi, Botswana",
    "Kgatleng, Botswana",
    "Kweneng, Botswana",
    "Lobatse, Botswana",
    "North East, Botswana",
    "North West, Botswana",
    "Selibe Phikwe, Botswana",
    "South East, Botswana",
    "Southern, Botswana",
    "Sowa Town, Botswana",
    "Acre, Brazil",
    "Alagoas, Brazil",
    "Amapá, Brazil",
    "Amazonas, Brazil",
    "Bahia, Brazil",
    "Ceará, Brazil",
    "Distrito Federal, Brazil",
    "Espírito Santo, Brazil",
    "Goiás, Brazil",
    "Maranhão, Brazil",
    "Mato Grosso, Brazil",
    "Mato Grosso do Sul, Brazil",
    "Minas Gerais, Brazil",
    "Pará, Brazil",
    "Paraíba, Brazil",
    "Paraná, Brazil",
    "Pernambuco, Brazil",
    "Piauí, Brazil",
    "Rio de Janeiro, Brazil",
    "Rio Grande do Norte, Brazil",
    "Rio Grande do Sul, Brazil",
    "Rondônia, Brazil",
    "Roraima, Brazil",
    "Santa Catarina, Brazil",
    "São Paulo, Brazil",
    "Sergipe, Brazil",
    "Tocantins, Brazil",
    "Belait, Brunei Darussalam",
    "Brunei-Muara, Brunei Darussalam",
    "Temburong, Brunei Darussalam",
    "Tutong, Brunei Darussalam",
    "Blagoevgrad, Bulgaria",
    "Burgas, Bulgaria",
    "Dobrich, Bulgaria",
    "Gabrovo, Bulgaria",
    "Haskovo, Bulgaria",
    "Kardzhali, Bulgaria",
    "Kyustendil, Bulgaria",
    "Lovech, Bulgaria",
    "Montana, Bulgaria",
    "Pazardzhik, Bulgaria",
    "Pernik, Bulgaria",
    "Pleven, Bulgaria",
    "Plovdiv, Bulgaria",
    "Razgrad, Bulgaria",
    "Ruse, Bulgaria",
    "Shumen, Bulgaria",
    "Silistra, Bulgaria",
    "Sliven, Bulgaria",
    "Smolyan, Bulgaria",
    "Sofia, Bulgaria",
    "Sofia (stolitsa), Bulgaria",
    "Stara Zagora, Bulgaria",
    "Targovishte, Bulgaria",
    "Varna, Bulgaria",
    "Veliko Tarnovo, Bulgaria",
    "Vidin, Bulgaria",
    "Vratsa, Bulgaria",
    "Yambol, Bulgaria",
    "Boucle du Mouhoun, Burkina Faso",
    "Cascades, Burkina Faso",
    "Centre, Burkina Faso",
    "Centre-Est, Burkina Faso",
    "Centre-Nord, Burkina Faso",
    "Centre-Ouest, Burkina Faso",
    "Centre-Sud, Burkina Faso",
    "Est, Burkina Faso",
    "Hauts-Bassins, Burkina Faso",
    "Nord, Burkina Faso",
    "Plateau-Central, Burkina Faso",
    "Sahel, Burkina Faso",
    "Sud-Ouest, Burkina Faso",
    "Bubanza, Burundi",
    "Bujumbura Mairie, Burundi",
    "Bujumbura Rural, Burundi",
    "Bururi, Burundi",
    "Cankuzo, Burundi",
    "Cibitoke, Burundi",
    "Gitega, Burundi",
    "Karuzi, Burundi",
    "Kayanza, Burundi",
    "Kirundo, Burundi",
    "Makamba, Burundi",
    "Muramvya, Burundi",
    "Muyinga, Burundi",
    "Mwaro, Burundi",
    "Ngozi, Burundi",
    "Rumonge, Burundi",
    "Rutana, Burundi",
    "Ruyigi, Burundi",
    "Ilhas de Barlavento, Cabo Verde",
    "Ilhas de Sotavento, Cabo Verde",
    "Baat Dambang, Cambodia",
    "Banteay Mean Choăy, Cambodia",
    "Kaeb, Cambodia",
    "Kampong Chaam, Cambodia",
    "Kampong Chhnang, Cambodia",
    "Kampong Spueu, Cambodia",
    "Kampong Thum, Cambodia",
    "Kampot, Cambodia",
    "Kandaal, Cambodia",
    "Kaoh Kong, Cambodia",
    "Kracheh, Cambodia",
    "Mondol Kiri, Cambodia",
    "Otdar Mean Chey, Cambodia",
    "Pailin, Cambodia",
    "Phnom Penh, Cambodia",
    "Pousaat, Cambodia",
    "Preah Sihanouk, Cambodia",
    "Preah Vihear, Cambodia",
    "Prey Veaeng, Cambodia",
    "Rotanak Kiri, Cambodia",
    "Siem Reab, Cambodia",
    "Stueng Traeng, Cambodia",
    "Svaay Rieng, Cambodia",
    "Taakaev, Cambodia",
    "Tbong Khmum, Cambodia",
    "Adamaoua, Cameroon",
    "Centre, Cameroon",
    "East, Cameroon",
    "Far North, Cameroon",
    "Littoral, Cameroon",
    "North, Cameroon",
    "North-West, Cameroon",
    "South, Cameroon",
    "South-West, Cameroon",
    "West, Cameroon",
    "Alberta, Canada",
    "British Columbia, Canada",
    "Manitoba, Canada",
    "New Brunswick, Canada",
    "Newfoundland and Labrador, Canada",
    "Nova Scotia, Canada",
    "Ontario, Canada",
    "Prince Edward Island, Canada",
    "Quebec, Canada",
    "Saskatchewan, Canada",
    "Northwest Territories, Canada",
    "Nunavut, Canada",
    "Yukon, Canada",
    "Bangui, Central African Republic",
    "Bamingui-Bangoran, Central African Republic",
    "Basse-Kotto, Central African Republic",
    "Gribingui, Central African Republic",
    "Haut-Mbomou, Central African Republic",
    "Haute-Kotto, Central African Republic",
    "Haute-Sangha / Mambéré-Kadéï, Central African Republic",
    "Kémo-Gribingui, Central African Republic",
    "Lobaye, Central African Republic",
    "Mbomou, Central African Republic",
    "Nana-Mambéré, Central African Republic",
    "Ombella-Mpoko, Central African Republic",
    "Ouaka, Central African Republic",
    "Ouham, Central African Republic",
    "Ouham-Pendé, Central African Republic",
    "Sangha, Central African Republic",
    "Vakaga, Central African Republic",
    "Bahr el Ghazal (local variant is Barh-el-Ghazal), Chad",
    "Batha, Chad",
    "Borkou, Chad",
    "Chari-Baguirmi, Chad",
    "Ennedi-Est, Chad",
    "Ennedi-Ouest, Chad",
    "Guéra, Chad",
    "Hadjer Lamis, Chad",
    "Kanem, Chad",
    "Lac, Chad",
    "Logone-Occidental, Chad",
    "Logone-Oriental, Chad",
    "Mandoul, Chad",
    "Mayo-Kebbi-Est, Chad",
    "Mayo-Kebbi-Ouest, Chad",
    "Moyen-Chari, Chad",
    "Ouaddaï, Chad",
    "Salamat, Chad",
    "Sila, Chad",
    "Tandjilé, Chad",
    "Tibesti, Chad",
    "Ville de Ndjamena, Chad",
    "Wadi Fira, Chad",
    "Aisén del General Carlos Ibañez del Campo, Chile",
    "Antofagasta, Chile",
    "Arica y Parinacota, Chile",
    "Atacama, Chile",
    "Biobío, Chile",
    "Coquimbo, Chile",
    "La Araucanía, Chile",
    "Libertador General Bernardo O'Higgins, Chile",
    "Los Lagos, Chile",
    "Los Ríos, Chile",
    "Magallanes, Chile",
    "Maule, Chile",
    "Ñuble, Chile",
    "Región Metropolitana de Santiago, Chile",
    "Tarapacá, Chile",
    "Valparaíso, Chile",
    "Anhui Sheng, China",
    "Beijing Shi, China",
    "Chongqing Shi, China",
    "Fujian Sheng, China",
    "Guangdong Sheng, China",
    "Gansu Sheng, China",
    "Guangxi Zhuangzu Zizhiqu, China",
    "Guizhou Sheng, China",
    "Henan Sheng, China",
    "Hubei Sheng, China",
    "Hebei Sheng, China",
    "Hainan Sheng, China",
    "Hong Kong SAR (en)",
    "Heilongjiang Sheng, China",
    "Hunan Sheng, China",
    "Jilin Sheng, China",
    "Jiangsu Sheng, China",
    "Jiangxi Sheng, China",
    "Liaoning Sheng, China",
    "Macao SAR (en)",
    "Nei Mongol Zizhiqu, China",
    "Ningxia Huizu Zizhiqu, China",
    "Qinghai Sheng, China",
    "Sichuan Sheng, China",
    "Shandong Sheng, China",
    "Shanghai Shi, China",
    "Shaanxi Sheng, China",
    "Shanxi Sheng, China",
    "Tianjin Shi, China",
    "Taiwan Sheng, China",
    "Xinjiang Uygur Zizhiqu, China",
    "Xizang Zizhiqu, China",
    "Yunnan Sheng, China",
    "Zhejiang Sheng, China",
    "Amazonas, Colombia",
    "Antioquia, Colombia",
    "Arauca, Colombia",
    "Atlántico, Colombia",
    "Bolívar, Colombia",
    "Boyacá, Colombia",
    "Caldas, Colombia",
    "Caquetá, Colombia",
    "Casanare, Colombia",
    "Cauca, Colombia",
    "Cesar, Colombia",
    "Córdoba, Colombia",
    "Cundinamarca, Colombia",
    "Chocó, Colombia",
    "Distrito Capital de Bogotá, Colombia",
    "Guainía, Colombia",
    "Guaviare, Colombia",
    "Huila, Colombia",
    "La Guajira, Colombia",
    "Magdalena, Colombia",
    "Meta, Colombia",
    "Nariño, Colombia",
    "Norte de Santander, Colombia",
    "Putumayo, Colombia",
    "Quindío, Colombia",
    "Risaralda, Colombia",
    "San Andrés, Providencia y Santa Catalina, Colombia",
    "Santander, Colombia",
    "Sucre, Colombia",
    "Tolima, Colombia",
    "Valle del Cauca, Colombia",
    "Vaupés, Colombia",
    "Vichada, Colombia",
    "Grande Comore, Comoros",
    "Anjouan, Comoros",
    "Mohéli, Comoros",
    "Bouenza, Congo",
    "Brazzaville, Congo",
    "Cuvette, Congo",
    "Cuvette-Ouest, Congo",
    "Kouilou, Congo",
    "Lékoumou, Congo",
    "Likouala, Congo",
    "Niari, Congo",
    "Plateaux, Congo",
    "Pointe-Noire, Congo",
    "Pool, Congo",
    "Sangha, Congo",
    "Bas-Uélé, Democratic Republic of Congo",
    "Équateur, Democratic Republic of Congo",
    "Haut-Katanga, Democratic Republic of Congo",
    "Haut-Lomami, Democratic Republic of Congo",
    "Haut-Uélé, Democratic Republic of Congo",
    "Ituri, Democratic Republic of Congo",
    "Kasaï, Democratic Republic of Congo",
    "Kasaï Central, Democratic Republic of Congo",
    "Kasaï Oriental, Democratic Republic of Congo",
    "Kinshasa, Democratic Republic of Congo",
    "Kongo Central, Democratic Republic of Congo",
    "Kwango, Democratic Republic of Congo",
    "Kwilu, Democratic Republic of Congo",
    "Lomami, Democratic Republic of Congo",
    "Lualaba, Democratic Republic of Congo",
    "Mai-Ndombe, Democratic Republic of Congo",
    "Maniema, Democratic Republic of Congo",
    "Mongala, Democratic Republic of Congo",
    "Nord-Kivu, Democratic Republic of Congo",
    "Nord-Ubangi, Democratic Republic of Congo",
    "Sankuru, Democratic Republic of Congo",
    "Sud-Kivu, Democratic Republic of Congo",
    "Sud-Ubangi, Democratic Republic of Congo",
    "Tanganyika, Democratic Republic of Congo",
    "Tshopo, Democratic Republic of Congo",
    "Tshuapa, Democratic Republic of Congo",
    "Alajuela, Costa Rica",
    "Cartago, Costa Rica",
    "Guanacaste, Costa Rica",
    "Heredia, Costa Rica",
    "Limón, Costa Rica",
    "Puntarenas, Costa Rica",
    "San José, Costa Rica",
    "Abidjan, Côte d'Ivoire",
    "Bas-Sassandra, Côte d'Ivoire",
    "Comoé, Côte d'Ivoire",
    "Denguélé, Côte d'Ivoire",
    "Gôh-Djiboua, Côte d'Ivoire",
    "Lacs, Côte d'Ivoire",
    "Lagunes, Côte d'Ivoire",
    "Montagnes, Côte d'Ivoire",
    "Sassandra-Marahoué, Côte d'Ivoire",
    "Savanes, Côte d'Ivoire",
    "Vallée du Bandama, Côte d'Ivoire",
    "Woroba, Côte d'Ivoire",
    "Yamoussoukro, Côte d'Ivoire",
    "Zanzan, Côte d'Ivoire",
    "Bjelovarsko-bilogorska županija, Croatia",
    "Brodsko-posavska županija, Croatia",
    "Dubrovačko-neretvanska županija, Croatia",
    "Grad Zagreb, Croatia",
    "Istarska županija, Croatia",
    "Karlovačka županija, Croatia",
    "Koprivničko-križevačka županija, Croatia",
    "Krapinsko-zagorska županija, Croatia",
    "Ličko-senjska županija, Croatia",
    "Međimurska županija, Croatia",
    "Osječko-baranjska županija, Croatia",
    "Požeško-slavonska županija, Croatia",
    "Primorsko-goranska županija, Croatia",
    "Sisačko-moslavačka županija, Croatia",
    "Splitsko-dalmatinska županija, Croatia",
    "Šibensko-kninska županija, Croatia",
    "Varaždinska županija, Croatia",
    "Virovitičko-podravska županija, Croatia",
    "Vukovarsko-srijemska županija, Croatia",
    "Zadarska županija, Croatia",
    "Zagrebačka županija, Croatia",
    "Artemisa, Cuba",
    "Camagüey, Cuba",
    "Ciego de Ávila, Cuba",
    "Cienfuegos, Cuba",
    "Granma, Cuba",
    "Guantánamo, Cuba",
    "Holguín, Cuba",
    "La Habana†, Cuba",
    "Las Tunas, Cuba",
    "Matanzas, Cuba",
    "Mayabeque, Cuba",
    "Pinar del Río, Cuba",
    "Sancti Spíritus, Cuba",
    "Santiago de Cuba, Cuba",
    "Villa Clara, Cuba",
    "Isla de la Juventud, Cuba",
    "Ammochostos, Cyprus",
    "Keryneia, Cyprus",
    "Larnaka, Cyprus",
    "Lefkosia, Cyprus",
    "Lemesos, Cyprus",
    "Pafos, Cyprus",
    "Jihočeský kraj, Czechia",
    "Jihomoravský kraj, Czechia",
    "Karlovarský kraj, Czechia",
    "Královéhradecký kraj, Czechia",
    "Liberecký kraj, Czechia",
    "Moravskoslezský kraj, Czechia",
    "Olomoucký kraj, Czechia",
    "Pardubický kraj, Czechia",
    "Plzeňský kraj, Czechia",
    "Praha, Hlavní město, Czechia",
    "Středočeský kraj, Czechia",
    "Ústecký kraj, Czechia",
    "Kraj Vysočina, Czechia",
    "Zlínský kraj, Czechia",
    "Region Hovedstaden, Denmark",
    "Region Midjylland, Denmark",
    "Region Nordjylland, Denmark",
    "Region Sjælland, Denmark",
    "Region Syddanmark, Denmark",
    "Ali Sabieh, Djibouti",
    "Arta, Djibouti",
    "Dikhil, Djibouti",
    "Djibouti, Djibouti",
    "Obock, Djibouti",
    "Tadjourah, Djibouti",
    "Saint Andrew, Dominica",
    "Saint David, Dominica",
    "Saint George, Dominica",
    "Saint John, Dominica",
    "Saint Joseph, Dominica",
    "Saint Luke, Dominica",
    "Saint Mark, Dominica",
    "Saint Patrick, Dominica",
    "Saint Paul, Dominica",
    "Saint Peter, Dominica",
    "Azua, Dominican Republic",
    "Baoruco, Dominican Republic",
    "Barahona, Dominican Republic",
    "Dajabón, Dominican Republic",
    "Distrito Nacional (Santo Domingo), Dominican Republic",
    "Duarte, Dominican Republic",
    "El Seibo, Dominican Republic",
    "Elías Piña, Dominican Republic",
    "Espaillat, Dominican Republic",
    "Hato Mayor, Dominican Republic",
    "Hermanas Mirabal, Dominican Republic",
    "Independencia, Dominican Republic",
    "La Altagracia, Dominican Republic",
    "La Romana, Dominican Republic",
    "La Vega, Dominican Republic",
    "María Trinidad Sánchez, Dominican Republic",
    "Monseñor Nouel, Dominican Republic",
    "Monte Cristi, Dominican Republic",
    "Monte Plata, Dominican Republic",
    "Pedernales, Dominican Republic",
    "Peravia, Dominican Republic",
    "Puerto Plata, Dominican Republic",
    "Samaná, Dominican Republic",
    "San Cristóbal, Dominican Republic",
    "San José de Ocoa, Dominican Republic",
    "San Juan, Dominican Republic",
    "San Pedro de Macorís, Dominican Republic",
    "Sánchez Ramírez, Dominican Republic",
    "Santiago, Dominican Republic",
    "Santiago Rodríguez, Dominican Republic",
    "Santo Domingo, Dominican Republic",
    "Valverde, Dominican Republic",
    "Azuay, Ecuador",
    "Bolívar, Ecuador",
    "Cañar, Ecuador",
    "Carchi, Ecuador",
    "Chimborazo, Ecuador",
    "Cotopaxi, Ecuador",
    "El Oro, Ecuador",
    "Esmeraldas, Ecuador",
    "Galápagos, Ecuador",
    "Guayas, Ecuador",
    "Imbabura, Ecuador",
    "Loja, Ecuador",
    "Los Ríos, Ecuador",
    "Manabí, Ecuador",
    "Morona Santiago, Ecuador",
    "Napo, Ecuador",
    "Orellana, Ecuador",
    "Pastaza, Ecuador",
    "Pichincha, Ecuador",
    "Santa Elena, Ecuador",
    "Santo Domingo de los Tsáchilas, Ecuador",
    "Sucumbíos, Ecuador",
    "Tungurahua, Ecuador",
    "Zamora Chinchipe, Ecuador",
    "Ad Daqahlīyah, Egypt",
    "Al Baḩr al Aḩmar, Egypt",
    "Al Buḩayrah, Egypt",
    "Al Fayyūm, Egypt",
    "Al Gharbīyah, Egypt",
    "Al Iskandarīyah, Egypt",
    "Al Ismā'īlīyah, Egypt",
    "Al Jīzah, Egypt",
    "Al Minūfīyah, Egypt",
    "Al Minyā, Egypt",
    "Al Qāhirah, Egypt",
    "Al Qalyūbīyah, Egypt",
    "Al Uqşur, Egypt",
    "Al Wādī al Jadīd, Egypt",
    "As Suways, Egypt",
    "Ash Sharqīyah, Egypt",
    "Aswān, Egypt",
    "Asyūţ, Egypt",
    "Banī Suwayf, Egypt",
    "Būr Sa‘īd, Egypt",
    "Dumyāţ, Egypt",
    "Janūb Sīnā', Egypt",
    "Kafr ash Shaykh, Egypt",
    "Maţrūḩ, Egypt",
    "Qinā, Egypt",
    "Shamāl Sīnā', Egypt",
    "Sūhāj, Egypt",
    "Ahuachapán, El Salvador",
    "Cabañas, El Salvador",
    "Chalatenango, El Salvador",
    "Cuscatlán, El Salvador",
    "La Libertad, El Salvador",
    "La Paz, El Salvador",
    "La Unión, El Salvador",
    "Morazán, El Salvador",
    "San Miguel, El Salvador",
    "San Salvador, El Salvador",
    "San Vicente, El Salvador",
    "Santa Ana, El Salvador",
    "Sonsonate, El Salvador",
    "Usulután, El Salvador",
    "Annobón, Equatorial Guinea",
    "Bioko Norte, Equatorial Guinea",
    "Bioko Sur, Equatorial Guinea",
    "Centro Sur, Equatorial Guinea",
    "Djibloho, Equatorial Guinea",
    "Kié-Ntem, Equatorial Guinea",
    "Litoral, Equatorial Guinea",
    "Wele-Nzas, Equatorial Guinea",
    "Al Awsaţ, Eritrea",
    "Al Janūbī, Eritrea",
    "Ansabā, Eritrea",
    "Janūbī al Baḩrī al Aḩmar, Eritrea",
    "Qāsh-Barkah, Eritrea",
    "Shimālī al Baḩrī al Aḩmar, Eritrea",
    "Harjumaa, Estonia",
    "Hiiumaa, Estonia",
    "Ida-Virumaa, Estonia",
    "Jõgevamaa, Estonia",
    "Järvamaa, Estonia",
    "Lääne-Virumaa, Estonia",
    "Läänemaa, Estonia",
    "Põlvamaa, Estonia",
    "Pärnumaa, Estonia",
    "Raplamaa, Estonia",
    "Saaremaa, Estonia",
    "Tartumaa, Estonia",
    "Valgamaa, Estonia",
    "Viljandimaa, Estonia",
    "Võrumaa, Estonia",
    "Hhohho, Eswatini",
    "Lubombo, Eswatini",
    "Manzini, Eswatini",
    "Shiselweni, Eswatini",
    "Ādīs Ābeba, Ethiopia",
    "Āfar, Ethiopia",
    "Āmara, Ethiopia",
    "Bīnshangul Gumuz, Ethiopia",
    "Dirē Dawa, Ethiopia",
    "Gambēla Hizboch, Ethiopia",
    "Hārerī Hizb, Ethiopia",
    "Oromīya, Ethiopia",
    "Sīdama, Ethiopia",
    "Sumalē, Ethiopia",
    "Tigray, Ethiopia",
    "YeDebub Bihēroch Bihēreseboch na Hizboch, Ethiopia",
    "YeDebub M‘irab Ītyop’iya Hizboch, Ethiopia",
    "Central, Fiji",
    "Eastern, Fiji",
    "Northern, Fiji",
    "Western, Fiji",
    "Rotuma, Fiji",
    "Ahvenanmaan maakunta, Finland",
    "Etelä-Karjala, Finland",
    "Etelä-Pohjanmaa, Finland",
    "Etelä-Savo, Finland",
    "Kainuu, Finland",
    "Kanta-Häme, Finland",
    "Keski-Pohjanmaa, Finland",
    "Keski-Suomi, Finland",
    "Kymenlaakso, Finland",
    "Lappi, Finland",
    "Pirkanmaa, Finland",
    "Pohjanmaa, Finland",
    "Pohjois-Karjala, Finland",
    "Pohjois-Pohjanmaa, Finland",
    "Pohjois-Savo, Finland",
    "Päijät-Häme, Finland",
    "Satakunta, Finland",
    "Uusimaa, Finland",
    "Varsinais-Suomi, Finland",
    "Auvergne-Rhône-Alpes, France",
    "Bourgogne-Franche-Comté, France",
    "Bretagne, France",
    "Centre-Val de Loire, France",
    "Corse, France",
    "Grand-Est, France",
    "Hauts-de-France, France",
    "Île-de-France, France",
    "Normandie, France",
    "Nouvelle-Aquitaine, France",
    "Occitanie, France",
    "Pays-de-la-Loire, France",
    "Provence-Alpes-Côte-d’Azur, France",
    "Ain, France",
    "Aisne, France",
    "Allier, France",
    "Alpes-de-Haute-Provence, France",
    "Alpes-Maritimes, France",
    "Alsace, France",
    "Ardèche, France",
    "Ardennes, France",
    "Ariège, France",
    "Aube, France",
    "Aude, France",
    "Aveyron, France",
    "Bouches-du-Rhône, France",
    "Calvados, France",
    "Cantal, France",
    "Charente, France",
    "Charente-Maritime, France",
    "Cher, France",
    "Corrèze, France",
    "Corse-du-Sud, France",
    "Côte-d'Or, France",
    "Côtes-d'Armor, France",
    "Creuse, France",
    "Deux-Sèvres, France",
    "Dordogne, France",
    "Doubs, France",
    "Drôme, France",
    "Essonne, France",
    "Eure, France",
    "Eure-et-Loir, France",
    "Finistère, France",
    "Gard, France",
    "Gers, France",
    "Gironde, France",
    "Guadeloupe, France",
    "Guyane (française), France",
    "Haute-Corse, France",
    "Haute-Garonne, France",
    "Haute-Loire, France",
    "Haute-Marne, France",
    "Haute-Saône, France",
    "Haute-Savoie, France",
    "Haute-Vienne, France",
    "Hautes-Alpes, France",
    "Hautes-Pyrénées, France",
    "Hauts-de-Seine, France",
    "Hérault, France",
    "Ille-et-Vilaine, France",
    "Indre, France",
    "Indre-et-Loire, France",
    "Isère, France",
    "Jura, France",
    "La Réunion, France",
    "Landes, France",
    "Loir-et-Cher, France",
    "Loire, France",
    "Loire-Atlantique, France",
    "Loiret, France",
    "Lot, France",
    "Lot-et-Garonne, France",
    "Lozère, France",
    "Maine-et-Loire, France",
    "Manche, France",
    "Marne, France",
    "Martinique, France",
    "Mayenne, France",
    "Mayotte, France",
    "Métropole de Lyon, France",
    "Meurthe-et-Moselle, France",
    "Meuse, France",
    "Morbihan, France",
    "Moselle, France",
    "Nièvre, France",
    "Nord, France",
    "Oise, France",
    "Orne, France",
    "Paris, France",
    "Pas-de-Calais, France",
    "Puy-de-Dôme, France",
    "Pyrénées-Atlantiques, France",
    "Pyrénées-Orientales, France",
    "Rhône[1], France",
    "Saône-et-Loire, France",
    "Sarthe, France",
    "Savoie, France",
    "Seine-et-Marne, France",
    "Seine-Maritime, France",
    "Seine-Saint-Denis, France",
    "Somme, France",
    "Tarn, France",
    "Tarn-et-Garonne, France",
    "Territoire de Belfort, France",
    "Val-d'Oise, France",
    "Val-de-Marne, France",
    "Var, France",
    "Vaucluse, France",
    "Vendée, France",
    "Vienne, France",
    "Vosges, France",
    "Yonne, France",
    "Yvelines, France",
    "Estuaire, Gabon",
    "Haut-Ogooué, Gabon",
    "Moyen-Ogooué, Gabon",
    "Ngounié, Gabon",
    "Nyanga, Gabon",
    "Ogooué-Ivindo, Gabon",
    "Ogooué-Lolo, Gabon",
    "Ogooué-Maritime, Gabon",
    "Woleu-Ntem, Gabon",
    "Banjul, Gambia",
    "Central River, Gambia",
    "Lower River, Gambia",
    "North Bank, Gambia",
    "Upper River, Gambia",
    "Western, Gambia",
    "Abkhazia, Georgia",
    "Ajaria, Georgia",
    "Guria, Georgia",
    "Imereti, Georgia",
    "K'akheti, Georgia",
    "Kvemo Kartli, Georgia",
    "Mtskheta-Mtianeti, Georgia",
    "Rach'a-Lechkhumi-Kvemo Svaneti, Georgia",
    "Samegrelo-Zemo Svaneti, Georgia",
    "Samtskhe-Javakheti, Georgia",
    "Shida Kartli, Georgia",
    "Tbilisi, Georgia",
    "Baden-Württemberg, Germany",
    "Bayern, Germany",
    "Berlin, Germany",
    "Brandenburg, Germany",
    "Bremen, Germany",
    "Hamburg, Germany",
    "Hessen, Germany",
    "Mecklenburg-Vorpommern, Germany",
    "Niedersachsen, Germany",
    "Nordrhein-Westfalen, Germany",
    "Rheinland-Pfalz, Germany",
    "Saarland, Germany",
    "Sachsen, Germany",
    "Sachsen-Anhalt, Germany",
    "Schleswig-Holstein, Germany",
    "Thüringen, Germany",
    "Ahafo, Ghana",
    "Ashanti, Ghana",
    "Bono, Ghana",
    "Bono East, Ghana",
    "Central, Ghana",
    "Eastern, Ghana",
    "Greater Accra, Ghana",
    "North East, Ghana",
    "Northern, Ghana",
    "Oti, Ghana",
    "Savannah, Ghana",
    "Upper East, Ghana",
    "Upper West, Ghana",
    "Volta, Ghana",
    "Western, Ghana",
    "Western North, Ghana",
    "Ágion Óros, Greece",
    "Anatolikí Makedonía kai Thráki, Greece",
    "Attikí, Greece",
    "Dytikí Elláda, Greece",
    "Dytikí Makedonía, Greece",
    "Ionía Nísia, Greece",
    "Ípeiros, Greece",
    "Kentrikí Makedonía, Greece",
    "Kríti, Greece",
    "Nótio Aigaío, Greece",
    "Pelopónnisos, Greece",
    "Stereá Elláda, Greece",
    "Thessalía, Greece",
    "Vóreio Aigaío, Greece",
    "Saint Andrew, Grenada",
    "Saint David, Grenada",
    "Saint George, Grenada",
    "Saint John, Grenada",
    "Saint Mark, Grenada",
    "Saint Patrick, Grenada",
    "Southern Grenadine Islands, Grenada",
    "Alta Verapaz, Guatemala",
    "Baja Verapaz, Guatemala",
    "Chimaltenango, Guatemala",
    "Chiquimula, Guatemala",
    "El Progreso, Guatemala",
    "Escuintla, Guatemala",
    "Guatemala, Guatemala",
    "Huehuetenango, Guatemala",
    "Izabal, Guatemala",
    "Jalapa, Guatemala",
    "Jutiapa, Guatemala",
    "Petén, Guatemala",
    "Quetzaltenango, Guatemala",
    "Quiché, Guatemala",
    "Retalhuleu, Guatemala",
    "Sacatepéquez, Guatemala",
    "San Marcos, Guatemala",
    "Santa Rosa, Guatemala",
    "Sololá, Guatemala",
    "Suchitepéquez, Guatemala",
    "Totonicapán, Guatemala",
    "Zacapa, Guatemala",
    "Boké, Guinea",
    "Faranah, Guinea",
    "Kankan, Guinea",
    "Kindia, Guinea",
    "Labé, Guinea",
    "Mamou, Guinea",
    "Nzérékoré, Guinea",
    "Conakry, Guinea",
    "Leste, Guinea-Bissau",
    "Norte, Guinea-Bissau",
    "Sul, Guinea-Bissau",
    "Barima-Waini, Guyana",
    "Cuyuni-Mazaruni, Guyana",
    "Demerara-Mahaica, Guyana",
    "East Berbice-Corentyne, Guyana",
    "Essequibo Islands-West Demerara, Guyana",
    "Mahaica-Berbice, Guyana",
    "Pomeroon-Supenaam, Guyana",
    "Potaro-Siparuni, Guyana",
    "Upper Demerara-Berbice, Guyana",
    "Upper Takutu-Upper Essequibo, Guyana",
    "Artibonite, Haiti",
    "Centre, Haiti",
    "Grande’Anse, Haiti",
    "Nippes, Haiti",
    "Nord, Haiti",
    "Nord-Est, Haiti",
    "Nord-Ouest, Haiti",
    "Ouest, Haiti",
    "Sud, Haiti",
    "Sud-Est, Haiti",
    "Atlántida, Honduras",
    "Choluteca, Honduras",
    "Colón, Honduras",
    "Comayagua, Honduras",
    "Copán, Honduras",
    "Cortés, Honduras",
    "El Paraíso, Honduras",
    "Francisco Morazán, Honduras",
    "Gracias a Dios, Honduras",
    "Intibucá, Honduras",
    "Islas de la Bahía, Honduras",
    "La Paz, Honduras",
    "Lempira, Honduras",
    "Ocotepeque, Honduras",
    "Olancho, Honduras",
    "Santa Bárbara, Honduras",
    "Valle, Honduras",
    "Yoro, Honduras",
    "Bács-Kiskun, Hungary",
    "Baranya, Hungary",
    "Békés, Hungary",
    "Békéscsaba, Hungary",
    "Borsod-Abaúj-Zemplén, Hungary",
    "Budapest, Hungary",
    "Csongrád-Csanád, Hungary",
    "Debrecen, Hungary",
    "Dunaújváros, Hungary",
    "Eger, Hungary",
    "Érd, Hungary",
    "Fejér, Hungary",
    "Győr, Hungary",
    "Győr-Moson-Sopron, Hungary",
    "Hajdú-Bihar, Hungary",
    "Heves, Hungary",
    "Hódmezővásárhely, Hungary",
    "Jász-Nagykun-Szolnok, Hungary",
    "Kaposvár, Hungary",
    "Kecskemét, Hungary",
    "Komárom-Esztergom, Hungary",
    "Miskolc, Hungary",
    "Nagykanizsa, Hungary",
    "Nógrád, Hungary",
    "Nyíregyháza, Hungary",
    "Pécs, Hungary",
    "Pest, Hungary",
    "Salgótarján, Hungary",
    "Somogy, Hungary",
    "Sopron, Hungary",
    "Szabolcs-Szatmár-Bereg, Hungary",
    "Szeged, Hungary",
    "Székesfehérvár, Hungary",
    "Szekszárd, Hungary",
    "Szolnok, Hungary",
    "Szombathely, Hungary",
    "Tatabánya, Hungary",
    "Tolna, Hungary",
    "Vas, Hungary",
    "Veszprém, Hungary",
    "Veszprém, Hungary",
    "Zala, Hungary",
    "Zalaegerszeg, Hungary",
    "Höfuðborgarsvæði, Iceland",
    "Suðurnes, Iceland",
    "Vesturland, Iceland",
    "Vestfirðir, Iceland",
    "Norðurland vestra, Iceland",
    "Norðurland eystra, Iceland",
    "Austurland, Iceland",
    "Suðurland, Iceland",
    "Andhra Pradesh, India",
    "Arunāchal Pradesh, India",
    "Assam, India",
    "Bihār, India",
    "Chhattīsgarh[note 1], India",
    "Goa, India",
    "Gujarāt, India",
    "Haryāna, India",
    "Himāchal Pradesh, India",
    "Jhārkhand, India",
    "Karnātaka, India",
    "Kerala, India",
    "Madhya Pradesh, India",
    "Mahārāshtra, India",
    "Manipur, India",
    "Meghālaya, India",
    "Mizoram, India",
    "Nāgāland, India",
    "Odisha[note 2], India",
    "Punjab, India",
    "Rājasthān, India",
    "Sikkim, India",
    "Tamil Nādu, India",
    "Telangāna[note 3], India",
    "Tripura, India",
    "Uttar Pradesh, India",
    "Uttarākhand[note 4], India",
    "West Bengal, India",
    "Andaman and Nicobar Islands, India",
    "Chandīgarh, India",
    "Delhi, India",
    "Dādra and Nagar Haveli and Damān and Diu[note 5], India",
    "Jammu and Kashmīr, India",
    "Ladākh, India",
    "Lakshadweep, India",
    "Puducherry, India",
    "Aceh, Indonesia",
    "Bali, Indonesia",
    "Banten, Indonesia",
    "Bengkulu, Indonesia",
    "Gorontalo, Indonesia",
    "Jakarta Raya, Indonesia",
    "Jambi, Indonesia",
    "Jawa Barat, Indonesia",
    "Jawa Tengah, Indonesia",
    "Jawa Timur, Indonesia",
    "Kalimantan Barat, Indonesia",
    "Kalimantan Selatan, Indonesia",
    "Kalimantan Tengah, Indonesia",
    "Kalimantan Timur, Indonesia",
    "Kalimantan Utara, Indonesia",
    "Kepulauan Bangka Belitung, Indonesia",
    "Kepulauan Riau, Indonesia",
    "Lampung, Indonesia",
    "Maluku, Indonesia",
    "Maluku Utara, Indonesia",
    "Nusa Tenggara Barat, Indonesia",
    "Nusa Tenggara Timur, Indonesia",
    "Papua, Indonesia",
    "Papua Barat, Indonesia",
    "Papua Pengunungan, Indonesia",
    "Papua Selatan, Indonesia",
    "Papua Tengah, Indonesia",
    "Riau, Indonesia",
    "Sulawesi Barat, Indonesia",
    "Sulawesi Selatan, Indonesia",
    "Sulawesi Tengah, Indonesia",
    "Sulawesi Tenggara, Indonesia",
    "Sulawesi Utara, Indonesia",
    "Sumatera Barat, Indonesia",
    "Sumatera Selatan, Indonesia",
    "Sumatera Utara, Indonesia",
    "Yogyakarta, Indonesia",
    "Alborz, Iran ",
    "Ardabīl, Iran ",
    "Āz̄ārbāyjān-e Ghārbī, Iran ",
    "Āz̄ārbāyjān-e Shārqī, Iran ",
    "Būshehr, Iran ",
    "Chahār Maḩāl va Bakhtīārī, Iran ",
    "Eşfahān, Iran ",
    "Fārs, Iran ",
    "Gīlān, Iran ",
    "Golestān, Iran ",
    "Hamadān, Iran ",
    "Hormozgān, Iran ",
    "Īlām, Iran ",
    "Kermān, Iran ",
    "Kermānshāh, Iran ",
    "Khorāsān-e Jonūbī, Iran ",
    "Khorāsān-e Raẕavī, Iran ",
    "Khorāsān-e Shomālī, Iran ",
    "Khūzestān, Iran ",
    "Kohgīlūyeh va Bowyer Aḩmad, Iran ",
    "Kordestān, Iran ",
    "Lorestān, Iran ",
    "Markazī, Iran ",
    "Māzandarān, Iran ",
    "Qazvīn, Iran ",
    "Qom, Iran ",
    "Semnān, Iran ",
    "Sīstān va Balūchestān, Iran ",
    "Tehrān, Iran ",
    "Yazd, Iran ",
    "Zanjān, Iran ",
    "Al Anbār, Iraq",
    "Al Başrah, Iraq",
    "Al Muthanná, Iraq",
    "Al Qādisīyah, Iraq",
    "An Najaf, Iraq",
    "Bābil, Iraq",
    "Baghdād, Iraq",
    "Dhī Qār, Iraq",
    "Diyālá, Iraq",
    "Iqlīm Kūrdistān, Iraq",
    "Karbalā’, Iraq",
    "Kirkūk, Iraq",
    "Maysān, Iraq",
    "Nīnawá, Iraq",
    "Şalāḩ ad Dīn, Iraq",
    "Wāsiţ, Iraq",
    "Arbīl, Iraq",
    "As Sulaymānīyah, Iraq",
    "Dahūk, Iraq",
    "Carlow, Ireland",
    "Cavan, Ireland",
    "Clare, Ireland",
    "Cork, Ireland",
    "Donegal, Ireland",
    "Dublin, Ireland",
    "Galway, Ireland",
    "Kerry, Ireland",
    "Kildare, Ireland",
    "Kilkenny, Ireland",
    "Laois, Ireland",
    "Leitrim, Ireland",
    "Limerick, Ireland",
    "Longford, Ireland",
    "Louth, Ireland",
    "Mayo, Ireland",
    "Meath, Ireland",
    "Monaghan, Ireland",
    "Offaly, Ireland",
    "Roscommon, Ireland",
    "Sligo, Ireland",
    "Tipperary, Ireland",
    "Waterford, Ireland",
    "Westmeath, Ireland",
    "Wexford, Ireland",
    "Wicklow, Ireland",
    "HaDarom, Israel",
    "HaMerkaz, Israel",
    "HaTsafon, Israel",
    "H̱efa, Israel",
    "Tel Aviv, Israel",
    "Yerushalayim, Israel",
    "Abruzzo, Italy",
    "Basilicata, Italy",
    "Calabria, Italy",
    "Campania, Italy",
    "Emilia-Romagna, Italy",
    "Friuli Venezia Giulia, Italy",
    "Lazio, Italy",
    "Liguria, Italy",
    "Lombardia, Italy",
    "Marche, Italy",
    "Molise, Italy",
    "Piemonte, Italy",
    "Puglia, Italy",
    "Sardegna, Italy",
    "Sicilia, Italy",
    "Toscana, Italy",
    "Trentino-Alto Adige, Trentino-Südtirol (de), Italy",
    "Umbria, Italy",
    "Valle d'Aosta, Val d'Aoste (fr), Italy",
    "Veneto, Italy",
    "Clarendon, Jamaica",
    "Hanover, Jamaica",
    "Kingston, Jamaica",
    "Manchester, Jamaica",
    "Portland, Jamaica",
    "Saint Andrew, Jamaica",
    "Saint Ann, Jamaica",
    "Saint Catherine, Jamaica",
    "Saint Elizabeth, Jamaica",
    "Saint James, Jamaica",
    "Saint Mary, Jamaica",
    "Saint Thomas, Jamaica",
    "Trelawny, Jamaica",
    "Westmoreland, Jamaica",
    "Aiti, Japan",
    "Akita, Japan",
    "Aomori, Japan",
    "Ehime, Japan",
    "Gihu, Japan",
    "Gunma, Japan",
    "Hirosima, Japan",
    "Hokkaidô, Japan",
    "Hukui, Japan",
    "Hukuoka, Japan",
    "Hukusima, Japan",
    "Hyôgo, Japan",
    "Ibaraki, Japan",
    "Isikawa, Japan",
    "Iwate, Japan",
    "Kagawa, Japan",
    "Kagosima, Japan",
    "Kanagawa, Japan",
    "Kôti, Japan",
    "Kumamoto, Japan",
    "Kyôto, Japan",
    "Mie, Japan",
    "Miyagi, Japan",
    "Miyazaki, Japan",
    "Nagano, Japan",
    "Nagasaki, Japan",
    "Nara, Japan",
    "Niigata, Japan",
    "Ôita, Japan",
    "Okayama, Japan",
    "Okinawa, Japan",
    "Ôsaka, Japan",
    "Saga, Japan",
    "Saitama, Japan",
    "Siga, Japan",
    "Simane, Japan",
    "Sizuoka, Japan",
    "Tiba, Japan",
    "Tokusima, Japan",
    "Tôkyô, Japan",
    "Totigi, Japan",
    "Tottori, Japan",
    "Toyama, Japan",
    "Wakayama, Japan",
    "Yamagata, Japan",
    "Yamaguti, Japan",
    "Yamanasi, Japan",
    "‘Ajlūn, Jordan",
    "Al ‘Aqabah, Jordan",
    "Al ‘A̅şimah, Jordan",
    "Al Balqā’, Jordan",
    "Al Karak, Jordan",
    "Al Mafraq, Jordan",
    "Aţ Ţafīlah, Jordan",
    "Az Zarqā’, Jordan",
    "Irbid, Jordan",
    "Jarash, Jordan",
    "Ma‘ān, Jordan",
    "Mādabā, Jordan",
    "Abay oblysy, Kazakhstan",
    "Almaty, Kazakhstan",
    "Almaty oblysy, Kazakhstan",
    "Aqmola oblysy, Kazakhstan",
    "Aqtöbe oblysy, Kazakhstan",
    "Astana, Kazakhstan",
    "Atyraū oblysy, Kazakhstan",
    "Batys Qazaqstan oblysy, Kazakhstan",
    "Mangghystaū oblysy, Kazakhstan",
    "Pavlodar oblysy, Kazakhstan",
    "Qaraghandy oblysy, Kazakhstan",
    "Qostanay oblysy, Kazakhstan",
    "Qyzylorda oblysy, Kazakhstan",
    "Shyghys Qazaqstan oblysy, Kazakhstan",
    "Shymkent, Kazakhstan",
    "Soltüstik Qazaqstan oblysy, Kazakhstan",
    "Türkistan oblysy, Kazakhstan",
    "Ulytaū oblysy, Kazakhstan",
    "Zhambyl oblysy, Kazakhstan",
    "Zhetisū oblysy, Kazakhstan",
    "Baringo, Kenya",
    "Bomet, Kenya",
    "Bungoma, Kenya",
    "Busia, Kenya",
    "Elgeyo/Marakwet, Kenya",
    "Embu, Kenya",
    "Garissa, Kenya",
    "Homa Bay, Kenya",
    "Isiolo, Kenya",
    "Kajiado, Kenya",
    "Kakamega, Kenya",
    "Kericho, Kenya",
    "Kiambu, Kenya",
    "Kilifi, Kenya",
    "Kirinyaga, Kenya",
    "Kisii, Kenya",
    "Kisumu, Kenya",
    "Kitui, Kenya",
    "Kwale, Kenya",
    "Laikipia, Kenya",
    "Lamu, Kenya",
    "Machakos, Kenya",
    "Makueni, Kenya",
    "Mandera, Kenya",
    "Marsabit, Kenya",
    "Meru, Kenya",
    "Migori, Kenya",
    "Mombasa, Kenya",
    "Murang'a, Kenya",
    "Nairobi City, Kenya",
    "Nakuru, Kenya",
    "Nandi, Kenya",
    "Narok, Kenya",
    "Nyamira, Kenya",
    "Nyandarua, Kenya",
    "Nyeri, Kenya",
    "Samburu, Kenya",
    "Siaya, Kenya",
    "Taita/Taveta, Kenya",
    "Tana River, Kenya",
    "Tharaka-Nithi, Kenya",
    "Trans Nzoia, Kenya",
    "Turkana, Kenya",
    "Uasin Gishu, Kenya",
    "Vihiga, Kenya",
    "Wajir, Kenya",
    "West Pokot, Kenya",
    "Gilbert Islands, Kiribati",
    "Line Islands, Kiribati",
    "Phoenix Islands, Kiribati",
    "Chagang, North Korea",
    "Kaesong, North Korea",
    "Kangwon, North Korea",
    "Nampo, North Korea",
    "North Hamgyong, North Korea",
    "North Hwanghae, North Korea",
    "North Pyongan, North Korea",
    "Pyongyang, North Korea",
    "Rason, North Korea",
    "Ryanggang, North Korea",
    "South Hamgyong, North Korea",
    "South Hwanghae, North Korea",
    "South Pyongan, North Korea",
    "Busan, South Korea",
    "Daegu, South Korea",
    "Daejeon, South Korea",
    "Gangwon, South Korea",
    "Gwangju, South Korea",
    "Gyeonggi, South Korea",
    "Incheon, South Korea",
    "Jeju, South Korea",
    "North Chungcheong, South Korea",
    "North Gyeongsang, South Korea",
    "North Jeolla, South Korea",
    "Sejong, South Korea",
    "Seoul, South Korea",
    "South Chungcheong, South Korea",
    "South Gyeongsang, South Korea",
    "South Jeolla, South Korea",
    "Ulsan, South Korea",
    "Al Aḩmadī, Kuwait",
    "Al Farwānīyah, Kuwait",
    "Al Jahrā’, Kuwait",
    "Al ‘Āşimah, Kuwait",
    "Ḩawallī, Kuwait",
    "Mubārak al Kabīr, Kuwait",
    "Batken, Kyrgyzstan",
    "Bishkek Shaary, Kyrgyzstan",
    "Chüy, Kyrgyzstan",
    "Jalal-Abad, Kyrgyzstan",
    "Naryn, Kyrgyzstan",
    "Osh, Kyrgyzstan",
    "Osh Shaary, Kyrgyzstan",
    "Talas, Kyrgyzstan",
    "Ysyk-Köl, Kyrgyzstan",
    "Attapu, Laos",
    "Bokèo, Laos",
    "Bolikhamxai, Laos",
    "Champasak, Laos",
    "Houaphan, Laos",
    "Khammouan, Laos",
    "Louang Namtha, Laos",
    "Louangphabang, Laos",
    "Oudômxai, Laos",
    "Phôngsali, Laos",
    "Salavan, Laos",
    "Savannakhét, Laos",
    "Viangchan, Laos",
    "Viangchan, Laos",
    "Xaignabouli, Laos",
    "Xékong, Laos",
    "Xiangkhouang, Laos",
    "Xaisômboun, Laos",
    "Aizkraukles novads, Latvia",
    "Alūksnes novads, Latvia",
    "Augšdaugavas novads, Latvia",
    "Ādažu novads, Latvia",
    "Balvu novads, Latvia",
    "Bauskas novads, Latvia",
    "Cēsu novads, Latvia",
    "Daugavpils, Latvia",
    "Dienvidkurzemes Novads, Latvia",
    "Dobeles novads, Latvia",
    "Gulbenes novads, Latvia",
    "Jelgava, Latvia",
    "Jelgavas novads, Latvia",
    "Jēkabpils novads, Latvia",
    "Jūrmala, Latvia",
    "Krāslavas novads, Latvia",
    "Kuldīgas novads, Latvia",
    "Ķekavas novads, Latvia",
    "Liepāja, Latvia",
    "Limbažu novads, Latvia",
    "Līvānu novads, Latvia",
    "Ludzas novads, Latvia",
    "Madonas novads, Latvia",
    "Mārupes novads, Latvia",
    "Ogres novads, Latvia",
    "Olaines novads, Latvia",
    "Preiļu novads, Latvia",
    "Rēzekne, Latvia",
    "Rēzeknes novads, Latvia",
    "Rīga, Latvia",
    "Ropažu novads, Latvia",
    "Salaspils novads, Latvia",
    "Saldus novads, Latvia",
    "Saulkrastu novads, Latvia",
    "Siguldas novads, Latvia",
    "Smiltenes novads, Latvia",
    "Talsu novads, Latvia",
    "Tukuma novads, Latvia",
    "Valkas novads, Latvia",
    "Valmieras Novads, Latvia",
    "Varakļānu novads, Latvia",
    "Ventspils, Latvia",
    "Ventspils novads, Latvia",
    "Aakkâr, Lebanon",
    "Baalbek-Hermel, Lebanon",
    "Béqaa, Lebanon",
    "Beyrouth, Lebanon",
    "Liban-Nord, Lebanon",
    "Liban-Sud, Lebanon",
    "Mont-Liban, Lebanon",
    "Nabatîyé, Lebanon",
    "Berea, Lesotho",
    "Botha-Bothe, Lesotho",
    "Leribe, Lesotho",
    "Mafeteng, Lesotho",
    "Maseru, Lesotho",
    "Mohale's Hoek, Lesotho",
    "Mokhotlong, Lesotho",
    "Qacha's Nek, Lesotho",
    "Quthing, Lesotho",
    "Thaba-Tseka, Lesotho",
    "Bomi, Liberia",
    "Bong, Liberia",
    "Gbarpolu, Liberia",
    "Grand Bassa, Liberia",
    "Grand Cape Mount, Liberia",
    "Grand Gedeh, Liberia",
    "Grand Kru, Liberia",
    "Lofa, Liberia",
    "Margibi, Liberia",
    "Maryland, Liberia",
    "Montserrado, Liberia",
    "Nimba, Liberia",
    "River Cess",
    "River Gee, Liberia",
    "Sinoe, Liberia",
    "Al Buţnān, Libya",
    "Al Jabal al Akhḑar, Libya",
    "Al Jabal al Gharbī, Libya",
    "Al Jafārah, Libya",
    "Al Jufrah, Libya",
    "Al Kufrah, Libya",
    "Al Marj, Libya",
    "Al Marqab, Libya",
    "Al Wāḩāt, Libya",
    "An Nuqāţ al Khams, Libya",
    "Az Zāwiyah, Libya",
    "Banghāzī, Libya",
    "Darnah, Libya",
    "Ghāt, Libya",
    "Mişrātah, Libya",
    "Murzuq, Libya",
    "Nālūt, Libya",
    "Sabhā, Libya",
    "Surt, Libya",
    "Ţarābulus, Libya",
    "Wādī al Ḩayāt, Libya",
    "Wādī ash Shāţi’, Libya",
    "Balzers, Liechtenstein",
    "Eschen, Liechtenstein",
    "Gamprin, Liechtenstein",
    "Mauren, Liechtenstein",
    "Planken, Liechtenstein",
    "Ruggell, Liechtenstein",
    "Schaan, Liechtenstein",
    "Schellenberg, Liechtenstein",
    "Triesen, Liechtenstein",
    "Triesenberg, Liechtenstein",
    "Vaduz, Liechtenstein",
    "Alytaus apskritis, Lithuania",
    "Kauno apskritis, Lithuania",
    "Klaipėdos apskritis, Lithuania",
    "Marijampolės apskritis, Lithuania",
    "Panevėžio apskritis, Lithuania",
    "Šiaulių apskritis, Lithuania",
    "Tauragės apskritis, Lithuania",
    "Telšių apskritis, Lithuania",
    "Utenos apskritis, Lithuania",
    "Vilniaus apskritis, Lithuania",
    "Capellen, Luxembourg",
    "Clerf, Luxembourg",
    "Diekirch, Luxembourg",
    "Echternach, Luxembourg",
    "Esch an der Alzette, Luxembourg",
    "Grevenmacher, Luxembourg",
    "Luxemburg, Luxembourg",
    "Mersch, Luxembourg",
    "Redingen, Luxembourg",
    "Remich, Luxembourg",
    "Vianden, Luxembourg",
    "Wiltz, Luxembourg",
    "Antananarivo, Madagascar",
    "Antsiranana, Madagascar",
    "Fianarantsoa, Madagascar",
    "Mahajanga, Madagascar",
    "Toamasina, Madagascar",
    "Toliara, Madagascar",
    "Central Region, Malawi",
    "Northern Region, Malawi",
    "Southern Region, Malawi",
    "Johor, Malaysia",
    "Kedah, Malaysia",
    "Kelantan, Malaysia",
    "Melaka, Malaysia",
    "Negeri Sembilan, Malaysia",
    "Pahang, Malaysia",
    "Perak, Malaysia",
    "Perlis, Malaysia",
    "Pulau Pinang, Malaysia",
    "Sabah, Malaysia",
    "Sarawak, Malaysia",
    "Selangor, Malaysia",
    "Terengganu, Malaysia",
    "Wilayah Persekutuan Kuala Lumpur, Malaysia",
    "Wilayah Persekutuan Labuan, Malaysia",
    "Wilayah Persekutuan Putrajaya, Malaysia",
    "Addu City, Maldives",
    "Male, Maldives",
    "Bamako, Mali",
    "Gao, Mali",
    "Kayes, Mali",
    "Kidal, Mali",
    "Koulikoro, Mali",
    "Ménaka, Mali",
    "Mopti, Mali",
    "Ségou, Mali",
    "Sikasso, Mali",
    "Taoudénit",
    "Tombouctou, Mali",
    "Attard, Malta",
    "Balzan, Malta",
    "Birgu, Malta",
    "Birkirkara, Malta",
    "Birżebbuġa, Malta",
    "Bormla, Malta",
    "Dingli, Malta",
    "Fgura, Malta",
    "Floriana, Malta",
    "Fontana, Malta",
    "Gudja, Malta",
    "Gżira, Malta",
    "Għajnsielem, Malta",
    "Għarb, Malta",
    "Għargħur, Malta",
    "Għasri, Malta",
    "Għaxaq, Malta",
    "Ħamrun, Malta",
    "Iklin, Malta",
    "Isla, Malta",
    "Kalkara, Malta",
    "Kerċem, Malta",
    "Kirkop, Malta",
    "Lija, Malta",
    "Luqa, Malta",
    "Marsa, Malta",
    "Marsaskala, Malta",
    "Marsaxlokk, Malta",
    "Mdina, Malta",
    "Mellieħa, Malta",
    "Mġarr, Malta",
    "Mosta, Malta",
    "Mqabba, Malta",
    "Msida, Malta",
    "Mtarfa, Malta",
    "Munxar, Malta",
    "Nadur, Malta",
    "Naxxar, Malta",
    "Paola, Malta",
    "Pembroke, Malta",
    "Pietà, Malta",
    "Qala, Malta",
    "Qormi, Malta",
    "Qrendi, Malta",
    "Rabat Gozo, Malta",
    "Rabat Malta, Malta",
    "Safi, Malta",
    "Saint Julian's, Malta",
    "Saint John, Malta",
    "Saint Lawrence, Malta",
    "Saint Paul's Bay, Malta",
    "Sannat, Malta",
    "Saint Lucia's, Malta",
    "Santa Venera, Malta",
    "Siġġiewi, Malta",
    "Sliema, Malta",
    "Swieqi, Malta",
    "Ta' Xbiex, Malta",
    "Tarxien, Malta",
    "Valletta, Malta",
    "Xagħra, Malta",
    "Xewkija, Malta",
    "Xgħajra, Malta",
    "Żabbar, Malta",
    "Żebbuġ Gozo, Malta",
    "Żebbuġ Malta, Malta",
    "Żejtun, Malta",
    "Żurrieq, Malta",
    "Ralik chain, Marshall Islands",
    "Ratak chain, Marshall Islands",
    "Adrar, Mauritania",
    "Assaba, Mauritania",
    "Brakna, Mauritania",
    "Dakhlet Nouâdhibou, Mauritania",
    "Gorgol, Mauritania",
    "Guidimaka, Mauritania",
    "Hodh ech Chargui, Mauritania",
    "Hodh el Gharbi, Mauritania",
    "Inchiri, Mauritania",
    "Nouakchott Nord, Mauritania",
    "Nouakchott Ouest, Mauritania",
    "Nouakchott Sud, Mauritania",
    "Tagant, Mauritania",
    "Tiris Zemmour, Mauritania",
    "Trarza, Mauritania",
    "Agalega Islands, Mauritius",
    "Black River, Mauritius",
    "Cargados Carajos Shoals",
    "Flacq, Mauritius",
    "Grand Port, Mauritius",
    "Moka, Mauritius",
    "Pamplemousses, Mauritius",
    "Plaines Wilhems, Mauritius",
    "Port Louis, Mauritius",
    "Rivière du Rempart, Mauritius",
    "Rodrigues Island, Mauritius",
    "Savanne, Mauritius",
    "Aguascalientes, Mexico",
    "Baja California, Mexico",
    "Baja California Sur, Mexico",
    "Campeche, Mexico",
    "Ciudad de México, Mexico",
    "Coahuila de Zaragoza, Mexico",
    "Colima, Mexico",
    "Chiapas, Mexico",
    "Chihuahua, Mexico",
    "Durango, Mexico",
    "Guanajuato, Mexico",
    "Guerrero, Mexico",
    "Hidalgo, Mexico",
    "Jalisco, Mexico",
    "México, Mexico",
    "Michoacán de Ocampo, Mexico",
    "Morelos, Mexico",
    "Nayarit, Mexico",
    "Nuevo León, Mexico",
    "Oaxaca, Mexico",
    "Puebla, Mexico",
    "Querétaro, Mexico",
    "Quintana Roo, Mexico",
    "San Luis Potosí, Mexico",
    "Sinaloa, Mexico",
    "Sonora, Mexico",
    "Tabasco, Mexico",
    "Tamaulipas, Mexico",
    "Tlaxcala, Mexico",
    "Veracruz de Ignacio de la Llave, Mexico",
    "Yucatán, Mexico",
    "Zacatecas, Mexico",
    "Chuuk, Micronesia ",
    "Kosrae, Micronesia ",
    "Pohnpei, Micronesia ",
    "Yap, Micronesia ",
    "Anenii Noi, Moldova",
    "Basarabeasca, Moldova",
    "Bălți, Moldova",
    "Bender [Tighina], Moldova",
    "Briceni, Moldova",
    "Cahul, Moldova",
    "Cantemir, Moldova",
    "Călărași, Moldova",
    "Căușeni, Moldova",
    "Chișinău, Moldova",
    "Cimișlia, Moldova",
    "Criuleni, Moldova",
    "Dondușeni, Moldova",
    "Drochia, Moldova",
    "Dubăsari, Moldova",
    "Edineț, Moldova",
    "Fălești, Moldova",
    "Florești, Moldova",
    "Găgăuzia, Unitatea teritorială autonomă (UTAG), Moldova",
    "Glodeni, Moldova",
    "Hîncești, Moldova",
    "Ialoveni, Moldova",
    "Leova, Moldova",
    "Nisporeni, Moldova",
    "Ocnița, Moldova",
    "Orhei, Moldova",
    "Rezina, Moldova",
    "Rîșcani, Moldova",
    "Sîngerei, Moldova",
    "Soroca, Moldova",
    "Stînga Nistrului, unitatea teritorială din, Moldova",
    "Strășeni, Moldova",
    "Șoldănești, Moldova",
    "Ștefan Vodă, Moldova",
    "Taraclia, Moldova",
    "Telenești, Moldova",
    "Ungheni, Moldova",
    "Fontvieille, Monaco",
    "Jardin Exotique, Monaco",
    "La Colle, Monaco",
    "La Condamine, Monaco",
    "La Gare, Monaco",
    "La Source, Monaco",
    "Larvotto, Monaco",
    "Malbousquet, Monaco",
    "Monaco-Ville, Monaco",
    "Moneghetti, Monaco",
    "Monte-Carlo, Monaco",
    "Moulins, Monaco",
    "Port-Hercule, Monaco",
    "Saint-Roman, Monaco",
    "Sainte-Dévote, Monaco",
    "Spélugues, Monaco",
    "Vallon de la Rousse, Monaco",
    "Arhangay, Mongolia",
    "Bayanhongor, Mongolia",
    "Bayan-Ölgiy, Mongolia",
    "Bulgan, Mongolia",
    "Darhan uul, Mongolia",
    "Dornod, Mongolia",
    "Dornogovĭ, Mongolia",
    "Dundgovĭ, Mongolia",
    "Dzavhan, Mongolia",
    "Govĭ-Altay, Mongolia",
    "Govĭ-Sümber, Mongolia",
    "Hentiy, Mongolia",
    "Hovd, Mongolia",
    "Hövsgöl, Mongolia",
    "Ömnögovĭ, Mongolia",
    "Orhon, Mongolia",
    "Övörhangay, Mongolia",
    "Selenge, Mongolia",
    "Sühbaatar, Mongolia",
    "Töv, Mongolia",
    "Ulaanbaatar, Mongolia",
    "Uvs, Mongolia",
    "Andrijevica, Montenegro",
    "Bar, Montenegro",
    "Berane, Montenegro",
    "Bijelo Polje, Montenegro",
    "Budva, Montenegro",
    "Cetinje, Montenegro",
    "Danilovgrad, Montenegro",
    "Herceg-Novi, Montenegro",
    "Kolašin, Montenegro",
    "Kotor, Montenegro",
    "Mojkovac, Montenegro",
    "Nikšić, Montenegro",
    "Plav, Montenegro",
    "Pljevlja, Montenegro",
    "Plužine, Montenegro",
    "Podgorica, Montenegro",
    "Rožaje, Montenegro",
    "Šavnik, Montenegro",
    "Tivat, Montenegro",
    "Ulcinj, Montenegro",
    "Žabljak, Montenegro",
    "Gusinje, Montenegro",
    "Petnjica, Montenegro",
    "Tuzi, Montenegro",
    "Béni Mellal-Khénifra, Morocco",
    "Casablanca-Settat, Morocco",
    "Dakhla-Oued Ed-Dahab (EH), Morocco",
    "Drâa-Tafilalet, Morocco",
    "Fès-Meknès, Morocco",
    "Guelmim-Oued Noun (EH-partial), Morocco",
    "L'Oriental, Morocco",
    "Laâyoune-Sakia El Hamra (EH-partial), Morocco",
    "Marrakech-Safi, Morocco",
    "Rabat-Salé-Kénitra, Morocco",
    "Souss-Massa, Morocco",
    "Tanger-Tétouan-Al Hoceïma, Morocco",
    "Cabo Delgado, Mozambique",
    "Gaza, Mozambique",
    "Inhambane, Mozambique",
    "Manica, Mozambique",
    "Maputo, Mozambique",
    "Maputo, Mozambique",
    "Nampula, Mozambique",
    "Niassa, Mozambique",
    "Sofala, Mozambique",
    "Tete, Mozambique",
    "Zambézia, Mozambique",
    "Ayeyarwady, Myanmar",
    "Bago, Myanmar",
    "Chin, Myanmar",
    "Kachin, Myanmar",
    "Kayah, Myanmar",
    "Kayin, Myanmar",
    "Magway, Myanmar",
    "Mandalay, Myanmar",
    "Mon, Myanmar",
    "Nay Pyi Taw, Myanmar",
    "Rakhine, Myanmar",
    "Sagaing, Myanmar",
    "Shan, Myanmar",
    "Tanintharyi, Myanmar",
    "Yangon, Myanmar",
    "Erongo, Namibia",
    "Hardap, Namibia",
    "Karas",
    "Kavango East, Namibia",
    "Kavango West, Namibia",
    "Khomas, Namibia",
    "Kunene, Namibia",
    "Ohangwena, Namibia",
    "Omaheke, Namibia",
    "Omusati, Namibia",
    "Oshana, Namibia",
    "Oshikoto, Namibia",
    "Otjozondjupa, Namibia",
    "Zambezi, Namibia",
    "Aiwo, Nauru",
    "Anabar, Nauru",
    "Anetan, Nauru",
    "Anibare, Nauru",
    "Baitsi (local variant is Baiti), Nauru",
    "Boe, Nauru",
    "Buada, Nauru",
    "Denigomodu, Nauru",
    "Ewa, Nauru",
    "Ijuw, Nauru",
    "Meneng, Nauru",
    "Nibok, Nauru",
    "Uaboe, Nauru",
    "Yaren, Nauru",
    "Pradesh 1, Nepal",
    "Madhesh, Nepal",
    "Bāgmatī, Nepal",
    "Gaṇḍakī, Nepal",
    "Lumbinī, Nepal",
    "Karṇālī, Nepal",
    "Sudūrpashchim, Nepal",
    "Drenthe, Netherlands",
    "Flevoland, Netherlands",
    "Fryslân (fy), Netherlands",
    "Gelderland, Netherlands",
    "Groningen, Netherlands",
    "Limburg, Netherlands",
    "Noord-Brabant, Netherlands",
    "Noord-Holland, Netherlands",
    "Overijssel, Netherlands",
    "Utrecht, Netherlands",
    "Zeeland, Netherlands",
    "Zuid-Holland, Netherlands",
    "Auckland, New Zealand",
    "Bay of Plenty, New Zealand",
    "Canterbury, New Zealand",
    "Chatham Islands Territory, New Zealand",
    "Gisborne, New Zealand",
    "Greater Wellington, New Zealand",
    "Hawke's Bay, New Zealand",
    "Manawatū-Whanganui, New Zealand",
    "Marlborough, New Zealand",
    "Nelson, New Zealand",
    "Northland, New Zealand",
    "Otago, New Zealand",
    "Southland, New Zealand",
    "Taranaki, New Zealand",
    "Tasman, New Zealand",
    "Waikato, New Zealand",
    "West Coast, New Zealand",
    "Boaco, Nicaragua",
    "Carazo, Nicaragua",
    "Chinandega, Nicaragua",
    "Chontales, Nicaragua",
    "Costa Caribe Norte, Nicaragua",
    "Costa Caribe Sur, Nicaragua",
    "Estelí, Nicaragua",
    "Granada, Nicaragua",
    "Jinotega, Nicaragua",
    "León, Nicaragua",
    "Madriz, Nicaragua",
    "Managua, Nicaragua",
    "Masaya, Nicaragua",
    "Matagalpa, Nicaragua",
    "Nueva Segovia, Nicaragua",
    "Río San Juan, Nicaragua",
    "Rivas, Nicaragua",
    "Agadez, Niger",
    "Diffa, Niger",
    "Dosso, Niger",
    "Maradi, Niger",
    "Niamey, Niger",
    "Tahoua, Niger",
    "Tillabéri, Niger",
    "Zinder, Niger",
    "Abia, Nigeria",
    "Abuja Federal Capital Territory, Nigeria",
    "Adamawa, Nigeria",
    "Akwa Ibom, Nigeria",
    "Anambra, Nigeria",
    "Bauchi, Nigeria",
    "Bayelsa, Nigeria",
    "Benue, Nigeria",
    "Borno, Nigeria",
    "Cross River, Nigeria",
    "Delta, Nigeria",
    "Ebonyi, Nigeria",
    "Edo, Nigeria",
    "Ekiti, Nigeria",
    "Enugu, Nigeria",
    "Gombe, Nigeria",
    "Imo, Nigeria",
    "Jigawa, Nigeria",
    "Kaduna, Nigeria",
    "Kano, Nigeria",
    "Katsina, Nigeria",
    "Kebbi, Nigeria",
    "Kogi, Nigeria",
    "Kwara, Nigeria",
    "Lagos, Nigeria",
    "Nasarawa, Nigeria",
    "Niger, Nigeria",
    "Ogun, Nigeria",
    "Ondo, Nigeria",
    "Osun, Nigeria",
    "Oyo, Nigeria",
    "Plateau, Nigeria",
    "Rivers, Nigeria",
    "Sokoto, Nigeria",
    "Taraba, Nigeria",
    "Yobe, Nigeria",
    "Zamfara, Nigeria",
    "Aerodrom, North Macedonia",
    "Aračinovo, North Macedonia",
    "Berovo, North Macedonia",
    "Bitola, North Macedonia",
    "Bogdanci, North Macedonia",
    "Bogovinje, North Macedonia",
    "Bosilovo, North Macedonia",
    "Brvenica, North Macedonia",
    "Butel, North Macedonia",
    "Centar, North Macedonia",
    "Centar Župa, North Macedonia",
    "Čair, North Macedonia",
    "Čaška, North Macedonia",
    "Češinovo-Obleševo, North Macedonia",
    "Čučer-Sandevo, North Macedonia",
    "Debar, North Macedonia",
    "Debrca, North Macedonia",
    "Delčevo, North Macedonia",
    "Demir Hisar, North Macedonia",
    "Demir Kapija, North Macedonia",
    "Dojran, North Macedonia",
    "Dolneni, North Macedonia",
    "Gazi Baba, North Macedonia",
    "Gevgelija, North Macedonia",
    "Gjorče Petrov, North Macedonia",
    "Gostivar, North Macedonia",
    "Gradsko, North Macedonia",
    "Ilinden, North Macedonia",
    "Jegunovce, North Macedonia",
    "Karbinci, North Macedonia",
    "Karpoš, North Macedonia",
    "Kavadarci, North Macedonia",
    "Kičevo, North Macedonia",
    "Kisela Voda, North Macedonia",
    "Kočani, North Macedonia",
    "Konče, North Macedonia",
    "Kratovo, North Macedonia",
    "Kriva Palanka, North Macedonia",
    "Krivogaštani, North Macedonia",
    "Kruševo, North Macedonia",
    "Kumanovo, North Macedonia",
    "Lipkovo, North Macedonia",
    "Lozovo, North Macedonia",
    "Makedonska Kamenica, North Macedonia",
    "Makedonski Brod, North Macedonia",
    "Mavrovo i Rostuše, North Macedonia",
    "Mogila, North Macedonia",
    "Negotino, North Macedonia",
    "Novaci, North Macedonia",
    "Novo Selo, North Macedonia",
    "Ohrid, North Macedonia",
    "Pehčevo, North Macedonia",
    "Petrovec, North Macedonia",
    "Plasnica, North Macedonia",
    "Prilep, North Macedonia",
    "Probištip, North Macedonia",
    "Radoviš, North Macedonia",
    "Rankovce, North Macedonia",
    "Resen, North Macedonia",
    "Rosoman, North Macedonia",
    "Saraj, North Macedonia",
    "Sopište, North Macedonia",
    "Staro Nagoričane, North Macedonia",
    "Struga, North Macedonia",
    "Strumica, North Macedonia",
    "Studeničani, North Macedonia",
    "Sveti Nikole, North Macedonia",
    "Štip, North Macedonia",
    "Šuto Orizari, North Macedonia",
    "Tearce, North Macedonia",
    "Tetovo, North Macedonia",
    "Valandovo, North Macedonia",
    "Vasilevo, North Macedonia",
    "Veles, North Macedonia",
    "Vevčani, North Macedonia",
    "Vinica, North Macedonia",
    "Vrapčište, North Macedonia",
    "Zelenikovo, North Macedonia",
    "Zrnovci, North Macedonia",
    "Želino, North Macedonia",
    "Agder, Norway",
    "Innlandet, Norway",
    "Møre og Romsdal, Norway",
    "Nordland, Norway",
    "Oslo, Norway",
    "Rogaland, Norway",
    "Troms og Finnmark / Romsa ja Finnmárku (se) / Tromssan ja Finmarkun (-), Norway",
    "Trøndelag / Trööndelage (-), Norway",
    "Vestfold og Telemark, Norway",
    "Vestland, Norway",
    "Viken, Norway",
    "Jan Mayen, Norway",
    "Svalbard, Norway",
    "Ad Dākhilīyah, Oman",
    "Al Buraymī, Oman",
    "Al Wusţá, Oman",
    "Az̧ Z̧āhirah, Oman",
    "Janūb al Bāţinah, Oman",
    "Janūb ash Sharqīyah, Oman",
    "Masqaţ, Oman",
    "Musandam, Oman",
    "Shamāl al Bāţinah, Oman",
    "Shamāl ash Sharqīyah, Oman",
    "Z̧ufār, Oman",
    "Islamabad, Pakistan",
    "Balochistan, Pakistan",
    "Khyber Pakhtunkhwa, Pakistan",
    "Punjab, Pakistan",
    "Sindh, Pakistan",
    "Azad Jammu and Kashmir",
    "Gilgit-Baltistan, Pakistan",
    "Aimeliik, Palau",
    "Airai, Palau",
    "Angaur, Palau",
    "Hatohobei, Palau",
    "Kayangel, Palau",
    "Koror, Palau",
    "Melekeok, Palau",
    "Ngaraard, Palau",
    "Ngarchelong, Palau",
    "Ngardmau, Palau",
    "Ngatpang, Palau",
    "Ngchesar, Palau",
    "Ngeremlengui, Palau",
    "Ngiwal, Palau",
    "Peleliu, Palau",
    "Sonsorol, Palau",
    "Bocas del Toro, Panama",
    "Chiriquí, Panama",
    "Coclé, Panama",
    "Colón, Panama",
    "Darién, Panama",
    "Emberá, Panama",
    "Guna Yala",
    "Herrera, Panama",
    "Los Santos, Panama",
    "Naso Tjër Di, Panama",
    "Ngäbe-Buglé, Panama",
    "Panamá, Panama",
    "Panamá Oeste, Panama",
    "Veraguas, Panama",
    "Bougainville, Papua New Guinea",
    "Central, Papua New Guinea",
    "Chimbu, Papua New Guinea",
    "East New Britain, Papua New Guinea",
    "East Sepik, Papua New Guinea",
    "Eastern Highlands, Papua New Guinea",
    "Enga, Papua New Guinea",
    "Gulf, Papua New Guinea",
    "Hela, Papua New Guinea",
    "Jiwaka, Papua New Guinea",
    "Madang, Papua New Guinea",
    "Manus, Papua New Guinea",
    "Milne Bay, Papua New Guinea",
    "Morobe, Papua New Guinea",
    "National Capital District (Port Moresby), Papua New Guinea",
    "New Ireland, Papua New Guinea",
    "Northern, Papua New Guinea",
    "Southern Highlands, Papua New Guinea",
    "West New Britain, Papua New Guinea",
    "West Sepik, Papua New Guinea",
    "Western, Papua New Guinea",
    "Western Highlands, Papua New Guinea",
    "Alto Paraguay, Paraguay",
    "Alto Paraná, Paraguay",
    "Amambay, Paraguay",
    "Asunción, Paraguay",
    "Boquerón, Paraguay",
    "Caaguazú, Paraguay",
    "Caazapá, Paraguay",
    "Canindeyú, Paraguay",
    "Central, Paraguay",
    "Concepción, Paraguay",
    "Cordillera, Paraguay",
    "Guairá, Paraguay",
    "Itapúa, Paraguay",
    "Misiones, Paraguay",
    "Ñeembucú, Paraguay",
    "Paraguarí, Paraguay",
    "Presidente Hayes, Paraguay",
    "San Pedro, Paraguay",
    "Amazonas, Peru",
    "Ancash, Peru",
    "Apurímac, Peru",
    "Arequipa, Peru",
    "Ayacucho, Peru",
    "Cajamarca, Peru",
    "Cusco (local variant: Cuzco), Peru",
    "El Callao, Peru",
    "Huancavelica, Peru",
    "Huánuco, Peru",
    "Ica, Peru",
    "Junín, Peru",
    "La Libertad, Peru",
    "Lambayeque, Peru",
    "Lima, Peru",
    "Loreto, Peru",
    "Madre de Dios, Peru",
    "Moquegua, Peru",
    "Municipalidad Metropolitana de Lima, Peru",
    "Pasco, Peru",
    "Piura, Peru",
    "Puno, Peru",
    "San Martín, Peru",
    "Tacna, Peru",
    "Tumbes, Peru",
    "Ucayali, Peru",
    "Autonomous Region in Muslim Mindanao[b], Philippines",
    "Bicol, Philippines",
    "Cagayan Valley, Philippines",
    "Calabarzon, Philippines",
    "Caraga, Philippines",
    "Central Luzon, Philippines",
    "Central Visayas, Philippines",
    "Cordillera Administrative Region, Philippines",
    "Davao, Philippines",
    "Eastern Visayas, Philippines",
    "Ilocos, Philippines",
    "Mimaropa, Philippines",
    "National Capital Region, Philippines",
    "Northern Mindanao, Philippines",
    "Soccsksargen, Philippines",
    "Western Visayas, Philippines",
    "Zamboanga Peninsula, Philippines",
    "Dolnośląskie, Poland",
    "Kujawsko-pomorskie, Poland",
    "Lubelskie, Poland",
    "Lubuskie, Poland",
    "Łódzkie, Poland",
    "Małopolskie, Poland",
    "Mazowieckie, Poland",
    "Opolskie, Poland",
    "Podkarpackie, Poland",
    "Podlaskie, Poland",
    "Pomorskie, Poland",
    "Śląskie, Poland",
    "Świętokrzyskie, Poland",
    "Warmińsko-mazurskie, Poland",
    "Wielkopolskie, Poland",
    "Zachodniopomorskie, Poland",
    "Aveiro, Portugal",
    "Beja, Portugal",
    "Braga, Portugal",
    "Bragança, Portugal",
    "Castelo Branco, Portugal",
    "Coimbra, Portugal",
    "Évora, Portugal",
    "Faro, Portugal",
    "Guarda, Portugal",
    "Leiria, Portugal",
    "Lisboa, Portugal",
    "Portalegre, Portugal",
    "Porto, Portugal",
    "Região Autónoma da Madeira, Portugal",
    "Região Autónoma dos Açores, Portugal",
    "Santarém, Portugal",
    "Setúbal, Portugal",
    "Viana do Castelo, Portugal",
    "Vila Real, Portugal",
    "Viseu, Portugal",
    "Ad Dawḩah, Qatar",
    "Al Khawr wa adh Dhakhīrah, Qatar",
    "Al Wakrah, Qatar",
    "Ar Rayyān, Qatar",
    "Ash Shamāl, Qatar",
    "Ash Shīḩānīyah, Qatar",
    "Az̧ Z̧a‘āyin, Qatar",
    "Umm Şalāl, Qatar",
    "Alba, Romania",
    "Arad, Romania",
    "Argeș, Romania",
    "Bacău, Romania",
    "Bihor, Romania",
    "Bistrița-Năsăud, Romania",
    "Botoșani, Romania",
    "Brașov, Romania",
    "Brăila, Romania",
    "București, Romania",
    "Buzău, Romania",
    "Caraș-Severin, Romania",
    "Călărași, Romania",
    "Cluj, Romania",
    "Constanța, Romania",
    "Covasna, Romania",
    "Dâmbovița, Romania",
    "Dolj, Romania",
    "Galați, Romania",
    "Giurgiu, Romania",
    "Gorj, Romania",
    "Harghita, Romania",
    "Hunedoara, Romania",
    "Ialomița, Romania",
    "Iași, Romania",
    "Ilfov, Romania",
    "Maramureș, Romania",
    "Mehedinți, Romania",
    "Mureș, Romania",
    "Neamț, Romania",
    "Olt, Romania",
    "Prahova, Romania",
    "Satu Mare, Romania",
    "Sălaj, Romania",
    "Sibiu, Romania",
    "Suceava, Romania",
    "Teleorman, Romania",
    "Timiș, Romania",
    "Tulcea, Romania",
    "Vaslui, Romania",
    "Vâlcea, Romania",
    "Vrancea, Romania",
    "Adygeya, Respublika, Russian Federation",
    "Altay, Respublika, Russian Federation",
    "Bashkortostan, Respublika, Russian Federation",
    "Buryatiya, Respublika, Russian Federation",
    "Chechenskaya Respublika, Russian Federation",
    "Chuvashskaya Respublika, Russian Federation",
    "Dagestan, Respublika, Russian Federation",
    "Ingushetiya, Respublika, Russian Federation",
    "Kabardino-Balkarskaya Respublika, Russian Federation",
    "Kalmykiya, Respublika, Russian Federation",
    "Karachayevo-Cherkesskaya Respublika, Russian Federation",
    "Kareliya, Respublika, Russian Federation",
    "Khakasiya, Respublika, Russian Federation",
    "Komi, Respublika, Russian Federation",
    "Mariy El, Respublika, Russian Federation",
    "Mordoviya, Respublika, Russian Federation",
    "Saha, Respublika",
    "Severnaya Osetiya, Respublika",
    "Tatarstan, Respublika, Russian Federation",
    "Tyva, Respublika",
    "Udmurtskaya Respublika, Russian Federation",
    "Moskva, Russian Federation",
    "Sankt-Peterburg, Russian Federation",
    "Yevreyskaya avtonomnaya oblast', Russian Federation",
    "Chukotskiy avtonomnyy okrug, Russian Federation",
    "Khanty-Mansiyskiy avtonomnyy okrug",
    "Nenetskiy avtonomnyy okrug, Russian Federation",
    "Yamalo-Nenetskiy avtonomnyy okrug, Russian Federation",
    "City of Kigali, Rwanda",
    "Eastern, Rwanda",
    "Northern, Rwanda",
    "Southern, Rwanda",
    "Western, Rwanda",
    "Saint Kitts, Saint Kitts and Nevis",
    "Nevis, Saint Kitts and Nevis",
    "Anse la Raye, Saint Lucia",
    "Canaries, Saint Lucia",
    "Castries, Saint Lucia",
    "Choiseul, Saint Lucia",
    "Dennery, Saint Lucia",
    "Gros Islet, Saint Lucia",
    "Laborie, Saint Lucia",
    "Micoud, Saint Lucia",
    "Soufrière, Saint Lucia",
    "Vieux Fort, Saint Lucia",
    "Charlotte, Saint Vincent and the Grenadines",
    "Grenadines, Saint Vincent and the Grenadines",
    "Saint Andrew, Saint Vincent and the Grenadines",
    "Saint David, Saint Vincent and the Grenadines",
    "Saint George, Saint Vincent and the Grenadines",
    "Saint Patrick, Saint Vincent and the Grenadines",
    "A'ana, Samoa",
    "Aiga-i-le-Tai, Samoa",
    "Atua, Samoa",
    "Fa'asaleleaga, Samoa",
    "Gaga'emauga, Samoa",
    "Gagaifomauga, Samoa",
    "Palauli, Samoa",
    "Satupa'itea, Samoa",
    "Tuamasaga, Samoa",
    "Va'a-o-Fonoti, Samoa",
    "Vaisigano, Samoa",
    "Acquaviva, San Marino",
    "Borgo Maggiore, San Marino",
    "Chiesanuova, San Marino",
    "Città di San Marino",
    "Domagnano, San Marino",
    "Faetano, San Marino",
    "Fiorentino, San Marino",
    "Montegiardino, San Marino",
    "Serravalle, San Marino",
    "Água Grande, Sao Tome and Principe",
    "Cantagalo, Sao Tome and Principe",
    "Caué, Sao Tome and Principe",
    "Lembá, Sao Tome and Principe",
    "Lobata, Sao Tome and Principe",
    "Mé-Zóchi, Sao Tome and Principe",
    "Príncipe, Sao Tome and Principe",
    "Asīr, Saudi Arabia",
    "Al Bāḩah, Saudi Arabia",
    "Al Ḩudūd ash Shamālīyah, Saudi Arabia",
    "Al Jawf, Saudi Arabia",
    "Al Madīnah al Munawwarah, Saudi Arabia",
    "Al Qaşīm, Saudi Arabia",
    "Ar Riyāḑ, Saudi Arabia",
    "Ash Sharqīyah, Saudi Arabia",
    "Ḩā'il, Saudi Arabia",
    "Jāzān, Saudi Arabia",
    "Makkah al Mukarramah, Saudi Arabia",
    "Najrān, Saudi Arabia",
    "Tabūk, Saudi Arabia",
    "Dakar, Senegal",
    "Diourbel, Senegal",
    "Fatick, Senegal",
    "Kaffrine, Senegal",
    "Kaolack, Senegal",
    "Kédougou, Senegal",
    "Kolda, Senegal",
    "Louga, Senegal",
    "Matam, Senegal",
    "Saint-Louis, Senegal",
    "Sédhiou, Senegal",
    "Tambacounda, Senegal",
    "Thiès, Senegal",
    "Ziguinchor, Senegal",
    "Kosovo-Metohija, Serbia",
    "Vojvodina, Serbia",
    "Anse aux Pins, Seychelles",
    "Anse Boileau, Seychelles",
    "Anse Etoile, Seychelles",
    "Anse Royale, Seychelles",
    "Au Cap, Seychelles",
    "Baie Lazare, Seychelles",
    "Baie Sainte Anne, Seychelles",
    "Beau Vallon, Seychelles",
    "Bel Air, Seychelles",
    "Bel Ombre, Seychelles",
    "Cascade, Seychelles",
    "English River, Seychelles",
    "Glacis, Seychelles",
    "Grand Anse Mahe, Seychelles",
    "Grand Anse Praslin, Seychelles",
    "Ile Perseverance I, Seychelles",
    "Ile Perseverance II, Seychelles",
    "La Digue, Seychelles",
    "Les Mamelles, Seychelles",
    "Mont Buxton, Seychelles",
    "Mont Fleuri, Seychelles",
    "Plaisance, Seychelles",
    "Pointe Larue, Seychelles",
    "Port Glaud, Seychelles",
    "Roche Caiman, Seychelles",
    "Saint Louis, Seychelles",
    "Takamaka, Seychelles",
    "Eastern, Sierra Leone",
    "North Western, Sierra Leone",
    "Northern, Sierra Leone",
    "Southern, Sierra Leone",
    "Western Area (Freetown), Sierra Leone",
    "Central Singapore, Singapore",
    "North East, Singapore",
    "North West, Singapore",
    "South East, Singapore",
    "South West, Singapore",
    "Banskobystrický kraj, Slovakia",
    "Bratislavský kraj, Slovakia",
    "Košický kraj, Slovakia",
    "Nitriansky kraj, Slovakia",
    "Prešovský kraj, Slovakia",
    "Trenčiansky kraj, Slovakia",
    "Trnavský kraj, Slovakia",
    "Žilinský kraj, Slovakia",
    "Ajdovščina, Slovenia",
    "Ankaran, Slovenia",
    "Apače, Slovenia",
    "Beltinci, Slovenia",
    "Benedikt, Slovenia",
    "Bistrica ob Sotli, Slovenia",
    "Bled, Slovenia",
    "Bloke, Slovenia",
    "Bohinj, Slovenia",
    "Borovnica, Slovenia",
    "Bovec, Slovenia",
    "Braslovče, Slovenia",
    "Brda, Slovenia",
    "Brezovica, Slovenia",
    "Brežice, Slovenia",
    "Cankova, Slovenia",
    "Celje, Slovenia",
    "Cerklje na Gorenjskem, Slovenia",
    "Cerknica, Slovenia",
    "Cerkno, Slovenia",
    "Cerkvenjak, Slovenia",
    "Cirkulane, Slovenia",
    "Črenšovci, Slovenia",
    "Črna na Koroškem, Slovenia",
    "Črnomelj, Slovenia",
    "Destrnik, Slovenia",
    "Divača, Slovenia",
    "Dobje, Slovenia",
    "Dobrepolje, Slovenia",
    "Dobrna, Slovenia",
    "Dobrova-Polhov Gradec, Slovenia",
    "Dobrovnik, Slovenia",
    "Dol pri Ljubljani, Slovenia",
    "Dolenjske Toplice, Slovenia",
    "Domžale, Slovenia",
    "Dornava, Slovenia",
    "Dravograd, Slovenia",
    "Duplek, Slovenia",
    "Gorenja vas-Poljane, Slovenia",
    "Gorišnica, Slovenia",
    "Gorje, Slovenia",
    "Gornja Radgona, Slovenia",
    "Gornji Grad, Slovenia",
    "Gornji Petrovci, Slovenia",
    "Grad, Slovenia",
    "Grosuplje, Slovenia",
    "Hajdina, Slovenia",
    "Hoče-Slivnica, Slovenia",
    "Hodoš, Slovenia",
    "Horjul, Slovenia",
    "Hrastnik, Slovenia",
    "Hrpelje-Kozina, Slovenia",
    "Idrija, Slovenia",
    "Ig, Slovenia",
    "Ilirska Bistrica, Slovenia",
    "Ivančna Gorica, Slovenia",
    "Izola, Slovenia",
    "Jesenice, Slovenia",
    "Jezersko, Slovenia",
    "Juršinci, Slovenia",
    "Kamnik, Slovenia",
    "Kanal ob Soči, Slovenia",
    "Kidričevo, Slovenia",
    "Kobarid, Slovenia",
    "Kobilje, Slovenia",
    "Kočevje, Slovenia",
    "Komen, Slovenia",
    "Komenda, Slovenia",
    "Koper, Slovenia",
    "Kostanjevica na Krki, Slovenia",
    "Kostel, Slovenia",
    "Kozje, Slovenia",
    "Kranj, Slovenia",
    "Kranjska Gora, Slovenia",
    "Križevci, Slovenia",
    "Krško, Slovenia",
    "Kungota, Slovenia",
    "Kuzma, Slovenia",
    "Laško, Slovenia",
    "Lenart, Slovenia",
    "Lendava, Slovenia",
    "Litija, Slovenia",
    "Ljubljana, Slovenia",
    "Ljubno, Slovenia",
    "Ljutomer, Slovenia",
    "Log-Dragomer, Slovenia",
    "Logatec, Slovenia",
    "Loška dolina, Slovenia",
    "Loški Potok, Slovenia",
    "Lovrenc na Pohorju, Slovenia",
    "Luče, Slovenia",
    "Lukovica, Slovenia",
    "Majšperk, Slovenia",
    "Makole, Slovenia",
    "Maribor, Slovenia",
    "Markovci, Slovenia",
    "Medvode, Slovenia",
    "Mengeš, Slovenia",
    "Metlika, Slovenia",
    "Mežica, Slovenia",
    "Miklavž na Dravskem polju, Slovenia",
    "Miren-Kostanjevica, Slovenia",
    "Mirna, Slovenia",
    "Mirna Peč, Slovenia",
    "Mislinja, Slovenia",
    "Mokronog-Trebelno, Slovenia",
    "Moravče, Slovenia",
    "Moravske Toplice, Slovenia",
    "Mozirje, Slovenia",
    "Murska Sobota, Slovenia",
    "Muta, Slovenia",
    "Naklo, Slovenia",
    "Nazarje, Slovenia",
    "Nova Gorica, Slovenia",
    "Novo Mesto, Slovenia",
    "Odranci, Slovenia",
    "Oplotnica, Slovenia",
    "Ormož, Slovenia",
    "Osilnica, Slovenia",
    "Pesnica, Slovenia",
    "Piran, Slovenia",
    "Pivka, Slovenia",
    "Podčetrtek, Slovenia",
    "Podlehnik, Slovenia",
    "Podvelka, Slovenia",
    "Poljčane, Slovenia",
    "Polzela, Slovenia",
    "Postojna, Slovenia",
    "Prebold, Slovenia",
    "Preddvor, Slovenia",
    "Prevalje, Slovenia",
    "Ptuj, Slovenia",
    "Puconci, Slovenia",
    "Rače-Fram, Slovenia",
    "Radeče, Slovenia",
    "Radenci, Slovenia",
    "Radlje ob Dravi, Slovenia",
    "Radovljica, Slovenia",
    "Ravne na Koroškem, Slovenia",
    "Razkrižje, Slovenia",
    "Rečica ob Savinji, Slovenia",
    "Renče-Vogrsko, Slovenia",
    "Ribnica, Slovenia",
    "Ribnica na Pohorju, Slovenia",
    "Rogaška Slatina, Slovenia",
    "Rogašovci, Slovenia",
    "Rogatec, Slovenia",
    "Ruše, Slovenia",
    "Selnica ob Dravi, Slovenia",
    "Semič, Slovenia",
    "Sevnica, Slovenia",
    "Sežana, Slovenia",
    "Slovenj Gradec, Slovenia",
    "Slovenska Bistrica, Slovenia",
    "Slovenske Konjice, Slovenia",
    "Sodražica, Slovenia",
    "Solčava, Slovenia",
    "Središče ob Dravi, Slovenia",
    "Starše, Slovenia",
    "Straža, Slovenia",
    "Sveta Ana, Slovenia",
    "Sveta Trojica v Slovenskih goricah, Slovenia",
    "Sveti Andraž v Slovenskih goricah, Slovenia",
    "Sveti Jurij ob Ščavnici, Slovenia",
    "Sveti Jurij v Slovenskih goricah, Slovenia",
    "Sveti Tomaž, Slovenia",
    "Šalovci, Slovenia",
    "Šempeter-Vrtojba, Slovenia",
    "Šenčur, Slovenia",
    "Šentilj, Slovenia",
    "Šentjernej, Slovenia",
    "Šentjur, Slovenia",
    "Šentrupert, Slovenia",
    "Škocjan, Slovenia",
    "Škofja Loka, Slovenia",
    "Škofljica, Slovenia",
    "Šmarje pri Jelšah, Slovenia",
    "Šmarješke Toplice, Slovenia",
    "Šmartno ob Paki, Slovenia",
    "Šmartno pri Litiji, Slovenia",
    "Šoštanj, Slovenia",
    "Štore, Slovenia",
    "Tabor, Slovenia",
    "Tišina, Slovenia",
    "Tolmin, Slovenia",
    "Trbovlje, Slovenia",
    "Trebnje, Slovenia",
    "Trnovska Vas, Slovenia",
    "Trzin, Slovenia",
    "Tržič, Slovenia",
    "Turnišče, Slovenia",
    "Velenje, Slovenia",
    "Velika Polana, Slovenia",
    "Velike Lašče, Slovenia",
    "Veržej, Slovenia",
    "Videm, Slovenia",
    "Vipava, Slovenia",
    "Vitanje, Slovenia",
    "Vodice, Slovenia",
    "Vojnik, Slovenia",
    "Vransko, Slovenia",
    "Vrhnika, Slovenia",
    "Vuzenica, Slovenia",
    "Zagorje ob Savi, Slovenia",
    "Zavrč, Slovenia",
    "Zreče, Slovenia",
    "Žalec, Slovenia",
    "Železniki, Slovenia",
    "Žetale, Slovenia",
    "Žiri, Slovenia",
    "Žirovnica, Slovenia",
    "Žužemberk, Slovenia",
    "Capital Territory (Honiara), Solomon Islands",
    "Central, Solomon Islands",
    "Choiseul, Solomon Islands",
    "Guadalcanal, Solomon Islands",
    "Isabel, Solomon Islands",
    "Makira-Ulawa, Solomon Islands",
    "Malaita, Solomon Islands",
    "Rennell and Bellona, Solomon Islands",
    "Temotu, Solomon Islands",
    "Western, Solomon Islands",
    "Awdal, Somalia",
    "Bakool, Somalia",
    "Banaadir, Somalia",
    "Bari, Somalia",
    "Bay, Somalia",
    "Galguduud, Somalia",
    "Gedo, Somalia",
    "Hiiraan, Somalia",
    "Jubbada Dhexe, Somalia",
    "Jubbada Hoose, Somalia",
    "Mudug, Somalia",
    "Nugaal, Somalia",
    "Sanaag, Somalia",
    "Shabeellaha Dhexe, Somalia",
    "Shabeellaha Hoose, Somalia",
    "Sool, Somalia",
    "Togdheer, Somalia",
    "Woqooyi Galbeed, Somalia",
    "Eastern Cape, South Africa",
    "Free State, South Africa",
    "Gauteng, South Africa",
    "Kwazulu-Natal, South Africa",
    "Limpopo, South Africa",
    "Mpumalanga, South Africa",
    "North-West, South Africa",
    "Northern Cape, South Africa",
    "Western Cape, South Africa",
    "Kapa-Vuxa, South Africa",
    "Free State, South Africa",
    "Gauteng, South Africa",
    "Kwazulu-Natal, South Africa",
    "Limpopo, South Africa",
    "Mpumalanga, South Africa",
    "N'walungu-Vupeladyambu, South Africa",
    "Kapa-N'walungu, South Africa",
    "Kapa-Vupeladyambu, South Africa",
    "Central Equatoria, South Sudan",
    "Eastern Equatoria, South Sudan",
    "Jonglei, South Sudan",
    "Lakes, South Sudan",
    "Northern Bahr el Ghazal, South Sudan",
    "Unity, South Sudan",
    "Upper Nile, South Sudan",
    "Warrap, South Sudan",
    "Western Bahr el Ghazal, South Sudan",
    "Western Equatoria, South Sudan",
    "Andalucía, Spain",
    "Aragón, Spain",
    "Asturias, Principado de, Spain",
    "Canarias, Spain",
    "Cantabria, Spain",
    "Castilla y León, Spain",
    "Castilla-La Mancha, Spain",
    "Catalunya (ca) [Cataluña], Spain",
    "Ceuta, Spain",
    "Extremadura, Spain",
    "Galicia (gl) [Galicia], Spain",
    "Illes Balears (ca) [Islas Baleares], Spain",
    "La Rioja, Spain",
    "Madrid, Comunidad de, Spain",
    "Melilla, Spain",
    "Murcia, Región de, Spain",
    "Navarra, Comunidad Foral de",
    "País Vasco",
    "Valenciana, Comunidad",
    "Western Province, Sri Lanka",
    "Central Province, Sri Lanka",
    "Southern Province, Sri Lanka",
    "Northern Province, Sri Lanka",
    "Eastern Province, Sri Lanka",
    "North Western Province, Sri Lanka",
    "North Central Province, Sri Lanka",
    "Uva Province, Sri Lanka",
    "Sabaragamuwa Province, Sri Lanka",
    "Al Baḩr al Aḩmar, Sudan",
    "Al Jazīrah, Sudan",
    "Al Kharţūm, Sudan",
    "Al Qaḑārif, Sudan",
    "An Nīl al Abyaḑ, Sudan",
    "An Nīl al Azraq, Sudan",
    "Ash Shamālīyah, Sudan",
    "Gharb Dārfūr, Sudan",
    "Gharb Kurdufān, Sudan",
    "Janūb Dārfūr, Sudan",
    "Janūb Kurdufān, Sudan",
    "Kassalā, Sudan",
    "Nahr an Nīl, Sudan",
    "Shamāl Dārfūr, Sudan",
    "Shamāl Kurdufān, Sudan",
    "Sharq Dārfūr, Sudan",
    "Sinnār, Sudan",
    "Wasaţ Dārfūr",
    "Brokopondo, Suriname",
    "Commewijne, Suriname",
    "Coronie, Suriname",
    "Marowijne, Suriname",
    "Nickerie, Suriname",
    "Para, Suriname",
    "Paramaribo, Suriname",
    "Saramacca, Suriname",
    "Sipaliwini, Suriname",
    "Wanica, Suriname",
    "Blekinge län, Sweden",
    "Dalarnas län, Sweden",
    "Gotlands län, Sweden",
    "Gävleborgs län, Sweden",
    "Hallands län, Sweden",
    "Jämtlands län, Sweden",
    "Jönköpings län, Sweden",
    "Kalmar län, Sweden",
    "Kronobergs län, Sweden",
    "Norrbottens län, Sweden",
    "Skåne län, Sweden",
    "Stockholms län, Sweden",
    "Södermanlands län, Sweden",
    "Uppsala län, Sweden",
    "Värmlands län, Sweden",
    "Västerbottens län, Sweden",
    "Västernorrlands län, Sweden",
    "Västmanlands län, Sweden",
    "Västra Götalands län, Sweden",
    "Örebro län, Sweden",
    "Östergötlands län, Sweden",
    "Aargau (de), Switzerland",
    "Appenzell Ausserrhoden (de), Switzerland",
    "Appenzell Innerrhoden (de), Switzerland",
    "Basel-Landschaft (de), Switzerland",
    "Basel-Stadt (de), Switzerland",
    "Bern (de), Berne (fr), Switzerland",
    "Fribourg (fr), Freiburg (de), Switzerland",
    "Genève (fr), Switzerland",
    "Glarus (de), Switzerland",
    "Graubünden (de), Grigioni (it), Grischun (rm), Switzerland",
    "Jura (fr), Switzerland",
    "Luzern (de), Switzerland",
    "Neuchâtel (fr), Switzerland",
    "Nidwalden (de), Switzerland",
    "Obwalden (de), Switzerland",
    "Sankt Gallen (de), Switzerland",
    "Schaffhausen (de), Switzerland",
    "Schwyz (de), Switzerland",
    "Solothurn (de), Switzerland",
    "Thurgau (de), Switzerland",
    "Ticino (it), Switzerland",
    "Uri (de), Switzerland",
    "Valais (fr), Wallis (de), Switzerland",
    "Vaud (fr), Switzerland",
    "Zug (de), Switzerland",
    "Zürich (de), Switzerland",
    "Al Ḩasakah, Syrian Arab Republic",
    "Al Lādhiqīyah, Syrian Arab Republic",
    "Al Qunayţirah, Syrian Arab Republic",
    "Ar Raqqah, Syrian Arab Republic",
    "As Suwaydā', Syrian Arab Republic",
    "Dar'ā, Syrian Arab Republic",
    "Dayr az Zawr, Syrian Arab Republic",
    "Dimashq, Syrian Arab Republic",
    "Ḩalab, Syrian Arab Republic",
    "Ḩamāh, Syrian Arab Republic",
    "Ḩimş, Syrian Arab Republic",
    "Idlib, Syrian Arab Republic",
    "Rīf Dimashq, Syrian Arab Republic",
    "Ţarţūs, Syrian Arab Republic",
    "Dushanbe, Tajikistan",
    "Khatlon, Tajikistan",
    "Kŭhistoni Badakhshon, Tajikistan",
    "nohiyahoi tobei jumhurí, Tajikistan",
    "Sughd, Tajikistan",
    "Arusha, Tanzania",
    "Dar es Salaam, Tanzania",
    "Dodoma, Tanzania",
    "Geita, Tanzania",
    "Iringa, Tanzania",
    "Kagera, Tanzania",
    "Kaskazini Pemba, Tanzania",
    "Kaskazini Unguja, Tanzania",
    "Katavi, Tanzania",
    "Kigoma, Tanzania",
    "Kilimanjaro, Tanzania",
    "Kusini Pemba, Tanzania",
    "Kusini Unguja, Tanzania",
    "Lindi, Tanzania",
    "Manyara, Tanzania",
    "Mara, Tanzania",
    "Mbeya, Tanzania",
    "Mjini Magharibi, Tanzania",
    "Morogoro, Tanzania",
    "Mtwara, Tanzania",
    "Mwanza, Tanzania",
    "Njombe, Tanzania",
    "Pwani, Tanzania",
    "Rukwa, Tanzania",
    "Ruvuma, Tanzania",
    "Shinyanga, Tanzania",
    "Simiyu, Tanzania",
    "Singida, Tanzania",
    "Songwe, Tanzania",
    "Tabora, Tanzania",
    "Tanga, Tanzania",
    "Amnat Charoen, Thailand",
    "Ang Thong, Thailand",
    "Bueng Kan, Thailand",
    "Buri Ram, Thailand",
    "Chachoengsao, Thailand",
    "Chai Nat, Thailand",
    "Chaiyaphum, Thailand",
    "Chanthaburi, Thailand",
    "Chiang Mai, Thailand",
    "Chiang Rai, Thailand",
    "Chon Buri, Thailand",
    "Chumphon, Thailand",
    "Kalasin, Thailand",
    "Kamphaeng Phet, Thailand",
    "Kanchanaburi, Thailand",
    "Khon Kaen, Thailand",
    "Krabi, Thailand",
    "Krung Thep Maha Nakhon",
    "Lampang, Thailand",
    "Lamphun, Thailand",
    "Loei, Thailand",
    "Lop Buri, Thailand",
    "Mae Hong Son, Thailand",
    "Maha Sarakham, Thailand",
    "Mukdahan, Thailand",
    "Nakhon Nayok, Thailand",
    "Nakhon Pathom, Thailand",
    "Nakhon Phanom, Thailand",
    "Nakhon Ratchasima, Thailand",
    "Nakhon Sawan, Thailand",
    "Nakhon Si Thammarat, Thailand",
    "Nan, Thailand",
    "Narathiwat, Thailand",
    "Nong Bua Lam Phu, Thailand",
    "Nong Khai, Thailand",
    "Nonthaburi, Thailand",
    "Pathum Thani, Thailand",
    "Pattani, Thailand",
    "Phangnga, Thailand",
    "Phatthalung, Thailand",
    "Phatthaya, Thailand",
    "Phayao, Thailand",
    "Phetchabun, Thailand",
    "Phetchaburi, Thailand",
    "Phichit, Thailand",
    "Phitsanulok, Thailand",
    "Phra Nakhon Si Ayutthaya, Thailand",
    "Phrae, Thailand",
    "Phuket, Thailand",
    "Prachin Buri, Thailand",
    "Prachuap Khiri Khan, Thailand",
    "Ranong, Thailand",
    "Ratchaburi, Thailand",
    "Rayong, Thailand",
    "Roi Et, Thailand",
    "Sa Kaeo, Thailand",
    "Sakon Nakhon, Thailand",
    "Samut Prakan, Thailand",
    "Samut Sakhon, Thailand",
    "Samut Songkhram, Thailand",
    "Saraburi, Thailand",
    "Satun, Thailand",
    "Si Sa Ket, Thailand",
    "Sing Buri, Thailand",
    "Songkhla, Thailand",
    "Sukhothai, Thailand",
    "Suphan Buri, Thailand",
    "Surat Thani, Thailand",
    "Surin, Thailand",
    "Tak, Thailand",
    "Trang, Thailand",
    "Trat, Thailand",
    "Ubon Ratchathani, Thailand",
    "Udon Thani, Thailand",
    "Uthai Thani, Thailand",
    "Uttaradit, Thailand",
    "Yala, Thailand",
    "Yasothon, Thailand",
    "Aileu, Timor-Leste",
    "Ainaro, Timor-Leste",
    "Baucau, Timor-Leste",
    "Bobonaro, Timor-Leste",
    "Cova Lima, Timor-Leste",
    "Díli, Timor-Leste",
    "Ermera, Timor-Leste",
    "Lautém, Timor-Leste",
    "Liquiça, Timor-Leste",
    "Manatuto, Timor-Leste",
    "Manufahi, Timor-Leste",
    "Oé-Cusse Ambeno",
    "Viqueque, Timor-Leste",
    "Centrale, Togo",
    "Kara, Togo",
    "Maritime (Région), Togo",
    "Plateaux, Togo",
    "Savanes, Togo",
    "Eua, Tonga",
    "Ha'apai, Tonga",
    "Niuas, Tonga",
    "Tongatapu, Tonga",
    "Vava'u, Tonga",
    "Arima, Trinidad and Tobago",
    "Chaguanas, Trinidad and Tobago",
    "Couva-Tabaquite-Talparo, Trinidad and Tobago",
    "Diego Martin, Trinidad and Tobago",
    "Mayaro-Rio Claro, Trinidad and Tobago",
    "Penal-Debe, Trinidad and Tobago",
    "Port of Spain, Trinidad and Tobago",
    "Princes Town, Trinidad and Tobago",
    "Point Fortin, Trinidad and Tobago",
    "San Fernando, Trinidad and Tobago",
    "Sangre Grande, Trinidad and Tobago",
    "Siparia, Trinidad and Tobago",
    "San Juan-Laventille, Trinidad and Tobago",
    "Tobago, Trinidad and Tobago",
    "Tunapuna-Piarco, Trinidad and Tobago",
    "Béja, Tunisia",
    "Ben Arous, Tunisia",
    "Bizerte, Tunisia",
    "Gabès, Tunisia",
    "Gafsa, Tunisia",
    "Jendouba, Tunisia",
    "Kairouan, Tunisia",
    "Kasserine, Tunisia",
    "Kébili, Tunisia",
    "L'Ariana, Tunisia",
    "La Manouba, Tunisia",
    "Le Kef, Tunisia",
    "Mahdia, Tunisia",
    "Médenine, Tunisia",
    "Monastir, Tunisia",
    "Nabeul, Tunisia",
    "Sfax, Tunisia",
    "Sidi Bouzid, Tunisia",
    "Siliana, Tunisia",
    "Sousse, Tunisia",
    "Tataouine, Tunisia",
    "Tozeur, Tunisia",
    "Tunis, Tunisia",
    "Zaghouan, Tunisia",
    "Adana, Türkiye",
    "Adıyaman, Türkiye",
    "Afyonkarahisar, Türkiye",
    "Ağrı, Türkiye",
    "Aksaray, Türkiye",
    "Amasya, Türkiye",
    "Ankara, Türkiye",
    "Antalya, Türkiye",
    "Ardahan, Türkiye",
    "Artvin, Türkiye",
    "Aydın, Türkiye",
    "Balıkesir, Türkiye",
    "Bartın, Türkiye",
    "Batman, Türkiye",
    "Bayburt, Türkiye",
    "Bilecik, Türkiye",
    "Bingöl, Türkiye",
    "Bitlis, Türkiye",
    "Bolu, Türkiye",
    "Burdur, Türkiye",
    "Bursa, Türkiye",
    "Çanakkale, Türkiye",
    "Çankırı, Türkiye",
    "Çorum, Türkiye",
    "Denizli, Türkiye",
    "Diyarbakır, Türkiye",
    "Düzce, Türkiye",
    "Edirne, Türkiye",
    "Elazığ, Türkiye",
    "Erzincan, Türkiye",
    "Erzurum, Türkiye",
    "Eskişehir, Türkiye",
    "Gaziantep, Türkiye",
    "Giresun, Türkiye",
    "Gümüşhane, Türkiye",
    "Hakkâri, Türkiye",
    "Hatay, Türkiye",
    "Iğdır, Türkiye",
    "Isparta, Türkiye",
    "İstanbul, Türkiye",
    "İzmir, Türkiye",
    "Kahramanmaraş, Türkiye",
    "Karabük, Türkiye",
    "Karaman, Türkiye",
    "Kars, Türkiye",
    "Kastamonu, Türkiye",
    "Kayseri, Türkiye",
    "Kırıkkale, Türkiye",
    "Kırklareli, Türkiye",
    "Kırşehir, Türkiye",
    "Kilis, Türkiye",
    "Kocaeli, Türkiye",
    "Konya, Türkiye",
    "Kütahya, Türkiye",
    "Malatya, Türkiye",
    "Manisa, Türkiye",
    "Mardin, Türkiye",
    "Mersin, Türkiye",
    "Muğla, Türkiye",
    "Muş, Türkiye",
    "Nevşehir, Türkiye",
    "Niğde, Türkiye",
    "Ordu, Türkiye",
    "Osmaniye, Türkiye",
    "Rize, Türkiye",
    "Sakarya, Türkiye",
    "Samsun, Türkiye",
    "Siirt, Türkiye",
    "Sinop, Türkiye",
    "Sivas, Türkiye",
    "Şanlıurfa, Türkiye",
    "Şırnak, Türkiye",
    "Tekirdağ, Türkiye",
    "Tokat, Türkiye",
    "Trabzon, Türkiye",
    "Tunceli, Türkiye",
    "Uşak, Türkiye",
    "Van, Türkiye",
    "Yalova, Türkiye",
    "Yozgat, Türkiye",
    "Zonguldak, Türkiye",
    "Ahal, Turkmenistan",
    "Aşgabat, Turkmenistan",
    "Balkan, Turkmenistan",
    "Daşoguz, Turkmenistan",
    "Lebap, Turkmenistan",
    "Mary, Turkmenistan",
    "Funafuti, Tuvalu",
    "Nanumaga, Tuvalu",
    "Nanumea, Tuvalu",
    "Niutao, Tuvalu",
    "Nui, Tuvalu",
    "Nukufetau, Tuvalu",
    "Nukulaelae, Tuvalu",
    "Vaitupu, Tuvalu",
    "Central, Uganda",
    "Eastern, Uganda",
    "Northern, Uganda",
    "Western, Uganda",
    "Cherkaska oblast, Ukraine",
    "Chernihivska oblast, Ukraine",
    "Chernivetska oblast, Ukraine",
    "Dnipropetrovska oblast, Ukraine",
    "Donetska oblast, Ukraine",
    "Ivano-Frankivska oblast, Ukraine",
    "Kharkivska oblast, Ukraine",
    "Khersonska oblast, Ukraine",
    "Khmelnytska oblast, Ukraine",
    "Kirovohradska oblast, Ukraine",
    "Kyivska oblast, Ukraine",
    "Luhanska oblast, Ukraine",
    "Lvivska oblast, Ukraine",
    "Mykolaivska oblast, Ukraine",
    "Odeska oblast, Ukraine",
    "Poltavska oblast, Ukraine",
    "Rivnenska oblast, Ukraine",
    "Sumska oblast, Ukraine",
    "Ternopilska oblast, Ukraine",
    "Vinnytska oblast, Ukraine",
    "Volynska oblast, Ukraine",
    "Zakarpatska oblast, Ukraine",
    "Zaporizka oblast, Ukraine",
    "Zhytomyrska oblast, Ukraine",
    "Avtonomna Respublika Krym, Ukraine",
    "Kyiv, Ukraine",
    "Sevastopol, Ukraine",
    "‘Ajmān, United Arab Emirates",
    "Abū Z̧aby, United Arab Emirates",
    "Al Fujayrah, United Arab Emirates",
    "Ash Shāriqah, United Arab Emirates",
    "Dubayy, United Arab Emirates",
    "Ra’s al Khaymah, United Arab Emirates",
    "Umm al Qaywayn, United Arab Emirates",
    "England, United Kingdom ",
    "Northern Ireland, United Kingdom ",
    "Scotland, United Kingdom ",
    "Wales [Cymru GB-CYM], United Kingdom ",
    "Alabama, United States of America",
    "Alaska, United States of America",
    "Arizona, United States of America",
    "Arkansas, United States of America",
    "California, United States of America",
    "Colorado, United States of America",
    "Connecticut, United States of America",
    "Delaware, United States of America",
    "Florida, United States of America",
    "Georgia, United States of America",
    "Hawaii, United States of America",
    "Idaho, United States of America",
    "Illinois, United States of America",
    "Indiana, United States of America",
    "Iowa, United States of America",
    "Kansas, United States of America",
    "Kentucky, United States of America",
    "Louisiana, United States of America",
    "Maine, United States of America",
    "Maryland, United States of America",
    "Massachusetts, United States of America",
    "Michigan, United States of America",
    "Minnesota, United States of America",
    "Mississippi, United States of America",
    "Missouri, United States of America",
    "Montana, United States of America",
    "Nebraska, United States of America",
    "Nevada, United States of America",
    "New Hampshire, United States of America",
    "New Jersey, United States of America",
    "New Mexico, United States of America",
    "North Carolina, United States of America",
    "North Dakota, United States of America",
    "Ohio, United States of America",
    "Oklahoma, United States of America",
    "Oregon, United States of America",
    "Pennsylvania, United States of America",
    "Rhode Island, United States of America",
    "South Carolina, United States of America",
    "South Dakota, United States of America",
    "Tennessee, United States of America",
    "Texas, United States of America",
    "Utah, United States of America",
    "Vermont, United States of America",
    "Virginia, United States of America",
    "Washington, United States of America",
    "West Virginia, United States of America",
    "Wisconsin, United States of America",
    "Wyoming, United States of America",
    "District of Columbia, United States of America",
    "American Samoa, United States of America",
    "Guam, United States of America",
    "Northern Mariana Islands, United States of America",
    "Puerto Rico, United States of America",
    "United States Minor Outlying Islands, United States of America",
    "Virgin Islands, U.S., United States of America",
    "Artigas, Uruguay",
    "Canelones, Uruguay",
    "Cerro Largo, Uruguay",
    "Colonia, Uruguay",
    "Durazno, Uruguay",
    "Flores, Uruguay",
    "Florida, Uruguay",
    "Lavalleja, Uruguay",
    "Maldonado, Uruguay",
    "Montevideo, Uruguay",
    "Paysandú, Uruguay",
    "Río Negro, Uruguay",
    "Rivera, Uruguay",
    "Rocha, Uruguay",
    "Salto, Uruguay",
    "San José, Uruguay",
    "Soriano, Uruguay",
    "Tacuarembó, Uruguay",
    "Treinta y Tres, Uruguay",
    "Andijon, Uzbekistan",
    "Buxoro, Uzbekistan",
    "Farg‘ona, Uzbekistan",
    "Jizzax, Uzbekistan",
    "Namangan, Uzbekistan",
    "Navoiy, Uzbekistan",
    "Qashqadaryo, Uzbekistan",
    "Qoraqalpog‘iston Respublikasi, Uzbekistan",
    "Samarqand, Uzbekistan",
    "Sirdaryo, Uzbekistan",
    "Surxondaryo, Uzbekistan",
    "Toshkent, Uzbekistan",
    "Toshkent, Uzbekistan",
    "Xorazm, Uzbekistan",
    "Malampa, Vanuatu",
    "Pénama, Vanuatu",
    "Sanma, Vanuatu",
    "Shéfa, Vanuatu",
    "Taféa, Vanuatu",
    "Torba, Vanuatu",
    "Amazonas, Venezuela ",
    "Anzoátegui, Venezuela ",
    "Apure, Venezuela ",
    "Aragua, Venezuela ",
    "Barinas, Venezuela ",
    "Bolívar, Venezuela ",
    "Carabobo, Venezuela ",
    "Cojedes, Venezuela ",
    "Delta Amacuro, Venezuela ",
    "Dependencias Federales, Venezuela ",
    "Distrito Capital, Venezuela ",
    "Falcón, Venezuela ",
    "Guárico, Venezuela ",
    "La Guaira, Venezuela ",
    "Lara, Venezuela ",
    "Mérida, Venezuela ",
    "Miranda, Venezuela ",
    "Monagas, Venezuela ",
    "Nueva Esparta, Venezuela ",
    "Portuguesa, Venezuela ",
    "Sucre, Venezuela ",
    "Táchira, Venezuela ",
    "Trujillo, Venezuela ",
    "Yaracuy, Venezuela ",
    "Zulia, Venezuela ",
    "An Giang, Viet Nam",
    "Bà Rịa - Vũng Tàu, Viet Nam",
    "Bắc Giang, Viet Nam",
    "Bắc Kạn, Viet Nam",
    "Bạc Liêu, Viet Nam",
    "Bắc Ninh, Viet Nam",
    "Bến Tre, Viet Nam",
    "Bình Định, Viet Nam",
    "Bình Dương, Viet Nam",
    "Bình Phước, Viet Nam",
    "Bình Thuận, Viet Nam",
    "Cà Mau, Viet Nam",
    "Cần Thơ, Viet Nam",
    "Cao Bằng, Viet Nam",
    "Đà Nẵng, Viet Nam",
    "Đắk Lắk, Viet Nam",
    "Đắk Nông, Viet Nam",
    "Điện Biên, Viet Nam",
    "Đồng Nai, Viet Nam",
    "Đồng Tháp, Viet Nam",
    "Gia Lai, Viet Nam",
    "Hà Giang, Viet Nam",
    "Hà Nam, Viet Nam",
    "Hà Nội, Viet Nam",
    "Hà Tĩnh, Viet Nam",
    "Hải Dương, Viet Nam",
    "Hải Phòng, Viet Nam",
    "Hậu Giang, Viet Nam",
    "Hồ Chí Minh",
    "Hòa Bình, Viet Nam",
    "Hưng Yên, Viet Nam",
    "Khánh Hòa, Viet Nam",
    "Kiến Giang, Viet Nam",
    "Kon Tum, Viet Nam",
    "Lai Châu, Viet Nam",
    "Lâm Đồng, Viet Nam",
    "Lạng Sơn, Viet Nam",
    "Lào Cai, Viet Nam",
    "Long An, Viet Nam",
    "Nam Định, Viet Nam",
    "Nghệ An, Viet Nam",
    "Ninh Bình, Viet Nam",
    "Ninh Thuận, Viet Nam",
    "Phú Thọ, Viet Nam",
    "Phú Yên, Viet Nam",
    "Quảng Bình, Viet Nam",
    "Quảng Nam, Viet Nam",
    "Quảng Ngãi, Viet Nam",
    "Quảng Ninh, Viet Nam",
    "Quảng Trị, Viet Nam",
    "Sóc Trăng, Viet Nam",
    "Sơn La, Viet Nam",
    "Tây Ninh, Viet Nam",
    "Thái Bình, Viet Nam",
    "Thái Nguyên, Viet Nam",
    "Thanh Hóa, Viet Nam",
    "Thừa Thiên-Huế, Viet Nam",
    "Tiền Giang, Viet Nam",
    "Trà Vinh, Viet Nam",
    "Tuyên Quang, Viet Nam",
    "Vĩnh Long, Viet Nam",
    "Vĩnh Phúc, Viet Nam",
    "Yên Bái, Viet Nam",
    "‘Adan, Yemen",
    "‘Amrān, Yemen",
    "Abyan, Yemen",
    "Aḑ Ḑāli‘, Yemen",
    "Al Bayḑā’, Yemen",
    "Al Ḩudaydah, Yemen",
    "Al Jawf, Yemen",
    "Al Mahrah, Yemen",
    "Al Maḩwīt, Yemen",
    "Amānat al ‘Āşimah [city], Yemen",
    "Arkhabīl Suquţrá, Yemen",
    "Dhamār, Yemen",
    "Ḩaḑramawt, Yemen",
    "Ḩajjah, Yemen",
    "Ibb, Yemen",
    "Laḩij, Yemen",
    "Ma’rib, Yemen",
    "Raymah, Yemen",
    "Şāʻdah, Yemen",
    "Şanʻā’, Yemen",
    "Shabwah, Yemen",
    "Tāʻizz, Yemen",
    "Central, Zambia",
    "Copperbelt, Zambia",
    "Eastern, Zambia",
    "Luapula, Zambia",
    "Lusaka, Zambia",
    "Muchinga, Zambia",
    "North-Western, Zambia",
    "Northern, Zambia",
    "Southern, Zambia",
    "Western, Zambia",
    "Bulawayo, Zimbabwe",
    "Harare, Zimbabwe",
    "Manicaland, Zimbabwe",
    "Mashonaland Central, Zimbabwe",
    "Mashonaland East, Zimbabwe",
    "Mashonaland West, Zimbabwe",
    "Masvingo, Zimbabwe",
    "Matabeleland North, Zimbabwe",
    "Matabeleland South, Zimbabwe",
    "Midlands, Zimbabwe"
]