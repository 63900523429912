import React, {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Image} from '../../ui-components';
import routePaths from '../../routing/routePaths';

const FeatureWidget = props => {
    const {src, alt, title, description, even, height, width, stepNo} = props;
    const navigate = useNavigate();

    return (
        <Fragment>
            {even ? (
                <div className={`${stepNo === 1 ? 'flex flex-col lg:flex-row items-center mb-spona-44 lg:mb-spona-0' : 'lg:grid grid-cols-12 mb-spona-44'} gap-spona-20 lg:gap-spona-80`}>
                    <div className="col-span-6 self-center flex flex-col items-center">
                        <div className="text-default-100 text-center">
                            <h3 className="text-[32px] md:text-[44px] lg:text-left font-extrabold mb-spona-20 lg:mb-spona-44">
                                {title}
                            </h3>
                            <div
                                className="text-xl md:text-2xl lg:text-left font-medium"
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                            {stepNo === 3 && (
                                <div className="mt-spona-24 lg:text-left font-black text-lg">
                                    <Button buttonType="primary" onClick={() => navigate(routePaths.START_QUESTIONNAIRE_ONE)}>
                                        GET FREE LEADS
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${stepNo === 3 ? 'mt-spona-20 lg:mt-spona-0' : ''} col-span-6 shrink-0`}>
                        <Image src={src} alt={alt} height={height} width={width} isResponsive={true}/>
                    </div>
                </div>
            ) : (
                <div className={`flex flex-col-reverse lg:grid grid-cols-12 mb-spona-44 ${stepNo === 2 ? '' : 'gap-spona-20 lg:gap-spona-80'}`}>
                    <div className="col-span-7 shrink-0">
                        <Image src={src} alt={alt} height={height} width={width} isResponsive={true}/>
                    </div>
                    <div className="col-span-5 self-center lg:mt-spona-64">
                        <h3 className="text-[28px] text-center md:text-[44px] lg:text-left font-extrabold text-default-100 mb-spona-44">
                            {title}
                        </h3>
                        <div
                            className="text-xl text-center md:text-2xl lg:text-left font-medium text-default-100"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default FeatureWidget;