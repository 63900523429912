import React from 'react';
import {HighlightedText} from '../../../ui-components';

const SPONACompatibilityContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                At SPONA, we understand the importance of accessibility and ease of use,
                which is why we've ensured our platform is compatible with a wide range of operating systems.
                However, there are some limitations in terms of device compatibility that you should be aware of.<br/>
                Here’s a detailed look at SPONA’s compatibility:
            </p>
            <div className="ml-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Operating System Compatibility:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Broad OS Support:</span> SPONA is designed to work efficiently across various operating systems including Windows, macOS, and Linux.
                            This broad compatibility ensures that no matter your preferred desktop environment, you can access and utilize SPONA without any hurdles.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Seamless Integration:</span> Our platform’s functionalities are optimized for these operating systems, providing a smooth and efficient user experience.
                        </li>
                    </ul>
                    <li>Device Limitations:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Mobile Device Compatibility:</span> Currently, SPONA is not available as a mobile application and does not support mobile devices.
                            This is to ensure that we deliver the best performance and user experience, which is more feasible on desktop interfaces.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Tablet Usage:</span> SPONA can be accessed on some tablets, offering a degree of flexibility for users who prefer these devices.
                            However, for optimal performance, using SPONA on a desktop computer is recommended.
                        </li>
                    </ul>
                    <li>No Mobile App Availability:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li>
                            <span className="font-bold">Focus on Desktop Experience:</span> Our focus is on delivering a robust and comprehensive desktop experience,
                            harnessing the full capabilities of our platform.
                        </li>
                    </ul>
                </ul>
            </div>
            <HighlightedText className='mt-spona-24'>
                We continuously work to enhance SPONA’s compatibility and accessibility to meet the evolving needs of our users.
                For any updates on device compatibility or to get more information on the best ways to use SPONA, feel free to reach out to our support team.
                Let SPONA be your go-to solution for lead generation, optimized for your preferred operating system.
            </HighlightedText>
        </div>
    )
}

export default SPONACompatibilityContent;