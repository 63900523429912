import React from 'react';
import {HighlightedText} from '../../../ui-components';

const LeadManagementAndNurturingContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                SPONA excels in lead management and nurturing, ensuring that your campaigns effectively connect with and engage your target audience.<br/>
                Our approach is data-driven and user-centric, focusing on both the quality and quantity of leads.<br/>
                Here’s how we manage and nurture leads:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Real-Time Lead Analysis:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Market Matching:</span> SPONA continuously analyzes the market to identify how many leads match your specific Ideal Customer Profile (ICP).
                            This real-time analysis ensures that you're always targeting the most relevant and potential leads.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Profile Optimization:</span> If your search criteria are too narrow, our analysts step in to optimize it.
                            We aim to maintain a minimum of 60 outstanding contacts in the market that match your search criteria, maximizing your outreach opportunities.
                            If not possible, we will close a questionnaire.
                        </li>
                    </ul>
                    <li>Campaign Integration and Recommendations:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">CRM Integration:</span> While SPONA provides robust tools for lead management, we also recommend integrating these leads into your existing Customer Relationship Management (CRM) system.
                            This ensures a seamless flow of information and better tracking of interactions with each lead.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Email Flow Tracking:</span> By uploading leads to your CRM, you can efficiently track the email flow and engagement levels.
                            This integration allows for a more cohesive approach to lead nurturing, enabling personalized follow-ups and relationship building.
                        </li>
                    </ul>
                </ul>
            </div>
            <HighlightedText className='mt-spona-24'>
                SPONA’s lead management and nurturing strategies are designed to align with your business goals,
                ensuring that you not only capture the right leads but also engage them effectively to foster long-term relationships.<br/>
                Our focus on real-time analysis and strategic recommendations empowers your campaigns to perform at their best.
            </HighlightedText>
        </div>
    )
}

export default LeadManagementAndNurturingContent;