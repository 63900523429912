import React, {useEffect, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { verifyRegistrationToken } from './authActions';
import routePaths from '../routing/routePaths';
import config from '../config';

const TokenVerificationScreen = () => {
    const didComponentMountRef = useRef(false);
    const {token} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect( () => {
        if (token && !didComponentMountRef.current) {
            const verifyToken = async () => {
                try {
                    await dispatch(verifyRegistrationToken({token})).unwrap();
                    navigate(routePaths.LOGIN);
                }
                catch (error) {
                    if (error.statusCode === config.STATUS_CODE_404)  {
                       navigate(routePaths.TOKEN_ALREADY_ACTIVATED);
                    }
                }
            }

            verifyToken();
            didComponentMountRef.current = true;
        }
    });

    return (
        <div className="bg-highlighted-100 w-full h-full fixed">
            <div className="z-0 absolute left-[-300px] top-[450px] bg-highlighted-200 opacity-50 rounded-[160px] w-[750px] h-[750px] origin-center rotate-[-135deg]" />
            <div className="z-0 absolute right-[-300px] bottom-[450px] bg-highlighted-200 opacity-50 rounded-[160px] w-[750px] h-[750px] origin-center rotate-[-135deg]" />
        </div>
    )
}

export default TokenVerificationScreen;