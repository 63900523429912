import React from 'react';

const DropdownList = props => {
    const {children, onClick} = props;

    return (
        <ul
            className="divide-y divide-contrast-600 border-b border-contrast-600"
            onClick={event => {
                if (!event.target?.attributes[1]?.value) return;
                onClick(event.target.attributes[1].value);
            }}
        >
            {children}
        </ul>
    );
};

export default DropdownList;