import React from 'react';
import {HighlightedText} from '../../../ui-components';

const IssuesWithIntegrationsContent = () => {
    return (
        <div>
            <p className="text-lg font-medium text-default-100 mb-spona-24">
                At SPONA, we aim to provide a seamless integration experience with third-party applications.
                If you encounter any issues with integrating Quickmail or Mailflow, here are the steps you can follow to quickly resolve them:
            </p>
            <div className="ml-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Automatic Reactivation Links:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Initial Response:</span> If an integration issue is detected, SPONA automatically
                            sends you an email containing a reactivation link for the respective service (Quickmail or Mailflow).
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Follow the Link:</span> Click on the provided link to attempt reactivating the permissions.
                            This is often the quickest way to resolve common integration issues.
                        </li>
                    </ul>
                    <li>Contacting Support:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Alternative Support Channels:</span> If the reactivation link does not resolve the issue,
                            or if you encounter other integration-related problems, you can reach out to our support team for assistance.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Reaching Out via Chatbot:</span> Use the chatbot feature available on our platform for immediate assistance.
                            This is a quick and efficient way to get help.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Email Support:</span> Alternatively, you can email us at <a className="underline decoration-1 underline-offset-2" href="mailto:support@sponasales.com">support@sponasales.com</a>.
                            Our dedicated support team is committed to responding to your queries within 1 business day.
                        </li>
                    </ul>
                    <li>Ensuring Smooth Resolution:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li>
                            <span className="font-bold">Provide Details:</span> When contacting support, it’s helpful to provide specific details about the issue you’re facing.
                            This enables our team to diagnose and address the problem more effectively.
                        </li>
                    </ul>
                </ul>
            </div>
            <HighlightedText className='mt-spona-24'>
                We understand the importance of uninterrupted service and are committed to ensuring that your experience with
                SPONA is smooth and productive. Our support team is well-equipped to handle any challenges you may face
                and is dedicated to providing timely and effective solutions.
                Let us help you keep your lead generation efforts running seamlessly.
            </HighlightedText>
        </div>
    )
}

export default IssuesWithIntegrationsContent;