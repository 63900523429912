import React from 'react';

const PoliciesContent = () => {
    return (
        <div className="text-lg font-medium text-default-100">
            <p className="mb-spona-24">
                We are recognizing the importance of swift and transparent action in such situations and here’s how we handle these critical events:
            </p>
            <div className="ml-spona-24 mb-spona-24">
                <ul className="list-decimal font-bold text-default-100 text-[22px]">
                    <li>Immediate Incident Response:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Rapid Action:</span> Our dedicated security team is trained to respond immediately to any suspected data breach or security incident, ensuring minimal impact.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Investigation and Analysis:</span> We conduct thorough investigations to understand the scope and impact of the incident, utilizing advanced tools and techniques.
                        </li>
                    </ul>
                    <li>Notification Procedures:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Timely Communication:</span> In the event of a data breach, we adhere to strict notification procedures. Affected users are informed promptly, in compliance with GDPR and other relevant regulations.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Detailed Information:</span> Notifications include details about the nature of the breach, the data involved, and the actions taken to address the incident.
                        </li>
                    </ul>
                    <li>Preventive Measures and Continuous Monitoring:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Strengthening Security:</span> Post-incident, we implement additional preventive measures to further strengthen our security and prevent future occurrences.
                        </li>
                        <li className="pb-spona-24">
                            <span className="font-bold">Ongoing Surveillance:</span> Continuous monitoring of our systems ensures early detection and prevention of potential security threats.
                        </li>
                    </ul>
                    <li>Regulatory Compliance:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Adherence to Laws:</span> We comply with all legal requirements pertaining to data breaches, ensuring that our response aligns with regional and global data protection standards.
                        </li>
                    </ul>
                    <li>User Support and Guidance:</li>
                    <ul className="list-disc font-medium text-default-100 text-lg mt-spona-24 ml-spona-24">
                        <li className="pb-spona-24">
                            <span className="font-bold">Assistance for Users:</span> Our support team is available to assist users affected by a breach, offering guidance and support to mitigate any potential risks.
                        </li>
                    </ul>
                </ul>
            </div>
            <p className="text-lg font-medium text-default-100 mt-spona-24">
                Our policies for handling data breaches and security incidents reflect SPONA’s commitment to maintaining the highest standards of data protection and security.
                We are dedicated to upholding user trust by ensuring the integrity and confidentiality of your data at all times.
            </p>
        </div>
    )
}

export default PoliciesContent;