import React from 'react';
import {verifyAccountImage} from '../assets/images';

const VerifyYourAccountPage = () => {
    return (
        <div className="flex flex-col justify-center items-center w-full mt-spona-64">
            <h1 className="text-default-300 font-bold text-[32px] mb-spona-32">Please verify your account</h1>
            <img src={verifyAccountImage} alt="pageNotFoundImage" />
        </div>
    );
}

export default VerifyYourAccountPage;